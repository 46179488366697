import React, { useState } from 'react'
import {Table, TableHead, TableRow, TableCell, makeStyles, TablePagination,Paper} from '@material-ui/core'
import { pageContent } from "../lang/pageContent";
import { useSelector } from "react-redux";


const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '500',
            // color: theme.palette.primary.main,
            // color: theme.palette.text.primary,
            color : "#ffffff",
            // backgroundColor: theme.palette.primary.light,
            backgroundColor: "#0146ab",
            borderBottom: "none"
            // borderRadius: 35,
        },
        '& thead th:last-child ': {
            borderRadius: "0 30px 30px 0px",
        },
        '& thead th:first-child ': {
            borderRadius: "30px 0px 0px 30px",
        },
        '& tbody td': {
            fontWeight: '300',
        },
        // '& tbody tr': {
        //     fontWeight: '300',
        //     borderTop: '1px solid red'
        // },
        '& tbody tr:hover': {
            backgroundColor: '#f2f2f2',
            cursor: 'pointer',
        },
    },
}))


export default function useTable(records, headCells){

    const classes = useStyles();

    const selectedLang = useSelector(
        (reduxState) => reduxState.selectedLanguageReducer
      );

    const pages =  [10, 20,30,40,50]
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pages[page]);

    const TblContainer = props => (
        <Table className={classes.table}>
            {props.children}
        </Table>
    )


    const TblHead = props =>{

        // style={{borderTop: '1px solid red', borderRadius: "35px"}}

        return (<TableHead >
            <TableRow >
                {
                    headCells.map(headCell => (<TableCell key={headCell.id}>
                        {headCell.label}
                    </TableCell>))
                }
            </TableRow>
        </TableHead>)
    }
 
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value,10))
        setPage(0);
    }

    const TblPagination = () =>(<TablePagination
        component = "div"
        page = {page}
        labelRowsPerPage={pageContent[`${selectedLang}`].rowsPerPage}
        labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + pageContent[`${selectedLang}`].tableOf + count
            }
          }
        rowsPerPageOptions = {pages}
        rowsPerPage = {rowsPerPage}
        count = {records ? records.length : 0}
        onChangePage = {handleChangePage}
        onChangeRowsPerPage = {handleChangeRowsPerPage}
    />)

    const recordsAfterPagingAndSorting = () => {
        if(records && records.length >=1){
            return records.slice(page*rowsPerPage, (page+1)*rowsPerPage)

        }
    }

    return {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    }
}