import { GET_LINE_INPUTS } from "../actions/lineActions";

const initialState = {};

export default function selectedLineInputsReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case GET_LINE_INPUTS: {
      return (state = action.payload);
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
