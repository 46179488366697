import request from "superagent";
import {checkJwt} from "../actions/jwtActions";
// export const baseUrl = "http://localhost:4000";
export const GET_PROJECT_LINES = "GET_PROJECT_LINES"
export const GET_SORTED_DATA = "GET_SORTED_DATA"
export const CREATE_LINE = "CREATE_LINE"
export const NEW_LINE = "NEW_LINE"
export const GET_LINE_INPUTS = "GET_LINE_INPUTS"
export const FIELD_ONE_INPUT = "FIELD_ONE_INPUT"
export const FIELD_TWO_INPUT = "FIELD_TWO_INPUT"
export const FIELD_THREE_INPUT = "FIELD_THREE_INPUT"
export const FIELD_FOUR_INPUT = "FIELD_FOUR_INPUT"
export const FIELD_FIVE_INPUT = "FIELD_FIVE_INPUT"
export const FIELD_SIX_INPUT = "FIELD_SIX_INPUT"
export const FIELD_SEVEN_INPUT = "FIELD_SEVEN_INPUT"
export const FIELD_EIGHT_INPUT = "FIELD_EIGHT_INPUT"
export const FIELD_NINE_INPUT = "FIELD_NINE_INPUT"
export const FIELD_TEN_INPUT = "FIELD_TEN_INPUT"
export const FIELD_ELEVEN_INPUT = "FIELD_ELEVEN_INPUT"
export const FIELD_TWELVE_INPUT = "FIELD_TWELVE_INPUT"
export const FIELD_THIRTEEN_INPUT = "FIELD_THIRTEEN_INPUT"
export const FIELD_FOURTEEN_INPUT = "FIELD_FOURTEEN_INPUT"
export const FIELD_FIFTEEN_INPUT = "FIELD_FIFTEEN_INPUT"
export const FIELD_SIXTEEN_INPUT = "FIELD_SIXTEEN_INPUT"
export const FIELD_SEVENTEEN_INPUT = "FIELD_SEVENTEEN_INPUT"
export const FIELD_EIGHTEEN_INPUT = "FIELD_EIGHTEEN_INPUT"
export const FIELD_NINETEEN_INPUT = "FIELD_NINETEEN_INPUT"
export const FIELD_TWENTY_INPUT = "FIELD_TWENTY_INPUT"
export const FIELD_TWENTY_ONE_INPUT = "FIELD_TWENTY_ONE_INPUT"
export const FIELD_TWENTY_TWO_INPUT = "FIELD_TWENTY_TWO_INPUT"
export const FIELD_TWENTY_THREE_INPUT = "FIELD_TWENTY_THREE_INPUT"
export const CLEAR_SECTION_ONE_INPUTS = "CLEAR_SECTION_ONE_INPUTS"
export const CLEAR_SECTION_TWO_INPUTS = "CLEAR_SECTION_TWO_INPUTS"
export const CLEAR_SECTION_THREE_INPUTS = "CLEAR_SECTION_THREE_INPUTS"
export const CLEAR_SECTION_FOUR_INPUTS = "CLEAR_SECTION_FOUR_INPUTS"
export const CLEAR_SECTION_FIVE_INPUTS = "CLEAR_SECTION_FIVE_INPUTS"
export const CLEAR_INPUT_SECTION_REDUCER = "CLEAR_INPUT_SECTION_REDUCER"
export const DALI_SELECTED = "DALI_SELECTED"
export const LINE_STATUS = "LINE_STATUS"

// export const RENAME_LINE_POPUP = "RENAME_LINE_POPUP"
// export const DELETE_LINE_POPUP ="DELETE_LINE_POPUP"

function newLine(payload){
    return{
        type: NEW_LINE,
        payload:payload
    }
}

export function clearInputSectionReducer(){
    return{
        type: CLEAR_INPUT_SECTION_REDUCER
    }
}


// The function below creates a line when the + icon is clicked
export const createLine = (lineName, callback) => (dispatch,getState) => {
    // const{jwt,project} = getState().projectLoginReducer;
    const jwt = getState().projectLoginReducer.jwt
    const project = getState().projectLoginReducer.project
    const country = getState().projectLoginReducer.project.country

    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .post(`/createLine/${project.referenceNumber}/${country}/${lineName}`)
        .set("Authorization", `Bearer ${jwt}`)
        .send({project, projectId:project.id})
        .then(response => {
            dispatch(newLine(response.body))
            typeof callback == "function" && callback(response.body);
            console.log("Line Created Successfully")
            dispatch(getLines())
        })
        .catch(error => {
            console.log(error);
        })
    }
}

// The function below deletes the line when the delete icon is clicked
export const deleteLine = (lineId, callback) => (dispatch,getState) => {
    const jwt = getState().projectLoginReducer.jwt
    const project = getState().projectLoginReducer.project
    const lineId = getState().popupReducer.deleteLine.lineId

    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .delete(`/deleteLine/${project.referenceNumber}/${lineId}`)
        .set("Authorization", `Bearer ${jwt}`)
        .send({project, projectId:project.id})
        .then(response => {
            // dispatch(newLine(response.body))
            typeof callback == "function" && callback(response.body);
            console.log("Line Deleted Successfully")
            dispatch(getLines())
        })
        .catch(error => {
            console.log(error);
        })
    }
}

// Action to get all the lines of a project
export function getProjectLines(payload){
    return{
        // This action is in the lineReducer in the redux 
        type: GET_PROJECT_LINES,
        payload:payload
    }
}

// Action to get all the sorted data
export function getSortedData(payload){
    return{
        // This action is in the lineReducer in the redux 
        type: GET_SORTED_DATA,
        payload:payload
    }
}

// Function to make a call to the backend to get all the lines of a project
export const getLines = (callback) => (dispatch, getState) => {
    const{jwt,project} = getState().projectLoginReducer;
    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .get(`/lines/${project.referenceNumber}`)
        .set("Authorization", `Bearer ${jwt}`)
        // .send({referenceNumber: project.referenceNumber})
        .then(response => {
            dispatch(getProjectLines(response.body))
            typeof callback == "function" && callback(response.body);
            console.log("Lines fetched successfully in get lines", response.body)
        })
        .catch(error => {
            console.log(error);
        })
    }
}

// Function to make a call to the backend to get sorted data for showing in display results
export const getData = (callback) => (dispatch, getState) => {
    const{jwt,project} = getState().projectLoginReducer;
    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .get(`/lines/${project.referenceNumber}/sortedData`)
        .set("Authorization", `Bearer ${jwt}`)
        // .send({referenceNumber: project.referenceNumber})
        .then(response => {
            dispatch(getSortedData(response.body))
            typeof callback == "function" && callback(response.body);
            console.log("Lines fetched successfully in get lines", response.body)
        })
        .catch(error => {
            console.log(error);
        })
    }
}

// Action that sends the selected line's input values to the reducer
function getSelectedLineInputs(payload){
    return{
        type: GET_LINE_INPUTS,
        payload:payload
    }
}

// function that gets the input values of the selected line from the database. The values can be shown as default values of the input fields
export const getSelectedLine = (callback) => (dispatch,getState) => {
    const{jwt,project} = getState().projectLoginReducer;
    const selectedLine = getState().selectedLineIdReducer;

    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .get(`/selectedLine/${selectedLine}/${project.referenceNumber}`)
        .set("Authorization", `Bearer ${jwt}`)
        .then(response => {
            dispatch(getSelectedLineInputs(response.body))
            // dispatch(fieldOneInput(response.body))
            // This is where we load all our input section 1-5 reducers 
            // this is an important step to check if a user has inputed all the values in the fields
            typeof callback == "function" && callback(response.body);
            console.log("Line data received successfully", response.body)
        })
        .catch(error => {
            console.log(error);
        })
    }
}

// Change Line Comment
export const changeLineComment = (lineId,lineComment, callback) => (dispatch, getState) => {
    const jwt = getState().projectLoginReducer.jwt
    const project = getState().projectLoginReducer.project
    const lineId = getState().popupReducer.addLineComment.lineId

    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .put(`${project.referenceNumber}/${lineId}/lineComment`)
        .set("Authorization", `Bearer ${jwt}`)
        .send({project, projectId:project.id, lineComment})
        .then(response => {
            typeof callback == "function" && callback(response.body);
            console.log("Data added Successfully to change line name and response received", response.body);
            dispatch(getProjectLines(response.body));
            // dispatch(getLines())
        })
        .catch(error => {
            console.log(error);
        })
    }
}
  

//Actions to store field inputs in the Input section 1 reducer 
export function fieldOneInput(payload){
    return{
        type: FIELD_ONE_INPUT,
        payload:payload
    }
}

export function fieldTwoInput(payload){
    return{
        type: FIELD_TWO_INPUT,
        payload:payload
    }
}

export function fieldThreeInput(payload){
    return{
        type: FIELD_THREE_INPUT,
        payload:payload
    }
}

//Actions to store field inputs in the Input section 2 reducer 

export function fieldFourInput(payload){
    return{
        type: FIELD_FOUR_INPUT,
        payload:payload
    }
}

export function fieldFiveInput(payload){
    return{
        type: FIELD_FIVE_INPUT,
        payload:payload
    }
}

export function daliSelected(payload){
    return{
        type: DALI_SELECTED,
        payload:payload
    }
}

export function fieldSixInput(payload){
    return{
        type: FIELD_SIX_INPUT,
        payload:payload
    }
}

export function fieldSevenInput(payload){
    return{
        type:FIELD_SEVEN_INPUT,
        payload:payload
    }
}

export function fieldEightInput(payload){
    return{
        type:FIELD_EIGHT_INPUT,
        payload:payload
    }
}

export function fieldNineInput(payload){
    return{
        type:FIELD_NINE_INPUT,
        payload:payload
    }
}

export function fieldTenInput(payload){
    return{
        type:FIELD_TEN_INPUT,
        payload:payload
    }
}

export function fieldElevenInput(payload){
    return{
        type:FIELD_ELEVEN_INPUT,
        payload:payload
    }
}

//Actions to store field inputs in the Input section 3 reducer 

export function fieldTwelveInput(payload){
    return{
        type:FIELD_TWELVE_INPUT,
        payload:payload
    }
}

export function fieldThirteenInput(payload){
    return{
        type:FIELD_THIRTEEN_INPUT,
        payload:payload
    }
}

export function fieldFourteenInput(payload){
    return{
        type:FIELD_FOURTEEN_INPUT,
        payload:payload
    }
}

export function fieldFifteenInput(payload){
    return{
        type:FIELD_FIFTEEN_INPUT,
        payload:payload
    }
}

export function fieldSixteenInput(payload){
    return{
        type:FIELD_SIXTEEN_INPUT,
        payload:payload
    }
}

//Actions to store field inputs in the Input section 4 reducer 

export function fieldSeventeenInput(payload){
    return{
        type:FIELD_SEVENTEEN_INPUT,
        payload:payload
    }
}

export function fieldEighteenInput(payload){
    return{
        type:FIELD_EIGHTEEN_INPUT,
        payload:payload
    }
}

export function fieldNineteenInput(payload){
    return{
        type:FIELD_NINETEEN_INPUT,
        payload:payload
    }
}

export function fieldTwentyTwoInput(payload){
    return{
        type:FIELD_TWENTY_TWO_INPUT,
        payload:payload
    }
}

export function fieldTwentyThreeInput(payload){
    return{
        type:FIELD_TWENTY_THREE_INPUT,
        payload:payload
    }
}

//Actions to store field inputs in the Input section 5 reducer 

export function fieldTwentyInput(payload){
    return{
        type:FIELD_TWENTY_INPUT,
        payload:payload
    }
}
export function fieldTwentyOneInput(payload){
    return{
        type:FIELD_TWENTY_ONE_INPUT,
        payload:payload
    }
}

export function lineStatus(payload){
    return{
        type:LINE_STATUS,
        payload:payload
    }
}

// export function renameLinePopup(payload){
//     return{
//         type: RENAME_LINE_POPUP,
//         payload:payload
//     }
// }

// export function deleteLinePopup(payload){
//     return{
//         type: DELETE_LINE_POPUP,
//         payload:payload
//     }
// }

// Send all values from Input sections to the database
export const sendSectionInputs = (referenceNumber, callback) => (dispatch,getState) => {
    const jwt = getState().projectLoginReducer.jwt
    const project = getState().projectLoginReducer.project
    const selectedLine = getState().selectedLineIdReducer
    const data = getState().inputSectionReducer

    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .put(`/input/${project.referenceNumber}/${selectedLine}`)
        .set("Authorization", `Bearer ${jwt}`)
        .send({project, projectId:project.id, data})
        .then(response => {
            typeof callback == "function" && callback(response.body);
            console.log("Data added Successfully boka", response.body);
            dispatch(getSelectedLineInputs({data:response.body.line}))
            // dispatch(getSelectedLine());
            dispatch(clearInputSectionReducer());
        })
        // .then(dispatch(clearInputSectionReducer()))
        .catch(error => {
            console.log(error);
        })
    }
}


// Change line Name
export const changeLineName = (lineName, callback) => (dispatch, getState) => {
    const jwt = getState().projectLoginReducer.jwt
    const project = getState().projectLoginReducer.project
    const lineId = getState().popupReducer.renameLine.lineId
    const data = lineName

    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .put(`input/${project.referenceNumber}/${lineId}/${lineName}`)
        .set("Authorization", `Bearer ${jwt}`)
        .send({project, projectId:project.id, data})
        .then(response => {
            typeof callback == "function" && callback(response.body);
            console.log("Data added Successfully to change line name and response received", response.body);
            dispatch(getProjectLines(response.body));
            // dispatch(getLines())
        })
        // .then(dispatch(clearInputSectionReducer()))
        .catch(error => {
            console.log(error);
        })
    }
}


