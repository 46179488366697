import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper, Typography, Checkbox, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { projectLogin } from "../../actions/projectActions";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import {
  createProjectPopup,
  closePopupAction,
} from "../../actions/popupActions";
import { createProject } from "../../actions/projectActions";
import { getProjects } from "../../actions/projectActions";
import { useForm, Controller } from "react-hook-form";
import { pageContent } from "../../lang/pageContent";
import { errorText } from "../../lang/errorText";
import { sendPdfEmail, sendPdfPriceEmail } from "../../actions/pdfActions";
// import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
    },
  },
  pageContent: {
    alignContent: "center",
    // padding: "0 15px 0 15px",
    width: 350,
    spacing: theme.spacing(2),
  },
}));

export default function RequestQuotationForm(tableData) {
  const classes = useStyles();
  const { register, handleSubmit, control, errors } = useForm();
  // const [error, setError] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const rows = tableData.tableData.rows;
  const emRows = tableData.tableData.emRows;
  const componentRows = tableData.tableData.componentRows;
  const lineRows = tableData.tableData.lineRows;
  const allLines = tableData.tableData.allLines;
  const selectedLang = tableData.tableData.selectedLang;


  const [checked1, setChecked1] = React.useState(true);
  const [checked2, setChecked2] = React.useState(true);
  const [loadingMessage, setLoadingMessage] = React.useState(false);

  // const selectedLang = useSelector(
  //   (reduxState) => reduxState.selectedLanguageReducer
  // );

  const user = useSelector((reduxState) => reduxState.userLoginReducer);

  const project = useSelector((reduxState) => reduxState.projectLoginReducer);

  const projectReferenceNumber = useSelector(
    (reduxState) => reduxState.referenceNumberReducer
  );

  const handleChange1 = (event) => {
    invalidEmail == true && setInvalidEmail(false);
  };
  const handleChange2 = (event) => {
    // setFormData({ ...formData, [event.target.name]: event.target.value });
    // console.log("value of form data is:", data.contactName);
    // checkFormData();
  };

  const handleCheckBox1 = (event) => {
    setChecked1(event.target.checked);
  };

  const handleCheckBox2 = (event) => {
    setChecked2(event.target.checked);
  };

  console.log("submit was clickeds and the errors are 11111,", errors);

  // This function checks if the value is in a general email format: abc@xyz.com
  function checkEmail(data) {
    let result;
    data.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
      ? (result = true)
      : (result = false);
    return result;
  }

  // This function checks the last n values of a string
  function checkEmailText(data, n, test) {
    let result;
    data.slice(-n) === test ? (result = true) : (result = false);
    return result;
  }

  const onSubmit = (data) => {
    
    // the condition below checks if the user has agreed to save their contact details
    const saveUserContacts = checked1 == true ? true : false;

    // the fucntion checkEmail checks if the email is in the right format
    if(checkEmail (data.contactEmail) == true){
      setLoadingMessage(true);
      // the condition below checks if the contact email contains opple.com or "raymonde.sampers@freestylelighting.nl" or "klaas@lichtbaas.nl". If the conditions are true, then email will be sent to the address with the pricing pdfs
      (checkEmailText(data.contactEmail, 9, "opple.com") || data.contactEmail === "raymonde.sampers@freestylelighting.nl" || data.contactEmail === "klaas@lichtbaas.nl")
      ? 
      dispatch(
          sendPdfEmail(
            data.contactEmail,
            data.contactName,
            rows,
            emRows,
            componentRows,
            lineRows,
            allLines,
            selectedLang,
            (response) => {
              setLoadingMessage(false);
              if (response.message === "Success") {
                dispatch(closePopupAction());
              }else if (response.message === "error119") {
                setInvalidEmail(true);
              }
            }
          )
        )
      : 
      dispatch(
          sendPdfPriceEmail(
            data.contactEmail,
            data.contactName,
            rows,
            emRows,
            componentRows,
            lineRows,
            allLines,
            selectedLang,
            saveUserContacts,
            (response) => {
              setLoadingMessage(false);
              if (response.message === "Success") {
                dispatch(closePopupAction());
              } else if (response.message === "error119") {
                setInvalidEmail(true);
              }
            }
          )
        );
    }else{
      setInvalidEmail(true);
    }
  };

  return (
    <Paper className={classes.pageContent} elevation={0}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          className={classes.root}
          Container
          align="center"
          direction={"row"}
          spacing={1}
        >
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ fontWeight: 300, lineHeight: 2, paddingBottom: "5%" }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].requestQuotationText1}
              <br></br>
              {pageContent[`${selectedLang}`].requestQuotationText2}
            </Typography>
          </Grid>
          <Grid item xs={10} align="left">
            <Typography
              variant="body1"
              style={{ fontWeight: 300, lineHeight: 2 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].requestQuotationBox1}
            </Typography>
          </Grid>
          <Grid item xs={2} align="right">
            <Checkbox
              checked={checked1}
              onChange={handleCheckBox1}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
            />
          </Grid>
          <Grid item xs={10} align="left">
            <Typography
              variant="body1"
              style={{ fontWeight: 300, lineHeight: 2 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].requestQuotationBox2}
              <Link onClick={() => window.open("https://www.opple.nl/nl/gebruiksvoorwaarden")} style={{cursor:"pointer"}}>
              {pageContent[`${selectedLang}`].termsAndConditions}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={2} align="right">
            <Checkbox
              checked={checked2}
              onChange={handleCheckBox2}
              inputProps={{ "aria-label": "controlled" }}
              color="primary"
              inputRef={register({
                required: "Please check this box",
                minLength: { value: 2, message: "Too Short" },
              })}
              name="checkBox2"
            />
          </Grid>
          <Grid item xs={12} align="center">
            {errors.checkBox2 && (
              <p style={{ color: "red" }}>{errors.checkBox2.message}</p>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              inputRef={register({
                required: errorText[`${selectedLang}`].error118,
                minLength: { value: 2, message: errorText[`${selectedLang}`].error116 },
              })}
              required
              fullWidth
              className={classes.root}
              id="contactEmail"
              label={pageContent[`${selectedLang}`].contactEmail}
              name="contactEmail"
              autoComplete="off"
              onChange={handleChange1}
              error={Boolean(errors.contactEmail)}
            />
            {errors.contactEmail && (
              <p style={{ color: "red" }}>{errors.contactEmail.message}</p>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              inputRef={register({
                required: errorText[`${selectedLang}`].error117,
                minLength: { value: 2, message: errorText[`${selectedLang}`].error116 },
                maxLength: { value: 30, message: "Too Long" },
              })}
              required
              fullWidth
              className={classes.root}
              id="contactName"
              label={pageContent[`${selectedLang}`].contactName}
              name="contactName"
              autoComplete="off"
              onChange={handleChange2}
              error={Boolean(errors.contactName)}
            />
            {errors.contactName && (
              <p style={{ color: "red" }}>{errors.contactName.message}</p>
            )}
            {invalidEmail == true && (
              <p style={{ color: "red" }}>{errorText[`${selectedLang}`].error119}</p>
            )}
            {loadingMessage == true && (
              <p style={{ color: "green" }}>
                {pageContent[`${selectedLang}`].processingRequest}
              </p>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="Button"
            >
              {pageContent[`${selectedLang}`].submit}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
