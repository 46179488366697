import React, { useState } from "react";
import {
  TextField,
  CssBaseline,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pageContent } from "../lang/pageContent";
import { helpPageContent } from "../lang/helpPageContent";
import "../material.css";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "4%",
    paddingBottom: "5%",
    spacing: theme.spacing(5),
    marginLeft: "10%",
    marginRight: "10%",
    maxWidth: "90%",
  },
  divColumn: {
    display: "flex",
    flexDirection: "column",
  },
  headingTitleStyle: {
    fontWeight: 500,
    color: "#000000",
    lineHeight: 1,
  },
  subheadingStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    fontWeight: 400,
    color: "#000000",
    lineHeight: 1,
    // textDecoration: "underline",
    paddingBottom: "1%",
    paddingTop: "2%",
  },
  bulletPoint: {
    fontWeight: 400,
    color: "#000000",
    lineHeight: 2,
    textDecoration: "underline",
    // paddingBottom: "5%",
    alignItems: "left",
  },
  columnText: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 400,
    color: "#000000",
    lineHeight: 1,
    paddingBottom: "1%",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  multiLineText: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 400,
    color: "#000000",
    justifyContent: "flex-start",
    textAlign: "left",
    lineHeight: 2,
  },
  noBulletsList: {
    listStyleType: "none",
  },
  bulletsList: {
    listStyleType: "disc",
  },
  italicStyleText: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 400,
    color: "#000000",
    lineHeight: 1,
    paddingBottom: "1%",
    justifyContent: "flex-start",
    textAlign: "left",
    fontStyle: "italic",
  },
}));

export default function HelpPage() {
  const classes = useStyles();
  const history = useHistory();

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <div 
    //   style={{ backgroundColor: "#d1deed" }}
      >
        <div className={classes.pageContent}>
          <div>
            <Typography
              variant="h4"
              className={classes.headingTitleStyle}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].howToUseTheConfigurator}
            </Typography>
            <br></br>
            <Typography
              variant="h6"
              style={{
                fontWeight: 400,
                color: "#000000",
                lineHeight: 1,
              }}
              gutterBottom
            >
              {helpPageContent[`${selectedLang}`].introLine}
            </Typography>
          </div>
          {/* Glossary section 0*/}
          <div className={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              style={{ textDecoration: "underline" }}
              gutterBottom
            >
              {helpPageContent[`${selectedLang}`].glossary}:
            </Typography>
            <Typography className={classes.columnText}>
              1. {helpPageContent[`${selectedLang}`].introduction}
            </Typography>
            <Typography className={classes.columnText}>
              2. {helpPageContent[`${selectedLang}`].homePage}
            </Typography>
            <Typography className={classes.columnText}>
              3. {pageContent[`${selectedLang}`].continueAsGuest}
            </Typography>
            <Typography className={classes.columnText}>
              4. {pageContent[`${selectedLang}`].projectDashboard}
            </Typography>
            <Typography className={classes.columnText}>
              5. {helpPageContent[`${selectedLang}`].configurator}
            </Typography>
            <Typography className={classes.columnText}>
              6. {helpPageContent[`${selectedLang}`].results}
            </Typography>
            <Typography className={classes.columnText}>
              7. {pageContent[`${selectedLang}`].signup}
            </Typography>
            <Typography className={classes.columnText}>
              8. {pageContent[`${selectedLang}`].userDashboard}
            </Typography>
            <Typography className={classes.columnText}>
              9. {pageContent[`${selectedLang}`].loginAsUser}
            </Typography>
            <Typography className={classes.columnText}>
              10. {helpPageContent[`${selectedLang}`].navAndBurgerMenu}
            </Typography>
          </div>
          {/* 1. Introduction Section 1*/}
          <div classname={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              gutterBottom
            >
              1. {helpPageContent[`${selectedLang}`].introduction}:
            </Typography>
            <Typography className={classes.multiLineText}>
              {helpPageContent[`${selectedLang}`].introLine1}
            </Typography>
            <br></br>
            <Typography className={classes.columnText}>
              {helpPageContent[`${selectedLang}`].introLine2}
            </Typography>
          </div>
          {/* 2. Homepage Section 2 */}
          <div classname={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              gutterBottom
            >
              2. {helpPageContent[`${selectedLang}`].homePage}:
            </Typography>
            <Typography className={classes.columnText}>
              {helpPageContent[`${selectedLang}`].homeLine1}
            </Typography>
            <Typography className={classes.columnText}>
              {helpPageContent[`${selectedLang}`].homeLine2}:
            </Typography>
            <ul>
              <li>
                <Typography className={classes.columnText}>
                  {pageContent[`${selectedLang}`].loginAsUser}
                </Typography>
              </li>
              <li>
                <Typography className={classes.columnText}>
                  {pageContent[`${selectedLang}`].signup}
                </Typography>
              </li>
              <li>
                <Typography className={classes.columnText}>
                  {pageContent[`${selectedLang}`].continueAsGuest}
                </Typography>
              </li>
            </ul>
            <Typography className={classes.multiLineText}>
              {helpPageContent[`${selectedLang}`].homeLine3}
            </Typography>
            <br></br>
            <Typography className={classes.columnText}>
              {helpPageContent[`${selectedLang}`].homeLine4}
            </Typography>
          </div>
          {/* 3. Continue as Guest Section 3 */}
          <div classname={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              gutterBottom
            >
              3. {pageContent[`${selectedLang}`].continueAsGuest}:
            </Typography>
            <Typography className={classes.columnText}>
              {helpPageContent[`${selectedLang}`].guestLine1}:
            </Typography>
            <ul className={classes.noBulletsList}>
              <li>
                <Typography className={classes.columnText}>
                  A. {pageContent[`${selectedLang}`].openAProject}:
                </Typography>
              </li>
              <li>
                <ul className={classes.noBulletsList}>
                  <li>
                    <Typography className={classes.multiLineText}>
                      {helpPageContent[`${selectedLang}`].guestLine2}
                    </Typography>
                    <br></br>
                  </li>
                </ul>
              </li>

              <li>
                <Typography className={classes.columnText}>
                  B. {pageContent[`${selectedLang}`].createAProject}:
                </Typography>
              </li>
              <li>
                <ul>
                  <li className={classes.bulletsList}>
                    <Typography className={classes.columnText}>
                      {helpPageContent[`${selectedLang}`].guestLine3}
                    </Typography>
                  </li>
                  <li className={classes.bulletsList}>
                    <Typography className={classes.columnText}>
                      {helpPageContent[`${selectedLang}`].guestLine4}
                    </Typography>
                  </li>
                  <li className={classes.noBulletsList}>
                    <Typography className={classes.italicStyleText}>
                      {helpPageContent[`${selectedLang}`].guestNote1}
                    </Typography>
                  </li>
                  <li className={classes.bulletsList}>
                    <Typography className={classes.columnText}>
                      {helpPageContent[`${selectedLang}`].guestLine5}
                    </Typography>
                  </li>
                  <li className={classes.noBulletsList}>
                    <Typography className={classes.italicStyleText}>
                      {helpPageContent[`${selectedLang}`].guestNote2}
                    </Typography>
                  </li>
                  <li className={classes.bulletsList}>
                    <Typography className={classes.columnText}>
                      {helpPageContent[`${selectedLang}`].guestLine6}
                    </Typography>
                  </li>
                  <li className={classes.bulletsList}>
                    <Typography className={classes.multiLineText}>
                      {helpPageContent[`${selectedLang}`].guestLine7}
                    </Typography>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          {/* 4. Project dashboard Section */}
          <div classname={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              gutterBottom
            >
              4. {pageContent[`${selectedLang}`].projectDashboard}
            </Typography>
            <Typography className={classes.columnText}>
              {helpPageContent[`${selectedLang}`].projectDasboardLine1}
            </Typography>
            <ul className={classes.bulletsList}>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].projectDasboardLine2}
                </Typography>
              </li>
              <li>
                <Typography className={classes.multiLineText}>
                  {helpPageContent[`${selectedLang}`].projectDasboardLine3}
                </Typography>
                <br></br>
              </li>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].projectDasboardLine4}
                </Typography>
              </li>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].projectDasboardLine5}
                </Typography>
              </li>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].projectDasboardLine6}
                </Typography>
              </li>
            </ul>
          </div>
          {/* 5. The Configuration Section */}
          <div classname={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              gutterBottom
            >
              5. {helpPageContent[`${selectedLang}`].theConfiguration}
            </Typography>
            <ul className={classes.noBulletsList}>
              <li>
                <Typography className={classes.multiLineText}>
                  {helpPageContent[`${selectedLang}`].configurationLine1}
                </Typography>
              </li>
              <br></br>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].configurationLine2}
                </Typography>
              </li>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].configurationLine3}
                </Typography>
              </li>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].configurationLine4}
                </Typography>
              </li>
            </ul>
          </div>

          {/* 6. Results Section */}
          <div classname={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              gutterBottom
            >
              6. {helpPageContent[`${selectedLang}`].results}
            </Typography>
            <ul className={classes.noBulletsList}>
              <li>
                <Typography className={classes.multiLineText}>
                  {helpPageContent[`${selectedLang}`].resultsLine1}
                </Typography>
              </li>
              <br></br>
              <li>
                <Typography className={classes.multiLineText}>
                  {helpPageContent[`${selectedLang}`].resultsLine2}
                </Typography>
                <br></br>
              </li>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].resultsLine3}
                </Typography>
              </li>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].resultsLine4}
                </Typography>
              </li>
            </ul>
          </div>
          {/* 7. The Configuration Section */}
          <div classname={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              gutterBottom
            >
              7. {pageContent[`${selectedLang}`].signup}
            </Typography>
            <ul className={classes.noBulletsList}>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].signUpLine1}
                </Typography>
              </li>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].signUpLine2}
                </Typography>
              </li>
            </ul>
            <Typography className={classes.italicStyleText}>
              {helpPageContent[`${selectedLang}`].signUpNote}
            </Typography>
          </div>
          {/* 8. User Dashboard section */}
          <div classname={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              gutterBottom
            >
              8. {pageContent[`${selectedLang}`].userDashboard}
            </Typography>
            <ul className={classes.noBulletsList}>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].userDashboardLine1}
                </Typography>
              </li>
              <br></br>
              <li className={classes.bulletsList}>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].userDashboardLine2}
                </Typography>
              </li>
              <li className={classes.bulletsList}>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].userDashboardLine3}
                </Typography>
              </li>
              <br></br>
              <li>
                <Typography className={classes.columnText}>
                  {helpPageContent[`${selectedLang}`].userDashboardLine4}
                </Typography>
              </li>
            </ul>
          </div>
          {/* 9. Log in as User Section */}
          <div classname={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              gutterBottom
            >
              9. {pageContent[`${selectedLang}`].loginAsUser}
            </Typography>
            <ul className={classes.noBulletsList}>
              <li>
                <Typography className={classes.multiLineText}>
                  {helpPageContent[`${selectedLang}`].loginAsUserLine1}
                </Typography>
              </li>
            </ul>
          </div>
          {/* 10. Burger Menu and Navigation Section */}
          <div classname={classes.divColumn}>
            <Typography
              variant="h6"
              className={classes.subheadingStyle}
              gutterBottom
            >
              10. {helpPageContent[`${selectedLang}`].navAndBurgerMenu}
            </Typography>
            <ul className={classes.noBulletsList}>
              <li>
                <Typography className={classes.multiLineText}>
                  {helpPageContent[`${selectedLang}`].BurgerMenuLine1}
                </Typography>
              </li>
              <li>
                <Typography className={classes.multiLineText}>
                  {helpPageContent[`${selectedLang}`].BurgerMenuLine2}
                </Typography>
              </li>
              <li>
                <ul className={classes.noBulletsList}>
                  <li>
                    <br></br>
                    <Typography className={classes.columnText}>
                      A. {pageContent[`${selectedLang}`].forGuests}:
                    </Typography>
                    <ul>
                      <li>
                        <Typography className={classes.columnText}>
                          {pageContent[`${selectedLang}`].createAProject}
                        </Typography>
                      </li>
                      <li>
                        <Typography className={classes.columnText}>
                          {pageContent[`${selectedLang}`].openAProject}
                        </Typography>
                      </li>
                      <li>
                        <Typography className={classes.columnText}>
                          {pageContent[`${selectedLang}`].projectDashboard}
                        </Typography>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <br></br>
                    <Typography className={classes.columnText}>
                      B. {pageContent[`${selectedLang}`].forUsers}:
                    </Typography>
                    <ul>
                      <li>
                        <Typography className={classes.columnText}>
                          {pageContent[`${selectedLang}`].signup}
                        </Typography>
                      </li>
                      <li>
                        <Typography className={classes.columnText}>
                          {pageContent[`${selectedLang}`].login}
                        </Typography>
                      </li>
                      <li>
                        <Typography className={classes.columnText}>
                          {pageContent[`${selectedLang}`].projectDashboard}
                        </Typography>
                      </li>
                      <li>
                        <Typography className={classes.columnText}>
                          {pageContent[`${selectedLang}`].myProjects}
                        </Typography>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
