import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Paper, makeStyles, Tabs, Tab } from "@material-ui/core";
import "../../material.css";
import { adminSelectedComponent } from "../../actions/adminActions";

const useStyles = makeStyles((theme) => ({
  customTab: {
    display: "flex",
    padding: theme.spacing(4),
  },
}));

export default function CustomTab({ tabs }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(`Tab ${newValue + 1} was clicked`);
    dispatch(adminSelectedComponent(`${newValue + 1}`));
  };

  return (
    <Paper square className={"customTab"}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
    </Paper>
  );
}
