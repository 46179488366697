export const USER_SIGNUP_MESSAGE = "USER_SIGNUP_MESSAGE"
export const RENAME_LINE_POPUP = "RENAME_LINE_POPUP"
export const DELETE_LINE_POPUP ="DELETE_LINE_POPUP"
export const CREATE_LINE_POPUP = "CREATE_LINE_POPUP"
export const CLOSE_POPUP_ACTION = "CLOSE_POPUP_ACTION"
export const DISPLAY_RESULTS_ERROR = "DISPLAY_RESULTS_ERROR"
export const CREATE_PROJECT_POPUP = "CREATE_PROJECT_POPUP"
export const RENAME_PROJECT_POPUP = "RENAME_PROJECT_POPUP"
export const CHANGE_PROJECT_PASSWORD_POPUP = "CHANGE_PROJECT_PASSWORD_POPUP"
export const DELETE_PROJECT_POPUP = "DELETE_PROJECT_POPUP"
export const EDIT_COMPONENT_POPUP = "EDIT_COMPONENT_POPUP"
export const LINE_CONFIGURATION_INFO_POPUP = "LINE_CONFIGURATION_INFO_POPUP"
export const SELECT_COUNTRY_POPUP = "SELECT_COUNTRY_POPUP"
export const REQUEST_QUOTATION_POPUP = "REQUEST_QUOTATION_POPUP"
export const ADD_PROJECT_COMMENT = "ADD_PROJECT_COMMENT"
export const ADD_LINE_COMMENT = "ADD_LINE_COMMENT"
export const LINE_FIELD_INFO = "LINE_FIELD_INFO"
export const LINE_FIELD_INFO_COMPACT = "LINE_FIELD_INFO_COMPACT"
export const LINE_DETAILS = "LINE_DETAILS"
export const SELECTED_COMPONENT = "SELECTED_COMPONENT"
export const COMPONENT_DETAILS_INFO = "COMPONENT_DETAILS_INFO"
export const RESET_LINE_STATUS = "RESET_LINE_STATUS"
export const FIELD_ONE_INFO = "FIELD_ONE_INFO"
export const FIELD_TWO_INFO = "FIELD_TWO_INFO"
export const FIELD_THREE_INFO = "FIELD_THREE_INFO"
export const FIELD_FOUR_INFO = "FIELD_FOUR_INFO"
export const FIELD_FIVE_INFO = "FIELD_FIVE_INFO"
export const FIELD_SIX_INFO = "FIELD_SIX_INFO"
export const FIELD_SEVEN_INFO = "FIELD_SEVEN_INFO"
export const FIELD_EIGHT_INFO = "FIELD_EIGHT_INFO"
export const FIELD_NINE_INFO = "FIELD_NINE_INFO"
export const FIELD_TEN_INFO = "FIELD_TEN_INFO"
export const FIELD_ELEVEN_INFO = "FIELD_ELEVEN_INFO"
export const FIELD_TWELVE_INFO = "FIELD_TWELVE_INFO"
export const FIELD_THIRTEEN_INFO = "FIELD_THIRTEEN_INFO"
export const FIELD_FOURTEEN_INFO = "FIELD_FOURTEEN_INFO"
export const FIELD_FIFTEEN_INFO = "FIELD_FIFTEEN_INFO"
export const FIELD_SIXTEEN_INFO = "FIELD_SIXTEEN_INFO"
export const FIELD_SEVENTEEN_INFO = "FIELD_SEVENTEEN_INFO"
export const FIELD_EIGHTEEN_INFO = "FIELD_EIGHTEEN_INFO"
export const FIELD_NINETEEN_INFO = "FIELD_NINETEEN_INFO"
export const FIELD_TWENTY_INFO = "FIELD_TWENTY_INFO"
export const FIELD_TWENTY_ONE_INFO = "FIELD_TWENTY_ONE_INFO"
export const FIELD_TWENTY_TWO_INFO = "FIELD_TWENTY_TWO_INFO"
export const FIELD_TWENTY_THREE_INFO = "FIELD_TWENTY_THREE_INFO"
export const LOG_OUT_POPUP = "LOG_OUT_POPUP"
export const TOKEN_EXPIRED_POPUP = "TOKEN_EXPIRED_POPUP"

// Action for user Signup popup
export function userSignupMessage(payload){
    return {
        type : USER_SIGNUP_MESSAGE,
        payload
    }
}

// Actions for line related popups
export function renameLinePopup(payload){
    return{
        type: RENAME_LINE_POPUP,
        payload:payload
    }
}

export function deleteLinePopup(payload){
    return{
        type: DELETE_LINE_POPUP,
        payload:payload
    }
}

export function createLinePopup(payload){
    return{
        type: CREATE_LINE_POPUP,
        payload:payload
    }
}

export function displayResultsError(payload){
    return {
        type : DISPLAY_RESULTS_ERROR,
        payload
    }
}

// actions for project related popups

export function createProjectPopup(payload){
    return {
        type : CREATE_PROJECT_POPUP,
        payload
    }
}

export function renameProjectPopup(payload){
    return {
        type : RENAME_PROJECT_POPUP,
        payload
    }
}

export function changeProjectPasswordPopup(payload){
    return {
        type : CHANGE_PROJECT_PASSWORD_POPUP,
        payload
    }
}

export function deleteProjectPopup(payload){
    return{
        type : DELETE_PROJECT_POPUP,
        payload
    }
}

export function editComponentPopup(payload){
    return {
        type : EDIT_COMPONENT_POPUP,
        payload
    }
}

export function lineConfigurationInfoPopup(payload){
    return{
        type : LINE_CONFIGURATION_INFO_POPUP,
        payload
    }
}

export function selectCountryPopup(payload){
    return{
        type : SELECT_COUNTRY_POPUP,
        payload
    }
}

export function requestQuotationPopup(payload){
    return{
        type : REQUEST_QUOTATION_POPUP,
        payload
    }
}

export function closePopupAction(payload){
    return{
        type: CLOSE_POPUP_ACTION,
        payload
    }
}

export function addProjectComment(payload){
    return{
        type: ADD_PROJECT_COMMENT,
        payload:payload
    }
}

export function addLineComment(payload){
    return{
        type: ADD_LINE_COMMENT,
        payload:payload
    }
}

export function lineFieldInfo(payload){
    return{
        type: LINE_FIELD_INFO,
        payload:payload
    }
}

export function lineFieldInfoCompact(payload){
    return{
        type: LINE_FIELD_INFO_COMPACT,
        payload:payload
    }
}

export function lineDetails(payload){
    return {
        type : LINE_DETAILS,
        payload
    }
}

export function selectedComponent(payload){
    return {
        type : SELECTED_COMPONENT,
        payload
    }
}

export function componentDetailsInfo(payload){
    return {
        type : COMPONENT_DETAILS_INFO,
        payload
    }
}

export function resetLineStatus(payload){
    return {
        type : RESET_LINE_STATUS,
        payload
    }
}

export function fieldOneInfo(payload){
    return{
        type: FIELD_ONE_INFO,
        payload:payload
    }
}

export function fieldTwoInfo(payload){
    return{
        type: FIELD_TWO_INFO,
        payload:payload
    }
}

export function fieldThreeInfo(payload){
    return{
        type: FIELD_THREE_INFO,
        payload:payload
    }
}

export function fieldFourInfo(payload){
    return{
        type: FIELD_FOUR_INFO,
        payload:payload
    }
}

export function fieldFiveInfo(payload){
    return{
        type: FIELD_FIVE_INFO,
        payload:payload
    }
}

export function fieldSixInfo(payload){
    return{
        type: FIELD_SIX_INFO,
        payload:payload
    }
}

export function fieldSevenInfo(payload){
    return{
        type: FIELD_SEVEN_INFO,
        payload:payload
    }
}

export function fieldEightInfo(payload){
    return{
        type: FIELD_EIGHT_INFO,
        payload:payload
    }
}

export function fieldNineInfo(payload){
    return{
        type: FIELD_NINE_INFO,
        payload:payload
    }
}

export function fieldTenInfo(payload){
    return{
        type: FIELD_TEN_INFO,
        payload:payload
    }
}

export function fieldElevenInfo(payload){
    return{
        type: FIELD_ELEVEN_INFO,
        payload:payload
    }
}

export function fieldTwelveInfo(payload){
    return{
        type: FIELD_TWELVE_INFO,
        payload:payload
    }
}

export function fieldThirteenInfo(payload){
    return{
        type: FIELD_THIRTEEN_INFO,
        payload:payload
    }
}

export function fieldFourteenInfo(payload){
    return{
        type: FIELD_FOURTEEN_INFO,
        payload:payload
    }
}

export function fieldFifteenInfo(payload){
    return{
        type: FIELD_FIFTEEN_INFO,
        payload:payload
    }
}

export function fieldSixteenInfo(payload){
    return{
        type: FIELD_SIXTEEN_INFO,
        payload:payload
    }
}

export function fieldSeventeenInfo(payload){
    return{
        type: FIELD_SEVENTEEN_INFO,
        payload:payload
    }
}

export function fieldEighteenInfo(payload){
    return{
        type: FIELD_EIGHTEEN_INFO,
        payload:payload
    }
}

export function fieldNineteenInfo(payload){
    return{
        type: FIELD_NINETEEN_INFO,
        payload:payload
    }
}

export function fieldTwentyInfo(payload){
    return{
        type: FIELD_TWENTY_INFO,
        payload:payload
    }
}

export function fieldTwentyOneInfo(payload){
    return{
        type: FIELD_TWENTY_ONE_INFO,
        payload:payload
    }
}

export function fieldTwentyTwoInfo(payload){
    return{
        type: FIELD_TWENTY_TWO_INFO,
        payload:payload
    }
}

export function fieldTwentyThreeInfo(payload){
    return{
        type: FIELD_TWENTY_THREE_INFO,
        payload:payload
    }
}

export function logOutPopup(payload){
    return {
        type : LOG_OUT_POPUP,
        payload
    }
}

export function tokenExpiredPopup(payload){
    return {
        type : TOKEN_EXPIRED_POPUP,
        payload
    }
}

