import React, { useState } from "react";
// import { connect } from "react-redux";
// import { Container } from "semantic-ui-react";
import {
  Grid,
  Paper,
  Typography,
  Link,
  TextField,
  Container,
  Button,
} from "@material-ui/core";
// import "../semantic.css";
import "../material.css";
import FieldOne from "../components/fieldComponents/FieldOne";
import FieldTwo from "../components/fieldComponents/FieldTwo";
import { useDispatch, useSelector } from "react-redux";
import FieldThree from "../components/fieldComponents/FieldThree";
import { sendSectionOneInput } from "../actions/lineActions";
import { sendSectionInputs } from "../actions/lineActions";
import { useHistory } from "react-router-dom";
import { clearInputSectionReducer } from "../actions/lineActions";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useForm, Controller } from "react-hook-form";
import HelpIcon from "@material-ui/icons/Help";
import { pageContent } from "../lang/pageContent";
import { lineStatus } from "../actions/lineActions";
import { errorText } from "../lang/errorText";
import {fieldSevenInput, fieldSixInput, fieldSixteenInput} from "../actions/lineActions";

// import { inputLabels } from "../lang/inputLabels";

const useStyles = makeStyles((theme) => ({
  root: {
    //   this changes the border colour
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7ea1d5",
    },
    // this changes the border colour on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7ea1d5",
    },
    // this changes the border colour on focus(when typing)
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#7ea1d5",
    },
    // this changes the text colour on typing
    "& .MuiInputBase-root": {
      color: "#000000",
    },
    //   this changes the border radius of the field
    [`& fieldset`]: {
      borderRadius: 35,
      // background:"#ffffff"
    },

    width: "650px",

    // this changes the background to white
    // background: "#ffffff"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 200,
  },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    flexGrow: 1,
  },
  button: {
    // color:"#ffffff",
    // maxWidth: "200px",
    width: "200px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "#0146ab",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function InputSection1Page() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, control, errors } = useForm();


  const [checkValue, setCheckValue] = React.useState("");

  const [errorCombo, setErrorCombo] = React.useState("");

  const inputLabels = useSelector(
    (reduxState) => reduxState.inputLabelsReducer
  );

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const currentValue = useSelector(
    (reduxState) => reduxState.selectedLineInputsReducer.data
  );

  const data = useSelector((reduxState) => reduxState.inputSectionReducer);

  const lineId = useSelector((reduxState) => reduxState.selectedLineIdReducer);

  const projectLoginReducer = useSelector((reduxState) => reduxState.projectLoginReducer)

  let message = "";

  let errorComboMessage = errorText[`${selectedLang}`].error120;

  if (checkValue === "invalid") {
    message = errorText[`${selectedLang}`].error114;
  } else if (checkValue === "invalid value") {
    message = errorText[`${selectedLang}`].error115;
  } else if (checkValue === "valid") {
    message = "valid";
  }

  function checkCombo() {
    console.log("checkCombo is running")
    if(data.fieldOneInput && data.fieldTwoInput){
      if(data.fieldOneInput <3 && data.fieldTwoInput === "2"){
        console.log("redux has both field 1 and field 2, condition 1 fired")
        setCheckValue("comboError");        
      }else if(data.fieldOneInput <=3 && data.fieldTwoInput === "1"){
        console.log("combo is safe, condition 3 fired")
        setCheckValue("");
        dispatch(fieldSevenInput("1"));
        dispatch(fieldSixInput("3"));
        dispatch(fieldSixteenInput("1.4"));
        checkFields();
      }else if(data.fieldOneInput >=3){
        setCheckValue("");
        dispatch(fieldSixteenInput("2"));
        console.log("combo is safe, condition 4 fired")
        checkFields();
      }
    }else if(data.fieldOneInput){
      console.log("redux has only 1 value, condition 7 fired")
      if(data.fieldOneInput<3 && currentValue.fieldTwoInput === "2"){
        console.log("redux has only field 1 value, condition 2 fired")
        setCheckValue("comboError");
      }else if(data.fieldOneInput >=3){
        console.log("safe, condition 5 fired");
        dispatch(fieldSixteenInput("2"));
        setCheckValue("");
        checkFields();
      }else if( data.fieldOneInput <=3 && currentValue.fieldTwoInput === "1"){
        console.log("safe, condition 8 fired");
        dispatch(fieldSevenInput("1"));
        dispatch(fieldSixInput("3"));
        dispatch(fieldSixteenInput("1.4"));
        setCheckValue("");
        checkFields();
      }
    }else if(data.fieldTwoInput){
      if(data.fieldTwoInput === "2" && currentValue.fieldOneInput <3){
        console.log("redux has only field two value, condition 6 fired")
        setCheckValue("comboError");
      }else {
        checkFields();
      }
    }else{
      checkFields();
    }
  }

  function checkFields() {
    console.log("I am checkfield")
    if (data.fieldOneInput === "invalid") {
      setCheckValue("invalid value");
    } else if (data.fieldThreeInput === "invalid") {
      setCheckValue("invalid value");
    } else if (checkValue === "comboError") {
      console.log("combo error triggered")
    }else {
      dispatch(
        sendSectionInputs(data, (response) => {
          if (
            !response.line.fieldOneInput ||
            !response.line.fieldTwoInput ||
            !response.line.fieldThreeInput
          ) {
            setCheckValue("invalid");
          } else {
            setCheckValue("valid");
            history.push("/inputPageSection2");
          }
        })
      );
    }
  }

  const handleChange = (event) => {
    console.log("value of form data is:", event.target.value);
  };

  const handleNext = (event) => {
    event.preventDefault();
    checkCombo();
    // checkFields();
    // setCheckValue("valid");
  };

  return (
    <React.Fragment>
      {!projectLoginReducer.jwt && history.push("/")}
      <CssBaseline />
      <Container maxWidth="sm" className={"inputSections"}>
        <Grid container direction={"column"} maxWidth="xs" spacing={2}>
          <Grid
            item
            style={{
              position: "absolute",
              left: "10%",
              top: "20%",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              // inputProps={{ style: { fontSize: 300 } }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].trunkingConfigurator}
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].inputPageSectionOneTranslation}
            </Typography>
            <br></br>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].lineName}: {currentValue.name}
              <br></br>
              <br></br>
            </Typography>

            {/* <Typography variant="body2">
              <Link
                onClick={() => history.push("/helpPage")}
                style={{
                  color: "#ffffff",
                  fontWeight: 350,
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                {pageContent[`${selectedLang}`].HowtoUseCreateProjectForm}
              </Link>
            </Typography> */}
          </Grid>
        </Grid>
      </Container>

      <div
        style={{
          marginTop: "5%",
        }}
      >
        <FieldOne />
        <FieldTwo />
        <FieldThree />
      </div>
      <div style={{
          marginTop: "2%",
        }}>
        {checkValue === "invalid" && (
          <h5 style={{ color: "red" }}>{message}</h5>
        )}
        {checkValue === "invalid value" && (
          <h5 style={{ color: "red" }}>{message}</h5>
        )}{" "}
         {/* {errorCombo === "true" && (
          <h5 style={{ color: "red" }}>{message}</h5>
        )} */}
         {checkValue === "comboError" && (
          <h5 style={{ color: "red" }}>{errorComboMessage}</h5>
        )}
      </div>
      <div
        style={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginLeft: "7%",
          marginRight: "7%",
          marginBottom: "200px",
          padding: "50px",
          // borderColor : "#000000",
          // border: "1px solid black"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={() => {
            dispatch(clearInputSectionReducer());
            history.push("/projectDashboard");
          }}
        >
          {pageContent[`${selectedLang}`].cancel}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={handleNext}
        >
          {pageContent[`${selectedLang}`].nextTranslation}
        </Button>
      </div>
    </React.Fragment>
  );
}
