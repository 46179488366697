import React from "react";
import Container from "@material-ui/core/Container";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import "../semantic.css";
import "../material.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import { Link } from "@material-ui/core";
import { pageContent } from "../lang/pageContent";

// import CustomButtonOne from "../components/buttons/CustomButtonOne";

const useStyles = makeStyles((theme) => ({
  endIcon: {
    fontSize: 12,
  },
  button: {
    // color:"#ffffff",
    width: "20em",
    height: "50px",
    borderRadius: 35,
    backgroundColor: "#1a9fe0",
    justifyContent: "space-between",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "20px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

export default function GuestDashboardPage() {
  const classes = useStyles();
  const history = useHistory();


  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const handleClick = () => {
    history.push("/createProjectFormPage");
  };

  const handleClick2 = () => {
    history.push("/openProjectFormPage");
  };


  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxwidth="lg" className="GuestDashboard" style={{minHeight: "100vh"}}>
        <div
          style={{
            position: "absolute",
            left: "10%",
            top: "20%",
          }}
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
            inputProps={{ style: { fontSize: 300 } }}
            gutterBottom
          >
            {pageContent[`${selectedLang}`].trunkingConfigurator}
          </Typography>
          <br></br>
          <Typography
            variant="h1"
            style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1, width  :"80%" }}
            inputProps={{ style: { fontSize: 300 } }}
            gutterBottom
          >
            {pageContent[`${selectedLang}`].guestDashboardPage}
          </Typography>
          <br></br>
          <br></br>
          <br></br>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={handleClick}
          >
            {pageContent[`${selectedLang}`].createAProject}
            <ArrowForwardIosIcon className={classes.endIcon} />
          </Button>
          <br></br>
          <br></br>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={handleClick2}
          >
            {pageContent[`${selectedLang}`].openAProject} <ArrowForwardIosIcon className={classes.endIcon} />
          </Button>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          {/* <Typography variant="body2">
            <Link
                onClick={() => history.push("/")}
                // underline="always"
              style={{
                color: "#ffffff",
                fontWeight: 350,
                fontSize: "20px",
                cursor : "pointer"
              }}
            >
              {"How to use Guest Dashboard Page"}
            </Link>
          </Typography> */}
        </div>
      </Container>
    </React.Fragment>
  );
}

