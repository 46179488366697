import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import "../../semantic.css";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { fieldElevenInput, lineStatus } from "../../actions/lineActions";
import {Grid,Paper,Typography,Link,TextField,CssBaseline, Tooltip} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { inputLabels } from "../../lang/inputLabels";
import { inputLabelOptions } from "../../lang/inputLabelOptions";
import {pageContent} from "../../lang/pageContent";
import {errorText} from "../../lang/errorText";
import Controls from "../controls/Controls";
import HelpIcon from "@material-ui/icons/Help";

import {fieldElevenInfo} from "../../actions/popupActions";
import Popup from "../../components/Popup";
import FieldElevenInfo from "../../components/fieldInfoComponents/FieldElevenInfo";


const useStyles = makeStyles((theme) => ({
  root: {
    //   this changes the border colour
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the border colour on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the border colour on focus(when typing)
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the text colour on typing
    "& .MuiInputBase-root": {
      color: "#000000",
    },
    //   this changes the border radius of the field
    [`& fieldset`]: {
      borderRadius: 35,
      // background:"#ffffff"
    },

    width: "650px",

    // this changes the background to white
    // background: "#ffffff"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
}));

export default function FieldEleven() {
  const classes = useStyles();
  const { register, handleSubmit, control, errors } = useForm();

  const [fieldElevenValue, setFieldElevenValue] = React.useState("");
  const [checkValue, setCheckvalue] = React.useState("");

  const dispatch = useDispatch();

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const currentValue = useSelector(
    (reduxState) => reduxState.selectedLineInputsReducer.data
  );

  const lengthOfLineRounded = useSelector(
    (reduxState) =>
      reduxState.selectedLineInputsReducer.data.lengthOfLineRounded
  );

  let currentValueName = "";

  if (!currentValue.fieldElevenInput) {
    currentValueName = pageContent[`${selectedLang}`].inputValue;
  } else {
    currentValueName = `${pageContent[`${selectedLang}`].savedValue}: ${currentValue.fieldElevenInput}`;
  }

  let maximumValue = "";

  let numberOfComponents = "";

  let numberOfModules = "";

  if (currentValue.fieldSevenInput === "1") {
    maximumValue = lengthOfLineRounded / 1.5 - 1;
  } else if (currentValue.fieldSevenInput === "2") {
    numberOfComponents = lengthOfLineRounded / 1.5;
    numberOfModules = Math.ceil(numberOfComponents / 2);
    maximumValue = numberOfModules;
  } else if (currentValue.fieldSevenInput === "3") {
    numberOfComponents = lengthOfLineRounded / 1.5;
    numberOfModules = Math.ceil(numberOfComponents / 3);
    maximumValue = numberOfModules;
  } else if (currentValue.fieldSevenInput === "4") {
    numberOfComponents = lengthOfLineRounded / 1.5;
    numberOfModules = currentValue.fieldEightInput;
    maximumValue = numberOfModules;
  }

  function checkNumber(value) {
    // console.log("maximum value is", maximumValue);
    // console.log("length of line rounder", lengthOfLineRounded);
    // console.log("current value is", currentValue);
    if (value === "0") {
      setCheckvalue("invalid");
      dispatch(fieldElevenInput("invalid"));
    // } else if (value.match(/^\d+$/) && value <= maximumValue) {
    } else if (parseInt(value) <= maximumValue) {
      console.log("valid valuegg");
      setFieldElevenValue(value);
      setCheckvalue("valid");
      dispatch(fieldElevenInput(value));
      dispatch(lineStatus("false"));
    } else {
      console.log("invalid value");
      setCheckvalue("invalid");
      dispatch(fieldElevenInput("invalid"));
    }
  }

  const handleChange = (event) => {
    // console.log("this is causing error", event.target.value);
    checkNumber(event.target.value);
  };
  const onSubmit = (data) => {
    console.log("the data in the form is:", data);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div
        style={{
          maxWidth: "80%",
          marginLeft: "10%",
          // border: "1px solid black",
          borderRadius: "35px",
          marginTop: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <Typography
             variant="body1"
             style={{ marginTop: "20px", fontSize : "18px"  }}
            // inputProps={{ style: { fontSize: 400 } }}
            gutterBottom
          >
              <Controls.ActionButton color="secondary">
              <Tooltip title="Field Info">
                <HelpIcon 
                fontSize="small"
                color="action"
                onClick={() => {
                  dispatch(
                    fieldElevenInfo({status:true})
                  )
                }}
                />
              </Tooltip>
            </Controls.ActionButton>
            {inputLabels[`${selectedLang}`].fieldEleven}
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              inputRef={register({
                required: "Enter a Valid value",
                minLength: { value: 1, message: "Too Short" },
              })}
              fullWidth
              className={classes.root}
              InputProps={{
                inputProps: {
                  style: { textAlign: "center" }
                },
              }}              
              id="fieldElevenValue"
              name="fieldElevenValue"
              label={`${pageContent[`${selectedLang}`].savedValue}: ${currentValue.fieldElevenInput}`}
              autoComplete="referenceNumber"
              onChange={handleChange}
              error={Boolean(errors.referenceNumber)}
            />
            {errors.referenceNumber && (
              <p style={{ color: "red" }}>{errors.fieldElevenValue.message}</p>
            )}
            {checkValue === "invalid" && (
              <p style={{ color: "red" }}>{errorText[`${selectedLang}`].error115}</p>
            )}
          </form>
        </div>
      </div>
      <Popup
      openPopup={popupState.fieldElevenInfo}
      setOpenPopup={setOpenPopup}
      title="Field Info"
      >
        <FieldElevenInfo/>
      </Popup>
    </React.Fragment>
  );
}


