import Input from "./Input";
// import RadioGroup from "./RadioGroup";
// import Select from "./Select";
// import Checkbox from "./Checkbox";
// import DatePicker from "./DatePicker";
// import Button from "./Button";
import ActionButton from "./ActionButton";

const Controls = {
    Input,
    // RadioGroup,
    // Select,
    // Checkbox,
    // DatePicker,
    // Button,
    ActionButton
}

export default Controls;