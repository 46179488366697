import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper, Button, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { closePopupAction } from "../../actions/popupActions";
import { pageContent } from "../../lang/pageContent";
import { popupContent } from "../../lang/popupContent";
import { inputLabels } from "../../lang/inputLabels";
import { inputLabelOptions } from "../../lang/inputLabelOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(2),
    },
  },
  pageContent: {
    // alignContent: "center",
    // padding: "0 15px 0 15px",
    width: "100%",
    // width: 800,

    spacing: theme.spacing(2),
  },
  button: {
    width: "100px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "##0146ab",
    // marginTop:"5%",
    marginBottom: "3%",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
    childDiv: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
}));

export default function LineFieldInfo() {
  const classes = useStyles();
  const history = useHistory();

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );
  const user = useSelector((reduxState) => reduxState.userLoginReducer);

  const dispatch = useDispatch();

  const allLines = useSelector((reduxState) => reduxState.lineReducer.data);

  const displayResultsPass = false;

  const lineData = useSelector((reduxState) => reduxState.lineInfoReducer.line);

  const fieldOptions = inputLabelOptions[`${selectedLang}`];

 
  const allFieldOptions = {
    fieldTwoOptions: {
      "1": fieldOptions.FieldTwoOptionOne,
      "2": fieldOptions.FieldTwoOptionTwo,
    },
    fieldFourOptions: {
      "542005008200" : fieldOptions.FieldFourOptionOne,
      "542005006000" : fieldOptions.FieldFourOptionTwo,
      "542005005900" : fieldOptions.FieldFourOptionThree,
      "542005005800" : fieldOptions.FieldFourOptionFour,
      "542005006100" : fieldOptions.FieldFourOptionFive,
      "542005006400" : fieldOptions.FieldFourOptionSix,
      "542005006300" : fieldOptions.FieldFourOptionSeven,
      "542005006200" : fieldOptions.FieldFourOptionEight,
      "542005006500" : fieldOptions.FieldFourOptionNine,
      "542005008600" : fieldOptions.FieldFourOptionTen,
      "542005006800" : fieldOptions.FieldFourOptionEleven,
      "542005006700" : fieldOptions.FieldFourOptionTwelve,
      "542005006600" : fieldOptions.FieldFourOptionThirteen,
      "542005006900" : fieldOptions.FieldFourOptionFourteen,
      "542005007200" : fieldOptions.FieldFourOptionFifteen,
      "542005007100" : fieldOptions.FieldFourOptionSixteen,
      "542005007000" : fieldOptions.FieldFourOptionSeventeen,
      "542005007300" : fieldOptions.FieldFourOptionEighteen
    },
    fieldFiveOptions : {
      "1" : fieldOptions.FieldFiveOptionOne,
      "2" : fieldOptions.FieldFiveOptionTwo
    },
    fieldSixOptions : {
      "1" : fieldOptions.FieldSixOptionOne,
      "2" : fieldOptions.FieldSixOptionTwo,
      "3" : fieldOptions.FieldSixOptionThree
    },
    fieldSevenOptions : {
      "1" : fieldOptions.FieldSevenOptionOne,
      "2" : fieldOptions.FieldSevenOptionTwo,
      "3" : fieldOptions.FieldSevenOptionThree,
      "4" : fieldOptions.FieldSevenOptionFour
    },
    fieldTenOptions : {
      "1" : fieldOptions.FieldTenOptionOne,
      "2" : fieldOptions.FieldTenOptionTwo
    },
    fieldTwelveOptions : {
      "1" : fieldOptions.FieldTwelveOptionOne,
      "2" : fieldOptions.FieldTwelveOptionTwo
    },
    fieldThirteenOptions : {
      "1" : fieldOptions.FieldThirteenOptionOne,
      "2" : fieldOptions.FieldThirteenOptionTwo
    },
    fieldFourteenOptions : {
      "1" : fieldOptions.FieldFourteenOptionOne,
      "2" : fieldOptions.FieldFourteenOptionTwo,
      "3" : fieldOptions.FieldFourteenOptionThree
    },
    fieldFifteenOptions : {
      "1" : fieldOptions.FieldFifteenOptionOne,
      "2" : fieldOptions.FieldFifteenOptionTwo,
      "3" : fieldOptions.FieldFifteenOptionThree
    },
    fieldSeventeenOptions : {
      "1" : fieldOptions.FieldSeventeenOptionOne,
      "2" : fieldOptions.FieldSeventeenOptionTwo
    },
    fieldNineteenOptions : {
      "1" : fieldOptions.FieldNineteenOptionOne,
      "2" : fieldOptions.FieldNineteenOptionTwo,
      "3" : fieldOptions.FieldNineteenOptionThree,
      "4" : fieldOptions.FieldNineteenOptionFour
    },
    fieldTwentyOptions : {
      "1" : fieldOptions.FieldTwentyOptionOne,
      "2" : fieldOptions.FieldTwentyOptionTwo
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Paper className={classes.pageContent} elevation={0}>
          <Grid container spacing={1} columns={12}>
          <Grid item xs={12}>
              <Typography
                variant="body2"
                style={{ fontSize: "14px" , fontWeight: 600, color: "#0146ab",paddingBottom : "2%"}}
                gutterBottom
              >
                {pageContent[`${selectedLang}`].lineName}:{lineData.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldOne}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {lineData.lengthOfLineRounded}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldTwelve}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldTwelveOptions[lineData.fieldTwelveInput]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldTwo}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldTwoOptions[lineData.fieldTwoInput]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldThirteen}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldThirteenOptions[lineData.fieldThirteenInput]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldThree}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {lineData.fieldThreeInput}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldFourteen}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldFourteenOptions[lineData.fieldFourteenInput]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldFour}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldFourOptions[lineData.fieldFourInput]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldFifteen}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldFifteenOptions[lineData.fieldFifteenInput]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldFive}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
                >
                {lineData.daliSelected === "true" && allFieldOptions.fieldFiveOptions[lineData.fieldFiveInput] || "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldSixteen}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {lineData.fieldSixteenInput}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldSix}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldSixOptions[lineData.fieldSixInput]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldSeventeen}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldSeventeenOptions[lineData.fieldSeventeenInput]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldSeven}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldSevenOptions[lineData.fieldSevenInput]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldEighteen}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {lineData.fieldSeventeenInput === "1" && lineData.fieldEighteenInput || "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldEight}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {lineData.fieldSevenInput === 4 && lineData.fieldEightInput || "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldNineteen}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldNineteenOptions[lineData.fieldNineteenInput] || "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldTen}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {allFieldOptions.fieldTenOptions[lineData.fieldTenInput]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldTwenty}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {lineData.fieldTwentyComment !== "true" && allFieldOptions.fieldTwentyOptions[lineData.fieldTwentyInput] || "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldEleven}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {lineData.fieldTenInput === "1" && lineData.fieldElevenInput || "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {inputLabels[`${selectedLang}`].fieldTwentyOne}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {lineData.fieldTwentyInput === "1" && lineData.fieldTwentyOneInput || "n/a"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
