// this reducer will hold all the projects of a user

import { GET_USER_PROJECTS } from "../actions/projectActions";

const initialState = {};

export default function userProjectReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_PROJECTS: {
      return { ...state, ...action.payload };
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
