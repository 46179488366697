import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import {createLine} from "../../actions/lineActions";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { createProjectPopup, displayResultsError} from "../../actions/popupActions";
import Popup from "../../components/Popup";
import DisplayResutlsErrorForm from "../../components/forms/DisplayResultsErrorForm";
import { pageContent } from "../../lang/pageContent";


const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

export default function AddProjectIcon() {
  const classes = useStyles();
  const referenceNumber = useSelector(reduxState => reduxState.projectLoginReducer);
  const dispatch = useDispatch();

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);


const handleClick = (event) => {
  dispatch(createProjectPopup(true))
}


  return (
      <React.Fragment>

    <div>
       <Tooltip title={pageContent[`${selectedLang}`].addProject} aria-label="add" onClick={handleClick}>
        <Fab color="primary" className={classes.fab}>
          <AddIcon/>
        </Fab>
      </Tooltip>
    </div>
      </React.Fragment>
  );
}



