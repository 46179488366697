import React, { useEffect, useState } from "react";
import { IconButton, Badge, makeStyles, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { InputAdornment } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { projectLogin } from "../actions/projectActions";
import {ProjectLogin, clearProjectSignupReducer, clearProjectReferenceNumberReducer} from "../actions/projectActions";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Grid from "@material-ui/core/Grid";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { createProject } from "../actions/projectActions";
import { inputReferenceNumber } from "../actions/contentActions";
import { pageContent } from "../lang/pageContent";
import { errorText } from "../lang/errorText";


import "../material.css";

const useStyles = makeStyles((theme) => ({
  root: {
    //   this changes the border colour
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
    },
    // this changes the border colour on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
    },
    // this changes the border colour on focus(when typing)
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
    },
    // this changes the text colour on typing
    "& .MuiInputBase-root": {
      color: "#ffffff",
    },
    //   this changes the border radius of the field
    [`& fieldset`]: {
      borderRadius: 35,
      // background:"#ffffff"
    },

    width: "33em",

    // this changes the background to white
    // background: "#ffffff"
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    width: "20em",
    height: "50px",
    borderRadius: 35,
    backgroundColor: "#1a9fe0",
    justifyContent: "space-between",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "20px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
  input: {
    color: "#FFFFFF",
  },
  endIcon: {
    fontSize: 12,
  },
}));

export default function CreateProjectFormPage() {
  const classes = useStyles();
  const { register, handleSubmit, control, errors } = useForm();
  const [error, setError] = React.useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearProjectSignupReducer());
    dispatch(clearProjectReferenceNumberReducer());
  }, []);



  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const projectReferenceNumber = useSelector(
    (reduxState) => reduxState.referenceNumberReducer
  );

  const user = useSelector((reduxState) => reduxState.userLoginReducer);

  const project = useSelector(
    (reduxState) => reduxState.projectLoginReducer
  );

  const referenceNumber = parseInt(Date.now());


  const handleChange = (event) => {
    // setFormData({ ...formData, [event.target.name]: event.target.value });
    console.log("value of form data is:", event.target.value);
  };

  const [showPassword, setShow] = useState(false);

  const [projectError, setProjectError] = React.useState();

  const togglePassword = () => {
    setShow(!showPassword);
  };


  const onSubmit = (data) => {
    data.referenceNumber = `${referenceNumber}`;
    data.userName = "guest";
    dispatch(
      createProject(
        data.userName,
        data.referenceNumber,
        data.projectName,
        data.password,
        (response) => {
          if (response.message === "Success") {
            // history.push("/projectDashboard");
          }
        }
      )
    );

    console.log("the data in the form is:", data);
    dispatch(inputReferenceNumber(data.referenceNumber));
  };

  const handleClick = () => {
    dispatch(clearProjectSignupReducer());
    history.push("/openProjectFormPage");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className="createProjectFormPage" style={{minHeight: "100vh"}}>
        <div
        style={{
          position: "absolute",
          left: "10%",
          top: "20%",
        }}>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            direction={"column"}
            maxWidth="xs"
            spacing={2}
          >
            <Grid item>
              <Typography
                variant="h5"
                style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
                inputProps={{ style: { fontSize: 300 } }}
                gutterBottom
              >
                 {pageContent[`${selectedLang}`].trunkingConfigurator}
              </Typography>
              <br></br>
              <Typography
                variant="h1"
                style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1, width : "80%" }}
                gutterBottom
              >
                {pageContent[`${selectedLang}`].createProjectForm}
              </Typography>
              {/* <Typography
              variant="body1"
              style={{
                color: "#ffffff",
                fontWeight: 350,
                fontSize: "20px",
              }}
              gutterBottom
            >
              Fill the form and click submit to generate a reference Number.
              <br></br>
              Copy the reference Number and use it in Login
            </Typography> */}
            </Grid>
            <br></br>
            <br></br>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                inputRef={register({
                  required: "Project Name Required",
                  minLength: { value: 2, message: "Too Short" },
                })}
                required
                fullWidth
                className={classes.root}
                InputLabelProps={{
                  style: { color: "#ffffff" },
                }}
                id="projectName"
                label={pageContent[`${selectedLang}`].projectName}
                name="projectName"
                autoComplete="off"
                onChange={handleChange}
                error={Boolean(errors.projectName)}
              />
              {errors.projectName && (
                <p style={{ color: "red" }}>{errors.projectName.message}</p>
              )}
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                inputRef={register({
                  required: "Password Required",
                  minLength: { value: 3, message: "Too Short" },
                })}
                required
                name="password"
                label={pageContent[`${selectedLang}`].password}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="off"
                className={classes.root}
                InputLabelProps={{
                  style: { color: "#ffffff" },
                }}
                error={Boolean(errors.password)}
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="Show/Hide Password"
                      aria-label="showPassword"
                      onClick={togglePassword}
                    >
                      <IconButton>
                        <Badge color="secondary">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ color: "#76a6c2" }}
                          />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
              {errors.password && (
                <p style={{ color: "red" }}>{errorText[`${selectedLang}`].error113}</p>
              )}
                {projectError=="true" && (
                <p style={{color:"red"}}>{errorText[`${selectedLang}`][`${project.message.errorCode}`]}</p>
              )}
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
              >
                 {pageContent[`${selectedLang}`].generateNumber} <ArrowForwardIosIcon className={classes.endIcon} />
              </Button>
            </Grid>
            <Grid item>
              {projectReferenceNumber && 
              <Typography
              variant="body2"
              style={{
                color: "#ffffff",
                fontWeight: 350,
                fontSize: "20px",
              }}
              gutterBottom
            >
              {/* Your Reference Number: {projectReferenceNumber} */}
              {pageContent[`${selectedLang}`].yourReferenceNumber}:
              {projectReferenceNumber && projectReferenceNumber}
              <CopyToClipboard text={projectReferenceNumber}>
                <IconButton>
                  <Tooltip title="Copy to clipboard">
                    <FileCopyIcon
                      fontSize="small"
                      style={{ color: "#ffffff" }}
                    />
                  </Tooltip>
                </IconButton>
              </CopyToClipboard>
            </Typography>
              }
            
          </Grid>
          <Grid item>
            {projectReferenceNumber && 
            <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={handleClick}
          >
            {pageContent[`${selectedLang}`].login} <ArrowForwardIosIcon className={classes.endIcon} />
          </Button>
            }
          </Grid>
            {/* <Grid item>
              <Typography variant="body2">
                <Link
                  onClick={() => history.push("/")}
                  underline="always"
                  style={{
                    color: "#ffffff",
                    fontWeight: 350,
                    fontSize: "20px",
                    cursor : "pointer"
                  }}
                >
                  {"How to use Create Project Form"}
                </Link>
              </Typography>
            </Grid> */}
          </Grid>
        </form>
        </div>
      </Container>
    </React.Fragment>
  );
}
