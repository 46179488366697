export const pageContent = {
  english: {
    language: "English",
    landingPageReference: "Please type your reference number if you have one",
    dashboardTranslation: "Dashboard",
    nextTranslation: "Next",
    backTranslation: "Back",
    saveTranslation: "Save",
    goToDashboardTranslation: "Go to Dashboard",
    configuringLineTranslation: "Configuring Line:",
    inputPageSectionOneTranslation: "Main trunking line dimensions",
    inputPageSectionTwoTranslation: "Module configuration",
    inputPageSectionThreeTranslation: "Connection & mounting method",
    inputPageSectionFourTranslation: "Sensor configuration",
    inputPageSectionFiveTranslation: "Additional products",
    resultsPageTranslation: "Trunking Configuration results",
    configurationCompleteTranslation: "Configuration complete for Line!",
    home: "Home",
    howToUseTheConfigurator: "How to use the Trunking Configurator?",
    createAProject: "Create a new project",
    openAProject: "Open an existing project",
    projectDashboard: "Project Dashboard",
    signup: "Sign up",
    login: "Log in",
    myProjects: "My Trunking projects",
    loginAsUser: "Log in as User",
    continueAsGuest: "Continue as Guest",
    selectYourLanguage: "Please select your language",
    changeCountry: "Change country",
    email: "Email",
    password: "Password",
    userLoginPage: "User log in",
    userSignupPage: "User sign up",
    userName: "Username",
    submit: "Submit",
    howToUseThisPage: "How to use this page?",
    userDashboard: "User Dashboard",
    projectName: "Project name",
    editProject: "Edit project",
    projectActions: "Project actions",
    projectStatus: "Project status",
    referenceNumber: "Reference number",
    welcome: "Welcome",
    rowsPerPage: "Rows per page",
    lineName: "Line name",
    baseModule: "Selected LED Module",
    lineStatus: "Line configuration status",
    configureLine: "Line configuration",
    lineActions: "Line actions",
    displayResults: "Display results",
    addLine: "Add line",
    renameLine: "Rename line",
    deleteLine: "Delete line",
    deleteProject: "Delete project",
    renameProject: "Rename project",
    addProject: "Add project",
    enterNewLineName: "Enter new line name",
    enterNewName: "Enter new name",
    confirm: "Confirm",
    deleteLineConfirm: "Are you sure you want to delete this line?",
    enterNewProjectName: "Please enter a new project name",
    enterNewProjectPassword: "Please enter a new project password",
    deleteProjectConfirm: "Are you sure you want to delete this project?",
    lineConfigurationInfo: "Configurator notes",
    savedValue: "Saved value",
    inputValue: "Please enter a value",
    selectAnOption: "Please select an option",
    saveLineConfiguration: "Save line configuration",
    lineDoesNotEndWithLedModule: "The line does not end with a LED Module",
    displayResultsPage: "Display configuration results",
    productDescription: "Product description",
    productCode: "Product code",
    unitsNeeded: "Units needed",
    downloadPdfWithoutPrices: "Download PDF without prices",
    requestForQuotation: "Request for a quotation",
    forgotPassword: "Forgot password?",
    logout: "Log out",
    tableOf: " of ",
    pleaseDoNotLeaveFieldEmpty: "Please do not leave field empty",
    country: "Country",
    requestQuotation: "Request Quotation",
    requestQuotationText1:
      "A pdf with the prices will be sent to the email mentioned below containing all the components and their prices.",
    requestQuotationText2:
      "Our sales team will follow up on your quotation and will contact you in 3-5 business days.",
    requestQuotationBox1: "Check this box to receive latest offers",
    requestQuotationBox2: "Check this box to accept",
    termsAndConditions: " Terms & Conditions",
    contactName: "Contact Name",
    contactEmail: "Contact Email",
    incomplete: "Incomplete",
    addLineComment: "Add line comment",
    valueRounded: "Value will be rounded to",
    logout: "Logout",
    logoutAll: "Press yes to logout of all project/user profiles",
    HowtoUseCreateProjectForm: "How to use Create Project Form",
    forGuests: "For Guests",
    forUsers: "For Users",
    trunkingConfigurator: "Trunking Configurator",
    guestDashboardPage: "Guest Dashboard Page",
    createProjectForm: "Create Project Form",
    generateNumber: "Generate Number",
    yourReferenceNumber: "Your Reference Number",
    openProjectForm: "Open Project Form",
    configure: "Configure",
    copyToClipboard: "Copy to Clipboard",
    yes: "Yes",
    no: "No",
    cancel: "Cancel",
    projectOverview: "Project Overview",
    length: "Length",
    complete: "Complete",
    okay: "Okay",
    pleaseSelectACountry: "Please Select a country",
    addProjectComment: "Add Project comment",
    emModulesAmountUnknown: "The amount of EM Modules are unknown",
    processingRequest:
      "Please wait while the request is processing. The popup will close if the request was sucessful",
    attention: "Attention!",
    tokenExpired: "The token has expired ",
    pleaseReLogin: "Please login again",
    pleaseSavePassword:
      "Please save your password securely. The popup will close when the request is succesful and redirect you to the login page",
    clickNextToContinue: "Click next to continue",

    // We need the translations for the lines below

    displayResultsForm: "Please set all line Status to complete and try again.",
    lineFieldDetails: "Line Fields Details",
    changePassword: "Change Password",
  },
  dutch: {
    language: "Nederlands",
    landingPageReference:
      "Gelieve uw referentienummer in te voeren, indien u dit heeft",
    dashboardTranslation: "Dashboard",
    nextTranslation: "Volgende",
    backTranslation: "Terug",
    saveTranslation: "Opslaan",
    goToDashboardTranslation: "Ga naar dashboard",
    configuringLineTranslation: "Lichtlijn configureren:",
    inputPageSectionOneTranslation: "Afmetingen hoofdlichtlijn",
    inputPageSectionTwoTranslation: "Moduleconfiguratie",
    inputPageSectionThreeTranslation: "Aansluitings- en montagemethode",
    inputPageSectionFourTranslation: "Sensorconfiguratie",
    inputPageSectionFiveTranslation: "Aanvullende producten",
    resultsPageTranslation: "Configuratieresultaten lichtlijn",
    configurationCompleteTranslation: "Configuration complete for Line!",
    home: "Home",
    howToUseTheConfigurator: "Hoe gebruikt u de lichtlijnconfigurator?",
    createAProject: "Een nieuw project aanmaken",
    openAProject: "Een bestaand project openen",
    projectDashboard: "Dashboard project",
    signup: "Registreren",
    login: "Inloggen",
    myProjects: "Mijn lichtlijnprojecten",
    loginAsUser: "Als gebruiker inloggen",
    continueAsGuest: "Als gast verdergaan",
    selectYourLanguage: "Kies a.u.b. uw taal",
    changeCountry: "Wijzig land",
    email: "Email",
    password: "Wachtwoord",
    userLoginPage: "Inloggen gebruiker",
    userSignupPage: "Registreren gebruiker",
    userName: "Gebruikersnaam",
    submit: "Verzenden",
    howToUseThisPage: "Hoe gebruikt u deze pagina?",
    userDashboard: "Dashboard gebruiker",
    projectName: "Projectnaam",
    editProject: "Project bewerken",
    projectActions: "Projectacties",
    projectStatus: "Projectstatus",
    referenceNumber: "Referentienummer",
    welcome: "Welkom",
    rowsPerPage: "Rijen per pagina",
    lineName: "Naam lichtlijn",
    baseModule: "Gekozen LED-module",
    lineStatus: "Status lichtlijnconfiguratie",
    configureLine: "Lichtlijnconfiguratie",
    lineActions: "Lichtlijnacties",
    displayResults: "Resultaten weergeven",
    addLine: "Lichtlijn toevoegen",
    renameLine: "Lichtlijn hernoemen",
    deleteLine: "Lichtlijn verwijderen",
    deleteProject: "Project verwijderen",
    renameProject: "Project hernoemen",
    addProject: "Project toevoegen",
    enterNewLineName: "Naam nieuwe lichtlijn invoeren",
    enterNewName: "Nieuwe naam invoeren",
    confirm: "Bevestigen",
    deleteLineConfirm: "Weet u zeker dat u deze lichtlijn wilt verwijderen?",
    enterNewProjectName: "Voer een nieuwe projectnaam in",
    enterNewProjectPassword: "Voer een nieuw projectwachtwoord in",
    deleteProjectConfirm: "Weet u zeker dat u dit project wilt verwijderen?",
    lineConfigurationInfo: "Toelichting configurator",
    savedValue: "Opgeslagen waarde",
    inputValue: "Voer een waarde in",
    selectAnOption: "Kies een optie",
    saveLineConfiguration: "Bewaar lichtlijnconfiguratie",
    lineDoesNotEndWithLedModule: "De lichtlijn eindigt niet met een LED-module",
    displayResultsPage: "Configuratieresultaten weergeven",
    productDescription: "Productomschrijving",
    productCode: "Productcode",
    unitsNeeded: "Benodigde eenheden",
    downloadPdfWithoutPrices: "PDF zonder prijzen downloaden",
    requestForQuotation: "Offerte-aanvraag",
    forgotPassword: "Wachtwoord vergeten?",
    logout: "Uitloggen",
    tableOf: " van ",
    pleaseDoNotLeaveFieldEmpty: "Gelieve geen velden leeg te laten",
    country: "Land",
    requestQuotation: "Offerte aanvragen",
    requestQuotationText1:
      "Een pdf met de prijzen zal naar het hieronder vermelde e-mailadres worden gestuurd met alle onderdelen en hun prijzen.",
    requestQuotationText2:
      "Ons verkoopteam zal uw offerte opvolgen en zal binnen 3-5 werkdagen contact met u opnemen.",
    requestQuotationBox1:
      "Vink dit vakje aan om de laatste aanbiedingen te ontvangen",
    requestQuotationBox2: "Vink dit vakje aan om de",
    termsAndConditions: " Algemene voorwaarden",
    contactName: "Contact Naam",
    contactEmail: "Contact Email",
    incomplete: "Onvolledig",
    addLineComment: "Regel commentaar toevoegen",
    valueRounded: "Waarde zal afgerond worden naar",
    logout: "Uitloggen",
    logoutAll:
      "Druk op ja om uit te loggen van alle project/gebruikers profielen",
    HowtoUseCreateProjectForm: "Hoe het Projectformulier aanmaken te gebruiken",
    forGuests: "Voor gasten",
    forUsers: "Voor gebruikers",
    trunkingConfigurator: "Trunking Configurator",
    guestDashboardPage: "Gasten Dashboard Pagina",
    createProjectForm: "Maak Project Formulier",
    generateNumber: "Genereer Nummer",
    yourReferenceNumber: "Uw referentienummer",
    openProjectForm: "Open Projectformulier",
    configure: "Configureer",
    copyToClipboard: "Kopiëren naar Klembord",
    yes: "Ja",
    no: "Nee",
    cancel: "Annuleren",
    projectOverview: "Project Overzicht",
    length: "Lengte",
    complete: "Volledig",
    okay: "Oké",
    pleaseSelectACountry: "Selecteer een land",
    addProjectComment: "Project commentaar toevoegen",
    emModulesAmountUnknown: "Het aantal EM Modules is onbekend",
    processingRequest:
      "Gelieve te wachten terwijl het verzoek wordt verwerkt. De popup zal sluiten als de aanvraag succesvol was.",
    attention: "Opgelet!",
    tokenExpired: "Het token is verlopen ",
    pleaseReLogin: "Gelieve opnieuw in te loggen",
    pleaseSavePassword:
      "Bewaar uw wachtwoord veilig. De popup zal sluiten als het verzoek succesvol is en u doorverwijzen naar de login pagina",
    clickNextToContinue: "Klik op volgende om verder te gaan",

    // We need the translations for the lines below

    displayResultsForm: "Please set all line Status to complete and try again.",
    lineFieldDetails: "Line Fields Details",
    changePassword: "Change Password",
  },
  french: {
    language: "Français",
    landingPageReference:
      "Saisissez votre numéro référence, si vous en avez un",
    dashboardTranslation: "Tableau de bord",
    nextTranslation: "Suivant",
    backTranslation: "Retour",
    saveTranslation: "Enregistrer",
    goToDashboardTranslation: "Accéder au tableau de bord",
    configuringLineTranslation: "Configuration de la ligne:",
    inputPageSectionOneTranslation:
      "Dimensions de la ligne principale trunking",
    inputPageSectionTwoTranslation: "Configuration du module",
    inputPageSectionThreeTranslation: "Méthode de connexion et de montage",
    inputPageSectionFourTranslation: "Configuration du détecteur",
    inputPageSectionFiveTranslation: "Produits complémentaires",
    resultsPageTranslation: "Résultats de configuration de la ligne lumineuse",
    configurationCompleteTranslation:
      "Configuration de la ligne lumineuse terminée",
    home: "Accueil",
    howToUseTheConfigurator:
      "Comment utiliser le configurateur de ligne lumineuse?",
    createAProject: "Créer un nouveau projet",
    openAProject: "Ouvrir un projet existant",
    projectDashboard: "Tableau de bord du projet",
    signup: "S’enregistrer",
    login: "Se connecter",
    myProjects: "Mes projets de ligne lumineuse",
    loginAsUser: "Se connecter en tant qu’utilisateur",
    continueAsGuest: "Continuer en tant qu’invité",
    selectYourLanguage: "Veuillez choisir votre langue",
    changeCountry: "Changer de pays",
    email: "E-mail",
    password: "Mot de passe",
    userLoginPage: "Connexion utilisateur",
    userSignupPage: "Enregistrement utilisateur",
    userName: "Nom d’utilisateur",
    submit: "Envoyer",
    howToUseThisPage: "Comment utiliser cette page?",
    userDashboard: "Tableau de bord de l'utilisateur",
    projectName: "Nom du projet",
    editProject: "Modifier le projet",
    projectActions: "Actions du projet",
    projectStatus: "Statut du projet",
    referenceNumber: "Numéro de référence",
    welcome: "Bienvenue",
    rowsPerPage: "Lignes par page",
    lineName: "Nom de la ligne lumineuse",
    baseModule: "Module LED sélectionné",
    lineStatus: "Statut de la configuration de la ligne lumineuse",
    configureLine: "Configuration de la ligne lumineuse",
    lineActions: "Actions de la ligne lumineuse",
    displayResults: "Afficher les résultats",
    addLine: "Ajouter une ligne lumineuse",
    renameLine: "Renommer la ligne lumineuse",
    deleteLine: "Supprimer la ligne lumineuse",
    deleteProject: "Supprimer le projet",
    renameProject: "Renommer le projet",
    addProject: "Ajouter un projet",
    enterNewLineName: "Nom de la nouvelle ligne lumineuse",
    enterNewName: "Nieuwe naam invoerenSaisir le nouveau nom",
    confirm: "Confirmer",
    deleteLineConfirm:
      "Êtes-vous certain de vouloir supprimer cette ligne lumineuse?",
    enterNewProjectName: "Saisir le nouveau nom de projet",
    enterNewProjectPassword:
      "Veuillez entrer un nouveau mot de passe de projet",
    deleteProjectConfirm: "Êtes-vous certain de vouloir supprimer ce projet?",
    lineConfigurationInfo: "Explications sur le configurateur",
    savedValue: "Valeur enregistrée",
    inputValue: "Saisir une valeur",
    selectAnOption: "Choisir une option",
    saveLineConfiguration: "Sauvegarder la configuration de la ligne lumineuse",
    lineDoesNotEndWithLedModule:
      "La ligne lumineuse ne se termine pas par un module LED.",
    displayResultsPage: "Afficher les résultats de la configuration",
    productDescription: "Description du produit",
    productCode: "Code produit",
    unitsNeeded: "Unités nécessaires",
    downloadPdfWithoutPrices: "Télécharger le PDF sans prix",
    requestForQuotation: "Demander un devis",
    forgotPassword: "Mot de passe oublié",
    logout: "Déconnexion",
    tableOf: " de ",
    pleaseDoNotLeaveFieldEmpty: "Veuillez ne pas laisser de champs vides",
    country: "Pays",
    requestQuotation: "Demander un devis",
    requestQuotationText1:
      "Un pdf avec les prix sera envoyé à l'email mentionné ci-dessous contenant tous les composants et leurs prix.",
    requestQuotationText2:
      "Notre équipe commerciale donnera suite à votre devis et vous contactera dans les 3 à 5 jours ouvrables.",
    requestQuotationBox1:
      "Cochez cette case pour recevoir les dernières offres",
    requestQuotationBox2: "Cochez cette case pour accepter",
    termsAndConditions: " Les termes et conditions",
    contactName: "Nom du contact",
    contactEmail: "Courriel du contact",
    incomplete: "Incomplet",
    addLineComment: "Ajouter une ligne de commentaire",
    valueRounded: "La valeur sera arrondie à",
    logout: "Déconnexion",
    logoutAll:
      "Cliquez sur 'oui' pour vous déconnecter de tous les projets/profils d'utilisateur.",
    HowtoUseCreateProjectForm:
      "Comment utiliser le formulaire de création de projet",
    forGuests: "Pour les invités",
    forUsers: "Pour les utilisateurs",
    trunkingConfigurator: "Trunking Configurator",
    guestDashboardPage: "Page du tableau de bord des invités",
    createProjectForm: "Formulaire de création de projet",
    generateNumber: "Générer un numéro",
    yourReferenceNumber: "Votre numéro de référence",
    openProjectForm: "Ouvrir le formulaire de projet",
    configure: "Configurer",
    copyToClipboard: "Copier dans le presse-papiers",
    yes: "Oui",
    no: "Non",
    cancel: "Annuler",
    projectOverview: "Aperçu du projet",
    length: "Longueur",
    complete: "Compléter",
    okay: "D'accord",
    pleaseSelectACountry: "Veuillez sélectionner un pays",
    addProjectComment: "Ajouter un commentaire sur le projet",
    emModulesAmountUnknown: "La quantité de modules EM est inconnue",
    processingRequest:
      "Veuillez patienter pendant que la demande est traitée. La popup se fermera si la demande a abouti.",
    attention: "Attention!",
    tokenExpired: "Le jeton a expiré ",
    pleaseReLogin: "Veuillez vous reconnecter",
    pleaseSavePassword:
      "Veuillez enregistrer votre mot de passe en toute sécurité. Le popup se fermera si la demande est réussie et vous redirigera vers la page de connexion",
    clickNextToContinue: "Cliquez sur suivant pour continuer",

    // We need the translations for the lines below

    displayResultsForm: "Please set all line Status to complete and try again.",
    lineFieldDetails: "Line Fields Details",
    changePassword: "Change Password",
  },
  german: {
    language: "Deutsch",
    landingPageReference:
      "Bitte geben Sie Ihre Referenznummer ein, sofern Sie eine haben",
    dashboardTranslation: "Anzeige",
    nextTranslation: "Weiter",
    backTranslation: "Zurück",
    saveTranslation: "Speichern",
    goToDashboardTranslation: "zur Anzeige gehen",
    configuringLineTranslation: "Lichtband konfigurieren:",
    inputPageSectionOneTranslation: "Abmessungen des Hauptlichtbandes",
    inputPageSectionTwoTranslation: "Modulkonfiguration",
    inputPageSectionThreeTranslation: "Anschluss- & Montageart",
    inputPageSectionFourTranslation: "Sensorkonfiguration",
    inputPageSectionFiveTranslation: "Zusätzliche Produkte",
    resultsPageTranslation: "Ergebnisse der Lichtband-Konfiguration",
    configurationCompleteTranslation:
      "Konfiguration für Lichtband abgeschlossen",
    home: "Home",
    howToUseTheConfigurator: "Wie wird der Lichtband-Konfigurator verwendet?",
    createAProject: "Ein neues Projekt erstellen",
    openAProject: "Ein bestehendes Projekt öffnen",
    projectDashboard: "Projekt-Dashboard",
    signup: "Registrieren",
    login: "Anmelden",
    myProjects: "Meine Lichtband-Projekte",
    loginAsUser: "Als Benutzer anmelden",
    continueAsGuest: "Weiter als Gast",
    selectYourLanguage: "Bitte wählen Sie Ihre Sprache",
    changeCountry: "Land wählen",
    email: "E-Mail",
    password: "Passwort",
    userLoginPage: "Benutzeranmeldung",
    userSignupPage: "Benutzerregistrierung",
    userName: "Benutzername",
    submit: "Senden",
    howToUseThisPage: "Wie wird diese Seite verwendet?",
    userDashboard: "Benutzer-Dashboard",
    projectName: "Projektname",
    editProject: "Projekt bearbeiten",
    projectActions: "Projekt-Aktionen",
    projectStatus: "Projektstatus",
    referenceNumber: "Referenznummer",
    welcome: "Willkommen",
    rowsPerPage: "Zeilen pro Seite",
    lineName: "Name des Lichtbandes",
    baseModule: "Ausgewählte LED-Module",
    lineStatus: "Konfigurationsstatus",
    configureLine: "Lichtbandkonfiguration",
    lineActions: "Lichtband-Aktionen",
    displayResults: "Ergebnisse anzeigen",
    addLine: "Lichtband hinzufügen",
    renameLine: "Lichtband umbenennen",
    deleteLine: "Lichtband löschen",
    deleteProject: "Projekt löschen",
    renameProject: "Projekt umbenennen",
    addProject: "Projekt hinzufügen",
    enterNewLineName: "Neuen Lichtbandnamen eingeben",
    enterNewName: "Neuen Namen eingeben",
    confirm: "Bestätigen",
    deleteLineConfirm:
      "Sind Sie sicher, dass Sie dieses Lichtband löschen möchten?",
    enterNewProjectName: "Bitte geben Sie einen neuen Projektnamen ein.",
    enterNewProjectPassword: "Bitte geben Sie ein neues Projektpasswort ein",
    deleteProjectConfirm:
      "Sind Sie sicher, dass Sie dieses Projekt löschen möchten?",
    lineConfigurationInfo: "Hinweise zum Konfigurator",
    savedValue: "Gespeicherter Wert",
    inputValue: "Bitte geben Sie einen Wert ein.",
    selectAnOption: "Bitte wählen Sie eine Option.",
    saveLineConfiguration: "Lichtbandkonfiguration speichern",
    lineDoesNotEndWithLedModule:
      "Das Lichtband endet nicht mit einem LED-Modul",
    displayResultsPage: "Konfigurationsergebnisse anzeigen",
    productDescription: "Produktbeschreibung",
    productCode: "Produktcode",
    unitsNeeded: "Benötigte Einheiten",
    downloadPdfWithoutPrices: "PDF-Download ohne Preise",
    requestForQuotation: "Angebotsanfrage",
    forgotPassword: "Passwort vergessen?",
    logout: "Abmelden",
    tableOf: " von ",
    pleaseDoNotLeaveFieldEmpty: "Bitte lassen Sie keine Felder leer",
    country: "Land",
    requestQuotation: "Kostenvoranschlag anfordern",
    requestQuotationText1:
      "Eine PDF-Datei mit den Preisen wird an die unten angegebene E-Mail-Adresse gesendet und enthält alle Komponenten und deren Preise.",
    requestQuotationText2:
      "Unser Vertriebsteam wird Ihr Angebot prüfen und Sie innerhalb von 3-5 Werktagen kontaktieren.",
    requestQuotationBox1:
      "Aktivieren Sie dieses Kästchen, um aktuelle Angebote zu erhalten",
    requestQuotationBox2: "Markieren Sie dieses Kästchen, um die",
    termsAndConditions: " Bedingungen und Konditionen",
    contactName: "Kontakt Name",
    contactEmail: "Kontakt-E-Mail",
    incomplete: "Unvollständig",
    addLineComment: "Zeilenkommentar hinzufügen",
    valueRounded: "Der Wert wird aufgerundet",
    logout: "Abmelden",
    logoutAll:
      "Drücken Sie Ja, um sich von allen Projekt-/Benutzerprofilen abzumelden.",
    HowtoUseCreateProjectForm:
      'So verwenden Sie das Formular "Projekt erstellen"',
    forGuests: "Für Gäste",
    forUsers: "Für Benutzer",
    trunkingConfigurator: "Trunking Configurator",
    guestDashboardPage: "Gast-Dashboard-Seite",
    createProjectForm: "Projektformular erstellen",
    generateNumber: "Nummer generieren",
    yourReferenceNumber: "Ihre Referenznummer",
    openProjectForm: "Projektformular öffnen",
    configure: "Konfigurieren Sie",
    copyToClipboard: "In die Zwischenablage kopieren",
    yes: "Ja",
    no: "Nein",
    cancel: "Abbrechen",
    projectOverview: "Projekt-Übersicht",
    length: "Länge",
    complete: "Vollständig",
    okay: "Okay",
    pleaseSelectACountry: "Bitte wählen Sie ein Land",
    addProjectComment: "Projektkommentar hinzufügen",
    emModulesAmountUnknown: "Die Anzahl der EM-Module ist unbekannt",
    processingRequest:
      "Bitte warten Sie, während die Anfrage bearbeitet wird. Das Popup wird geschlossen, wenn die Anfrage erfolgreich war.",
    attention: "Achtung!",
    tokenExpired: "Das Token ist abgelaufen ",
    pleaseReLogin: "Bitte melden Sie sich erneut an",
    pleaseSavePassword:
      "Bitte speichern Sie Ihr Passwort sicher. Das Popup-Fenster schließt sich, wenn die Anfrage erfolgreich war, und leitet Sie auf die Anmeldeseite weiter",
    clickNextToContinue: "Klicken Sie auf Weiter, um fortzufahren",
    // We need the translations for the lines below

    displayResultsForm: "Please set all line Status to complete and try again.",
    lineFieldDetails: "Line Fields Details",
    changePassword: "Change Password",
  },
  spanish: {
    language: "Español",
    landingPageReference: "Escriba su número de referencia si tiene alguno",
    dashboardTranslation: "Panel de control",
    nextTranslation: "Siguiente",
    backTranslation: "Volver",
    saveTranslation: "Guardar",
    goToDashboardTranslation: "Ir al panel de control",
    configuringLineTranslation: "Línea de configuración:",
    inputPageSectionOneTranslation:
      "Dimensiones de la línea de trunking principal",
    inputPageSectionTwoTranslation: "Configuración del módulo",
    inputPageSectionThreeTranslation: "Método de conexión y montaje",
    inputPageSectionFourTranslation: "Configuración del sensor",
    inputPageSectionFiveTranslation: "Productos adicionales",
    resultsPageTranslation: "Resultados de configuración troncal",
    configurationCompleteTranslation: "Configuración completa para la línea",
    home: "Inicio",
    howToUseTheConfigurator: "¿Cómo usar el configurador troncal?",
    createAProject: "Crear un proyecto nuevo",
    openAProject: "Abra un proyecto existente",
    projectDashboard: "Panel de control del proyecto",
    signup: "Inscribirse",
    login: "Inicio de sesión",
    myProjects: "Mis proyectos troncales",
    loginAsUser: "Inicio de sesión como usuario",
    continueAsGuest: "Seguir como usuario no registrado",
    selectYourLanguage: "Seleccione su idioma",
    changeCountry: "Cambie de país",
    email: "E-Mail",
    password: "Contraseña",
    userLoginPage: "Inicio de sesión para usuario",
    userSignupPage: "Inscribirse para usuario",
    userName: "Nombre de usuario",
    submit: "Enviar",
    howToUseThisPage: "¿Cómo usar esta página?",
    userDashboard: "Panel de control del usuario",
    projectName: "Nombre del proyecto",
    editProject: "Editar proyecto",
    projectActions: "Acciones de proyecto",
    projectStatus: "Estado de proyecto",
    referenceNumber: "Número de referencia",
    welcome: "Bienvenido",
    rowsPerPage: "Líneas por página",
    lineName: "Nombre de la línea",
    baseModule: "Módulo LED seleccionado",
    lineStatus: "Estado de configuración de la línea",
    configureLine: "Configuración de la línea",
    lineActions: "Acciones de la línea",
    displayResults: "Mostrar resultados",
    addLine: "Añadir línea",
    renameLine: "Cambiar nombre de línea",
    deleteLine: "Eliminar línea",
    deleteProject: "Eliminar proyecto",
    renameProject: "Cambiar nombre de proyecto",
    addProject: "Añadir proyecto",
    enterNewLineName: "Escribir nuevo nombre de línea",
    enterNewName: "Escribir nuevo nombre",
    confirm: "Confirmar",
    deleteLineConfirm: "¿Está seguro/a de que quiere eliminar esta línea?",
    enterNewProjectName: "Escriba un nuevo nombre de proyecto",
    enterNewProjectPassword:
      "Por favor ingrese una nueva contraseña de proyecto",
    deleteProjectConfirm:
      "¿Está seguro/a de que quiere eliminar este proyecto?",
    lineConfigurationInfo: "Notas del configurador",
    savedValue: "Valor guardado",
    inputValue: "Escriba un valor",
    selectAnOption: "Elija una opción",
    saveLineConfiguration: "Guardar la configuración de línea",
    lineDoesNotEndWithLedModule: "La línea no acaba con un módulo LED",
    displayResultsPage: "Mostrar resultados de configuración",
    productDescription: "Descripción del producto",
    productCode: "Código del Producto",
    unitsNeeded: "Unidades necesarias",
    downloadPdfWithoutPrices: "Descargar PDF sin los precios",
    requestForQuotation: "Solicitud de presupuesto",
    forgotPassword: "¿Olvidó su contraseña?",
    logout: "Cerrar sesión",
    tableOf: " de ",
    pleaseDoNotLeaveFieldEmpty: "No deje ningún campo vacío",
    country: "País",
    requestQuotation: "Solicitar presupuesto",
    requestQuotationText1:
      "Se enviará un pdf con los precios al correo electrónico mencionado a continuación con todos los componentes y sus precios.",
    requestQuotationText2:
      "Nuestro equipo de ventas hará un seguimiento de su presupuesto y se pondrá en contacto con usted en 3-5 días laborables.",
    requestQuotationBox1:
      "Marque esta casilla para recibir las últimas ofertas",
    requestQuotationBox2: "Marque esta casilla para aceptar",
    termsAndConditions: " Los términos y condiciones",
    contactName: "Nombre de contacto",
    contactEmail: "Correo electrónico de contacto",
    incomplete: "Incompleto",
    addLineComment: "Añada un comentario en la línea",
    valueRounded: "El valor será redondeado a",
    logout: "Cierre de sesión",
    logoutAll:
      "Pulse sí para cerrar la sesión de todos los perfiles de proyecto/usuario",
    HowtoUseCreateProjectForm:
      "Cómo utilizar el formulario de creación de proyectos",
    forGuests: "Para invitados",
    forUsers: "Para usuarios",
    trunkingConfigurator: "Configurador de trunking ",
    guestDashboardPage: "Página del tablero para invitados",
    createProjectForm: "Formulario de creación del proyecto",
    generateNumber: "Generar número",
    yourReferenceNumber: "Su número de referencia",
    openProjectForm: "Abrir formulario de proyecto",
    configure: "Configurar",
    copyToClipboard: "Copiar al portapapeles",
    yes: "Sí",
    no: "No",
    cancel: "Cancelar",
    projectOverview: "Resumen del proyecto",
    length: "Longitud",
    complete: "Completo",
    okay: "Vale",
    pleaseSelectACountry: "Por favor, seleccione un país",
    addProjectComment: "Agregar comentario de proyecto",
    emModulesAmountUnknown: "La cantidad de módulos EM es desconocida",
    processingRequest:
      "Por favor, espere mientras procesamos su solicitud. La ventana emergente se cerrará si la solicitud es exitosa.",
    attention: "Atención!",
    tokenExpired: "El token ha caducado ",
    pleaseReLogin: "Por favor, inicie su sesión de nuevo",
    pleaseSavePassword:
      "Por favor, guarde su contraseña de forma segura. La ventana emergente se cerrará cuando la solicitud sea satisfactoria y le redirigirá a la página de inicio de sesión",
    clickNextToContinue: "Haga clic a siguiente para continuar",

    // We need the translations for the lines below

    displayResultsForm: "Please set all line Status to complete and try again.",
    lineFieldDetails: "Line Fields Details",
    changePassword: "Change Password",
  },

  portugese: {
    language: "Português",
    landingPageReference: "Introduza o seu número de referência, caso o tenha",
    dashboardTranslation: "Dashboard",
    nextTranslation: "Seguinte",
    backTranslation: "Anterior",
    saveTranslation: "Guardar",
    goToDashboardTranslation: "Ir para o Dashboard",
    configuringLineTranslation: "Configurar linha:",
    inputPageSectionOneTranslation: "Dimensões da linha de junção principal",
    inputPageSectionTwoTranslation: "Configuração do módulo",
    inputPageSectionThreeTranslation: "Método de ligação e montagem",
    inputPageSectionFourTranslation: "Configuração do sensor",
    inputPageSectionFiveTranslation: "Produtos adicionais",
    resultsPageTranslation: "Resultados da configuração da junção (Trunking)",
    configurationCompleteTranslation: "Configuração concluída para a linha",
    home: "Início",
    howToUseTheConfigurator: "Como utilizar o Trunking Configurator?",
    createAProject: "Criar um novo projeto",
    openAProject: "Abrir um projeto existente",
    projectDashboard: "Dashboard de projeto",
    signup: "Registar-se",
    login: "Iniciar sessão",
    myProjects: "Os meus projetos Trunking",
    loginAsUser: "Iniciar sessão como utilizador",
    continueAsGuest: "Continuar como convidado",
    selectYourLanguage: "Selecionar o idioma",
    changeCountry: "Alterar país",
    email: "E-mail",
    password: "Palavra-passe",
    userLoginPage: "Início de sessão do utilizador",
    userSignupPage: "Registo de utilizador",
    userName: "Nome de utilizador",
    submit: "Enviar",
    howToUseThisPage: "Como utilizar esta página?",
    userDashboard: "Dashboard do utilizador",
    projectName: "Nome do projeto",
    editProject: "Editar projeto",
    projectActions: "Ações do projeto",
    projectStatus: "Estado do projeto",
    referenceNumber: "Referência",
    welcome: "Bem-vindo(a)",
    rowsPerPage: "Filas por página",
    lineName: "Nome da linha",
    baseModule: "Módulo LED selecionado",
    lineStatus: "Estado de configuração da linha",
    configureLine: "Configuração da linha",
    lineActions: "Ações da linha",
    displayResults: "Apresentar resultados",
    addLine: "Adicionar linha",
    renameLine: "Alterar o nome da linha",
    deleteLine: "Eliminar linha",
    deleteProject: "Eliminar projeto",
    renameProject: "Alterar o nome do projeto",
    addProject: "Adicionar projeto",
    enterNewLineName: "Introduzir o novo nome da linha",
    enterNewName: "Introduzir o novo nome",
    confirm: "Confirmar",
    deleteLineConfirm: "Tem a certeza que pretende eliminar esta linha?",
    enterNewProjectName: "Introduza um novo nome para o projeto",
    enterNewProjectPassword: "Insira uma nova senha de projeto",
    deleteProjectConfirm: "Tem certeza de que pretende eliminar este projeto?",
    lineConfigurationInfo: "Notas do configurador",
    savedValue: "Valor guardado",
    inputValue: "Introduza um valor",
    selectAnOption: "Selecione uma opção",
    saveLineConfiguration: "Guardar a configuração da linha",
    lineDoesNotEndWithLedModule: "A linha não termina com um Módulo LED",
    displayResultsPage: "Apresentar resultados da configuração",
    productDescription: "Descrição do produto",
    productCode: "Código de produto",
    unitsNeeded: "Unidades necessárias",
    downloadPdfWithoutPrices: "Transferir PDF sem preços",
    requestForQuotation: "Pedido de orçamento",
    forgotPassword: "Esqueceu-se da palavra-passe?",
    logout: "Terminar sessão",
    tableOf: " de ",
    pleaseDoNotLeaveFieldEmpty: "Por favor não deixe nenhum campo vazio",
    country: "País",
    requestQuotation: "Pedido de Orçamento",
    requestQuotationText1:
      "Um pdf com os preços será enviado para o e-mail abaixo mencionado contendo todos os componentes e os seus preços.",
    requestQuotationText2:
      "A nossa equipa de vendas fará o acompanhamento do seu orçamento e contactá-lo-á dentro de 3-5 dias úteis.",
    requestQuotationBox1: "Marque esta caixa para receber as últimas ofertas",
    requestQuotationBox2: "Marque esta caixa para aceitar",
    termsAndConditions: " Termos e condições",
    contactName: "Nome de contacto",
    contactEmail: "Email de contacto",
    incomplete: "Incompleto",
    addLineComment: "Adicionar comentário na linha",
    valueRounded: "O valor será arredondado para",
    logout: "Logout",
    logoutAll:
      "Prima sim para fazer logout de todos os perfis de projecto/utilizador",
    HowtoUseCreateProjectForm: "Como utilizar o Formulário Criar Projecto",
    forGuests: "Para convidados",
    forUsers: "Para Utilizadores",
    trunkingConfigurator: "Trunking Configurator",
    guestDashboardPage: "Página do Painel de Visitas",
    createProjectForm: "Criar formulário de projecto",
    generateNumber: "Gerar número",
    yourReferenceNumber: "O seu número de referência",
    openProjectForm: "Formulário de projecto aberto",
    configure: "Configurar",
    copyToClipboard: "Cópia para prancheta",
    yes: "Sim",
    no: "Não",
    cancel: "Cancelar",
    projectOverview: "Visão Geral do Projecto",
    length: "Comprimento",
    complete: "Completo",
    okay: "Está bem",
    pleaseSelectACountry: "Por favor Seleccione um país",
    addProjectComment: "Adicionar Comentário do Projecto",
    emModulesAmountUnknown: "A quantidade de Módulos EM é desconhecida",
    processingRequest:
      "Por favor aguarde enquanto o pedido é processado. O popup fechará se o pedido for bem sucedido.",
    attention: "Atenção",
    tokenExpired: "A ficha expirou ",
    pleaseReLogin: "Por favor faça novamente login",
    pleaseSavePassword:
      "Por favor, guarde a sua palavra-passe com segurança. O popup irá fechar quando o pedido for bem sucedido e redireccioná-lo para a página de login",
    clickNextToContinue: "Clique em seguinte para continuar",

    // We need the translations for the lines below

    displayResultsForm: "Please set all line Status to complete and try again.",
    lineFieldDetails: "Line Fields Details",
    changePassword: "Change Password",
  },
  finnish: {
    language: "Suomi",
    landingPageReference: "Kirjoita viitenumero, jos sinulla on sellainen",
    dashboardTranslation: "Hallintapaneeli",
    nextTranslation: "Seuraava",
    backTranslation: "Takaisin",
    saveTranslation: "Tallenna",
    goToDashboardTranslation: "Siirry hallintapaneeliin",
    configuringLineTranslation: "Määrittelyrivi:",
    inputPageSectionOneTranslation: "Pääkanavan mitat",
    inputPageSectionTwoTranslation: "Moduulin kokoonpano",
    inputPageSectionThreeTranslation: "Liitäntä- ja kiinnitystapa",
    inputPageSectionFourTranslation: "Anturin kokoonpano",
    inputPageSectionFiveTranslation: "Muita tuotteita",
    resultsPageTranslation: "Kanavan kokoonpanon tulokset",
    configurationCompleteTranslation: "Määritys linjaa varten on valmis",
    home: "Koti",
    howToUseTheConfigurator: "Kuinka käyttää kanavakokoonpano-ohjelmaa?",
    createAProject: "Luo uusi projekti",
    openAProject: "Avaa olemassa oleva projekti",
    projectDashboard: "Projektin hallintapaneeli",
    signup: "Kirjaudu",
    login: "Kirjaudu sisään",
    myProjects: "Minun kanavaprojektini",
    loginAsUser: "Kirjaudu sisään käyttäjänä",
    continueAsGuest: "Jatka vieraana",
    selectYourLanguage: "Valitse kieli",
    changeCountry: "Vaihda maa",
    email: "Sähköposti",
    password: "Salasana",
    userLoginPage: "Käyttäjän kirjautuminen",
    userSignupPage: "Käyttäjän rekisteröityminen",
    userName: "Käyttäjätunnus",
    submit: "Lähetä",
    howToUseThisPage: "Kuinka käytän tätä sivua?",
    userDashboard: "Käyttäjän hallintapaneeli",
    projectName: "Projektin nimi",
    editProject: "Muokkaa projektia",
    projectActions: "Projektitoimet",
    projectStatus: "Projektin tila",
    referenceNumber: "Viitenumero",
    welcome: "Tervetuloa",
    rowsPerPage: "Rivejä sivua kohden",
    lineName: "Rivin nimi",
    baseModule: "Valittu LED-moduuli",
    lineStatus: "Linjan määritystila",
    configureLine: "Linjan kokoonpano",
    lineActions: "Linjatoimet",
    displayResults: "Näytä tulokset",
    addLine: "Lisää rivi",
    renameLine: "Nimeä rivi uudelleen",
    deleteLine: "Poista rivi",
    deleteProject: "Poista projekti",
    renameProject: "Nimeä projekti uudelleen",
    addProject: "Lisää projekti",
    enterNewLineName: "Anna uuden linjan nimi",
    enterNewName: "Anna uusi nimi",
    confirm: "Vahvista",
    deleteLineConfirm: "Haluatko varmasti poistaa tämän rivin?",
    enterNewProjectName: "Anna uusi projektin nimi",
    enterNewProjectPassword: "Anna uusi projektin salasana",
    deleteProjectConfirm: "Haluatko varmasti poistaa tämän projektin?",
    lineConfigurationInfo: "Konfiguroijan muistiinpanot",
    savedValue: "Tallennettu arvo",
    inputValue: "Anna arvo",
    selectAnOption: "Valitse vaihtoehto",
    saveLineConfiguration: "Tallenna linjakokoonpano",
    lineDoesNotEndWithLedModule: "Linja ei pääty LED-moduuliin",
    displayResultsPage: "Näytä määritystulokset",
    productDescription: "Tuotteen kuvaus",
    productCode: "Tuotekoodi",
    unitsNeeded: "Tarvittavat yksiköt",
    downloadPdfWithoutPrices: "Lataa PDF ilman hintoja",
    requestForQuotation: "Tarjouspyyntö",
    forgotPassword: "Unohtuiko salasana?",
    logout: "Kirjaudu ulos",
    tableOf: " / ",
    pleaseDoNotLeaveFieldEmpty: "Älä jätä yhtään kenttää tyhjäksi",
    country: "Maa",
    requestQuotation: "Pyydä tarjous",
    requestQuotationText1:
      "Alla mainittuun sähköpostiosoitteeseen lähetetään pdf-tiedosto, joka sisältää kaikki osat ja niiden hinnat.",
    requestQuotationText2:
      "Myyntitiimimme seuraa tarjoustasi ja ottaa sinuun yhteyttä 3-5 arkipäivän kuluessa.",
    requestQuotationBox1: "Tarkista tämä ruutu saadaksesi uusimmat tarjoukset",
    requestQuotationBox2: "Rastita tämä ruutu hyväksyäksesi",
    termsAndConditions: " Ehdot",
    contactName: "Yhteyshenkilön nimi",
    contactEmail: "Yhteyshenkilön sähköpostiosoite",
    incomplete: "Epätäydellinen",
    addLineComment: "Lisää rivikommentti",
    valueRounded: "Arvo pyöristetään",
    logout: "Kirjaudu ulos",
    logoutAll:
      "Paina kyllä kirjautuaksesi ulos kaikista projekti-/käyttäjäprofiileista.",
    HowtoUseCreateProjectForm: "Miten projektilomakkeen luominen käytetään",
    forGuests: "Clique em seguinte para continuar",
    forUsers: "Käyttäjille",
    trunkingConfigurator: "Trunking Configurator",
    guestDashboardPage: "Vieraiden kojelauta-sivu",
    createProjectForm: "Luo projekti -lomake",
    generateNumber: "Luo numero",
    yourReferenceNumber: "Viitenumerosi",
    openProjectForm: "Avaa projektilomake",
    configure: "Määritä",
    copyToClipboard: "Kopioi leikepöydälle",
    yes: "Kyllä",
    no: "Ei",
    cancel: "Peruuta",
    projectOverview: "Projektin yleiskatsaus",
    length: "Pituus",
    complete: "Täydellinen",
    okay: "Okay",
    pleaseSelectACountry: "Valitse maa",
    addProjectComment: "Lisää projektin kommentti",
    emModulesAmountUnknown: "EM-moduulien määrä on tuntematon",
    processingRequest:
      "Odota, kun pyyntöä käsitellään. Ponnahdusikkuna sulkeutuu, jos pyyntö onnistui.",
    attention: "Huomio!",
    tokenExpired: "Merkki on vanhentunut ",
    pleaseReLogin: "Kirjaudu uudelleen",
    pleaseSavePassword:
      "Tallenna salasanasi turvallisesti. Ponnahdusikkuna sulkeutuu, kun pyyntö on onnistunut, ja ohjaa sinut kirjautumissivulle",
    clickNextToContinue: "Jatka klikkaamalla Seuraava",

    // We need the translations for the lines below

    displayResultsForm: "Please set all line Status to complete and try again.",
    lineFieldDetails: "Line Fields Details",
    changePassword: "Change Password",
  },
  polish: {
    language: "Polski",
    landingPageReference: "Proszę podać numer referencyjny, jeśli jest znany",
    dashboardTranslation: "Ekran startowy",
    nextTranslation: "Dalej",
    backTranslation: "Powrót",
    saveTranslation: "Zapisz",
    goToDashboardTranslation: "Przejdź do ekranu startowego",
    configuringLineTranslation: "Konfiguracja linii:",
    inputPageSectionOneTranslation: "Wymiary głównej linii świetlnej",
    inputPageSectionTwoTranslation: "Konfiguracja modułu",
    inputPageSectionThreeTranslation: "Metoda połączenia i mocowania",
    inputPageSectionFourTranslation: "Konfiguracja czujnika",
    inputPageSectionFiveTranslation: "Dodatkowe produkty",
    resultsPageTranslation: "Wyniki konfiguracji linii świetlnej",
    configurationCompleteTranslation: "Konfiguracja dla linii zakończona",
    home: "Strona główna",
    howToUseTheConfigurator: "Jak korzystać z konfiguratora linii świetlnej?",
    createAProject: "Stwórz nowy projekt",
    openAProject: "Otwórz istniejący projekt",
    projectDashboard: "Pulpit projektu",
    signup: "Zarejestruj się",
    login: "Zaloguj się",
    myProjects: "Moje projekty świetlne",
    loginAsUser: "Zaloguj się jako użytkownik",
    continueAsGuest: "Przejdź dalej jako odwiedzający",
    selectYourLanguage: "Proszę wybrać język",
    changeCountry: "Zmień kraj",
    email: "E-mail",
    password: "Hasło",
    userLoginPage: "Logowanie użytkownika",
    userSignupPage: "Rejestracja użytkownika",
    userName: "Nazwa użytkownika",
    submit: "Wyślij",
    howToUseThisPage: "Jak korzystać z tej strony?",
    userDashboard: "Pulpit użytkownika",
    projectName: "Nazwa projektu",
    editProject: "Edytuj projekt",
    projectActions: "Działania w ramach projektu",
    projectStatus: "Stan projektu",
    referenceNumber: "Numer referencyjny",
    welcome: "Witamy",
    rowsPerPage: "Liczba wierszy na stronę",
    lineName: "Nazwa linii",
    baseModule: "Wybierz moduł LED",
    lineStatus: "Stan konfiguracji linii",
    configureLine: "Konfiguracja linii",
    lineActions: "Działania w ramach linii",
    displayResults: "Wyświetl wyniki",
    addLine: "Dodaj linię",
    renameLine: "Zmień nazwę linii",
    deleteLine: "Usuń linię",
    deleteProject: "Usuń projekt",
    renameProject: "Zmień nazwę projektu",
    addProject: "Dodaj projekt",
    enterNewLineName: "Wpisz nową nazwę linii",
    enterNewName: "Wpisz nową nazwę",
    confirm: "Potwierdź",
    deleteLineConfirm: "Czy na pewno chcesz usunąć tę linię?",
    enterNewProjectName: "Proszę podać nową nazwę projektu",
    enterNewProjectPassword: "Wprowadź nowe hasło do projektu",
    deleteProjectConfirm: "Czy na pewno chcesz usunąć ten projekt?",
    lineConfigurationInfo: "Uwagi dotyczące konfiguratora",
    savedValue: "Zachowana wartość",
    inputValue: "Proszę podać wartość",
    selectAnOption: "Proszę wybrać opcję",
    saveLineConfiguration: "Zapisz konfigurację linii",
    lineDoesNotEndWithLedModule: "Ta linia nie kończy się modułem LED",
    displayResultsPage: "Wyświetl wyniki konfiguracji",
    productDescription: "Opis produktu",
    productCode: "Kod produktu",
    unitsNeeded: "Wymagane jednostki",
    downloadPdfWithoutPrices: "Pobierz PDF bez cen",
    requestForQuotation: "Poproś o cenę",
    forgotPassword: "Nie pamiętasz hasła?",
    logout: "Wyloguj się",
    tableOf: " z ",
    pleaseDoNotLeaveFieldEmpty: "Proszę nie pozostawiać żadnych pól pustych",
    country: "Kraj",
    requestQuotation: "Poproś o wycenę",
    requestQuotationText1:
      "Na podany poniżej adres e-mail zostanie wysłany plik PDF z cenami, zawierający wszystkie komponenty i ich ceny.",
    requestQuotationText2:
      "Nasz zespół sprzedaży zajmie się Państwa wyceną i skontaktuje się z Państwem w ciągu 3-5 dni roboczych.",
    requestQuotationBox1: "Zaznacz to pole, aby otrzymywać najnowsze oferty",
    requestQuotationBox2: "Zaznacz to pole, aby zaakceptować",
    termsAndConditions: " Warunki i zasady",
    contactName: "Nazwa kontaktowa",
    contactEmail: "Email kontaktowy",
    incomplete: "Niekompletne",
    addLineComment: "Dodaj komentarz w wierszu",
    valueRounded: "Wartość zostanie zaokrąglona do",
    logout: "Wyloguj",
    logoutAll:
      "Naciśnij tak, aby wylogować się z wszystkich profili projektu/użytkownika",
    HowtoUseCreateProjectForm: "Jak korzystać z formularza tworzenia projektu",
    forGuests: "Dla gości",
    forUsers: "Dla użytkowników",
    trunkingConfigurator: "Trunking Configurator",
    guestDashboardPage: "Strona pulpitu nawigacyjnego dla gości",
    createProjectForm: "Formularz tworzenia projektu",
    generateNumber: "Wygeneruj numer",
    yourReferenceNumber: "Twój numer referencyjny",
    openProjectForm: "Otwórz formularz projektu",
    configure: "Skonfiguruj",
    copyToClipboard: "Kopiuj do schowka",
    yes: "Tak",
    no: "Nie",
    cancel: "Anuluj",
    projectOverview: "Przegląd projektu",
    length: "Długość",
    complete: "Kompletny",
    okay: "Okay",
    pleaseSelectACountry: "Proszę wybrać kraj",
    addProjectComment: "Dodaj komentarz do projektu",
    emModulesAmountUnknown: "Ilość modułów EM jest nieznana.",
    processingRequest:
      "Proszę czekać podczas przetwarzania żądania. Popup zostanie zamknięty, jeśli zapytanie zakończy się sukcesem.",
    attention: "Uwaga!",
    tokenExpired: "Token wygasł ",
    pleaseReLogin: "Proszę zalogować się ponownie",
    pleaseSavePassword:
      "Proszę bezpiecznie zapisać swoje hasło. Popup zostanie zamknięty, gdy żądanie zakończy się sukcesem i przekieruje Cię na stronę logowania",
    clickNextToContinue: "Kliknij next, aby kontynuować",

    // We need the translations for the lines below

    displayResultsForm: "Please set all line Status to complete and try again.",
    lineFieldDetails: "Line Fields Details",
    changePassword: "Change Password",
  },
  italian: {
    language: "Italiano",
    landingPageReference:
      "Inserisci, se ne hai uno,  il tuo numero di riferimento",
    dashboardTranslation: "Dashboard",
    nextTranslation: "Avanti",
    backTranslation: "Indietro",
    saveTranslation: "Salva",
    goToDashboardTranslation: "Vai al Dashboard",
    configuringLineTranslation: "Configurazione della linea:",
    inputPageSectionOneTranslation:
      "Dimensioni della linea principale di binario",
    inputPageSectionTwoTranslation: "Configurazione del modulo",
    inputPageSectionThreeTranslation: "Connessione e metodo di montaggio",
    inputPageSectionFourTranslation: "Configurazione del sensore",
    inputPageSectionFiveTranslation: "Prodotti aggiuntivi",
    resultsPageTranslation:
      "Risultati della configurazione del sistema a binario",
    configurationCompleteTranslation: "Configurazione completa per il binario",
    home: "Home",
    howToUseTheConfigurator:
      "Come usare il Configuratore per il sistema a binario",
    createAProject: "Crea un nuovo progetto",
    openAProject: "Apri un progetto esistente",
    projectDashboard: "Dashboard di progetto",
    signup: "Registrati",
    login: "Esegui il login",
    myProjects: "I miei progetti con sistema a binario",
    loginAsUser: "Esegui il login come utente",
    continueAsGuest: "Continua come ospite",
    selectYourLanguage: "Seleziona la lingua",
    changeCountry: "Cambia paese",
    email: "Email",
    password: "Password",
    userLoginPage: "Login utente",
    userSignupPage: "Registrazione utente",
    userName: "Nome utente",
    submit: "Invia",
    howToUseThisPage: "Come usare questa pagina?",
    userDashboard: "Dashboard utente",
    projectName: "Nome progetto",
    editProject: "Modifica progetto",
    projectActions: "Azioni del progetto",
    projectStatus: "Stato del progetto",
    referenceNumber: "Numero di riferimento",
    welcome: "Benvenuto/a",
    rowsPerPage: "Righi per pagina",
    lineName: "Nome del binario",
    baseModule: "Modulo LED selezionato",
    lineStatus: "Stato configurazione del binario",
    configureLine: "Configurazione binario",
    lineActions: "Azioni per binario",
    displayResults: "Mostra risultati",
    addLine: "Aggiungi binario",
    renameLine: "Cambia nome al binario",
    deleteLine: "Cancella binario",
    deleteProject: "Cancella progetto",
    renameProject: "Cambia nome al progetto",
    addProject: "Aggiungi progetto",
    enterNewLineName: "Inserisci nuovo nome del binario",
    enterNewName: "Inserisci nuovo nome",
    confirm: "Conferma",
    deleteLineConfirm: "Sei sicuro/a di voler cancellare questo binario?",
    enterNewProjectName: "Per favore inserisci un nuovo nome progetto",
    enterNewProjectPassword: "Inserisci una nuova password per il progetto",
    deleteProjectConfirm: "Sei sicuro/a di voler cancellare questo progetto?",
    lineConfigurationInfo: "Note del configuratore",
    savedValue: "Valore salvato",
    inputValue: "Per favore inserisci un valore",
    selectAnOption: "Per favore seleziona un'opzione",
    saveLineConfiguration: "Salva configurazione del binario",
    lineDoesNotEndWithLedModule: "Il binario non finisce con un modulo LED",
    displayResultsPage: "Mostra i risultati della configurazione",
    productDescription: "Descrizione prodotto",
    productCode: "Codice prodotto",
    unitsNeeded: "Unità necessarie ",
    downloadPdfWithoutPrices: "Scarica PDF senza prezzi",
    requestForQuotation: "Richiedi una stima",
    forgotPassword: "Password dimenticata?",
    logout: "Esegui il logout",
    tableOf: " di ",
    pleaseDoNotLeaveFieldEmpty: "Si prega di non lasciare alcun campo vuoto",
    country: "Paese",
    requestQuotation: "Richiesta di preventivo",
    requestQuotationText1:
      "Un pdf con i prezzi sarà inviato all'email menzionata sotto, contenente tutti i componenti e i loro prezzi.",
    requestQuotationText2:
      "Il nostro team di vendita seguirà il tuo preventivo e ti contatterà in 3-5 giorni lavorativi.",
    requestQuotationBox1:
      "Seleziona questa casella per ricevere le ultime offerte",
    requestQuotationBox2: "Seleziona questa casella per accettare",
    termsAndConditions: " Termini e condizioni",
    contactName: "Nome del contatto",
    contactEmail: "Email di contatto",
    incomplete: "Incompleto",
    addLineComment: "Aggiungi un commento sulla linea",
    valueRounded: "Il valore sarà arrotondato a",
    logout: "Logout",
    logoutAll: "Premere sì per uscire da tutti i profili di progetto/utente",
    HowtoUseCreateProjectForm: "Come usare il modulo Crea progetto",
    forGuests: "Per gli ospiti",
    forUsers: "Per gli utenti",
    trunkingConfigurator: "Trunking Configurator",
    guestDashboardPage: "Pagina del cruscotto dell'ospite",
    createProjectForm: "Creare un progetto",
    generateNumber: "Generare il numero",
    yourReferenceNumber: "Il tuo numero di riferimento",
    openProjectForm: "Aprire il modulo del progetto",
    configure: "Configura",
    copyToClipboard: "Copia negli appunti",
    yes: "Sì",
    no: "No",
    cancel: "Annulla",
    projectOverview: "Panoramica del progetto",
    length: "Lunghezza",
    complete: "Completa",
    okay: "Ok",
    pleaseSelectACountry: "Seleziona un paese",
    addProjectComment: "Aggiungi un commento sul progetto",
    emModulesAmountUnknown: "La quantità di moduli EM è sconosciuta",
    processingRequest:
      "Si prega di attendere mentre la richiesta viene elaborata. Il popup si chiuderà se la richiesta ha avuto successo.",
    attention: "Attenzione!",
    tokenExpired: "Il token è scaduto ",
    pleaseReLogin: "Si prega di effettuare nuovamente il login",
    pleaseSavePassword:
      "Per favore salva la tua password in modo sicuro. Il popup si chiuderà quando la richiesta avrà successo e ti reindirizzerà alla pagina di login",
    clickNextToContinue: "Clicca su next per continuare",

    // We need the translations for the lines below

    displayResultsForm: "Please set all line Status to complete and try again.",
    lineFieldDetails: "Line Fields Details",
    changePassword: "Change Password",
  },
};
