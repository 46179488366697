import React from "react";
import AddIcon from "@material-ui/icons/Add";
import {createLine} from "../../actions/lineActions";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import {selectCountryPopup, createLinePopup} from "../../actions/popupActions";
import { pageContent } from "../../lang/pageContent";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
    backgroundColor : "#0146ab"
    // '& .MuiFab-primary':{
    //   backgroundColor : "#0146ab"
    // }
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

export default function AddLineIcon() {
  const classes = useStyles();
  const referenceNumber = useSelector(reduxState => reduxState.projectLoginReducer);
  const dispatch = useDispatch();

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const country = useSelector(reduxState => reduxState.projectLoginReducer.project.country)


function checkCountry(){
  if(!country){
    dispatch(
      selectCountryPopup({ status: true})
    );
  }
  else  
  dispatch(createLinePopup({status : true}))
  
  // dispatch(
  //   createLine(
  //     referenceNumber,
  //     (response) => {
  //       if (response.message === "Success") {
  //       console.log("Successfully received lines")
  //     }
  //   }
  //   )
  // )
}

const handleClick = (event) => {
 checkCountry();
console.log("i am clicking")
}


  return (
    <div>
       <Tooltip title={pageContent[`${selectedLang}`].addLine} aria-label="add" onClick={handleClick}>
        <Fab  className={classes.fab}>
          <AddIcon style={{color : "#ffffff"}}/>
        </Fab>
      </Tooltip>
    </div>
  );
}
