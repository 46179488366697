const initialState = {
  project : {
    referenceNumber : ""
  },
  errorCode : ""
};

export default function projectLoginReducer(state = initialState, action = {}) {
  switch (action.type) {
    case "PROJECT_LOG_IN":
      state.project = action.payload.project
      state.errorCode = action.payload.message.errorCode
      return { ...state, ...action.payload };
      case "CLEAR_PROJECT_LOGIN_REDUCER_MESSAGE":
      state.errorCode = "";
    case "LOG_OUT": {
      return initialState;
    }
    default:
      return state;
  }
}
