import React from "react";
// import { connect } from "react-redux";
// import { Container } from "semantic-ui-react";
// import { Button, Icon } from "semantic-ui-react";
// import "../semantic.css";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import FieldSeventeen from "../components/fieldComponents/FieldSeventeen";
import FieldEighteen from "../components/fieldComponents/FieldEighteen";
import FieldNineteen from "../components/fieldComponents/FieldNineteen";
import FieldTwentyTwo from "../components/fieldComponents/FieldTwentyTwo";
import FieldTwentyThree from "../components/fieldComponents/FieldTwentyThree";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendSectionInputs } from "../actions/lineActions";
import { getSelectedLine } from "../actions/lineActions";
import { fieldEighteenInput } from "../actions/lineActions";
import { clearInputSectionReducer } from "../actions/lineActions";
import { lineStatus } from "../actions/lineActions";
import { pageContent } from "../lang/pageContent";
import {errorText} from "../lang/errorText";
import {comments} from '../lang/comments';

import "../material.css";
import {
  Grid,
  Paper,
  Typography,
  Link,
  TextField,
  Container,
  Button
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useForm, Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  button: {
    // color:"#ffffff",
    // maxWidth: "200px",
    width: "200px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "#0146ab",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

function InputSection4Page() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [checkValue, setCheckValue] = React.useState("");

  const [checkPage, setCheckPage] = React.useState("");

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const currentValue = useSelector(
    (reduxState) => reduxState.selectedLineInputsReducer.data
  );

  const projectLoginReducer = useSelector((reduxState) => reduxState.projectLoginReducer)

  const data = useSelector((reduxState) => reduxState.inputSectionReducer);
  const lineId = useSelector((reduxState) => reduxState.selectedLineIdReducer);


  let pageMessage =
  errorText[`${selectedLang}`].error112;

  let message = "";

  if (currentValue.fieldNineteenComment === "2") {
    message = comments[`${selectedLang}`].comment101;
    pageMessage = errorText[`${selectedLang}`].error112;
  } else if (currentValue.fieldNineteenComment === "3") {
    message = comments[`${selectedLang}`].comment102;
    pageMessage = errorText[`${selectedLang}`].error112;
  } else if (currentValue.fieldNineteenComment === "4") {
    message = "Custom number cannot be more than blind covers. Please try a smaller number";
    pageMessage = errorText[`${selectedLang}`].error112;
  } else if (currentValue.fieldNineteenComment === "false") {
    message = "valid";
    pageMessage = "";
  }

  function checkFields() {
    if (currentValue.fieldNineteenComment !== "false" || data.fieldTwentyThreeInput == "invalid") {
      setCheckPage("invalid");
    } else {
      dispatch(
        sendSectionInputs(data, (response) => {
          if (response.message === "Success") {
            setCheckValue("valid");
            setCheckPage("valid");
            history.push("/inputPageSection5");
          }
        })
      );
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    checkFields();
  };

  return (
    <React.Fragment>
      {!projectLoginReducer.jwt && history.push("/")}
      <CssBaseline />
      <Container maxWidth="sm" className={"inputSections"}>
        <Grid container direction={"column"} maxWidth="xs" spacing={2}>
          <Grid
            item
            style={{
              position: "absolute",
              left: "10%",
              top: "20%",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              // inputProps={{ style: { fontSize: 300 } }}
              gutterBottom
            >
             {pageContent[`${selectedLang}`].trunkingConfigurator}
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
             {pageContent[`${selectedLang}`].inputPageSectionFourTranslation}
            </Typography>
            <br></br>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].lineName}: {currentValue.name}
              <br></br>
              <br></br>
            </Typography>
            
            {/* <Typography variant="body2">
              <Link
                onClick={() => history.push("/helpPage")}
                style={{
                  color: "#ffffff",
                  fontWeight: 350,
                  fontSize: "20px",
                  cursor : "pointer"
                }}
              >
                {pageContent[`${selectedLang}`].HowtoUseCreateProjectForm}
              </Link>
            </Typography> */}
          </Grid>
        </Grid>
      </Container>
      {/* <div
        style={{
          position: "absolute",
          left: "10%",
          paddingTop: "1%",
        }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: 600, color: "#0146ab", lineHeight: 1 }}
          inputProps={{ style: { fontSize: 300 } }}
          gutterBottom
        >
          Section 4
        </Typography>
      </div> */}

      <div 
       style={{
        marginTop: "5%",
      }}
      >
      <FieldSeventeen />
      {currentValue.fieldSeventeenInput === "1" && <FieldEighteen />}
      {currentValue.fieldSeventeenInput === "1" && <FieldNineteen />}
      <br></br>
      <br></br>
      {currentValue.fieldNineteenComment !== "false" && (
        <p style={{ color: "red" }}>{message}</p>
      )}
      {/* {currentValue.fieldNineteenInput === "3" && <FieldTwentyTwo />} */}
      {currentValue.fieldNineteenInput === "4" && <FieldTwentyThree />}
      </div>
      
      <div>
        {checkPage === "invalid" && (
          <h5 style={{ color: "red" }}>{pageMessage}</h5>
        )}
      </div>
        <div
          style={{
            maxWidth: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginLeft: "7%",
            marginRight: "7%",
            marginBottom: "200px",
            padding: "50px",
            // borderColor : "#000000",
            // border: "1px solid black"
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={() => {
              dispatch(clearInputSectionReducer());
              history.push("/inputPageSection3");
            }}
          >
            {pageContent[`${selectedLang}`].backTranslation}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={handleSubmit}
          >
           {pageContent[`${selectedLang}`].nextTranslation}
          </Button>
        </div>
    </React.Fragment>
  );
}

export default InputSection4Page;
