const initialState = {
    data: '',
    selectedComponent : ''
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case "GET_ALL_COMPONENTS": {
        state.data = action.payload
        return { ...state};
      }
      case "SELECTED_COMPONENT": {
        state.selectedComponent = action.payload;
        return { ...state };
      }
      case "LOG_OUT": {
        return initialState;
      }
      default:
        return state;
    }
  }
  