import jwt_decode from "jwt-decode";
import {tokenExpiredPopup, closePopupAction} from "../actions/popupActions";
export const LOG_OUT = "LOG_OUT";


export function logout(){
    return{
        type : LOG_OUT
    }
}

export const checkJwt = (
    token,
    callback
  ) => (dispatch) => {
    try{
        const jwt_decoded = jwt_decode(token)
        const timeToExpire = (jwt_decoded.exp * 1000)
        console.log("the jwt decoded is,", jwt_decoded)
        console.log("the date now is", Date.now())
        console.log("the time to expire is", timeToExpire)
        if(Date.now() > timeToExpire) {
            console.log("The token has expired, time to launch the logout sequence")
              // close all popups and dispatch action to logout
              dispatch(closePopupAction());
              dispatch(tokenExpiredPopup({ status: true}))
        }
         else return false;
    }
    catch(e){
        console.log(e)
    }
  };