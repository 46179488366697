const initialState = null;

export default function referenceNumberReducer(state = initialState, action) {
  switch (action.type) {
    case "INPUT_REFERENCE_NUMBER": {
      // => Ask yourself: what is action.payload?
      return state = action.payload;
    }
    case "CLEAR_PROJECT_REFERENCE_NUMBER_REDUCER" : {
      return initialState;
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
