import React from "react";
// import { Container } from "semantic-ui-react";
// import { Button, Icon } from "semantic-ui-react";
// import "../semantic.css";
import FieldFour from "../components/fieldComponents/FieldFour";
import FieldFive from "../components/fieldComponents/FieldFive";
import FieldSix from "../components/fieldComponents/FieldSix";
import FieldSeven from "../components/fieldComponents/FieldSeven";
import FieldEight from "../components/fieldComponents/FieldEight";
import FieldTen from "../components/fieldComponents/FieldTen";
import FieldEleven from "../components/fieldComponents/FieldEleven";
import { fieldFourteenInput } from "../actions/lineActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendSectionInputs } from "../actions/lineActions";
import { clearInputSectionReducer } from "../actions/lineActions";
import { makeStyles } from "@material-ui/core/styles";
import { lineStatus } from "../actions/lineActions";
import { pageContent } from "../lang/pageContent";
import {errorText} from "../lang/errorText";


import "../material.css";
import {
  Grid,
  Paper,
  Typography,
  Link,
  TextField,
  Container,
  Button,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useForm, Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  button: {
    // color:"#ffffff",
    // maxWidth: "200px",
    width: "200px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "#0146ab",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

function InputSection2Page() {
  const history = useHistory();
  const classes = useStyles();

  const [checkValue, setCheckValue] = React.useState("");

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const currentValue = useSelector(
    (reduxState) => reduxState.selectedLineInputsReducer.data
  );

  const projectLoginReducer = useSelector((reduxState) => reduxState.projectLoginReducer)

  const data = useSelector((reduxState) => reduxState.inputSectionReducer);
  const lineId = useSelector((reduxState) => reduxState.selectedLineIdReducer);


  const dispatch = useDispatch();

  let message = "";

  if (checkValue === "invalid") {
    message = errorText[`${selectedLang}`].error114;
  } else if (checkValue === "invalid value") {
    message = errorText[`${selectedLang}`].error112;
  } else if (checkValue === "valid") {
    message = "valid";
  }

  function checkFields() {
    if (data.fieldElevenInput === "invalid") {
      setCheckValue("invalid value");
    } else
      dispatch(
        sendSectionInputs(data, (response) => {
          if (!response.line.fieldFourInput || !response.line.fieldSevenInput) {
            setCheckValue("invalid");
          } else {
            setCheckValue("valid");
            history.push("/inputPageSection3");
          }
        })
      );
  }

  function setFieldFourteenValue() {
    if (currentValue.fieldSevenComment === "true") {
      dispatch(fieldFourteenInput("2"));
    } 
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // setFieldFourteenValue();
    checkFields();
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {!projectLoginReducer.jwt && history.push("/")}
      <Container maxWidth="sm" className={"inputSections"}>
        <Grid container direction={"column"} maxWidth="xs" spacing={2}>
          <Grid
            item
            style={{
              position: "absolute",
              left: "10%",
              top: "20%",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              // inputProps={{ style: { fontSize: 300 } }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].trunkingConfigurator}
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
             {pageContent[`${selectedLang}`].inputPageSectionTwoTranslation}
            </Typography>
            <br></br>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].lineName}: {currentValue.name}
              <br></br>
              <br></br>
            </Typography>
            
            {/* <Typography variant="body2">
              <Link
                onClick={() => history.push("/helpPage")}
                style={{
                  color: "#ffffff",
                  fontWeight: 350,
                  fontSize: "20px",
                  cursor : "pointer"
                }}
              >
                {pageContent[`${selectedLang}`].HowtoUseCreateProjectForm}
              </Link>
            </Typography> */}
          </Grid>
        </Grid>
      </Container>

      {/* <div
        style={{
          position: "absolute",
          left: "10%",
          paddingTop: "1%",
        }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: 600, color: "#0146ab", lineHeight: 1 }}
          inputProps={{ style: { fontSize: 300 } }}
          gutterBottom
        >
          Section 2
        </Typography>
      </div> */}

      <div
       style={{
        marginTop: "5%",
      }}
      >
      <FieldFour />
      {/* <h5>Field 5 appears if Dali is selected in Field Four</h5> */}
      {currentValue.daliSelected === "true" && <FieldFive />}
      <FieldSeven />
      {/* <h5>Field 8 appears if custom is selected in field 7</h5> */}
      {currentValue.fieldSevenInput === "4" && <FieldEight />}
      {(currentValue.lengthOfLineRounded > 3 && currentValue.fieldSevenInput.length > 0) && <FieldSix />}
      {/* {currentValue.lengthOfLineRounded > 3 && <FieldSix />} */}
      {currentValue.fieldSixInput !== "3" && <FieldTen />}
      {/* <h5>Field 11 appears if amount is known in field 10</h5> */}
      {(currentValue.fieldTenInput === "1" && currentValue.fieldSixInput !== "3") && <FieldEleven />}
      </div>


      <div>
        {checkValue === "invalid" && (
          <h5 style={{ color: "red" }}>{message}</h5>
        )}
        {checkValue === "invalid value" && (
          <h5 style={{ color: "red" }}>{message}</h5>
        )}
      </div>
      <div
        style={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginLeft: "7%",
          marginRight: "7%",
          marginBottom: "200px",
          padding: "50px",
          // borderColor : "#000000",
          // border: "1px solid black"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={() => {
            dispatch(clearInputSectionReducer());
            history.push("/inputPageSection1");
          }}
        >
          {pageContent[`${selectedLang}`].backTranslation}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={handleSubmit}
        >
           {pageContent[`${selectedLang}`].nextTranslation}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default React.memo(InputSection2Page);
