import request from "superagent";
export const ADMIN_LOG_IN = "ADMIN_LOG_IN";
export const ADMIN_LOG_IN_START = "ADMIN_LOG_IN_START";
export const JWT = "JWT";
export const CLEAR_ADMIN_LOGIN_REDUCER_MESSAGE = "CLEAR_ADMIN_LOGIN_REDUCER_MESSAGE";
export const GET_ALL_COMPONENTS = "GET_ALL_COMPONENTS";
export const ADMIN_SELECTED_COMPONENT = "ADMIN_SELECTED_COMPONENT";
// User login 

function AdminLogin(payload) {
  return {
    type: ADMIN_LOG_IN,
    payload: payload
  };
}

export function adminLoginState(payload){
  return {
    type  :ADMIN_LOG_IN_START,
    payload : payload
  }
}

export function adminSelectedComponent(payload){
  return {
    type: ADMIN_SELECTED_COMPONENT,
    payload : payload
  }
}

export const adminLogin = (userName, password, callback) => dispatch => {
  request
    .post(`/adminLogin`)
    .send({ userName, password })
    .then(response => {
      // console.log("log res juju:", response.body);
      dispatch(AdminLogin(response.body));
      typeof callback == "function" && callback(response.body);
      console.log("success");
    })
    .catch(error => {
      if(error){
        dispatch(AdminLogin(error.response.body.message))
      }
    });
};

// Clear error messages from userLoginReducer

export function clearAdminLoginReducerMessage(){
  return{
    type : CLEAR_ADMIN_LOGIN_REDUCER_MESSAGE
  }
}


// Action to get all the components
export function getAllComponents(payload){
  return{
      // This action is in the lineReducer in the redux 
      type: GET_ALL_COMPONENTS,
      payload:payload
  }
}


// Function to make a call to the backend to get all the components 
export const getComponents = (callback) => (dispatch, getState) => {
  const loginState = getState().adminLoginReducer.loginState;
  if (loginState === "true"){
    request
  .get(`/api/components`)
  // .send({referenceNumber: project.referenceNumber})
  .then(response => {
      dispatch(getAllComponents(response.body))
      typeof callback == "function" && callback(response.body);
      console.log("Lines fetched successfully in get components", response.body)
  })
  .catch(error => {
      console.log(error);
  })
  }
}

// Function to make an API call to the backend and edit the price of a component
export const editComponent = (componentData,lotion,componentName,callback) => (dispatch,getState) => {
  console.log("admin edititing component", )
  request
  .put(`api/components/check`)
  .send({componentData, lotion, componentName})
  .then(response => {
    typeof callback == "function" && callback(response.body);
    console.log("Data added Successfully boka freshadata", response.body.freshData);
    dispatch(getAllComponents(response.body.freshData));
  })
  .catch(error => {
      console.log(error);
  })
}

// Funtion to make a call to the backend to reset all the line status
export const resetLines = (lotion,callback) => (dispatch, getState) => {
  const loginState = getState().adminLoginReducer.loginState;
  if (loginState === "true"){
    request
  .post(`/api/lines/update`)
  .send({lotion})
  .then(response => {
      typeof callback == "function" && callback(response.body);
      console.log("Lines reset successfully")
  })
  .catch(error => {
      console.log(error);
  })
  }
}

// Function to make a call to the backend to change a user's password
export const changeUserPassword = (username,password,lotion, callback) => dispatch => {
  request
    .put(`/changeUserPassword`)
    .send({ username,password,lotion })
    .then(response => {
      typeof callback == "function" && callback(response.body);
      console.log("admin action change user password response", response.body)
      console.log("success");
    })
    .catch(error => {
   console.log(error);
    });
};