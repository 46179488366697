import React from "react";
import FieldTwenty from "../components/fieldComponents/FieldTwenty";
import FieldTwentyOne from "../components/fieldComponents/FieldTwentyOne";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendSectionInputs } from "../actions/lineActions";
import { getSelectedLine } from "../actions/lineActions";
import { lineStatus } from "../actions/lineActions";
import { makeStyles } from "@material-ui/core/styles";
import { clearInputSectionReducer } from "../actions/lineActions";
import { pageContent } from "../lang/pageContent";
import {errorText} from "../lang/errorText";
import {comments} from '../lang/comments';
import "../material.css";
import {
  Grid,
  Paper,
  Typography,
  Link,
  TextField,
  Container,
  Button
} from "@material-ui/core";import CssBaseline from "@material-ui/core/CssBaseline";
import { useForm, Controller } from "react-hook-form";


const useStyles = makeStyles((theme) => ({
  button: {
    // color:"#ffffff",
    // maxWidth: "200px",
    width: "200px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "#0146ab",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

export default function InputSection5Page() {
  const history = useHistory();
  const classes = useStyles();


  const [checkValue, setCheckValue] = React.useState("");

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const projectLoginReducer = useSelector((reduxState) => reduxState.projectLoginReducer)

  const data = useSelector((reduxState) => reduxState.inputSectionReducer);
  const lineId = useSelector((reduxState) => reduxState.selectedLineIdReducer);
  const dispatch = useDispatch();

  const currentValue = useSelector(
    (reduxState) => reduxState.selectedLineInputsReducer.data
  );

  let message = ""

  if(currentValue.fieldTwentyComment === "true"){
    message = comments[`${selectedLang}`].comment105;
  }else
  if(currentValue.fieldTwentyComment === "false"){
    message = ""
  }

  function pageJump() {
    if (
      currentValue.daliSelected === "true" &&
      currentValue.fieldSevenInput !== "1"
    ) {
      history.push("/inputPageSection4");
    } else {
      history.push("/inputPageSection3");
    }
  }

 function checkFields(){
   if(data.fieldTwentyOneInput === "invalid"){
    setCheckValue("invalid value");
   }else{
     dispatch(lineStatus("true"));
     dispatch(sendSectionInputs(data, (response) => {
       if(response.message === "Success"){
        dispatch(clearInputSectionReducer());
         history.push("/projectDashboard")
       }
     }))
   }
 }


  const handleSubmit = (event) => {
    event.preventDefault();
    checkFields();
  };

  return (
    <React.Fragment>
      {!projectLoginReducer.jwt && history.push("/")}
      <CssBaseline/>
      <Container maxWidth="sm" className={"inputSections"}>
        <Grid container direction={"column"} maxWidth="xs" spacing={2}>
          <Grid
            item
            style={{
              position: "absolute",
              left: "10%",
              top: "20%",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              // inputProps={{ style: { fontSize: 300 } }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].trunkingConfigurator}
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
             {pageContent[`${selectedLang}`].inputPageSectionFiveTranslation}
            </Typography>
            <br></br>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].lineName}: {currentValue.name}
              <br></br>
              <br></br>
            </Typography>
            
            {/* <Typography variant="body2">
              <Links
                onClick={() => history.push("/helpPage")}
                style={{
                  color: "#ffffff",
                  fontWeight: 350,
                  fontSize: "20px",
                  cursor : "pointer"
                }}
              >
                {pageContent[`${selectedLang}`].HowtoUseCreateProjectForm}
              </Link>
            </Typography> */}
          </Grid>
        </Grid>
      </Container>

      {/* <div
        style={{
          position: "absolute",
          left: "10%",
          paddingTop: "1%",
        }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: 600, color: "#0146ab", lineHeight: 1 }}
          inputProps={{ style: { fontSize: 300 } }}
          gutterBottom
        >
          Section 5
        </Typography>
      </div> */}

        <div
         style={{
          marginTop: "5%",
        }}
        >
        {currentValue.fieldTwentyComment !== "true" && <FieldTwenty />}
        {currentValue.fieldTwentyInput === "1" && <FieldTwentyOne />}
        </div>
        <br></br>
        <br></br>
        <div>
        {currentValue.fieldTwentyComment === "true" && <h5 style={{ color: '#000000' }}>{message}</h5>}
        </div>
      <div
        style={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginLeft: "7%",
          marginRight: "7%",
          marginBottom: "200px",
          padding: "50px",
          // borderColor : "#000000",
          // border: "1px solid black"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={() => {
            pageJump();
          }}
        >
          {pageContent[`${selectedLang}`].backTranslation}
        </Button>
         <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={handleSubmit}
        >
          {pageContent[`${selectedLang}`].saveLineConfiguration}
        </Button>
      
      
        {/* <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={() => {
            dispatch(clearInputSectionReducer());
            history.push("/projectDashboard");
          }}
        >
          {goToDashboard}
        </Button> */}
      </div>
    </React.Fragment>
  );
}

