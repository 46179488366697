import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Grid,
  Paper,
  IconButton,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { closePopupAction } from "../../actions/popupActions";
import { pageContent } from "../../lang/pageContent";
import { changeProjectPassword } from "../../actions/projectActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
    },
  },
  pageContent: {
    alignContent: "center",
    padding: "0 15px 0 15px",
    width: 500,
    spacing: theme.spacing(5),
  },
  button: {
    width: "50%",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "##0146ab",
    marginTop: "5%",
    marginBottom: "3%",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

export default function ChangeProjectPasswordForm() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((reduxState) => reduxState.userLoginReducer);
  const referenceNumber = useSelector(
    (reduxState) =>
      reduxState.popupReducer.changeProjectPassword.referenceNumber
  );
  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );
  const [newProjectPassword, setNewProjectPassword] = useState("");
  const [notification, setNotification] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState(false);

  const handleChange = (event) => {
    // setFormData({ ...formData, [event.target.name]: event.target.value });
    // console.log("value of form data is:", formData);
    setNewProjectPassword(event.target.value);
    setFormError(false);
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const showNotification = () => {
    setNotification(true);

    // After 5 seconds, set the notification state back to false
    setTimeout(() => {
      setNotification(false);
    }, 5000); // 5000 milliseconds (5 seconds)
  };

  const handleSubmit = (event) => {
    // Check if fields are filled
    if (newProjectPassword.trim() === "") {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    event.preventDefault();
    dispatch(
      changeProjectPassword(referenceNumber, newProjectPassword, (response) => {
        if (response.message === "Success") {
          showNotification();

          //   dispatch(closePopupAction());
        }
      })
    );
    // showNotification();
    // dispatch(closePopupAction());
  };

  return (
    <Paper className={classes.pageContent} elevation={0}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid
          className={classes.root}
          Container
          align="center"
          direction={"column"}
          spacing={3}
        >
          <Grid Item>
            <TextField
              className={classes.textField}
              label="New Project Password"
              type={showPassword ? "text" : "password"} // Toggle the type based on showPassword state
              value={newProjectPassword}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              required // Add the required attribute
              error={formError && newProjectPassword.trim() === ""} // Show error if formError is true and password is empty
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPasswordToggle}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {formError && (
            <p style={{ color: "red", textAlign: "center" }}>
              Cannotleave the field empty.
            </p>
          )}
          <br></br>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
          >
            {pageContent[`${selectedLang}`].submit}
          </Button>
          <br></br>
          <br></br>
          {notification && (
            <p style={{ color: "green", textAlign: "center" }}>
              Password updated successfully!
            </p>
          )}
        </Grid>
      </form>
    </Paper>
  );
}
