import request from "superagent";

export const SELECT_LANGUAGE_ENGLISH = "SELECT_LANGUAGE_ENGLISH";
export const SELECT_LANGUAGE_DUTCH = "SELECT_LANGUAGE_DUTCH";
export const SELECT_LANGUAGE_SPANISH = "SELECT_LANGUAGE_SPANISH";
export const INPUT_LABELS = "INPUT_LABELS";
export const INPUT_LABEL_OPTIONS = "INPUT_LABEL_OPTIONS";
export const SELECTED_LANGUAGE = "SELECTED_LANGUAGE";
export const NUMBER_OF_LINES_TO_CONFIGURE = "NUMBER_OF_LINES_TO_CONFIGURE";
export const baseUrl = "http://localhost:4000";
export const INPUT_REFERENCE_NUMBER = "INPUT_REFERENCE_NUMBER";
export const SELECTED_LINE = "SELECTED_LINE"
export const ADD_USER = "ADD_USER"
export const USER_LOG_IN = "USER_LOG_IN";
export const JWT = "JWT";



// action for choosing language
export function selectedLanguage(payload) {
  console.log("Language changed");
  return {
    type: SELECTED_LANGUAGE,
    payload: payload,
  };
}

// action to select number of lines to configure
export function numberOfLinesToConfigure(payload) {
  console.log("Number of lines to configure selected");
  return {
    type: NUMBER_OF_LINES_TO_CONFIGURE,
    payload: payload,
  };
}

// action for storing selected line
export function selectedLine(payload){
  console.log("A line was selected to configure");
  return{
    type: SELECTED_LINE,
    payload:payload
  }
}

// action to store the reference number
export function inputReferenceNumber(payload) {
  return {
    type: INPUT_REFERENCE_NUMBER,
    payload: payload,
  };
}

// function selectedLanguageEnglish(payload) {
//   return {
//     type: SELECT_LANGUAGE_ENGLISH,
//     payload: payload,
//   };
// }

// export const setSelectedLanguageEnglish = () => (dispatch) => {
//   const action = selectedLanguageEnglish("english");
//   dispatch(action)
//   console.log("language set to english")
// }

// action to get the object of input labels
function inputLabels(payload) {
  return {
    type: INPUT_LABELS,
    payload: payload,
  };
}

export const getInputLabels = () => (dispatch, getState) => {
  const state = getState();
  const lang = state.selectedLanguageReducer;
  console.log("Fetching Language:", lang);

  request
    .get(`${baseUrl}/api/inputLabels/${lang}`)
    .then((response) => {
      console.log("Trying to get the labels here", response.body);
      const action = inputLabels(response.body);
      dispatch(action);
    })
    .catch(console.error);
};

// action to get object for input label options
function inputLabelOptions(payload) {
  return {
    type: INPUT_LABEL_OPTIONS,
    payload: payload,
  };
}

export const getInputLabelOptions = () => (dispatch, getState) => {
  const state = getState();
  const lang = state.selectedLanguageReducer;

  request
    .get(`${baseUrl}/api/inputLabelOptions/${lang}`)
    .then((response) => {
      console.log("Trying to get the label options YYYYAAOOO", response.body);
      const action = inputLabelOptions(response.body);
      dispatch(action);
    })
    .catch(console.error);
};

// User login 

function UserLogin(payload) {
  return {
    type: USER_LOG_IN,
    payload: payload
  };
}

export const userLogin = (email, password, callback) => dispatch => {
  request
    .post(`${baseUrl}/userLogin`)
    .send({ email, password })
    .then(response => {
      console.log("log res:", response.body);
      dispatch(UserLogin(response.body));
      typeof callback == "function" && callback(response.body);
      console.log("success");
    })
    .catch(error => {
      // console.log("error:");
      console.error({ error });
    });
};

// Add a user - Sign up

function signUp(payload) {
  return {
    type: ADD_USER,
    payload: payload
  };
}

export const addUser = (email,userName,password, callback) => dispatch => {
  request
    .post(`${baseUrl}/userSignup`)
    .send({ email,userName,password })
    .then(response => {
      dispatch(signUp(response.body));
      typeof callback == "function" && callback(response.body);
      console.log("success");
    })
    .catch(error => {
      console.log(error); 
    });
};
