import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { projectLogin } from "../../actions/projectActions";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import {createProjectPopup, closePopupAction} from "../../actions/popupActions";
import { createProject } from "../../actions/projectActions";
import {getProjects} from "../../actions/projectActions";
import {createLine} from "../../actions/lineActions";
import {pageContent} from "../../lang/pageContent";
import {errorText} from "../../lang/errorText";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
      // left: "50%",
    },
  },
  pageContent: {
    // margin: theme.spacing(1),
    // padding: theme.spacing(3),
    // width: "80%",
    alignContent: "center",
    padding: "0 15px 0 15px",
    width: 350,
    spacing: theme.spacing(5),
  },
  button: {
    // color:"#ffffff",
    // maxWidth: "100px",
    width : "100px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "##0146ab",
    marginTop:"5%",
    marginBottom:"3%",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

export default function CreateLineProjectDashboardForm() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const userName = useSelector((reduxState) => reduxState.userLoginReducer.userName);

  
  // const userName = user && user.userName
  const [formData, setFormData] = useState({
    lineName: "",
  });
  
  const referenceNumber = useSelector(reduxState => reduxState.projectLoginReducer);

  const [errorMessage, setErrorMessage] = React.useState("false");

// function checkCountry(){}
  
  function checkFormData() {
    if (!formData.lineName) {
      setErrorMessage(true);
    }else{
      setErrorMessage(false);
    }
  }

  function newLine(){
      if(errorMessage == false){
        dispatch(
            createLine(
              formData.lineName,
              (response) => {
                if (response.message === "Success") {
                console.log("Successfully received lines")
                dispatch(closePopupAction());
              }
            }
            )
          )
      }
  }

  const handleChange1 = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    console.log("value of form data is:", formData.lineName);
    checkFormData();
  };
 


  const handleSubmit = (event) => {
    event.preventDefault();
    checkFormData();
    newLine();
  };

  return (
    <Paper className={classes.pageContent} elevation={0}>
    <form  noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid 
      className={classes.root} 
      Container align='center' direction={"column"} spacing={3}>
        <Grid Item >
      <TextField
              required
              variant="outlined"
              id="lineName"
              label={pageContent[`${selectedLang}`].lineName}
              name="lineName"
              onChange={handleChange1}
            />
        </Grid>
        {errorMessage == true && (
            <p style={{ color: "red" }}>{errorText[`${selectedLang}`].erorr114}</p>
          )}{" "}
        <Button
            type="submit"
            // fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
          >
            {pageContent[`${selectedLang}`].submit}
          </Button>
      </Grid>
    </form>
     </Paper> 
  );
}

// export default CreateProjectUserDashboardForm;
