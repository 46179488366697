import React, { useState } from "react";
// import { Container } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { projectLogin } from "../../actions/projectActions";
import Button from "@material-ui/core/Button";
// import "../../semantic.css";
import { useHistory } from "react-router-dom";
import { numberOfLinesToConfigure } from "../../actions";
import { inputReferenceNumber } from "../../actions/contentActions";
import {changeLineName, getProjectLines} from "../../actions/lineActions";
import {renameLinePopup, closePopupAction} from "../../actions/popupActions";
import { pageContent } from "../../lang/pageContent";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
      // left: "50%",
    },
  },
  pageContent: {
    // margin: theme.spacing(1),
    // padding: theme.spacing(3),
    // width: "80%",
    alignContent:"center",
    padding: '0 15px 0 15px', 
    // width: 350,
    width: 500,
    spacing: theme.spacing(5)
  },
}));

export default function RenameLineForm(LineId) {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((reduxState) => reduxState.userLoginReducer);
  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );
  // const userName = user && user.userName
  const [formData, setFormData] = useState({
    name: "",
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("submit was clicked");
    dispatch(changeLineName(formData.name,(response) => {
      if (response.message === "Success") {
        console.log("checking response message aaloo", response);
        dispatch(getProjectLines(response.body));
        // dispatch(renameLinePopup(false));
        dispatch(closePopupAction());
      }
    }));
  };

  return (
    <Paper className={classes.pageContent} elevation={0}>
    <form  noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid 
      className={classes.root} 
      Container align='center' direction={"column"} spacing={3}>
        <Grid Item >
      {/* <h3>Please enter the new Name</h3> */}
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label={pageContent[`${selectedLang}`].enterNewName}
            name="name"
            onChange={handleChange}
          />
        </Grid>
        <br></br>
        <Button
            type="submit"
            // fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
          >
            {pageContent[`${selectedLang}`].submit}
          </Button>
      </Grid>
    </form>
     </Paper> 
  );
}

