import React, { useEffect, useState } from "react";
import "../material.css";
import { selectedLineId } from "../actions/contentActions";
import AddProjectIcon from "../components/icons/AddProjectIcon";
import { useDispatch, useSelector } from "react-redux";
import { getProjects } from "../actions/projectActions";
import { useHistory } from "react-router-dom";
import {
  selectedProjectReferenceNumber,
  fakeProjectLogin,
} from "../actions/projectActions";
import useTable from "../components/useTable";
import Popup from "../components/Popup";
import RenameProjectForm from "../components/forms/RenameProjectForm";
import DeleteProjectForm from "../components/forms/DeleteProjectForm";
import CreateProjectUserDashboardForm from "../components/forms/CreateProjectUserDashboardForm";
import ChangeProjectPasswordForm from "../components/forms/ChangeProjectPasswordForm";
import TokenExpiredForm from "../components/forms/TokenExpiredForm";
import Controls from "../components/controls/Controls";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import {
  renameProjectPopup,
  deleteProjectPopup,
  createProjectPopup,
  changeProjectPasswordPopup
} from "../actions/popupActions";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Grid,
  Typography,
  Link,
  CssBaseline,
  Container,
  Button,
  IconButton,
} from "@material-ui/core";
import { pageContent } from "../lang/pageContent";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       margin: theme.spacing(1),
//       width: "11ch",
//     },
//   },
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    //   this changes the border colour
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the border colour on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the border colour on focus(when typing)
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the text colour on typing
    "& .MuiInputBase-root": {
      color: "#000000",
    },
    //   this changes the border radius of the field
    [`& fieldset`]: {
      borderRadius: 35,
      // background:"#ffffff"
    },

    width: "650px",

    // this changes the background to white
    // background: "#ffffff"
  },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    // borderRadius: "35px",
    // marginLeft : "10%",
    // marginRight : "10%"
  },
  button: {
    // color:"#ffffff",
    // width : "20em",
    maxWidth: "200px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "#1a9fe0",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
  searchInput: {
    width: "75%",
  },
  tableContainer: {
    borderRadius: 15,
    margin: "10px 10px",
    maxWidth: 950,
  },
}));

// const TableHead = withStyles((theme) => ({
//   root: {
//     backgroundColor: "#0146ab"
//   }
// }))(MuiTableHead);

export default function UserDashboard() {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    dispatch(getProjects());
    console.log("Use effect in user dashboard");
    // setState((state.data = newProject));
  }, []);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const project = useSelector(
    (reduxState) => reduxState.projectLoginReducer.project
  );

  const user = useSelector((reduxState) => reduxState.userLoginReducer);

  const inputLabels = useSelector(
    (reduxState) => reduxState.inputLabelsReducer
  );

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  console.log("popup state for create project", popupState.createProject);
  // console.log("popup state for popupState.displayResultsError ",popupState.displayResultsError);

  const deletePopupState = useSelector(
    (reduxState) => reduxState.popupReducer.deleteLine
  );

  // const referenceNumber = useSelector(
  //   (reduxState) => reduxState.projectLoginReducer.project.referenceNumber
  // );

  const [openPopup, setOpenPopup] = useState(popupState);
  const [openDeleteLinePopup, setOpenDeleteLinePopup] =
    useState(deletePopupState);

  const allProjects = useSelector(
    (reduxState) => reduxState.userProjectsReducer.data
  );

  const newLine = useSelector((reduxState) => reduxState.newLineReducer);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    data: newLine,
  });

  const records = allProjects;

  // console.log("records", records);

  const projectNameText = pageContent[`${selectedLang}`].projectName;

  const headCells = [
    { id: "lineName", label: pageContent[`${selectedLang}`].projectName },
    { id: "progress", label: pageContent[`${selectedLang}`].referenceNumber },
    // { id: "status", label: "Line Status" },
    { id: "modify", label: pageContent[`${selectedLang}`].editProject },
    { id: "actions", label: pageContent[`${selectedLang}`].projectActions },
  ];

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells);

  const userProjectLogin = (referenceNumber) => {
    dispatch(
      fakeProjectLogin(referenceNumber, (response) => {
        if (response.message === "Success") {
          history.push("/projectDashboard");
        }
      })
    );
  };

  const handleClick4 = () => {
    history.push("/openProjectFormPage");
  };

  const displayResultsPass = false;

  return (
    <React.Fragment>
      <CssBaseline />
      {!user.jwt && history.push("/")}
      <Container maxWidth="sm" className={"userDashboard"}>
        <Grid container direction={"column"} maxWidth="xs" spacing={2}>
          <Grid
            item
            style={{
              position: "absolute",
              left: "10%",
              top: "10%",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              // inputProps={{ style: { fontSize: 300 } }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].trunkingConfigurator}
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].userDashboard}
            </Typography>
            <br></br>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              // inputProps={{ style: { fontSize: 300 } }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].welcome} {user.userName}!
            </Typography>
            <br></br>
            <br></br>
            <br></br>
            {/* <Typography variant="body2">
              <Link
                // href="/openProjectFormPage"
                onClick={() => history.push("/")}
                // underline="always"
                style={{
                  color: "#ffffff",
                  fontWeight: 350,
                  fontSize: "15px",
                  cursor : "pointer"
                }}
              >
                {"How to use User Dashboard"}
              </Link>
            </Typography> */}
          </Grid>
        </Grid>
      </Container>
      {/* <div> */}
      <header></header>
      <Paper className={classes.pageContent} elevation={0}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting() &&
              recordsAfterPagingAndSorting().map((project) => (
                <TableRow key={project.id}>
                  <TableCell>{project.projectName}</TableCell>
                  <TableCell>
                    {project.referenceNumber}
                    <CopyToClipboard text={project.referenceNumber}>
                      <IconButton>
                        <Tooltip
                          title={pageContent[`${selectedLang}`].copyToClipboard}
                        >
                          <FileCopyIcon
                            fontSize="small"
                            style={{ color: "#0146ab" }}
                          />
                        </Tooltip>
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                  {/* <TableCell>{project.status}</TableCell> */}
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      onClick={() => {
                        dispatch(
                          selectedProjectReferenceNumber(
                            project.referenceNumber
                          )
                        );
                        userProjectLogin(project.referenceNumber);
                      }}
                    >
                      {pageContent[`${selectedLang}`].configure}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Controls.ActionButton color="primary">
                      <Tooltip
                        title={pageContent[`${selectedLang}`].renameProject}
                      >
                        <EditIcon
                          fontSize="small"
                          onClick={() => {
                            dispatch(
                              renameProjectPopup({
                                status: true,
                                referenceNumber: project.referenceNumber,
                              })
                            );
                          }}
                        />
                      </Tooltip>
                    </Controls.ActionButton>
                    <Controls.ActionButton>
                      <Tooltip
                        title={pageContent[`${selectedLang}`].changePassword}
                      >
                        <VpnKeyIcon
                          fontSize="small"
                          style={{ color: "#67dbd7" }} // Change the color to #9beb34
                          onClick={() => {
                            dispatch(
                              changeProjectPasswordPopup({
                                status: true,
                                referenceNumber: project.referenceNumber,
                              })
                            );
                          }}
                        />
                      </Tooltip>
                    </Controls.ActionButton>
                    <Controls.ActionButton color="secondary">
                      <Tooltip
                        title={pageContent[`${selectedLang}`].deleteProject}
                      >
                        <DeleteIcon
                          fontSize="small"
                          onClick={() => {
                            dispatch(
                              deleteProjectPopup({
                                status: true,
                                referenceNumber: project.referenceNumber,
                              })
                            );
                          }}
                        />
                      </Tooltip>
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />

        <Grid
          container
          alignItems="center"
          direction="row"
          justify="center"
          spacing={1}
        >
          <Grid item alignItems="center">
            <AddProjectIcon />
          </Grid>
        </Grid>
      </Paper>
      <br></br>
      <div></div>
      <Popup
        openPopup={popupState.renameProject}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].enterNewProjectName}
      >
        <RenameProjectForm />
      </Popup>
      <Popup
        openPopup={popupState.changeProjectPassword}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].enterNewProjectPassword}
      >
        <ChangeProjectPasswordForm />
      </Popup>
      <Popup
        openPopup={popupState.deleteProject}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].deleteProjectConfirm}
      >
        <DeleteProjectForm />
      </Popup>

      <Popup
        openPopup={popupState.createProject}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].createAProject}
      >
        <CreateProjectUserDashboardForm />
      </Popup>
      <Popup
        openPopup={popupState.tokenExpired}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].attention}
      >
        <TokenExpiredForm />
      </Popup>
    </React.Fragment>
  );
}
