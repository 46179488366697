export const helpPageContent = {
  english: {
    introLine: "Welcome to Opple lighting’s trunking configurator! The best tool to calculate all the components you need for your LED Trunking projects",
    glossary : "Glossary",
    introduction : "Introduction",
    homePage : "Homepage",
    configurator:"Configurator",
    results : "Results",
    navAndBurgerMenu : "Navigation and Burger Menu",
    introLine1:"This manual explains how to use the trunking configurator as a guest or as a user. You may follow the instructions that follow to be able to create a trunking project, assign and configure trunking lines, and finally get the list of the components that your project would need.",
    introLine2:"If you need any assistance, please contact data@opple.com.",
    homeLine1 : "At the homepage of the trunking configurator, you can choose the language that you want to use for the application on the top right corner of the screen.",
    homeLine2 : "You will see that at the center of the screen there are three buttons",
    homeLine3 : 'If you use the trunking configurator frequently, we advise to create an account by clicking on "Sign up" to later login as a user so you may access all the projects you wish to create or revisit.',
    homeLine4 : 'If you wish to open an existing project or to create a one-time project, click on the button "Continue as Guest".',
    guestLine1 : "By clicking on this button, you will have two options",
    guestLine2 : "Click on this button to open an existing project. You will need a project reference number and password. This is useful if someone shared a particular project with you, or if you already have a project you would like to open.",
    guestLine3 : "Click on this button to create a new project. ",
    guestLine4 : "Give your project a name in the “Project Name” field, and a password.",
    guestLine5 : 'Click on the "Generate Number" button, your reference number will appear.',
    guestLine6 : 'Click on the button "Log in".',
    guestNote1 : "Important note: it is important to remember or note down your password since we cannot retrieve it for you in case you forget it. However, your internet browser can also save it for you.",
    guestNote2 : 'Important note: Make sure to copy the reference number by clicking on the "copy" icon next to the reference number.',
    guestLine7 : "Paste the reference number in the reference number field and enter your password. Then click submit to open your project. If you forgot to copy your reference number, you can go back in your internet browser and you can find your reference number in the previous page. After this step you will arrive at your Project Dashboard page.",
    projectDasboardLine1 : "The project dashboard shows you your project lines and allows you to configure each line according to your trunking plan. You can see a project line's name, LED module, and status.",
    projectDasboardLine2 : "Select the country of your project.",
    projectDasboardLine3 : "Click on the "+" button to add a LED trunking line. A pop-up will open to select the country of your project in case you didn’t do so. This will help us plan our stock accordingly.",
    projectDasboardLine4 : "Once you select a country, click the "+" button again and a pop-up will appear which will allow you to give your line a name (i.e. Hallway B).",
    projectDasboardLine5 : "You may edit the name of the line later by clicking on the pencil icon or delete it by clicking on the red bin icon. ",
    projectDasboardLine6 : 'Click on "Configure" to start customizing your line.',
    theConfiguration : "The Configuration",
    configurationLine1 : "Our platform runs multiple algorithms that calculate the correct products that you will need for your trunking project according to your selections. Proceed by filling in the answers to the questions of each section, and click “Next” to continue to the next section.",
    configurationLine2 : 'Click on the "?" icon next to each question for more information about that question.',
    configurationLine3 : 'After completing your configuration, click on "Save line configuration" and you will return to the project dashboard.',
    configurationLine4 : 'Repeat this process for as many lines as you need. Then click on the button "Display results" on the bottom right corner of the screen.',
    resultsLine1 : "Once you click on the button “Display results”, you will see a table with an overview of the products you need for your project, and below it you will find a table for each line that includes all the products that you need for your trunking project.",
    resultsLine2 : '"You may add a comment at the bottom of each table by clicking on the red text icon if you wish to indicate any remarks. (i.e. “This line faces the main entrance.”)"',
    resultsLine3 : 'If you wish to modify your project, clicking on the bottom left button "Go to Dashboard", then repeat the steps of point 4. Project Dashboard.',
    resultsLine4 : 'To download the results in pdf form, click on the button "Download PDF without prices".',
    resultsLine5 : 'If you want to receive a quotation from Opple for your project, click on the "Request for a quotation" button on the bottom right corner of the screen.',
    signUpLine1 : "If you use the Trunking Configurator frequently, we advise you to sign up for an account so you may keep track of all of your projects.",
    signUpLine2 : 'Click on the "Sign up" button in the homepage to make an account, then type a Username and a Password.',
    signUpNote : "Important note: it is important to remember or note down your password since we cannot retrieve it for you in case you forget it. However, your internet browser can also save it for you.",
    userDashboardLine1 : "You will arrive to the User Dashboard. Here you have all your projects, and you may access or modify any of your projects.",
    userDashboardLine2 : "Click on the "+" button to create a new project. a pop-up will appear which will allow you to give your project a name (i.e. Eindhoven Supermarket).",
    userDashboardLine3 : 'You may edit the name of the project later by clicking on the pencil icon or delete it by clicking on the red bin icon. Click on the "Open Project" button to open your project.',
    userDashboardLine4 : "You will arrive at the project dashboard described in point 4. Project Dashboard.",
    loginAsUserLine1 : 'If you already have an account, you can log into your User Dashboard by clicking on the button "Log in as User" and entering your Username and Password accordingly. You will arrive at the user dashboard described in point 8. User Dashboard.',
    BurgerMenuLine1 : "You can navigate yourself to any page by clicking the burger menu on the top left corner of the screen.",
    BurgerMenuLine2 : "You may find all the locations you wish to go to accordingly. These locations are",
    letterA : "A",
    letterB : "B",
    
  },
  dutch: {
    introLine: "Welkom bij Opple lighting's trunking configurator! De beste tool om alle componenten te berekenen die u nodig heeft voor uw LED Trunking projecten",
    glossary : 'Woordenlijst',
    introduction : 'Introductie',
    homePage : 'Homepage',
    configurator:'Configurator',
    results : 'Resultaten',
    navAndBurgerMenu :'Navigatie en menu Burger',
    introLine1:'Deze handleiding legt uit hoe u de trunkingconfigurator kunt gebruiken als gast of als gebruiker. U kunt de volgende instructies volgen om een trunking project aan te maken, trunking lijnen toe te wijzen en te configureren, en tenslotte de lijst te krijgen van de componenten die uw project nodig zou hebben.',
    introLine2: 'Indien u hulp nodig heeft, neem dan contact op met data@opple.com.',
    homeLine1 : 'Op de startpagina van de trunking configurator, kunt u de taal kiezen die u wilt gebruiken voor de applicatie in de rechterbovenhoek van het scherm.',
    homeLine2 : 'U zult zien dat er in het midden van het scherm drie knoppen zijn:',
    homeLine3 : 'Als u de trunkingconfigurator vaak gebruikt, raden wij u aan om een account aan te maken door op "Sign up" te klikken om later als gebruiker in te loggen zodat u toegang heeft tot alle projecten die u wenst aan te maken of te herbekijken.',
    homeLine4 : 'Indien u een bestaand project wenst te openen of een eenmalig project wenst aan te maken, klik dan op de knop "Doorgaan als Gast".',
    guestLine1 : 'Door op deze knop te klikken, heeft u twee opties:',
    guestLine2 : 'Klik op deze knop om een bestaand project te openen. U heeft een projectreferentienummer en wachtwoord nodig. Dit is handig als iemand een bepaald project met u heeft gedeeld, of als u al een project heeft dat u wilt openen.',
    guestLine3 : 'Klik op deze knop om een nieuw project aan te maken.',
    guestLine4 : 'Geef uw project een naam in het veld "Projectnaam", en een wachtwoord.',
    guestLine5 : 'Klik op de knop "Nummer genereren", uw referentienummer verschijnt.',
    guestLine6 : 'Klik op de knop "Log in".',
    guestLine7 : 'Plak het referentienummer in het veld Referentienummer en voer uw wachtwoord in. Klik vervolgens op submit om uw project te openen. Als u vergeten bent uw referentienummer te kopiëren, kunt u teruggaan in uw internetbrowser en kunt u uw referentienummer terugvinden op de vorige pagina. Na deze stap komt u op uw Project Dashboard pagina.',
    guestNote1 : 'Belangrijke opmerking: het is belangrijk dat u uw wachtwoord onthoudt of noteert, aangezien wij het niet voor u kunnen opvragen als u het vergeet. Uw internetbrowser kan het echter ook voor u opslaan.',
    guestNote2 : 'Belangrijke opmerking: Zorg ervoor dat u het referentienummer kopieert door op het pictogram "kopiëren" naast het referentienummer te klikken. ',
    projectDasboardLine1 : 'Het project dashboard toont u uw project lijnen en stelt u in staat om elke lijn te configureren volgens uw trunking plan. U kunt de naam, LED module en status van een projectlijn zien.',
    projectDasboardLine2 : 'Selecteer het land van uw project.',
    projectDasboardLine3 : 'Klik op de "+" knop om een LED trunking lijn toe te voegen. Een pop-up zal openen om het land van uw project te selecteren voor het geval u dit niet heeft gedaan. Dit zal ons helpen onze voorraad dienovereenkomstig te plannen.',
    projectDasboardLine4 : 'Zodra u een land heeft geselecteerd, klik nogmaals op de "+" knop en een pop-up zal verschijnen die u toelaat om uw lijn een naam te geven (b.v. Hal B).',
    projectDasboardLine5 : 'U kunt de naam van de lijn later wijzigen door op het potlood-icoon te klikken of de lijn verwijderen door op het rode prullenbak-icoon te klikken. ',
    projectDasboardLine6 : 'Klik op "Configureren" om te beginnen met het aanpassen van uw lijn.',
    theConfiguration : 'De Configuratie',
    configurationLine1 : 'Ons platform werkt met meerdere algoritmes die aan de hand van uw selecties de juiste producten berekenen die u nodig zult hebben voor uw trunkingproject. Ga verder door de antwoorden op de vragen van elke sectie in te vullen, en klik op "Volgende" om door te gaan naar de volgende sectie.',
    configurationLine2 : 'Klik op het "?" icoon naast elke vraag voor meer informatie over die vraag. ',
    configurationLine3 : 'Nadat u uw configuratie hebt voltooid, klikt u op "Lijnconfiguratie opslaan" en u keert terug naar het projectdashboard.',
    configurationLine4 : 'Herhaal dit proces voor zoveel regels als u nodig hebt. Klik vervolgens op de knop "Resultaten weergeven" in de rechterbenedenhoek van het scherm.',
    resultsLine1 : 'Zodra u op de knop "Resultaten weergeven" klikt, ziet u een tabel met een overzicht van de producten die u nodig heeft voor uw project, en daaronder vindt u voor elke lijn een tabel met alle producten die u nodig heeft voor uw trunking project.',
    resultsLine2 : '"U kunt onderaan elke tabel een commentaar toevoegen door op het rode teksticoon te klikken indien u opmerkingen wenst te vermelden.(b.v. Deze lijn is gericht naar de hoofdingang."")"""',
    resultsLine3 : 'Als u uw project wilt wijzigen, klikt u linksonder op de knop "Ga naar Dashboard" en herhaalt u vervolgens de stappen van punt 4. Project Dashboard.',
    resultsLine4 : 'Om de resultaten in pdf-vorm te downloaden, klikt u op de knop "Download PDF zonder prijzen".',
    resultsLine5 : 'Indien u een offerte van Opple wenst te ontvangen voor uw project, klik dan op de knop "Offerte aanvragen" rechtsonder in het scherm.',
    signUpLine1 : 'Als u de Trunking Configurator vaak gebruikt, adviseren wij u om een account aan te maken zodat u al uw projecten kunt bijhouden.',
    signUpLine2 : 'Klik op de "Sign up" knop op de homepage om een account aan te maken, typ vervolgens een Gebruikersnaam en een Wachtwoord.',
    signUpNote : 'Belangrijke opmerking: het is belangrijk dat u uw wachtwoord onthoudt of noteert, aangezien wij het niet voor u kunnen opvragen als u het vergeet. Uw internetbrowser kan het echter ook voor u opslaan.',
    userDashboardLine1 : 'U komt dan op het Gebruikers Dashboard. Hier staan al uw projecten, en u kunt elk van uw projecten openen of wijzigen. ',
    userDashboardLine2 : 'Klik op de "+" knop om een nieuw project aan te maken. er verschijnt een pop-up waarin u uw project een naam kunt geven (bijv. Eindhoven Supermarkt).',
    userDashboardLine3 : 'U kunt de naam van het project later wijzigen door op het potlood-icoon te klikken of het verwijderen door op het rode prullenbak-icoon te klikken. Klik op de knop "Open Project" om uw project te openen.',
    userDashboardLine4 : 'U komt op het projectdashboard zoals beschreven in punt 4. Project Dashboard.',
    loginAsUserLine1 : 'Als u al een account heeft, kunt u inloggen op uw gebruikersdashboard door op de knop "Log in als gebruiker" te klikken en uw gebruikersnaam en wachtwoord in te voeren. U komt dan op het gebruikersdashboard zoals beschreven in punt 8. Gebruikers Dashboard.',
    BurgerMenuLine1 : 'U kunt zelf naar elke pagina navigeren door op het hamburgermenu linksboven in het scherm te klikken.',
    BurgerMenuLine2 : 'U kunt dan alle locaties vinden waar u naar toe wilt gaan. Deze locaties zijn:',
  },
  french: {
    introLine: "Bienvenue sur le configurateur de goulottes d'Opple lighting ! Le meilleur outil pour calculer tous les composants dont vous avez besoin pour vos projets de goulottes LED.",
    glossary : 'Glossaire',
    introduction : 'Introduction',
    homePage : "Page d'accueil",
    configurator:'Configurateur',
    results : 'Résultats',
    navAndBurgerMenu :'Navigation et menu Burger',
    introLine1:"Ce manuel explique comment utiliser le configurateur de lignes réseau en tant qu'invité ou en tant qu'utilisateur. Vous pouvez suivre les instructions qui suivent pour créer un projet de liaison, attribuer et configurer des lignes de liaison, et enfin obtenir la liste des composants dont votre projet a besoin.",
    introLine2:"Si vous avez besoin d'aide, veuillez contacter data@opple.com.",
    homeLine1 : "Sur la page d'accueil du configurateur de lignes de jonction, vous pouvez choisir la langue que vous souhaitez utiliser pour l'application dans le coin supérieur droit de l'écran.",
    homeLine2 : "Vous verrez qu'au centre de l'écran se trouvent trois boutons :",
    homeLine3 : `Si vous utilisez fréquemment le configurateur de goulottes, nous vous conseillons de créer un compte en cliquant sur "S'inscrire" pour vous connecter ensuite en tant qu'utilisateur afin d'accéder à tous les projets que vous souhaitez créer ou revisiter.`,
    homeLine4 : `Si vous souhaitez ouvrir un projet existant ou créer un projet ponctuel, cliquez sur le bouton "Continuer en tant qu'invité".`,
    guestLine1 : 'En cliquant sur ce bouton, vous aurez deux options :',
    guestLine2 : `Cliquez sur ce bouton pour ouvrir un projet existant. Vous aurez besoin d'un numéro de référence de projet et d'un mot de passe. Ceci est utile si quelqu'un a partagé un projet particulier avec vous, ou si vous avez déjà un projet que vous souhaitez ouvrir.`,
    guestLine3 : 'Cliquez sur ce bouton pour créer un nouveau projet. ',
    guestLine4 : 'Donnez un nom à votre projet dans le champ "Nom du projet", et un mot de passe.',
    guestLine5 : `Cliquez sur le bouton "Générer le numéro", votre numéro de référence apparaîtra. `,
    guestLine6 : 'Cliquez sur le bouton "Log in".',
    guestLine7 : `Collez le numéro de référence dans le champ du numéro de référence et entrez votre mot de passe. Cliquez ensuite sur "soumettre" pour ouvrir votre projet. Si vous avez oublié de copier votre numéro de référence, vous pouvez revenir en arrière dans votre navigateur Internet et vous trouverez votre numéro de référence dans la page précédente. Après cette étape, vous arriverez à la page du tableau de bord de votre projet.`,
    guestNote1 : `Remarque importante : il est important de se souvenir ou de noter votre mot de passe car nous ne pouvons pas le récupérer pour vous en cas d'oubli. Cependant, votre navigateur Internet peut également le sauvegarder pour vous.`,
    guestNote2 : `Remarque importante : veillez à copier le numéro de référence en cliquant sur l'icône "copier" à côté du numéro de référence. `,
    projectDasboardLine1 : `Le tableau de bord du projet vous montre vos lignes de projet et vous permet de configurer chaque ligne en fonction de votre plan d'acheminement. Vous pouvez voir le nom, le module LED et le statut d'une ligne de projet.`,
    projectDasboardLine2 : `Sélectionnez le pays de votre projet.`,
    projectDasboardLine3 : `Cliquez sur le bouton "+" pour ajouter une ligne de goulotte LED. Une fenêtre pop-up s'ouvrira pour sélectionner le pays de votre projet au cas où vous ne l'auriez pas fait. Cela nous aidera à planifier notre stock en conséquence.`,
    projectDasboardLine4 : 'Une fois que vous avez sélectionné un pays, cliquez à nouveau sur le bouton "+" et une fenêtre pop-up apparaîtra pour vous permettre de donner un nom à votre ligne (par exemple, Hallway B).',
    projectDasboardLine5 : `Vous pourrez modifier le nom de la ligne ultérieurement en cliquant sur l'icône du crayon ou le supprimer en cliquant sur l'icône de la poubelle rouge. `,
    projectDasboardLine6 : 'Cliquez sur "Configurer" pour commencer à personnaliser votre ligne.',
    theConfiguration : 'La configuration',
    configurationLine1 : 'Notre plateforme exécute plusieurs algorithmes qui calculent les produits corrects dont vous aurez besoin pour votre projet de goulotte en fonction de vos sélections. Remplissez les réponses aux questions de chaque section et cliquez sur "Suivant" pour passer à la section suivante.',
    configurationLine2 : `Cliquez sur l'icône " ?" à côté de chaque question pour obtenir plus d'informations sur cette question. `,
    configurationLine3 : 'Après avoir terminé votre configuration, cliquez sur "Enregistrer la configuration de la ligne" et vous retournerez au tableau de bord du projet.',
    configurationLine4 : `Répétez ce processus pour autant de lignes que vous le souhaitez. Cliquez ensuite sur le bouton "Afficher les résultats" dans le coin inférieur droit de l'écran.`,
    resultsLine1 : `Une fois que vous avez cliqué sur le bouton "Afficher les résultats", vous verrez un tableau avec un aperçu des produits dont vous avez besoin pour votre projet, et en dessous, vous trouverez un tableau pour chaque ligne qui comprend tous les produits dont vous avez besoin pour votre projet de goulotte.`,
    resultsLine2 : `" Vous pouvez ajouter un commentaire au bas de chaque tableau en cliquant sur l'icône de texte rouge si vous souhaitez indiquer des remarques.(ex : Cette ligne fait face à l'entrée principale."")"""`,
    resultsLine3 : `Si vous souhaitez modifier votre projet, en cliquant sur le bouton en bas à gauche "Aller au tableau de bord", puis répétez les étapes du point 4. Tableau de bord du projet.`,
    resultsLine4 : `Pour télécharger les résultats au format pdf, cliquez sur le bouton "Télécharger le PDF sans les prix".`,
    resultsLine5 : `Si vous souhaitez recevoir un devis d'Opple pour votre projet, cliquez sur le bouton "Demander un devis" dans le coin inférieur droit de l'écran.`,
    signUpLine1 : `Si vous utilisez fréquemment le configurateur de goulottes, nous vous conseillons de créer un compte afin de pouvoir suivre tous vos projets.`,
    signUpLine2 : `Cliquez sur le bouton "Sign up" de la page d'accueil pour créer un compte, puis saisissez un nom d'utilisateur et un mot de passe.`,
    signUpNote : `Remarque importante : il est important de se souvenir ou de noter votre mot de passe car nous ne pouvons pas le récupérer pour vous en cas d'oubli. Cependant, votre navigateur Internet peut également le sauvegarder pour vous.`,
    userDashboardLine1 : `Vous arriverez au tableau de bord de l'utilisateur. Ici, vous avez tous vos projets, et vous pouvez accéder à n'importe lequel de vos projets ou le modifier. `,
    userDashboardLine2 : `Cliquez sur le bouton "+" pour créer un nouveau projet. Une fenêtre pop-up apparaîtra qui vous permettra de donner un nom à votre projet (par exemple Supermarché Eindhoven).`,
    userDashboardLine3 : `Vous pourrez modifier le nom du projet ultérieurement en cliquant sur l'icône du crayon ou le supprimer en cliquant sur l'icône de la poubelle rouge. Cliquez sur le bouton "Ouvrir le projet" pour ouvrir votre projet.`,
    userDashboardLine4 : `Vous arriverez au tableau de bord du projet décrit au point 4. Tableau de bord du projet.`,
    loginAsUserLine1 : `Si vous avez déjà un compte, vous pouvez vous connecter à votre tableau de bord utilisateur en cliquant sur le bouton "Se connecter en tant qu'utilisateur" et en saisissant votre nom d'utilisateur et votre mot de passe en conséquence. Vous arriverez au tableau de bord de l'utilisateur décrit au point 8. Tableau de bord de l'utilisateur.`,
    BurgerMenuLine1 : `Vous pouvez vous rendre sur n'importe quelle page en cliquant sur le menu "burger" situé dans le coin supérieur gauche de l'écran.`,
    BurgerMenuLine2 : 'Vous pouvez trouver tous les endroits où vous souhaitez vous rendre. Ces emplacements sont les suivants :',
  },
  german:  {
    introLine: `Willkommen beim Lichtbandkonfigurator von Opple lighting! Das beste Werkzeug, um alle Komponenten zu berechnen, die Sie für Ihre LED-Lichtbandprojekte benötigen`,
    glossary : `Glossar`,
    introduction : `Einführung`,
    homePage : `Startseite`,
    configurator: `Konfigurator`,
    results : `Ergebnisse`,
    navAndBurgerMenu : `Navigation und Burger-Menü`,
    introLine1: `In diesem Handbuch wird erklärt, wie Sie den Bündelfunkkonfigurator als Gast oder als Benutzer verwenden können. Sie können die folgenden Anweisungen befolgen, um ein Trunking-Projekt zu erstellen, Trunking-Leitungen zuzuweisen und zu konfigurieren und schließlich die Liste der Komponenten zu erhalten, die für Ihr Projekt benötigt werden.`,
    introLine2: `Wenn Sie Hilfe benötigen, wenden Sie sich bitte an data@opple.com.`,
    homeLine1 : `Auf der Startseite des Trunking-Konfigurators können Sie in der rechten oberen Ecke des Bildschirms die Sprache auswählen, die Sie für die Anwendung verwenden möchten.`,
    homeLine2 : `In der Mitte des Bildschirms befinden sich drei Schaltflächen:`,
    homeLine3 : `Wenn Sie den Kanalkonfigurator häufig benutzen, empfehlen wir Ihnen, ein Konto zu erstellen, indem Sie auf "Anmelden" klicken, um sich später als Benutzer anzumelden, damit Sie auf alle Projekte zugreifen können, die Sie erstellen oder wieder besuchen möchten.`,
    homeLine4 : `Wenn Sie ein bestehendes Projekt öffnen oder ein einmaliges Projekt erstellen möchten, klicken Sie auf die Schaltfläche "Als Gast fortfahren".`,
    guestLine1 : `Wenn Sie auf diese Schaltfläche klicken, haben Sie zwei Möglichkeiten:`,
    guestLine2 : `Klicken Sie auf diese Schaltfläche, um ein bestehendes Projekt zu öffnen. Sie benötigen eine Projektreferenznummer und ein Passwort. Dies ist nützlich, wenn jemand ein bestimmtes Projekt mit Ihnen geteilt hat, oder wenn Sie bereits ein Projekt haben, das Sie öffnen möchten.`,
    guestLine3 : `Klicken Sie auf diese Schaltfläche, um ein neues Projekt zu erstellen. `,
    guestLine4 : `Geben Sie Ihrem Projekt im Feld "Projektname" einen Namen und ein Passwort.`,
    guestLine5 : `Klicken Sie auf die Schaltfläche "Nummer generieren", dann erscheint Ihre Referenznummer. `,
    guestLine6 : `Klicken Sie auf die Schaltfläche "Anmelden".`,
    guestLine7 : `Fügen Sie die Referenznummer in das Feld "Referenznummer" ein und geben Sie Ihr Passwort ein. Klicken Sie dann auf "Senden", um Ihr Projekt zu öffnen. Wenn Sie vergessen haben, Ihre Referenznummer zu kopieren, können Sie in Ihrem Internetbrowser zurückgehen und Ihre Referenznummer auf der vorherigen Seite finden. Nach diesem Schritt gelangen Sie auf Ihre Projekt-Dashboard-Seite.`,
    guestNote1 : `Wichtiger Hinweis: Es ist wichtig, dass Sie sich Ihr Passwort merken oder notieren, da wir es nicht für Sie abrufen können, falls Sie es vergessen. Ihr Internet-Browser kann es aber auch für Sie speichern.`,
    guestNote2 : `Wichtiger Hinweis: Achten Sie darauf, dass Sie die Referenznummer kopieren, indem Sie auf das Symbol "Kopieren" neben der Referenznummer klicken. `,
    projectDasboardLine1 : `Das Projekt-Dashboard zeigt Ihnen Ihre Projektleitungen und ermöglicht es Ihnen, jede Leitung entsprechend Ihrem Trunking-Plan zu konfigurieren. Sie können den Namen, das LED-Modul und den Status einer Projektleitung sehen.`,
    projectDasboardLine2 : `Wählen Sie das Land Ihres Projekts aus.`,
    projectDasboardLine3 : `Klicken Sie auf die Schaltfläche "+", um eine LED-Funkleitung hinzuzufügen. Es öffnet sich ein Pop-up-Fenster, in dem Sie das Land Ihres Projekts auswählen können, falls Sie dies nicht getan haben. Dies hilft uns, unseren Bestand entsprechend zu planen.`,
    projectDasboardLine4 : `Sobald Sie ein Land ausgewählt haben, klicken Sie erneut auf die Schaltfläche "+" und ein Pop-up-Fenster erscheint, in dem Sie Ihrer Leitung einen Namen geben können (z. B. Flur B).`,
    projectDasboardLine5 : `Sie können den Namen der Linie später bearbeiten, indem Sie auf das Bleistiftsymbol klicken, oder ihn löschen, indem Sie auf das rote Mülltonnensymbol klicken. `,
    projectDasboardLine6 : `Klicken Sie auf "Konfigurieren", um mit der Anpassung Ihrer Leitung zu beginnen.`,
    theConfiguration : `Die Konfiguration`,
    configurationLine1 : `Unsere Plattform führt mehrere Algorithmen aus, die die richtigen Produkte berechnen, die Sie für Ihr Kanalbauprojekt entsprechend Ihrer Auswahl benötigen. Geben Sie die Antworten auf die Fragen der einzelnen Abschnitte ein und klicken Sie auf "Weiter", um zum nächsten Abschnitt zu gelangen.`,
    configurationLine2 : `Klicken Sie auf das Symbol "?" neben jeder Frage, um weitere Informationen zu dieser Frage zu erhalten. `,
    configurationLine3 : `Nachdem Sie Ihre Konfiguration abgeschlossen haben, klicken Sie auf "Leitungskonfiguration speichern" und Sie kehren zum Projekt-Dashboard zurück.`,
    configurationLine4 : `Wiederholen Sie diesen Vorgang für so viele Zeilen, wie Sie benötigen. Klicken Sie dann auf die Schaltfläche "Ergebnisse anzeigen" in der unteren rechten Ecke des Bildschirms.`,
    resultsLine1 : `Sobald Sie auf die Schaltfläche "Ergebnisse anzeigen" klicken, sehen Sie eine Tabelle mit einer Übersicht über die Produkte, die Sie für Ihr Projekt benötigen, und darunter eine Tabelle für jede Leitung, die alle Produkte enthält, die Sie für Ihr Kanalprojekt benötigen.`,
    resultsLine2 : `"Sie können am unteren Rand jeder Tabelle einen Kommentar hinzufügen, indem Sie auf das rote Textsymbol klicken, wenn Sie Anmerkungen machen möchten.(z.B. Diese Leitung ist dem Haupteingang zugewandt."")"""`,
    resultsLine3 : `Wenn Sie Ihr Projekt ändern möchten, klicken Sie auf die Schaltfläche unten links "Zum Dashboard gehen" und wiederholen Sie die Schritte von Punkt 4. Projekt-Dashboard.`,
    resultsLine4 : `Um die Ergebnisse als PDF herunterzuladen, klicken Sie auf die Schaltfläche "PDF ohne Preise herunterladen".`,
    resultsLine5 : `Wenn Sie ein Angebot von Opple für Ihr Projekt erhalten möchten, klicken Sie auf die Schaltfläche "Angebot anfordern" in der unteren rechten Ecke des Bildschirms.`,
    signUpLine1 : `Wenn Sie den Kanal-Konfigurator häufig benutzen, empfehlen wir Ihnen, ein Konto einzurichten, damit Sie den Überblick über alle Ihre Projekte behalten können.`,
    signUpLine2 : `Klicken Sie auf die Schaltfläche "Anmelden" auf der Startseite, um ein Konto zu erstellen, und geben Sie dann einen Benutzernamen und ein Passwort ein.`,
    signUpNote : `Wichtiger Hinweis: Es ist wichtig, dass Sie sich Ihr Passwort merken oder notieren, da wir es nicht für Sie abrufen können, falls Sie es vergessen. Ihr Internetbrowser kann es aber auch für Sie speichern.`,
    userDashboardLine1 : `Sie gelangen zum User Dashboard. Hier finden Sie alle Ihre Projekte, und Sie können auf jedes Ihrer Projekte zugreifen oder es ändern. `,
    userDashboardLine2 : `Klicken Sie auf die Schaltfläche "+", um ein neues Projekt anzulegen. Es erscheint ein Pop-up-Fenster, in dem Sie Ihrem Projekt einen Namen geben können (z. B. Eindhoven Supermarket).`,
    userDashboardLine3 : `Sie können den Namen des Projekts später ändern, indem Sie auf das Bleistiftsymbol klicken, oder ihn löschen, indem Sie auf das rote Mülleimer-Symbol klicken. Klicken Sie auf die Schaltfläche "Projekt öffnen", um Ihr Projekt zu öffnen.`,
    userDashboardLine4 : `Sie gelangen zum Projekt-Dashboard, das unter Punkt 4. Projekt-Dashboard.`,
    loginAsUserLine1 : `Wenn Sie bereits ein Konto haben, können Sie sich in Ihr Benutzer-Dashboard einloggen, indem Sie auf die Schaltfläche "Als Benutzer einloggen" klicken und Ihren Benutzernamen und Ihr Passwort eingeben. Sie gelangen dann zu dem unter Punkt 8 beschriebenen User Dashboard. Benutzer-Dashboard.`,
    BurgerMenuLine1 : `Sie können zu jeder beliebigen Seite navigieren, indem Sie auf das Burger-Menü in der oberen linken Ecke des Bildschirms klicken.`,
    BurgerMenuLine2 : `Dort finden Sie alle Seiten, zu denen Sie gehen möchten. Diese Orte sind:`,
  },
  spanish: {
    introLine: `Bienvenido al configurador de canalizaciones de Opple. La mejor herramienta para calcular todos los componentes que necesita para sus proyectos de canalización LED`,
    glossary : `Glosario`,
    introduction : `Introducción`,
    homePage : `Página web`,
    configurator: `Configurador`,
    results : `Resultados`,
    navAndBurgerMenu : `Menú de navegación y hamburguesa`,
    introLine1: `Este manual explica cómo utilizar el configurador de trunking como invitado o como usuario. Puede seguir las instrucciones que siguen para poder crear un proyecto de trunking, asignar y configurar las líneas de trunking y, finalmente, obtener la lista de los componentes que necesitaría su proyecto.`,
    introLine2: `Si necesita ayuda, póngase en contacto con data@opple.com.`,
    homeLine1 : `En la página de inicio del configurador de trunking, puedes elegir el idioma que quieres utilizar para la aplicación en la esquina superior derecha de la pantalla.`,
    homeLine2 : `Verá que en el centro de la pantalla hay tres botones:`,
    homeLine3 : `Si utiliza el configurador de trunking con frecuencia, le aconsejamos que se cree una cuenta haciendo clic en "Registrarse" para posteriormente entrar como usuario y poder acceder a todos los proyectos que desee crear o revisar.`,
    homeLine4 : `Si desea abrir un proyecto existente o crear un proyecto único, haga clic en el botón "Continuar como invitado".`,
    guestLine1 : `Al hacer clic en este botón, tendrá dos opciones:`,
    guestLine2 : `Haga clic en este botón para abrir un proyecto existente. Necesitará un número de referencia del proyecto y una contraseña. Esto es útil si alguien compartió un proyecto particular con usted, o si ya tiene un proyecto que le gustaría abrir.`,
    guestLine3 : `Haga clic en este botón para crear un nuevo proyecto. `,
    guestLine4 : `Asigne un nombre a su proyecto en el campo "Nombre del proyecto" y una contraseña.`,
    guestLine5 : `Haga clic en el botón "Generar número" y aparecerá su número de referencia. `,
    guestLine6 : `Haga clic en el botón "Iniciar sesión".`,
    guestLine7 : `Pegue el número de referencia en el campo de número de referencia e introduzca su contraseña. A continuación, haga clic en "Enviar" para abrir su proyecto. Si ha olvidado copiar su número de referencia, puede volver a su navegador de Internet y podrá encontrar su número de referencia en la página anterior. Después de este paso, llegará a la página de su tablero de proyecto.`,
    guestNote1 : `Nota importante: es importante que recuerde o anote su contraseña, ya que no podemos recuperarla en caso de que la olvide. Sin embargo, su navegador de Internet también puede guardarla por usted.`,
    guestNote2 : `Nota importante: asegúrese de copiar el número de referencia haciendo clic en el icono "copiar" que aparece junto al número de referencia. `,
    projectDasboardLine1 : `El panel de control del proyecto le muestra sus líneas de proyecto y le permite configurar cada línea de acuerdo con su plan de trunking. Puede ver el nombre de una línea de proyecto, el módulo LED y el estado.`,
    projectDasboardLine2 : `Seleccione el país de su proyecto.`,
    projectDasboardLine3 : `Haga clic en el botón "+" para añadir una línea de canalización LED. Se abrirá una ventana emergente para seleccionar el país de su proyecto en caso de que no lo haya hecho. Esto nos ayudará a planificar nuestro stock en consecuencia.`,
    projectDasboardLine4 : `Una vez que seleccione un país, haga clic de nuevo en el botón "+" y aparecerá una ventana emergente que le permitirá dar un nombre a su línea (por ejemplo, Pasillo B).`,
    projectDasboardLine5 : `Puede editar el nombre de la línea más tarde haciendo clic en el icono del lápiz o borrarlo haciendo clic en el icono rojo de la papelera. `,
    projectDasboardLine6 : `Haga clic en "Configurar" para empezar a personalizar su línea.`,
    theConfiguration : `La configuración`,
    configurationLine1 : `Nuestra plataforma ejecuta múltiples algoritmos que calculan los productos correctos que necesitará para su proyecto de canalización según sus selecciones. Proceda rellenando las respuestas a las preguntas de cada sección, y haga clic en "Siguiente" para continuar con la siguiente sección.`,
    configurationLine2 : `Haga clic en el icono "?" que aparece junto a cada pregunta para obtener más información sobre la misma. `,
    configurationLine3 : `Una vez completada la configuración, haga clic en "Guardar la configuración de la línea" y volverá al panel de control del proyecto.`,
    configurationLine4 : `Repita este proceso para tantas líneas como necesite. A continuación, haga clic en el botón "Mostrar resultados" en la esquina inferior derecha de la pantalla.`,
    resultsLine1 : `Una vez que pulse el botón "Mostrar resultados", verá una tabla con un resumen de los productos que necesita para su proyecto, y debajo encontrará una tabla para cada línea que incluye todos los productos que necesita para su proyecto de trunking.`,
    resultsLine2 : `" Puede añadir un comentario en la parte inferior de cada tabla haciendo clic en el icono de texto rojo si desea indicar alguna observación.(por ejemplo, Esta línea está orientada a la entrada principal"")""."`,
    resultsLine3 : `Si desea modificar su proyecto, haga clic en el botón inferior izquierdo "Ir al tablero", y luego repita los pasos del punto 4. Cuadro de mandos del proyecto.`,
    resultsLine4 : `Para descargar los resultados en formato pdf, pulse el botón "Descargar PDF sin precios".`,
    resultsLine5 : `Si desea recibir un presupuesto de Opple para su proyecto, haga clic en el botón "Solicitar presupuesto" situado en la esquina inferior derecha de la pantalla.`,
    signUpLine1 : `Si utiliza el Configurador de Troncales con frecuencia, le aconsejamos que se registre en una cuenta para poder seguir todos sus proyectos.`,
    signUpLine2 : `Haga clic en el botón "Registrarse" en la página de inicio para crear una cuenta, y luego escriba un nombre de usuario y una contraseña.`,
    signUpNote : `Nota importante: es importante que recuerde o anote su contraseña, ya que no podemos recuperarla en caso de que la olvide. Sin embargo, su navegador de Internet también puede guardarla por usted.`,
    userDashboardLine1 : `Llegará al panel de control del usuario. Aquí tienes todos tus proyectos, y puedes acceder o modificar cualquiera de ellos. `,
    userDashboardLine2 : `Haga clic en el botón "+" para crear un nuevo proyecto. Aparecerá una ventana emergente que le permitirá dar un nombre a su proyecto (por ejemplo, Supermercado Eindhoven).`,
    userDashboardLine3 : `Puede editar el nombre del proyecto más tarde haciendo clic en el icono del lápiz o borrarlo haciendo clic en el icono rojo de la papelera. Haga clic en el botón "Abrir proyecto" para abrir su proyecto.`,
    userDashboardLine4 : `Llegará al panel de control del proyecto descrito en el punto 4. Panel de control del proyecto.`,
    loginAsUserLine1 : `Si ya tiene una cuenta, puede acceder a su panel de usuario haciendo clic en el botón "Iniciar sesión como usuario" e introduciendo su nombre de usuario y contraseña. Llegará al panel de usuario descrito en el punto 8. Panel de control del usuario.`,
    BurgerMenuLine1 : `Puede navegar a cualquier página haciendo clic en el menú de hamburguesas situado en la esquina superior izquierda de la pantalla.`,
    BurgerMenuLine2 : `Podrá encontrar todas las ubicaciones a las que desee ir en consecuencia. Estas ubicaciones son:`,
  },
  portugese: {
    introLine: `Bem-vindo ao configurador de iluminação trunking da Opple! A melhor ferramenta para calcular todos os componentes necessários para os seus projectos de Trunking LED`,
    glossary : `Glossário`,
    introduction : `Introdução`,
    homePage : `Página inicial`,
    configurator: `Configurador`,
    results : `Resultados`,
    navAndBurgerMenu : `Navegação e Menu Burger`,
    introLine1: `Este manual explica como utilizar o configurador de trunking como convidado ou como utilizador. Pode seguir as instruções que se seguem para poder criar um projecto trunking, atribuir e configurar linhas trunking, e finalmente obter a lista dos componentes que o seu projecto necessitaria.`,
    introLine2: `Se precisar de qualquer assistência, contacte data@opple.com.`,
    homeLine1 : `Na página inicial do configurador de troncos, pode escolher a língua que pretende utilizar para a aplicação no canto superior direito do ecrã.`,
    homeLine2 : `Verá que, no centro do ecrã, existem três botões:`,
    homeLine3 : `Se utilizar o configurador de trunking frequentemente, aconselhamos a criar uma conta clicando em "Sign up" para mais tarde iniciar sessão como utilizador, para que possa aceder a todos os projectos que deseja criar ou revisitar.`,
    homeLine4 : `Se desejar abrir um projecto existente ou criar um projecto único, clique no botão "Continuar como Convidado".`,
    guestLine1 : `Ao clicar neste botão, terá duas opções:`,
    guestLine2 : `Clicar neste botão para abrir um projecto já existente. Necessitará de um número de referência e senha para o projecto. Isto é útil se alguém partilhou consigo um determinado projecto, ou se já tem um projecto que gostaria de abrir.`,
    guestLine3 : `Clique neste botão para criar um novo projecto. `,
    guestLine4 : `Dê ao seu projecto um nome no campo "Nome do projecto", e uma palavra-passe.`,
    guestLine5 : `Clique no botão "Gerar número", o seu número de referência aparecerá. `,
    guestLine6 : `Clique no botão "Log in".`,
    guestLine7 : `Cole o número de referência no campo do número de referência e introduza a sua palavra-passe. Em seguida, clique em submeter para abrir o seu projecto. Se se esqueceu de copiar o seu número de referência, pode voltar ao seu navegador de Internet e encontrar o seu número de referência na página anterior. Após este passo, chegará à página do Painel do Projecto.`,
    guestNote1 : `Nota importante: é importante lembrar ou anotar a sua palavra-passe, uma vez que não a podemos recuperar no caso de se esquecer dela. No entanto, o seu navegador de Internet também pode guardá-la para si.`,
    guestNote2 : `Nota importante: certifique-se de copiar o número de referência, clicando no ícone "copy" junto ao número de referência. `,
    projectDasboardLine1 : `O painel do projecto mostra-lhe as linhas do seu projecto e permite-lhe configurar cada linha de acordo com o seu plano de trunking. Pode ver o nome de uma linha de projecto, módulo LED, e estado.`,
    projectDasboardLine2 : `Seleccione o país do seu projecto.`,
    projectDasboardLine3 : `Clique no botão "+" para adicionar uma linha troncalizada de LED. Um pop-up irá abrir para seleccionar o país do seu projecto, caso não o tenha feito. Isto ajudar-nos-á a planear o nosso stock em conformidade.`,
    projectDasboardLine4 : `Uma vez seleccionado um país, clique novamente no botão "+" e aparecerá um pop-up que lhe permitirá dar um nome à sua linha (ou seja, corredor B).`,
    projectDasboardLine5 : `Pode editar o nome da linha mais tarde, clicando no ícone do lápis ou apagá-lo clicando no ícone do caixote do lixo vermelho. `,
    projectDasboardLine6 : `Clique em "Configurar" para começar a personalizar a sua linha.`,
    theConfiguration : `A Configuração`,
    configurationLine1 : `A nossa plataforma executa múltiplos algoritmos que calculam os produtos correctos que necessitará para o seu projecto de trunking de acordo com as suas selecções. Proceda preenchendo as respostas às perguntas de cada secção, e clique em "Seguinte" para continuar para a secção seguinte.`,
    configurationLine2 : `Clique no ícone "?" ao lado de cada pergunta para obter mais informações sobre essa pergunta. `,
    configurationLine3 : `Após completar a sua configuração, clique em "Salvar configuração de linha" e voltará ao tablier do projecto.`,
    configurationLine4 : `Repita este processo para tantas linhas quantas precisar. Depois clique no botão "Mostrar resultados", no canto inferior direito do ecrã.`,
    resultsLine1 : `Assim que clicar no botão "Mostrar resultados", verá uma tabela com uma visão geral dos produtos que necessita para o seu projecto, e abaixo dela encontrará uma tabela para cada linha que inclui todos os produtos que necessita para o seu projecto de trunking.`,
    resultsLine2 : `"Pode acrescentar um comentário no fundo de cada tabela, clicando no ícone de texto vermelho se desejar indicar qualquer comentário.(i.e. Esta linha está virada para a entrada principal"")""."`,
    resultsLine3 : `Se desejar modificar o seu projecto, clicando no botão inferior esquerdo "Go to Dashboard", então repita os passos do ponto 4. Painel de controlo do projecto.`,
    resultsLine4 : `Para descarregar os resultados em formato pdf, clique no botão "Descarregar PDF sem preços".`,
    resultsLine5 : `Se quiser receber um orçamento da Opple para o seu projecto, clique no botão "Request for a quotation" no canto inferior direito do ecrã.`,
    signUpLine1 : `Se utiliza o Trunking Configurator frequentemente, aconselhamo-lo a inscrever-se numa conta para que possa acompanhar todos os seus projectos.`,
    signUpLine2 : `Clique no botão "Sign up" na página inicial para criar uma conta, depois escreva um Nome de Utilizador e uma Palavra-passe.`,
    signUpNote : `Nota importante: é importante lembrar ou anotar a sua palavra-passe, uma vez que não a podemos recuperar no caso de a esquecer. No entanto, o seu navegador de Internet também pode guardá-la para si.`,
    userDashboardLine1 : `Chegará ao Painel de Controlo do Utilizador. Aqui tem todos os seus projectos, e pode aceder ou modificar qualquer um dos seus projectos. `,
    userDashboardLine2 : `Clique no botão "+" para criar um novo projecto. aparecerá um pop-up que lhe permitirá dar um nome ao seu projecto (ou seja, Supermercado Eindhoven).`,
    userDashboardLine3 : `Pode editar o nome do projecto mais tarde, clicando no ícone do lápis ou apagá-lo clicando no ícone do caixote do lixo vermelho. Clique no botão "Open Project" (Abrir Projecto) para abrir o seu projecto.`,
    userDashboardLine4 : `Chegará ao painel de bordo do projecto descrito no ponto 4. Painel de controlo do projecto.`,
    loginAsUserLine1 : `Se já tiver uma conta, pode entrar no seu Painel de Controlo do Utilizador clicando no botão "Entrar como Utilizador" e introduzindo o seu Nome de Utilizador e Palavra-passe em conformidade. Chegará ao painel de controlo do utilizador descrito no ponto 8. Painel de Controlo do Utilizador.`,
    BurgerMenuLine1 : `Pode navegar para qualquer página, clicando no menu hambúrguer no canto superior esquerdo do ecrã.`,
    BurgerMenuLine2 : `Poderá encontrar todos os locais para os quais deseja ir em conformidade. Estes locais são:s`,
  },
  finnish: {
    introLine: `Tervetuloa Opple lightingin valaisinrunkojen konfiguraattoriin! Paras työkalu, jolla voit laskea kaikki komponentit, joita tarvitset LED-kanavaprojekteihisi.`,
    glossary : `Sanasto`,
    introduction : `Johdanto`,
    homePage : `Kotisivu`,
    configurator: `Konfiguraattori`,
    results : `Tulokset`,
    navAndBurgerMenu : `Navigointi ja Burger-valikko`,
    introLine1: `Tässä oppaassa selitetään, miten trunking-konfiguraattoria käytetään vieraana tai käyttäjänä. Voit seurata seuraavia ohjeita voidaksesi luoda trunking-projektin, määrittää ja konfiguroida trunking-linjat ja lopuksi saada luettelon komponenteista, joita projektisi tarvitsee.`,
    introLine2: `Jos tarvitset apua, ota yhteyttä osoitteeseen data@opple.com.`,
    homeLine1 : `Trunking-konfiguraattorin etusivulla voit valita sovelluksessa käytettävän kielen näytön oikeasta yläkulmasta.`,
    homeLine2 : `Näet, että näytön keskellä on kolme painiketta:`,
    homeLine3 : `Jos käytät kanavointikonfiguraattoria usein, suosittelemme luomaan tilin klikkaamalla "Rekisteröidy", jotta voit myöhemmin kirjautua käyttäjäksi, jolloin pääset käsiksi kaikkiin projekteihin, joita haluat luoda tai tarkastella uudelleen.`,
    homeLine4 : `Jos haluat avata olemassa olevan projektin tai luoda kertaluonteisen projektin, napsauta painiketta "Jatka vieraana".`,
    guestLine1 : `Kun napsautat tätä painiketta, sinulla on kaksi vaihtoehtoa:`,
    guestLine2 : `Avaa olemassa oleva projekti napsauttamalla tätä painiketta. Tarvitset projektin viitenumeron ja salasanan. Tämä on hyödyllistä, jos joku on jakanut tietyn projektin kanssasi tai jos sinulla on jo projekti, jonka haluat avata.`,
    guestLine3 : `Luo uusi projekti napsauttamalla tätä painiketta. `,
    guestLine4 : `Anna projektille nimi "Projektin nimi" -kentässä ja salasana.`,
    guestLine5 : `Napsauta "Generate Number" -painiketta, jolloin viitenumerosi tulee näkyviin. `,
    guestLine6 : `Klikkaa painiketta "Kirjaudu sisään".`,
    guestLine7 : `Liitä viitenumero viitenumerokenttään ja syötä salasanasi. Avaa projektisi napsauttamalla lähetä. Jos unohdit kopioida viitenumeron, voit palata internet-selaimellasi takaisin, jolloin löydät viitenumerosi edelliseltä sivulta. Tämän vaiheen jälkeen pääset Project Dashboard -sivulle.`,
    guestNote1 : `Tärkeä huomautus: on tärkeää muistaa tai kirjoittaa ylös salasanasi, sillä emme voi hakea sitä puolestasi, jos unohdat sen. Internet-selaimesi voi kuitenkin tallentaa sen puolestasi.`,
    guestNote2 : `Tärkeä huomautus: Varmista, että kopioit viitenumeron klikkaamalla viitenumeron vieressä olevaa "kopioi"-kuvaketta. `,
    projectDasboardLine1 : `Projektin kojelauta näyttää projektisi linjat ja voit määrittää jokaisen linjan kanavointisuunnitelmasi mukaisesti. Näet projektilinjan nimen, LED-moduulin ja tilan.`,
    projectDasboardLine2 : `Valitse projektisi maa.`,
    projectDasboardLine3 : `Lisää LED-runkolinja napsauttamalla "+"-painiketta. Avautuu ponnahdusikkuna, jossa voit valita projektisi maan, jos et ole tehnyt sitä. Tämä auttaa meitä suunnittelemaan varastomme vastaavasti.`,
    projectDasboardLine4 : `Kun olet valinnut maan, napsauta uudelleen "+"-painiketta, jolloin avautuu ponnahdusikkuna, jossa voit antaa linjalle nimen (esim. käytävä B).`,
    projectDasboardLine5 : `Voit muokata linjan nimeä myöhemmin napsauttamalla kynäkuvaketta tai poistaa sen napsauttamalla punaista roskakorikuvaketta. `,
    projectDasboardLine6 : `Klikkaa "Configure" (Määritä) aloittaaksesi linjan mukauttamisen.`,
    theConfiguration : `Konfigurointi`,
    configurationLine1 : `Alustamme käyttää useita algoritmeja, jotka laskevat oikeat tuotteet, joita tarvitset kanavaprojektissasi valintojesi mukaan. Jatka täyttämällä vastaukset kunkin osion kysymyksiin ja jatka seuraavaan osioon klikkaamalla "Seuraava".`,
    configurationLine2 : `Napsauta kunkin kysymyksen vieressä olevaa "?"-kuvaketta saadaksesi lisätietoja kyseisestä kysymyksestä. `,
    configurationLine3 : `Kun olet saanut kokoonpanosi valmiiksi, napsauta "Save line configuration" (Tallenna rivin kokoonpano) ja palaat projektin kojelautaan.`,
    configurationLine4 : `Toista tämä prosessi niin monelle riville kuin tarvitset. Napsauta sitten näytön oikeassa alakulmassa olevaa painiketta "Näytä tulokset".`,
    resultsLine1 : `Kun napsautat painiketta "Näytä tulokset", näet taulukon, jossa on yleiskatsaus projektissasi tarvitsemistasi tuotteista, ja sen alapuolella on kunkin linjan osalta taulukko, joka sisältää kaikki tuotteet, joita tarvitset kanavointiprojektiisi.`,
    resultsLine2 : `Voit lisätä kommentin kunkin taulukon alareunaan napsauttamalla punaista tekstikuvaketta, jos haluat ilmoittaa huomautuksia. Voit lisätä kommentin kunkin taulukon alareunaan napsauttamalla punaista tekstikuvaketta. (esim. "Tämä linja on pääsisäänkäynnin suuntaan.")"`,
    resultsLine3 : `Jos haluat muuttaa hankettasi, napsauta vasemmassa alakulmassa olevaa painiketta "Go to Dashboard" (Siirry kojelautaan) ja toista kohdan 4 vaiheet. Projektin kojelauta.`,
    resultsLine4 : `Voit ladata tulokset pdf-muodossa napsauttamalla painiketta "Lataa PDF ilman hintoja".`,
    resultsLine5 : `Jos haluat saada Opplelta tarjouksen projektistasi, napsauta näytön oikeassa alakulmassa olevaa painiketta "Pyydä tarjous".`,
    signUpLine1 : `Jos käytät Trunking Configuratoria usein, suosittelemme, että rekisteröidyt tilin, jotta voit seurata kaikkia projektejasi.`,
    signUpLine2 : `Tee tili napsauttamalla etusivun "Rekisteröidy" -painiketta ja kirjoita sitten käyttäjätunnus ja salasana.`,
    signUpNote : `Tärkeä huomautus: on tärkeää muistaa tai kirjoittaa ylös salasanasi, sillä emme voi hakea sitä puolestasi, jos unohdat sen. Internet-selaimesi voi kuitenkin tallentaa sen puolestasi.`,
    userDashboardLine1 : `Pääset käyttäjän kojelaudalle. Täällä näet kaikki projektisi, ja voit käyttää tai muokata mitä tahansa projektiasi. `,
    userDashboardLine2 : `Voit luoda uuden projektin napsauttamalla "+"-painiketta. avautuu ponnahdusikkuna, jossa voit antaa projektillesi nimen (esim. Eindhovenin supermarket).`,
    userDashboardLine3 : `Voit muokata projektin nimeä myöhemmin napsauttamalla kynäkuvaketta tai poistaa sen napsauttamalla punaista roskakorikuvaketta. Avaa projektisi napsauttamalla "Open Project" -painiketta.`,
    userDashboardLine4 : `Pääset kohdassa 4 kuvattuun projektin kojelautaan. Projektin kojelauta.`,
    loginAsUserLine1 : `Jos sinulla on jo tili, voit kirjautua User Dashboardiin napsauttamalla painiketta "Log in as User" ja syöttämällä käyttäjätunnuksesi ja salasanasi vastaavasti. Pääset kohdassa 8 kuvattuun käyttäjän kojelautaan. Käyttäjän kojelauta.`,
    BurgerMenuLine1 : `Voit siirtyä mille tahansa sivulle napsauttamalla näytön vasemmassa yläkulmassa olevaa burgerivalikkoa.`,
    BurgerMenuLine2 : `Löydät kaikki haluamasi kohteet vastaavasti. Nämä sijainnit ovat:`,
  },
  polish: {
    introLine: `Witamy w konfiguratorze listew oświetleniowych Opple! Najlepsze narzędzie do obliczania wszystkich komponentów potrzebnych do realizacji projektów oświetlenia LED Trunking.`,
    glossary : `Słowniczek`,
    introduction : `Wprowadzenie`,
    homePage : `Strona główna`,
    configurator: `Konfigurator`,
    results : `Wyniki`,
    navAndBurgerMenu : `Nawigacja i menu hamburgerów`,
    introLine1: `Niniejsza instrukcja wyjaśnia, jak korzystać z konfiguratora trunkingu jako gość lub użytkownik. Możesz postępować zgodnie z poniższymi instrukcjami, aby móc utworzyć projekt trunkingu, przypisać i skonfigurować linie trunkingu, a w końcu uzyskać listę komponentów, które będą potrzebne w Twoim projekcie.`,
    introLine2: `Jeśli potrzebujesz pomocy, prosimy o kontakt z data@opple.com.`,
    homeLine1 : `Na stronie głównej konfiguratora trunkingu, w prawym górnym rogu ekranu można wybrać język, który ma być używany w aplikacji.`,
    homeLine2 : `Na środku ekranu znajdują się trzy przyciski:`,
    homeLine3 : `Jeśli często korzystasz z konfiguratora trunkingu, zalecamy utworzenie konta poprzez kliknięcie na "Zarejestruj się", aby później zalogować się jako użytkownik, dzięki czemu uzyskasz dostęp do wszystkich projektów, które chcesz utworzyć lub ponownie przejrzeć.`,
    homeLine4 : `Jeżeli chcesz otworzyć istniejący projekt lub stworzyć projekt jednorazowy, kliknij na przycisk "Kontynuuj jako gość".`,
    guestLine1 : `Po kliknięciu na ten przycisk będziesz miał dwie opcje:`,
    guestLine2 : `Kliknij na ten przycisk, aby otworzyć istniejący projekt. Będziesz potrzebował numeru referencyjnego projektu i hasła. Jest to przydatne, jeśli ktoś udostępnił Ci konkretny projekt lub jeśli masz już projekt, który chciałbyś otworzyć.`,
    guestLine3 : `Kliknij na ten przycisk, aby utworzyć nowy projekt. `,
    guestLine4 : `Nadaj swojemu projektowi nazwę w polu "Nazwa projektu" oraz hasło.`,
    guestLine5 : `Kliknij na przycisk "Generuj numer", pojawi się Twój numer referencyjny. `,
    guestLine6 : `Kliknij na przycisk "Zaloguj się".`,
    guestLine7 : `Wklej numer referencyjny w polu numeru referencyjnego i wprowadź swoje hasło. Następnie kliknij wyślij, aby otworzyć swój projekt. Jeśli zapomniałeś skopiować swój numer referencyjny, możesz cofnąć się w przeglądarce internetowej i znaleźć swój numer referencyjny na poprzedniej stronie. Po tym kroku dotrzesz do strony pulpitu nawigacyjnego projektu.`,
    guestNote1 : `Ważna uwaga: ważne jest, aby zapamiętać lub zanotować swoje hasło, ponieważ nie możemy go odzyskać w przypadku jego zapomnienia. Jednakże, Twoja przeglądarka internetowa może je również zapisać.`,
    guestNote2 : `Ważna uwaga: Upewnij się, że skopiowałeś numer referencyjny klikając na ikonę "kopiuj" obok numeru referencyjnego. `,
    projectDasboardLine1 : `Pulpit nawigacyjny projektu pokazuje linie projektu i pozwala skonfigurować każdą linię zgodnie z planem trunkingowym. Możesz zobaczyć nazwę linii projektowej, moduł LED oraz status.`,
    projectDasboardLine2 : `Wybierz kraj, w którym realizowany jest projekt.`,
    projectDasboardLine3 : `Kliknij na przycisk "+", aby dodać linię trunkingową LED. W przypadku, gdy tego nie zrobiłeś, pojawi się okienko, aby wybrać kraj projektu. Pomoże nam to zaplanować nasze zapasy odpowiednio.`,
    projectDasboardLine4 : `Po wybraniu kraju, kliknij ponownie przycisk "+", a pojawi się wyskakujące okienko, które pozwoli Ci nadać nazwę linii (np. korytarz B).`,
    projectDasboardLine5 : `Nazwę linii można później edytować klikając na ikonę ołówka lub usunąć ją klikając na czerwoną ikonę kosza. `,
    projectDasboardLine6 : `Kliknąć na "Konfiguruj", aby rozpocząć dostosowywanie linii.`,
    theConfiguration : `Konfiguracja`,
    configurationLine1 : `Nasza platforma uruchamia wiele algorytmów, które obliczają prawidłowe produkty, które będą potrzebne do projektu trunkingu zgodnie z Twoimi wyborami. Wypełnij odpowiedzi na pytania w każdej sekcji, a następnie kliknij "Dalej", aby przejść do następnej sekcji.`,
    configurationLine2 : `Kliknij na ikonę "?" obok każdego pytania, aby uzyskać więcej informacji na jego temat. `,
    configurationLine3 : `Po zakończeniu konfiguracji, kliknij na "Zapisz konfigurację linii" i powrócisz do pulpitu nawigacyjnego projektu.`,
    configurationLine4 : `Powtórz ten proces dla tylu linii, ile potrzebujesz. Następnie kliknij na przycisk "Wyświetl wyniki" w prawym dolnym rogu ekranu.`,
    resultsLine1 : `Po kliknięciu na przycisk "Wyświetl wyniki", zobaczysz tabelę z przeglądem produktów, których potrzebujesz do swojego projektu, a poniżej znajdziesz tabelę dla każdej linii, która zawiera wszystkie produkty, których potrzebujesz do swojego projektu trunkingowego.`,
    resultsLine2 : `"Możesz dodać komentarz na dole każdej tabeli, klikając na czerwoną ikonę tekstu, jeśli chcesz wskazać jakieś uwagi.(np. Ta linia jest skierowana w stronę głównego wejścia."")""."`,
    resultsLine3 : `Jeśli chcesz zmodyfikować swój projekt, klikając w lewy dolny przycisk "Go to Dashboard", a następnie powtórz kroki z punktu 4. Dashboard projektu.`,
    resultsLine4 : `Aby pobrać wyniki w formie pdf, należy kliknąć na przycisk "Pobierz PDF bez cen".`,
    resultsLine5 : `Jeśli chcesz otrzymać od Opple wycenę swojego projektu, kliknij na przycisk "Zamów wycenę" w prawym dolnym rogu ekranu.`,
    signUpLine1 : `Jeśli używasz Konfigurator Trunking często, radzimy, aby zarejestrować się na konto, dzięki czemu można śledzić wszystkie swoje projekty.`,
    signUpLine2 : `Kliknij na przycisk "Zarejestruj się" na stronie głównej, aby utworzyć konto, a następnie wpisz nazwę użytkownika i hasło.`,
    signUpNote : `Ważna uwaga: ważne jest, aby pamiętać lub zanotować swoje hasło, ponieważ nie możemy go odzyskać dla Ciebie w przypadku, gdy go zapomnisz. Jednakże, Twoja przeglądarka internetowa może zapisać je dla Ciebie.`,
    userDashboardLine1 : `Przejdziesz do Panelu Użytkownika. Tutaj masz wszystkie swoje projekty i możesz uzyskać dostęp lub modyfikować każdy z nich. `,
    userDashboardLine2 : `Kliknij na przycisk "+", aby utworzyć nowy projekt. Pojawi się okienko, które pozwoli Ci nadać Twojemu projektowi nazwę (np. Supermarket Eindhoven).`,
    userDashboardLine3 : `Możesz później zmienić nazwę projektu klikając na ikonę ołówka lub usunąć ją klikając na czerwoną ikonę kosza. Kliknij na przycisk "Otwórz projekt", aby otworzyć swój projekt.`,
    userDashboardLine4 : `Przejdziesz do pulpitu nawigacyjnego projektu opisanego w punkcie 4. Project Dashboard.`,
    loginAsUserLine1 : `Jeśli masz już konto, możesz zalogować się do Panelu użytkownika klikając na przycisk "Zaloguj się jako użytkownik" i wpisując odpowiednio swoją nazwę użytkownika i hasło. Przejdziesz do pulpitu użytkownika opisanego w punkcie 8. Panel użytkownika.`,
    BurgerMenuLine1 : `Możesz przejść do dowolnej strony klikając na menu "burger" w lewym górnym rogu ekranu.`,
    BurgerMenuLine2 : `Znajdziesz tam wszystkie lokalizacje, do których chcesz się udać. Te lokalizacje to:`,
  },
  italian: {
    introLine: `Benvenuti nel configuratore di canalizzazioni di Opple lighting! Il miglior strumento per calcolare tutti i componenti di cui hai bisogno per i tuoi progetti di canalizzazione a LED`,
    glossary : `Glossario`,
    introduction : `Introduzione`,
    homePage : `Homepage`,
    configurator: `Configuratore`,
    results : `Risultati`,
    navAndBurgerMenu : `Menu di navigazione e di borghesia`,
    introLine1: `Questo manuale spiega come utilizzare il configuratore di trunking come ospite o come utente. Puoi seguire le istruzioni che seguono per essere in grado di creare un progetto di trunking, assegnare e configurare linee di trunking, e infine ottenere la lista dei componenti di cui il tuo progetto avrebbe bisogno.`,
    introLine2: `Se hai bisogno di assistenza, contatta data@opple.com.`,
    homeLine1 : `Nella homepage del configuratore di trunking, puoi scegliere la lingua che vuoi usare per l'applicazione nell'angolo in alto a destra dello schermo.`,
    homeLine2 : `Vedrai che al centro dello schermo ci sono tre pulsanti:`,
    homeLine3 : `Se utilizzi frequentemente il configuratore di trunking, ti consigliamo di creare un account cliccando su "Sign up" per poi effettuare il login come utente in modo da poter accedere a tutti i progetti che desideri creare o rivisitare.`,
    homeLine4 : `Se desideri aprire un progetto esistente o creare un progetto unico, clicca sul pulsante "Continua come ospite".`,
    guestLine1 : `Cliccando su questo pulsante, avrai due opzioni:`,
    guestLine2 : `Clicca su questo pulsante per aprire un progetto esistente. Avrai bisogno di un numero di riferimento del progetto e di una password. Questo è utile se qualcuno ha condiviso un particolare progetto con te, o se hai già un progetto che vorresti aprire.`,
    guestLine3 : `Clicca su questo pulsante per creare un nuovo progetto. `,
    guestLine4 : `Dai un nome al tuo progetto nel campo "Nome progetto" e una password.`,
    guestLine5 : `Clicca sul pulsante "Genera numero", apparirà il tuo numero di riferimento. `,
    guestLine6 : `Clicca sul pulsante "Log in".`,
    guestLine7 : `Incolla il numero di riferimento nel campo del numero di riferimento e inserisci la tua password. Poi clicca su invia per aprire il tuo progetto. Se hai dimenticato di copiare il tuo numero di riferimento, puoi tornare indietro nel tuo browser internet e puoi trovare il tuo numero di riferimento nella pagina precedente. Dopo questo passo arriverai alla pagina Project Dashboard.`,
    guestNote1 : `Nota importante: è importante ricordare o annotare la tua password perché non possiamo recuperarla per te nel caso tu la dimentichi. Tuttavia, il tuo browser internet può anche salvarla per te.`,
    guestNote2 : `Nota importante: assicurati di copiare il numero di riferimento cliccando sull'icona "copia" accanto al numero di riferimento. `,
    projectDasboardLine1 : `Il cruscotto del progetto ti mostra le tue linee di progetto e ti permette di configurare ogni linea secondo il tuo piano di trunking. Puoi vedere il nome di una linea del progetto, il modulo LED e lo stato.`,
    projectDasboardLine2 : `Seleziona il paese del tuo progetto.`,
    projectDasboardLine3 : `Clicca sul pulsante "+" per aggiungere una linea di canalizzazione a LED. Si aprirà un pop-up per selezionare il paese del tuo progetto nel caso tu non l'abbia fatto. Questo ci aiuterà a pianificare il nostro stock di conseguenza.`,
    projectDasboardLine4 : `Una volta selezionato il paese, clicca nuovamente sul pulsante "+" e si aprirà un pop-up che ti permetterà di dare un nome alla tua linea (es. Corridoio B).`,
    projectDasboardLine5 : `Puoi modificare il nome della linea in seguito cliccando sull'icona della matita o cancellarlo cliccando sull'icona rossa del cestino. `,
    projectDasboardLine6 : `Clicca su "Configura" per iniziare a personalizzare la tua linea.`,
    theConfiguration : `La configurazione`,
    configurationLine1 : `La nostra piattaforma esegue diversi algoritmi che calcolano i prodotti corretti di cui avrai bisogno per il tuo progetto di canalizzazione in base alle tue selezioni. Procedi compilando le risposte alle domande di ogni sezione e clicca su "Avanti" per continuare alla sezione successiva.`,
    configurationLine2 : `Clicca sull'icona "?" accanto ad ogni domanda per avere maggiori informazioni su quella domanda. `,
    configurationLine3 : `Dopo aver completato la configurazione, clicca su "Save line configuration" e tornerai alla dashboard del progetto.`,
    configurationLine4 : `Ripeti questo processo per tutte le linee di cui hai bisogno. Poi clicca sul pulsante "Visualizza risultati" nell'angolo in basso a destra dello schermo.`,
    resultsLine1 : `Una volta che hai cliccato sul pulsante "Visualizza risultati", vedrai una tabella con una panoramica dei prodotti di cui hai bisogno per il tuo progetto, e sotto di essa troverai una tabella per ogni linea che include tutti i prodotti di cui hai bisogno per il tuo progetto di trunking.`,
    resultsLine2 : `"Puoi aggiungere un commento in fondo ad ogni tabella cliccando sull'icona rossa del testo se vuoi indicare delle osservazioni.(es. Questa linea si affaccia sull'ingresso principale"")"""`,
    resultsLine3 : `Se volete modificare il vostro progetto, cliccando sul pulsante in basso a sinistra "Go to Dashboard", poi ripetete i passi del punto 4. Dashboard del progetto.`,
    resultsLine4 : `Per scaricare i risultati in formato pdf, clicca sul pulsante "Scarica il PDF senza prezzi".`,
    resultsLine5 : `Se volete ricevere un preventivo da Opple per il vostro progetto, cliccate sul pulsante "Request for a quotation" in basso a destra dello schermo.`,
    signUpLine1 : `Se utilizzate spesso il configuratore di canalizzazioni, vi consigliamo di registrarvi per un account in modo da poter tenere traccia di tutti i vostri progetti.`,
    signUpLine2 : `Clicca sul pulsante "Iscriviti" nella homepage per creare un account, quindi digita un nome utente e una password.`,
    signUpNote : `Nota importante: è importante ricordare o annotare la tua password perché non possiamo recuperarla per te nel caso la dimenticassi. Tuttavia, il tuo browser internet può anche salvarla per te.`,
    userDashboardLine1 : `Arriverai alla Dashboard dell'utente. Qui hai tutti i tuoi progetti, e puoi accedere o modificare qualsiasi progetto. `,
    userDashboardLine2 : `Clicca sul pulsante "+" per creare un nuovo progetto. apparirà un pop-up che ti permetterà di dare un nome al tuo progetto (es. Eindhoven Supermarket).`,
    userDashboardLine3 : `Puoi modificare il nome del progetto in seguito cliccando sull'icona della matita o cancellarlo cliccando sull'icona rossa del cestino. Clicca sul pulsante "Open Project" per aprire il tuo progetto.`,
    userDashboardLine4 : `Arriverai al cruscotto del progetto descritto al punto 4. Cruscotto del progetto.`,
    loginAsUserLine1 : `Se hai già un account, puoi accedere al tuo cruscotto utente cliccando sul pulsante "Log in as User" e inserendo il tuo nome utente e la tua password. Si arriva al cruscotto utente descritto al punto 8. Cruscotto utente.`,
    BurgerMenuLine1 : `Potete navigare da soli in qualsiasi pagina cliccando sul menu ad hamburger nell'angolo in alto a sinistra dello schermo.`,
    BurgerMenuLine2 : `Si possono trovare tutte le posizioni in cui si desidera andare di conseguenza. Queste posizioni sono:`,
  },
};
