const initialState = {};

export default function inputSectionReducer(state = initialState, action) {
  switch (action.type) {
    case "FIELD_ONE_INPUT": {
      // => Ask yourself: what is action.payload?
      state.fieldOneInput = action.payload;
      return { ...state };
    }
    case "FIELD_TWO_INPUT": {
      state.fieldTwoInput = action.payload;
      return { ...state };
    }
    case "FIELD_THREE_INPUT": {
      state.fieldThreeInput = action.payload;
      return { ...state };
    }
    case "FIELD_FOUR_INPUT": {
      state.fieldFourInput = action.payload;
      return { ...state };
    }
    case "FIELD_FIVE_INPUT": {
      state.fieldFiveInput = action.payload;
      return { ...state };
    }
    case "DALI_SELECTED": {
      state.daliSelected = action.payload;
      return { ...state };
    }
    case "FIELD_SIX_INPUT": {
      state.fieldSixInput = action.payload;
      return { ...state };
    }
    case "FIELD_SEVEN_INPUT": {
      state.fieldSevenInput = action.payload;
      return { ...state };
    }
    case "FIELD_EIGHT_INPUT": {
      state.fieldEightInput = action.payload;
      return { ...state };
    }
    case "FIELD_NINE_INPUT": {
      state.fieldNineInput = action.payload;
      return { ...state };
    }
    case "FIELD_TEN_INPUT": {
      state.fieldTenInput = action.payload;
      return { ...state };
    }
    case "FIELD_ELEVEN_INPUT": {
      state.fieldElevenInput = action.payload;
      return { ...state };
    }
    case "FIELD_TWELVE_INPUT": {
      state.fieldTwelveInput = action.payload;
      return { ...state };
    }
    case "FIELD_THIRTEEN_INPUT": {
      state.fieldThirteenInput = action.payload;
      return { ...state };
    }
    case "FIELD_FOURTEEN_INPUT": {
      state.fieldFourteenInput = action.payload;
      return { ...state };
    }
    case "FIELD_FIFTEEN_INPUT": {
      state.fieldFifteenInput = action.payload;
      return { ...state };
    }
    case "FIELD_SIXTEEN_INPUT": {
      state.fieldSixteenInput = action.payload;
      return { ...state };
    }
    case "FIELD_SEVENTEEN_INPUT": {
      state.fieldSeventeenInput = action.payload;
      return { ...state };
    }
    case "FIELD_EIGHTEEN_INPUT": {
      state.fieldEighteenInput = action.payload;
      return { ...state };
    }
    case "FIELD_NINETEEN_INPUT": {
      state.fieldNineteenInput = action.payload;
      return { ...state };
    }
    case "FIELD_TWENTY_TWO_INPUT": {
      state.fieldTwentyTwoInput = action.payload;
      return { ...state };
    }
    case "FIELD_TWENTY_THREE_INPUT": {
      state.fieldTwentyThreeInput = action.payload;
      return { ...state };
    }
    case "FIELD_TWENTY_INPUT": {
      state.fieldTwentyInput = action.payload;
      return { ...state };
    }
    case "FIELD_TWENTY_ONE_INPUT": {
      state.fieldTwentyOneInput = action.payload;
      return { ...state };
    }
    case "CLEAR_SECTION_FIVE_INPUTS": {
      return (state = {});
    }
    case "LINE_STATUS":{
      state.lineStatus = action.payload;
      return {...state};
    }
    case "CLEAR_INPUT_SECTION_REDUCER": {
        return (state = {});
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
