import React, { useState } from "react";
import {
  Container,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { changeUserPassword } from "../../actions/adminActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    boxShadow: "none",
  },
  textField: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
  },
}));

export default function ChangeUserPasswordForm() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [formError, setFormError] = useState(false);
  const [notification, setNotification] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAdminPassword, setShowAdminPassword] = useState(false);


  const dispatch = useDispatch();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleAdminRequestPasswordChange = (event) => {
    setAdminPassword(event.target.value);
  };

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleShowAdminPasswordToggle = () => {
    setShowAdminPassword(!showAdminPassword);
  };

  const showNotification = () => {
    setNotification(true);

    // After 5 seconds, set the notification state back to false
    setTimeout(() => {
      setNotification(false);
    }, 5000); // 5000 milliseconds (5 seconds)
  };

  const handleSubmit = () => {
    // Check if both fields are filled
    if (username.trim() === "" || password.trim() === "") {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    // Implement your change password logic here
    dispatch(
      changeUserPassword(username, password, adminPassword, (response) => {
        console.log("juju response", response.body);
        if (response.message === "Success") {
          showNotification();
        }
      })
    );
  };

  return (
    <Container maxWidth="xs">
      <Paper className={classes.paper}>
        <Grid container direction="column" alignItems="center">
          <TextField
            className={classes.textField}
            label="Username"
            value={username}
            onChange={handleUsernameChange}
            variant="outlined"
            fullWidth
            required // Add the required attribute
            error={formError && username.trim() === ""} // Show error if formError is true and username is empty
          />
          <TextField
            className={classes.textField}
            label="New User Password"
            type={showPassword ? "text" : "password"} // Toggle the type based on showPassword state
            value={password}
            onChange={handlePasswordChange}
            variant="outlined"
            fullWidth
            required // Add the required attribute
            error={formError && password.trim() === ""} // Show error if formError is true and password is empty
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPasswordToggle}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={classes.textField}
            label="Admin request password"
            type={showAdminPassword ? "text" : "password"} // Toggle the type based on showPassword state
            value={adminPassword}
            onChange={handleAdminRequestPasswordChange}
            variant="outlined"
            fullWidth
            required // Add the required attribute
            error={formError && adminPassword.trim() === ""} // Show error if formError is true and adminPassword is empty
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowAdminPasswordToggle}>
                    {showAdminPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {formError && (
          <p style={{ color: "red", textAlign: "center" }}>
            Both fields are required.
          </p>
        )}
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
        >
          Change Password
        </Button>
        <br />
        <br />
        {notification && (
          <p style={{ color: "green", textAlign: "center" }}>
            Password updated successfully!
          </p>
        )}
      </Paper>
    </Container>
  );
}
