import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper, Button, Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { closePopupAction } from "../../actions/popupActions";
import { pageContent } from "../../lang/pageContent";
import { popupContent } from "../../lang/popupContent";
import { inputLabels } from "../../lang/inputLabels";
import { inputLabelOptions } from "../../lang/inputLabelOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(2),
    },
  },
  pageContent: {
    // alignContent: "center",
    // padding: "0 15px 0 15px",
    width: "100%",
    // maxWidth : "80%",
    // width: 800,

    spacing: theme.spacing(2),
  },
  button: {
    width: "100px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "##0146ab",
    // marginTop:"5%",
    marginBottom: "3%",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
    childDiv: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
}));

// This component is used inside a popup to show details of a component selected by the user
export default function ComponentDetails() {
  const classes = useStyles();
  const history = useHistory();

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );
  const user = useSelector((reduxState) => reduxState.userLoginReducer);

  const dispatch = useDispatch();

  const componentData = useSelector(
    (reduxState) => reduxState.componentsReducer.selectedComponent.component
  );

  const fieldOptions = inputLabelOptions[`${selectedLang}`];

  return (
    <React.Fragment>
      <Container>
        <Paper className={classes.pageContent} elevation={0}>
          <Grid maxWidth="sm" container spacing={1} columns={12}>
          <Grid item xs={12}>
              <Typography
                variant="body2"
                style={{ fontSize: "14px" , fontWeight: 600, color: "#0146ab",paddingBottom : "2%"}}
                gutterBottom
              >
                Component Name : {componentData.name}
              </Typography>
            </Grid>
          <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Component Code
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {componentData.code}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Emergency Equivalent of
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {componentData.emergencyEquivalentOf && componentData.emergencyEquivalentOf || "n/a" }
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Emergency Category
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                {componentData.emergencyCategory && componentData.emergencyCategory || "n/a" }
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Gross Price NL
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                €{componentData.grossPriceNL}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Gross Price Germany
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                €{componentData.grossPriceGermany}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Gross Price Austria
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                €{componentData.grossPriceAustria}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Gross Price Switzerland
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                €{componentData.grossPriceSwitzerland}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Gross Price Belgium
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                €{componentData.grossPriceBelgium}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Gross Price France
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                €{componentData.grossPriceFrance}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Gross Price Spain
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                €{componentData.grossPriceSpain}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Gross Price Italy
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                €{componentData.grossPriceItaly}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Gross Price Portugal
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                €{componentData.grossPricePortugal}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                Gross Price Others
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body2"
                style={{ fontSize: "12px" }}
                gutterBottom
              >
                €{componentData.grossPriceOthers}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
