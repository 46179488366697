import React from "react";
import { selectedLanguage } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { sendLineCountry } from "../actions/lineActions";
import { inputLabelOptions } from "../lang/inputLabelOptions";
import { changeProjectCountry } from "../actions/projectActions";
import { closePopupAction } from "../actions/popupActions";
import {
  makeStyles,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { pageContent } from "../lang/pageContent";


const useStyles = makeStyles((theme) => ({
  quantityRoot: {
    color: "#ffffff",
    // backgroundColor: "#000000",
    opacity: 1,
    borderRadius: "35px",
    minWidth: "200px",
    height:"50px",
    // this one changes colour of the background on hover
    "&:hover": {
      // backgroundColor: "#ffffff",
      borderRadius: "35px",
      opacity: 1,
    },
    // This changes the color of the border
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
      borderRadius: "35px",
      // color:"#ffffff"
    },
    // this changes the static text color when not focused
    "& .MuiOutlinedInput-input": {
      color: "#ffffff"
    },
    // this changes the static small text color
    "& .MuiInputLabel-root": {
      color: "#ffffff"
    },
    // This changes the color of text on hover
    "&:hover .MuiInputLabel-root": {
      color: "#ffffff"
    },
    // This changes the border color on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff"
    },
    // This changes the small text color 
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#ffffff"
    },
    // This changes the text color of label
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#ffffff"
    },
    // This changes the border color on focus
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff"
    },
    // this changes background colour when field is selected
    "&:focus-within": {
      // backgroundColor: "#ffffff",
      borderRadius: "35px",
      opacity: 1,
    },
    // this one is when the curson hovers on the field
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #ffffff",
      borderRadius: "35px",
    },
    "& .Mui-disabled": {
      color: "#ffffff",
      opacity: 0.6,
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #4e7fc4",
      borderRadius: "35px",
    },
    // change the border when select is focused
    "& .MuiSelect-select:focus": {
      // backgroundColor: "#000000",
      borderRadius: "35px",
    },
  },
  selectRoot: {
    color: "#000000",
  },
  // This should change the text color of the lable on the field
  inputLabelRoot: {
    color: "#ffffff",
  },
  // this changes the colour of the pointer icon
  icon: {
    color: "#ffffff",
  },
  // paper comes out when the select is clicked
  selectPaper: {
    backgroundColor: "#ffffff",
    border: "1px solid #484850",
    // borderRadius: "35px",
    // colour below changes the text colour in paper
    color: "#000000",
    "& li:hover": {
      // change colour of list items on hover
      backgroundColor: "#c2c0ba",
    },
    maxHeight: 250,
  },
  formControl: {
    minWidth: "640px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 200,
  },
}));

export default function SelectCountry(LineId) {
  // render() {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedOption, setselectedOption] = React.useState("");

  // const inputLabels = useSelector(
  //   (reduxState) => reduxState.inputLabelsReducer.NextTranslation
  // );

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const currentValue = useSelector(
    (reduxState) => reduxState.projectLoginReducer.project.country
  );

  const content = pageContent[`${selectedLang}`];

  const fieldOptions = inputLabelOptions[`${selectedLang}`];

  const selectCountryOptions = [
    {
      name: fieldOptions.countryOptionOne,
      id: "Netherlands",
    },
    {
      name: fieldOptions.countryOptionTwo,
      id: "Germany",
    },
    {
      name: fieldOptions.countryOptionThree,
      id: "Austria",
    },
    {
      name: fieldOptions.countryOptionFour,
      id: "Switzerland",
    },
    {
      name: fieldOptions.countryOptionFive,
      id: "Belgium",
    },
    {
      name: fieldOptions.countryOptionSix,
      id: "France",
    },
    {
      name: fieldOptions.countryOptionSeven,
      id: "Spain",
    },
    {
      name: fieldOptions.countryOptionEight,
      id: "Italy",
    },
    {
      name: fieldOptions.countryOptionNine,
      id: "Portugal",
    },
    {
      name: fieldOptions.countryOptionTen,
      id: "Others",
    },
  ];

  let currentValueName = "";

  // if (currentValue === "grossPriceNL") {
  //   currentValueName = `saved value: ${fieldOptions.countryOptionOne}`;
  // } else if (currentValue === "grossPriceGermany") {
  //   currentValueName = `saved value: ${fieldOptions.countryOptionTwo}`;
  // } else if (currentValue === "grossPriceAustria") {
  //   currentValueName = `saved value: ${fieldOptions.countryOptionThree}`;
  // } else if (currentValue === "grossPriceSwitzerland") {
  //   currentValueName = `saved value: ${fieldOptions.countryOptionFour}`;
  // } else if (currentValue === "grossPriceBelgium") {
  //   currentValueName = `saved value: ${fieldOptions.countryOptionFive}`;
  // } else if (currentValue === "grossPriceFrance") {
  //   currentValueName = `saved value: ${fieldOptions.countryOptionSix}`;
  // } else if (currentValue === "grossPriceSpain") {
  //   currentValueName = `saved value: ${fieldOptions.countryOptionSeven}`;
  // } else if (currentValue === "grossPriceItaly") {
  //   currentValueName = `saved value: ${fieldOptions.countryOptionEight}`;
  // } else if (currentValue === "grossPricePortugal") {
  //   currentValueName = `saved value: ${fieldOptions.countryOptionNine}`;
  // } else if (currentValue === "grossPriceOthers") {
  //   currentValueName = `saved value: ${fieldOptions.countryOptionTen}`;
  // } else {
  //   currentValueName = content.changeCountry;
  // }

  if(currentValue){
    currentValueName = `saved value: ${currentValue}`
  }else{
    currentValueName = content.changeCountry;
  }

  const handleChange = (event) => {
    const country = event.target.value;
    setselectedOption(event.target.value);
    console.log("trying to chnage country", event.target.value);
    dispatch(
      changeProjectCountry(`${event.target.value}`, (reponse) => {
        if (reponse.message === "Success") {
          // dispatch(closePopupAction());
          console.log("response is bizzz", reponse)
        }
      })
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div
        style={{
          maxWidth: "100%",
          // marginLeft: "10%",
          // border: "1px solid black",
          // borderRadius: "35px",
          // marginTop: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Typography
            variant="h5"
            style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 , marginTop:"3%", marginRight : "10px"}}
            gutterBottom
          >
            {pageContent[`${selectedLang}`].country}:
          </Typography>
          <FormControl variant="outlined" className={classes.quantityRoot}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.inputLabelRoot}
            >
              {currentValueName}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedOption}
              onChange={handleChange}
              label={currentValueName}
              classes={{
                root: classes.selectRoot,
                icon: classes.icon,
              }}
              MenuProps={{ classes: { paper: classes.selectPaper } }}
            >
              {selectCountryOptions.map((option, i) => (
                <MenuItem value={option.id} key={i}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </React.Fragment>
  );
}
