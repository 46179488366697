import { NEW_LINE } from "../actions/lineActions";

const initialState = {};

export default function lineReducer(state = initialState, action = {}) {
  switch (action.type) {
    case NEW_LINE: {
      // return{...state,...action.payload}
      return (state = action.payload);
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
