import { combineReducers } from "redux";
import selectedLanguageReducer from "./selectedLanguageReducer";
import numberOfLinesToConfigureReducer from "./numberOfLinesToConfigureReducer";
import selectedLineIdReducer from "./selectedLineIdReducer";
import selectedProjectReferenceNumber from "./selectedProjectReducer";
import referenceNumberReducer from "./referenceNumberReducer";
import userLoginReducer from "./userLoginReducer";
import userSignupReducer from "./userSignupReducer";
import adminLoginReducer from "./adminLoginReducer"
import projectSignupReducer from "./projectSignupReducer";
import projectLoginReducer from "./projectLoginReducer";
import lineReducer from "./lineReducer";
import displayResultsReducer from "./displayResultsReducer";
import userProjectsReducer from "./userProjectsReducer";
import newLineReducer from "./newLineReducer";
import inputSectionReducer from "./inputSectionReducer";
import selectedLineInputsReducer from "./selectedLineInputsReducer";
import componentsReducer from "./componentsReducer";
import popupReducer from "./popupReducer";
import lineInfoReducer from "./lineInfoReducer";
import componentInfoReducer from "./componentInfoReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "selectedLanguageReducer",
    "numberOfLinesToConfigureReducer",
    "selectedLineIdReducer",
    "selectedProjectReferenceNumber",
    "referenceNumberReducer",
    "userLoginReducer",
    "userSignupReducer",
    "projectSignupReducer",
    "projectLoginReducer",
    "lineReducer",
    "displayResultsReducer",
    "userProjectsReducer",
    "newLineReducer",
    "inputSectionReducer",
    "selectedLineInputsReducer",
    "popupReducer",
    "lineInfoReducer",
    "adminLoginReducer",
    "componentsReducer",
  ],
};

const rootReducer = combineReducers({
  selectedLanguageReducer,
  numberOfLinesToConfigureReducer,
  selectedLineIdReducer,
  selectedProjectReferenceNumber,
  referenceNumberReducer,
  userLoginReducer,
  userSignupReducer,
  projectSignupReducer,
  projectLoginReducer,
  lineReducer,
  displayResultsReducer,
  userProjectsReducer,
  newLineReducer,
  inputSectionReducer,
  selectedLineInputsReducer,
  popupReducer,
  lineInfoReducer,
  adminLoginReducer,
  componentsReducer,
})

export default persistReducer(persistConfig, rootReducer);
