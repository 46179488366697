import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ActionButton from "./controls/ActionButton"
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { closePopupAction} from "../actions/popupActions";


const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(1),
    position: "absolute",
    // top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
}));

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup , closePopup, setClosePopup} = props;

  const classes = useStyles();

  const dispatch = useDispatch();

return (
  <Dialog open={openPopup} close={closePopup} maxWidth="md" classes={{ paper: classes.dialogWrapper }}>
      <DialogTitle className={classes.dialogTitle}>
          <div style={{ display: 'flex', textAlign:"center" }}>
              <Typography variant="h6" component="div" style={{ flexGrow: 1, color:"#0146ab", fontWeight: 600}}>
                  {title}
              </Typography>
              <ActionButton
                  color="secondary"
                  onClick={
                    ()=>{
                      dispatch(closePopupAction());
                      // console.log("x was clicked")
                    }}>
                  <CloseIcon />
              </ActionButton>
          </div>
      </DialogTitle>
      <DialogContent dividers>
          {children}
      </DialogContent>
  </Dialog>
)
}