const initialState = "";

export default function selectedProjectReducer(state = initialState, action) {
  switch (action.type) {
    case "SELECTED_PROJECT": {
      // => Ask yourself: what is action.payload?
      return (state = action.payload);
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
