import request from "superagent";
import fileDownload from "js-file-download";
import {checkJwt} from "../actions/jwtActions";




// The function send pdf with price to emails ending with "opple.com"
export const sendPdfEmail = (contactEmail,contactName,rows, emRows, componentRows, lineRows, allLines,selectedLang, callback) => (dispatch,getState) => {
    const jwt = getState().projectLoginReducer.jwt
    const project = getState().projectLoginReducer.project
    const country = getState().projectLoginReducer.project.country

    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .post(`/pdfPrice/${project.referenceNumber}`)
        .set("Authorization", `Bearer ${jwt}`)
        .send({project, projectId:project.id, contactEmail, contactName, rows, emRows, componentRows, lineRows, project, allLines, selectedLang})
        .then(response => {
            typeof callback == "function" && callback(response.body);
            console.log("pdf sent Successfully")
        })
        .catch(error => {
            console.log(error);
        })
    }  
}

// Function to make a call to the database to download the pdf
export const getPdf = (rows, emRows, componentRows, lineRows, allLines,selectedLang, callback) => (dispatch, getState) => {
    const{jwt,project} = getState().projectLoginReducer;

    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .post(`/pdf/${project.referenceNumber}`)
        .send({rows, emRows, componentRows, lineRows, project, allLines, selectedLang})
        .responseType("blob")
        .set("Authorization", `Bearer ${jwt}`)
        // .send({referenceNumber: project.referenceNumber})
        .then(function (res) {
          fileDownload(
            res.body,
            res.headers["content-disposition"]
              .split("filename=")[1]
              .replace(/['"]+/g, "")
          );
        });
    }
  }

//   this is only to check if the pdf with price is actually made
export const makePdf = (rows, emRows, componentRows, lineRows, allLines,selectedLang, callback) => (dispatch, getState) => {
    const{jwt,project} = getState().projectLoginReducer;
    
    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .post(`/pdf/price/email`)
        .set("Authorization", `Bearer ${jwt}`)
        .send({rows, emRows, componentRows, lineRows, project, allLines, selectedLang})
        .then(response => {
            typeof callback == "function" && callback(response.body);
            console.log("pdf made Successfully")
        })    
        .catch(error => {
            console.log(error);
        })
    }
  }


  // function for price quotation
  export const sendPdfPriceEmail = (contactEmail,contactName,rows, emRows, componentRows, lineRows, allLines,selectedLang,saveUserContacts, callback) => (dispatch,getState) => {
    const jwt = getState().projectLoginReducer.jwt
    const project = getState().projectLoginReducer.project
    const country = getState().projectLoginReducer.project.country

    const testJwt = dispatch(checkJwt(jwt));

    if(testJwt == false){
        request
        .post(`/pdfPrice/customerService/${project.referenceNumber}`)
        .set("Authorization", `Bearer ${jwt}`)
        .send({project, projectId:project.id, contactEmail, contactName, rows, emRows, componentRows, lineRows, project, allLines, selectedLang, saveUserContacts})
        .then(response => {
            typeof callback == "function" && callback(response.body);
            console.log("pdf sent Successfully")
        })
        .catch(error => {
            console.log(error);
        })
    }
}
