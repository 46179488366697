import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../material.css";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Popup from "../components/Popup";
import { displayResultsError, requestQuotationPopup, addProjectComment, addLineComment, lineFieldInfo,lineFieldInfoCompact, lineDetails } from "../actions/popupActions";
import RequestQuotationForm from "../components/forms/RequestQuotationForm";
import AddProjectComment from "../components/forms/AddProjectComment";
import AddLineComment from "../components/forms/AddLineComment";
import LineFieldInfo from "../components/fieldInfoComponents/LineFieldInfo";
import LineFieldInfoCompact from "../components/fieldInfoComponents/LineFieldInfoCompact";
import {
  Grid,
  CssBaseline,
  Container,
  Typography,
  Link,
  Button,
  Tooltip,
} from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import InfoIcon from "@material-ui/icons/Info";
import { isCompositeComponent } from "react-dom/test-utils";
import request from "superagent";
import fileDownload from "js-file-download";
import { getPdf } from "../actions/pdfActions";
import { makePdf } from "../actions/pdfActions";

import { pageContent } from "../lang/pageContent";

import { addNum } from "../components/displayResutlsAlgorithm";
import Controls from "../components/controls/Controls";
import { getData } from "../actions/lineActions";


const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
    "& thead th": {
      fontWeight: "500",
      // color: theme.palette.primary.main,
      // color: theme.palette.text.primary,
      color: "#ffffff",
      // backgroundColor: theme.palette.primary.light,
      backgroundColor: "#0146ab",
      borderBottom: "none",
      // borderRadius: 35,
    },
    "& thead th:last-child ": {
      borderRadius: "0 30px 30px 0px",
    },
    "& thead th:first-child ": {
      borderRadius: "30px 0px 0px 30px",
    },
    "& tbody td": {
      fontWeight: "300",
    },
    // '& tbody tr': {
    //     fontWeight: '300',
    //     borderTop: '1px solid red'
    // },
    "& tbody tr:hover": {
      backgroundColor: "#f2f2f2",
      cursor: "pointer",
    },
    // minWidth: 850,
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 200,
  },
  // table: {
  //   minWidth: 650,
  // },
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    width: "80%",
  },
  tableRow: {
    height: 10,
  },
  button: {
    // color:"#ffffff",
    maxWidth: "200px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "#1a9fe0",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 40,
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "0px 16px",
  },
}))(TableCell);

export default function DisplayResultsPage() {
  const classes = useStyles();

  const history = useHistory();

  const projectLoginReducer = useSelector(
    (reduxState) => reduxState.projectLoginReducer
  )

  const allLines = useSelector((reduxState) => reduxState.lineReducer.data);

  useEffect(() => {
    dispatch(getData());
    console.log("Use effect in display results");
    // setState((state.data = newLine));
    if(!projectLoginReducer.jwt){
      console.log("I did run sssss")
      history.push("/")
    }else {
      checkLineStatus();
    }
  }, []);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );
  

   

  const lineId = useSelector((reduxState) => reduxState.selectedLineIdReducer);

  const project = useSelector(
    (reduxState) => reduxState.projectLoginReducer.project
  );

  const rows = useSelector((reduxState) => reduxState.displayResultsReducer.sortedData.rows)

  const emRows = useSelector((reduxState) => reduxState.displayResultsReducer.sortedData.emRows)

  const componentRows = useSelector((reduxState) => reduxState.displayResultsReducer.sortedData.componentRows)

  const lineRows = useSelector((reduxState) => reduxState.displayResultsReducer.sortedData.lineRows)

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);

  const dispatch = useDispatch();

  let commentOne = pageContent[`${selectedLang}`].lineDoesNotEndWithLedModule;

  let commentTwo = "Amount Of Emergency modules is unknown";




  //  This is for the download as pdf button
  const handleSubmit1 = async (event) => {
    // console.log("The download as pdf button was clicked");
    const response = await request
      .post(`/pdf`)
      .responseType("blob")
      .then(function (res) {
        console.log("niru response", res);
        console.log(
          "response header is",
          res.headers["content-disposition"]
            .split("filename=")[1]
            .replace(/['"]+/g, "")
        );
        fileDownload(
          res.body,
          res.headers["content-disposition"]
            .split("filename=")[1]
            .replace(/['"]+/g, "")
        );
      });
    // fileDownload(
    //   response.body,
    //
    // );
  };

  const handleSubmit = async (event) => {
    // console.log("the line rows are check check", lineRows);
    // the action below uses API to download the pdf without price
    dispatch(
      getPdf(rows, emRows, componentRows, lineRows, allLines, selectedLang)
    );
    // use the dispatch function below to check pdf with price locally, for styling purposes
    // the pdf will be in the collections folder in the backend
    // dispatch(makePdf(rows, emRows, componentRows, lineRows, allLines, selectedLang));
  };

  const tableData = {
    rows,
    emRows,
    componentRows,
    lineRows,
    allLines,
    selectedLang,
  };

  function checkLineStatus() {
    const result = allLines.filter((line) => line.lineStatus === "true");
    if(allLines.length <1){
      history.push("/projectDashboard")
    }else
    if (result.length == allLines.length) {
      dispatch(getData ((response) => {
        if(response.message === "Success"){
          history.push("/displayResultsPage");
        }
      }))
    } else {
      history.push("/projectDashboard")
    }
  }

  return (
    <React.Fragment>
    {!projectLoginReducer.jwt && history.push("/")}
      <Container maxWidth="lg" className={"projectDashboard"}>
        <Grid container direction={"column"} maxWidth="lg" spacing={2}>
          <Grid
            item
            style={{
              position: "absolute",
              left: "10%",
              top: "10%",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].trunkingConfigurator}
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].projectOverview}
            </Typography>
            <br></br>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].referenceNumber}:{" "}
              {project.referenceNumber}
              <br></br>
              <br></br>
              {pageContent[`${selectedLang}`].projectName}:{" "}
              {project.projectName}
            </Typography>
            <br></br>

            <Typography variant="body2">
              <Link
                // href="/"
                onClick={() => history.push("/helpPage")}
                // underline="always"
                style={{
                  color: "#ffffff",
                  fontWeight: 350,
                  fontSize: "15px",
                  cursor: "pointer",
                }}
              >
                {"How to use Project Overview"}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Grid container justify="center">
        <Paper className={classes.pageContent} elevation={0}>
          {/* The table below is for all the LED Modules of a project */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#0146ab" }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].projectOverview}: {project.projectName}
            </Typography>
          </div>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="product overview table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    {pageContent[`${selectedLang}`].productCode}
                  </TableCell>
                  <TableCell>
                    {pageContent[`${selectedLang}`].productDescription}
                  </TableCell>
                  <TableCell>
                    {pageContent[`${selectedLang}`].unitsNeeded}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row" width="20%">
                      {row.code}
                    </StyledTableCell>
                    <StyledTableCell width="20%">{row.name}</StyledTableCell>
                    <StyledTableCell width="10%">
                      {row.numberOfUnits}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {emRows.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row" width="20%">
                      {row.code}
                    </StyledTableCell>
                    <StyledTableCell width="20%">{row.name}</StyledTableCell>
                    <StyledTableCell width="10%">
                      {row.numberOfUnits}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {componentRows.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="th" scope="row" width="20%">
                      {row.code}
                    </StyledTableCell>
                    <StyledTableCell width="20%">{row.name}</StyledTableCell>
                    <StyledTableCell width="10%">
                      {row.numberOfUnits}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              maxWidth: "100%",
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-between",
              flexWrap: "wrap",
              padding: "10px",
              // borderColor : "#000000",
              // border: "1px solid black"
            }}
          >
            <Controls.ActionButton color="secondary">
              <Tooltip title={pageContent[`${selectedLang}`].addProjectComment}>
                <CommentIcon 
                fontSize="small"
                onClick={() => {
                  dispatch(
                    addProjectComment({status:true})
                  )
                }}
                />
              </Tooltip>
            </Controls.ActionButton>
            <Typography
              variant="body1"
              style={{ fontWeight: 600, color: "#000000", lineHeight: 3 }}
              gutterBottom
            >
             {project.userComment}
            </Typography>
          </div>
        </Paper>
      </Grid>
      <br></br>

      <br></br>
      {lineRows && lineRows.map((row, i) => (
        <Grid container justify="center">
          <Paper className={classes.pageContent} elevation={0}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div>
                <Typography
                  variant="h5"
                  style={{ fontWeight: 600, color: "#0146ab" }}
                  gutterBottom
                >
                  {pageContent[`${selectedLang}`].lineName}: {allLines[i].name}
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  variant="h5"
                  style={{ fontWeight: 600, color: "#0146ab" }}
                  gutterBottom
                >
                  {pageContent[`${selectedLang}`].length}: {allLines[i].lengthOfLineRounded}m x{" "}
                  {allLines[i].fieldThreeInput}
                </Typography>
                <Controls.ActionButton color="secondary" style={{ marginBottom: "1px"}}>
                  <Tooltip title="Line Fields Details" >
                    <InfoIcon 
                    fontSize = "small"
                    onClick={() => {
                      if(allLines[i].lengthOfLineRounded < 3){
                        dispatch(lineDetails({line : allLines[i]}))
                        dispatch(lineFieldInfoCompact({status:true}))
                      }else {
                        dispatch (
                          lineDetails({line : allLines[i]})
                        )
                         dispatch(
                          lineFieldInfo({status:true})
                        )
                      }
                     
                    }}
                    />
                  </Tooltip>
                </Controls.ActionButton>
              </div>
            </div>

            <TableContainer>
              <Table
                className={classes.table}
                aria-label="product overview table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {pageContent[`${selectedLang}`].productCode}
                    </TableCell>
                    <TableCell>
                      {pageContent[`${selectedLang}`].productDescription}
                    </TableCell>
                    <TableCell>
                      {pageContent[`${selectedLang}`].unitsNeeded}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lineRows[i].map((line, j) => (
                    <StyledTableRow key={j}>
                      <StyledTableCell component="th" scope="row" width="20%">
                        {line.code}
                      </StyledTableCell>
                      <StyledTableCell width="20%">{line.name}</StyledTableCell>
                      <StyledTableCell width="10%">
                        {line.numberOfUnits}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
            style={{
              maxWidth: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginLeft : "1%",
              marginTop : "1%",
            }}
            >
            {/* <Typography
              variant="body1"
              style={{ fontWeight: 600, color: "#000000", lineHeight: 2 }}
              gutterBottom
            >
             {allLines[i].fieldSevenComment == "true" && commentOne}
            </Typography> */}
            </div>
            <div
            style={{
              maxWidth: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginLeft : "1%",
              marginTop : "1%",
            }}
            >
            <Typography
              variant="body1"
              style={{ fontWeight: 600, color: "#000000", lineHeight: 2 }}
              gutterBottom
            >
              {/* check field ten and field six to show the comment */}
              {(allLines[i].fieldSixInput !== "3" && allLines[i].fieldTenInput == "2") && commentTwo}
            </Typography>
            </div>
           
            <div
            style={{
              maxWidth: "100%",
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-between",
              flexWrap: "wrap",
              padding: "10px",
              // borderColor : "#000000",
              // border: "1px solid black"
            }}
          >
            <Controls.ActionButton color="secondary">
              <Tooltip title={pageContent[`${selectedLang}`].addLineComment}>
                <CommentIcon 
                fontSize="small"
                onClick={() => {
                  dispatch(
                    addLineComment({status:true, lineId : allLines[i].id})
                  )
                }}
                />
              </Tooltip>
            </Controls.ActionButton>
            <Typography
              variant="body1"
              style={{ fontWeight: 600, color: "#000000", lineHeight: 3 }}
              gutterBottom
            >
             {allLines[i].userComment}
            </Typography>
          </div>
          </Paper>
        </Grid>
      ))}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginLeft: "8%",
          marginRight: "10%",
          marginBottom: "200px",
          padding: "50px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={() => history.push("/projectDashboard")}
        >
          {pageContent[`${selectedLang}`].goToDashboardTranslation}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={handleSubmit}
        >
          {pageContent[`${selectedLang}`].downloadPdfWithoutPrices}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={() => dispatch(requestQuotationPopup({ status: true }))}
        >
          {pageContent[`${selectedLang}`].requestForQuotation}
        </Button>
      </div>
      <Popup
        openPopup={popupState.requestQuotation}
        setOpenPopup={setOpenPopup}
        title= {pageContent[`${selectedLang}`].requestQuotation}
      >
        <RequestQuotationForm tableData={tableData} />
      </Popup>
      <Popup
      openPopup={popupState.addProjectComment}
      setOpenPopup={setOpenPopup}
      title={pageContent[`${selectedLang}`].addProjectComment}
      >
        <AddProjectComment/>
      </Popup>
      <Popup
      openPopup={popupState.addLineComment}
      setOpenPopup={setOpenPopup}
      title="Add Line Comment"
      >
        <AddLineComment/>
      </Popup>
      <Popup
      openPopup={popupState.lineFieldInfo}
      setOpenPopup={setOpenPopup}
      title="Line Fields Details"
      >
        <LineFieldInfo/>
      </Popup>
      <Popup
      openPopup={popupState.lineFieldInfoCompact}
      setOpenPopup={setOpenPopup}
      title="Line Fields Details"
      >
        <LineFieldInfoCompact/>
      </Popup>
      <CssBaseline />
    </React.Fragment>
  );
}
