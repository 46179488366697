// error starting with 1 are for projects
export const errorText ={
    english :{
        "error101" : "Please supply a valid email and password",
        "error102" : "Project with that reference number does not exist",
        "error103" : "Password was incorrect",
        "error104" : "Something went wrong",
        "error105" : "Please supply valid credentials, do not leave any fields empty",
        "error106" : "Username is already in use, please try a different username",
        "error107" : "User with that username does not exist",
        "error108" : "Username Required",
        "error109" : "Password Required",
        "error110" : "Project Name Required",
        "error111" : "Reference Number Required",
        "error112" : "Please make sure that there are no invalid values and try again",
        "error113" : "This password is too short, please create a longer password",
        "error114" : "Please do not leave any fields empty",
        "error115" : "Please enter a valid value",

        // Translations not available for comments below
        "error116" : "Too Short",
        "error121" : "Custom number cannot be more than blind covers. Please try a smaller number.",

        "error117" : "Contact Name required",
        "error118" : "Contact Email required",
        "error119" : "Please check the email and try again",
        "error120" : "Please increase line length or change preferred length of trunk",
        "error121" : "Password must contain minimum 5 characters, at least one uppercase letter, one lowercase letter,one number and no special characters/symbols"
    },
    dutch :{
        "error101" : "Please supply a valid email and password",
        "error102" : "Project with that reference number does not exist",
        "error103" : "Password was incorrect",
        "error104" : "Something went wrong",
        "error105" : "Please supply valid credentials, do not leave any fields empty",
        "error106" : "Gebruikersnaam is al in gebruik, kies een andere gebruikersnaam",
        "error107" : "Gebruiker met die gebruikersnaam bestaat niet",
        "error108" : "Gebruikersnaam verplicht",
        "error109" : "Wachtwoord vereist",
        "error110" : "Naam project verplicht",
        "error111" : "Referentienummer verplicht",
        "error112" : "Zorg er a.u.b. voor dat u geen ongeldige waardes invoert en probeer het nog een keer",
        "error113" : "Het wachtwoord is te kort, voer a.u.b. een langer wachtwoord in",
        "error114" : "Laat a.u.b. geen velden leeg",
        "error115" : "Voer a.u.b. een geldige waarde in",
        "error116" : "Too Short",
        "error117" : "Naam contactpersoon verplicht",
        "error118" : "Contact e-mail verplicht",
        "error119" : "Controleer de e-mail en probeer het opnieuw",
        "error120" : "Vergroot de lengte van de lijn of verander de gewenste lengte van de trunk",
        "error121" : "Het wachtwoord moet minstens 5 tekens bevatten, minstens één hoofdletter, één kleine letter, één cijfer en geen speciale tekens/symbolen"
    },
    french :{
        "error101" : "Please supply a valid email and password",
        "error102" : "Project with that reference number does not exist",
        "error103" : "Password was incorrect",
        "error104" : "Something went wrong",
        "error105" : "Please supply valid credentials, do not leave any fields empty",
        "error106" : "Le nom d’utilisateur est déjà utilisé, veuillez en choisir un autre",
        "error107" : "L'utilisateur avec ce nom d'utilisateur n'existe pas",
        "error108" : "Nom d'utilisateur requis",
        "error109" : "Mot de passe obligatoire",
        "error110" : "Nom du projet requis",
        "error111" : "Numéro de référence requis",
        "error112" : "Veuillez vous assurer que vous n’avez pas saisi de valeurs incorrectes et réessayez",
        "error113" : "Le mot de passe est trop court, veuillez saisir un mot de passe plus long",
        "error114" : "Veuillez ne pas laisser de champs vides",
        "error115" : "Veuillez saisir une valeur correcte",
        "error116" : "Too Short",

        "error117" : "Nom du contact requis",
        "error118" : "Courriel du contact requis",
        "error119" : "Veuillez vérifier l'email et réessayer",
        "error120" : "Veuillez augmenter la longueur de la ligne ou modifier la longueur préférée du tronc",
        "error121" : "Le mot de passe doit contenir au minimum 5 caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et aucun caractère/symbole spécial"
    },  
    german :{
        "error101" : "Please supply a valid email and password",
        "error102" : "Project with that reference number does not exist",
        "error103" : "Password was incorrect",
        "error104" : "Something went wrong",
        "error105" : "Please supply valid credentials, do not leave any fields empty",
        "error106" : "Der Benutzername ist bereits vergeben, bitte versuchen Sie es mit einem anderen Benutzernamen.",
        "error107" : "Benutzer mit diesem Benutzernamen existiert nicht",
        "error108" : "Benutzername erforderlich",
        "error109" : "Passwort erforderlich",
        "error110" : "Projektname erforderlich",
        "error111" : "Referenznummer erforderlich",
        "error112" : "Bitte prüfen Sie die eingegeben Werte und versuchen Sie es erneut.",
        "error113" : "Das Passwort ist zu kurz, bitte verwenden Sie ein längeres Passwort.",
        "error114" : "Bitte lassen Sie keine Felder leer.",
        "error115" : "Bitte geben Sie einen gültigen Wert ein.",
        "error116" : "Too Short",

        "error117" : "Kontaktname erforderlich",
        "error118" : "Kontakt-E-Mail erforderlich",
        "error119" : "Bitte überprüfen Sie die E-Mail und versuchen Sie es erneut",
        "error120" : "Bitte erhöhen Sie die Leitungslänge oder ändern Sie die gewünschte Länge der Leitung",
        "error121" : "Das Passwort muss mindestens 5 Zeichen enthalten, mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und keine Sonderzeichen/Symbole"
    },  
    spanish :{
        "error101" : "Please supply a valid email and password",
        "error102" : "Project with that reference number does not exist",
        "error103" : "Password was incorrect",
        "error104" : "Something went wrong",
        "error105" : "Please supply valid credentials, do not leave any fields empty",
        "error106" : "El nombre de usuario ya está en uso. Escriba un nombre de usuario diferente",
        "error107" : "El usuario con ese nombre de usuario no existe",
        "error108" : "Nombre de usuario requerido",
        "error109" : "Contraseña requerida",
        "error110" : "Nombre del proyecto requerido",
        "error111" : "Número de referencia requerido",
        "error112" : "Asegúrese de que no haya ningún valor no válido y vuelva a intentarlo.",
        "error113" : "Esta contraseña es demasiado corta. Cree una contraseña más larga",
        "error114" : "No deje en blanco ninguno de los campos",
        "error115" : "Escriba un valor válido",
        "error116" : "Too Short",

        "error117" : "Nombre del contacto requerido",
        "error118" : "Correo electrónico de contacto requerido",
        "error119" : "Por favor, compruebe el correo electrónico e inténtelo de nuevo",
        "error120" : "Por favor, aumente la longitud de la línea o cambie la longitud preferida del tronco",
        "error121" : "La contraseña debe contener un mínimo de 5 caracteres, al menos una mayúscula, una minúscula, un número y ningún carácter/símbolo especial"
    },  
    portugese :{
        "error101" : "Please supply a valid email and password",
        "error102" : "Project with that reference number does not exist",
        "error103" : "Password was incorrect",
        "error104" : "Something went wrong",
        "error105" : "Please supply valid credentials, do not leave any fields empty",
        "error106" : "O nome de utilizador já está a ser utilizado. Tente usar um nome de utilizador diferente",
        "error107" : "Utilizador com esse nome de utilizador não existe",
        "error108" : "Nome de utilizador requerido",
        "error109" : "Senha necessária",
        "error110" : "Nome do projecto requerido",
        "error111" : "Número de referência necessário",
        "error112" : "Certifique-se de que não existem valores inválidos e tente novamente",
        "error113" : "Esta palavra-passe é demasiado curta. Crie uma palavra-passe mais longa",
        "error114" : "Não deixe nenhum campo vazio",
        "error115" : "Introduza um valor válido",
        "error116" : "Too Short",

        "error117" : "Nome de contacto requerido",
        "error118" : "E-mail de contacto necessário",
        "error119" : "Por favor verifique o e-mail e tente novamente",
        "error120" : "Por favor aumentar o comprimento da linha ou alterar o comprimento preferido do tronco",
        "error121" : "A palavra-passe deve conter no mínimo 5 caracteres, pelo menos uma letra maiúscula, uma letra minúscula, um número e sem caracteres/simbolos especiais"
    },
    finnish :{
        "error101" : "Please supply a valid email and password",
        "error102" : "Project with that reference number does not exist",
        "error103" : "Password was incorrect",
        "error104" : "Something went wrong",
        "error105" : "Please supply valid credentials, do not leave any fields empty",
        "error106" : "Käyttäjätunnus on jo käytössä, kokeile toista käyttäjätunnusta",
        "error107" : "Käyttäjää, jolla on kyseinen käyttäjätunnus, ei ole olemassa",
        "error108" : "Käyttäjätunnus vaaditaan",
        "error109" : "Salasana vaaditaan",
        "error110" : "Projektin nimi vaaditaan",
        "error111" : "Viitenumero vaaditaan",
        "error112" : "Varmista, että virheellisiä arvoja ei ole, ja yritä uudelleen",
        "error113" : "Salasana on liian lyhyt, luo pidempi salasana",
        "error114" : "Älä jätä yhtään kenttää tyhjäksi",
        "error115" : "Anna kelvollinen arvo",
        "error116" : "Too Short",

        "error117" : "Yhteyshenkilön nimi vaaditaan",
        "error118" : "Yhteyshenkilön sähköpostiosoite vaaditaan",
        "error119" : "Tarkista sähköpostiosoite ja yritä uudelleen",
        "error120" : "Suurenna rivin pituutta tai muuta haluamasi runkopituus",
        "error121" : "Salasanan on sisällettävä vähintään 5 merkkiä, vähintään yksi iso kirjain, yksi pieni kirjain, yksi numero ja ei erikoismerkkejä/symboleita"
    },
    polish :{
        "error101" : "Please supply a valid email and password",
        "error102" : "Project with that reference number does not exist",
        "error103" : "Password was incorrect",
        "error104" : "Something went wrong",
        "error105" : "Please supply valid credentials, do not leave any fields empty",
        "error106" : "Nazwa użytkownika jest już zajęta, proszę podać inną nazwę użytkownika",
        "error107" : "Użytkownik o tej nazwie użytkownika nie istnieje",
        "error108" : "Wymagana nazwa użytkownika",
        "error109" : "Wymagane hasło",
        "error110" : "Wymagana nazwa projektu",
        "error111" : "Wymagany numer referencyjny",
        "error112" : "Proszę się upewnić, że wszystkie wartości są prawidłowe i spróbować ponownie",
        "error113" : "To hasło jest za krótkie, proszę utworzyć dłuższe hasło",
        "error114" : "Proszę nie zostawiać pustych pól",
        "error115" : "Proszę podać prawidłową wartość",
        "error116" : "Too Short",

        "error117" : "Wymagane imię i nazwisko osoby kontaktowej",
        "error118" : "Wymagany e-mail kontaktowy",
        "error119" : "Proszę sprawdzić e-mail i spróbować ponownie",
        "error120" : "Proszę zwiększyć długość linii lub zmienić preferowaną długość trąbki",
        "error121" : "Hasło musi zawierać minimum 5 znaków, przynajmniej jedną wielką literę, jedną małą literę, jedną cyfrę i żadnych znaków specjalnych/symboli"
    },
    italian :{
        "error101" : "Please supply a valid email and password",
        "error102" : "Project with that reference number does not exist",
        "error103" : "Password was incorrect",
        "error104" : "Something went wrong",
        "error105" : "Please supply valid credentials, do not leave any fields empty",
        "error106" : "Nome utente già in uso, per favore scegli un altro nome",
        "error107" : "L'utente con quel nome utente non esiste",
        "error108" : "Nome utente richiesto",
        "error109" : "Password richiesta",
        "error110" : "Nome del progetto richiesto",
        "error111" : "Numero di riferimento richiesto",
        "error112" : "Accertati che non ci siano valori nulli e prova di nuovo",
        "error113" : "Questa password è troppo corta, per favore inserisci una password più lunga",
        "error114" : "Per favore compila tutti i campi",
        "error115" : "Per favore inserisci un valore valido",
        "error116" : "Too Short",

        "error117" : "Nome del contatto richiesto",
        "error118" : "Email di contatto richiesto",
        "error119" : "Si prega di controllare l'e-mail e riprovare",
        "error120" : "Si prega di aumentare la lunghezza della linea o cambiare la lunghezza preferita del tronco",
        "error121" : "La password deve contenere almeno 5 caratteri, almeno una lettera maiuscola, una lettera minuscola, un numero e nessun carattere/simbolo speciale"
    },
}