import React, { useEffect, useState } from "react";
import { IconButton, Badge, makeStyles, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Grid from "@material-ui/core/Grid";
import { errorText } from "../lang/errorText";
import { pageContent } from "../lang/pageContent";
import {clearUserSignupReducerMessage} from "../actions/userActions";
import Popup from "../components/Popup";
import UserSignupMessgaeForm from "../components/forms/UserSignupMessageForm";
import {userSignupMessage} from "../actions/popupActions";



import "../material.css";

const useStyles = makeStyles((theme) => ({
  root: {
    //   this changes the border colour
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
    },
    // this changes the border colour on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
    },
    // this changes the border colour on focus(when typing)
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
    },
    // this changes the text colour on typing
    "& .MuiInputBase-root": {
      color: "#ffffff",
    },
    //   this changes the border radius of the field
    [`& fieldset`]: {
      borderRadius: 35,
      // background:"#ffffff"
    },

    width: "33em",

    // this changes the background to white
    // background: "#ffffff"
  },
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    width:"20em",
    height: "50px",
    borderRadius: 35,
    backgroundColor: "#1a9fe0",
    justifyContent: "space-between",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "20px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
  input: {
    color: "#FFFFFF",
  },
  endIcon: {
    fontSize: 12,
  },
}));

export default function UserSignUpPage() {
  const classes = useStyles();
  const { register, handleSubmit, control, errors } = useForm();
  const [error, setError] = React.useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( clearUserSignupReducerMessage())
},[])

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);

  const user = useSelector((reduxState) => reduxState.userSignupReducer);

  const handleChange = (event) => {
    // setFormData({ ...formData, [event.target.name]: event.target.value });
    console.log("value of form data is:", event.target.value);
  };

  const [showPassword, setShow] = useState(false);

  const [userError, setUserError] = React.useState();

  const togglePassword = () => {
    setShow(!showPassword);
  };

  const onSubmit = (data) => {
    dispatch(userSignupMessage({status:true, data}))
    // console.log("the data is bzbzbzbzb", data)
    // dispatch(
    //   addUser(data.email, data.userName, data.password, (response) => {
    //     if (response.message === "Success") {
    //       setUserError("false");
    //       history.push("/userLogin");
    //     } 
    //   })
    // );
  };


  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className="userSignup" style={{minHeight: "100vh"}}>
        <br></br>
        <br></br>
        <div
        style={{
          position: "absolute",
          left: "10%",
          top: "20%",
        }}
        >
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            direction={"column"}
            spacing={2}
            // style={{
            //   position: "absolute",
            //   left: "10%",
            //   top: "14%",
            // }}
          >
            <Grid item>
              <Typography
                variant="h5"
                style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
                inputProps={{ style: { fontSize: 300 } }}
                gutterBottom
              >
                {pageContent[`${selectedLang}`].trunkingConfigurator}
              </Typography>
              <br></br>
              <Typography
                variant="h1"
                style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
                gutterBottom
              >
                {/* User Signup <br></br>
                Form */}
                {pageContent[`${selectedLang}`].userSignupPage}
              </Typography>
            </Grid>
            <br></br>
            <br></br>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                inputRef={register({
                  required: errorText[`${selectedLang}`].error108,
                  minLength: { value: 4, message: errorText[`${selectedLang}`].error116 },
                })}
                required
                fullWidth
                className={classes.root}
                InputLabelProps={{
                  style: { color: "#ffffff" },
                }}
                id="userName"
                label={pageContent[`${selectedLang}`].userName}
                name="userName"
                autoComplete="off"
                onChange={handleChange}
                error={Boolean(errors.userName)}
              />
              {errors.userName && (
                <p style={{ color: "red" }}>{errors.userName.message}</p>
              )}
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                inputRef={register({
                  required: errorText[`${selectedLang}`].error109,
                  minLength: { value: 2, message: errorText[`${selectedLang}`].error116 },
                  pattern: {
                    //regular expression for minimum 5 characters, at least one uppercase letter, one lowercase letter, one number and no special characters/symbols
                    value : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{5,}$/,
                    message : errorText[`${selectedLang}`].error121 //This message should show up if the error is true for the above regular expression. In this page, when the error is true, the error message is referenced from the errorText file. Check line 255
                  }
                })}
                required
                name="password"
                label={pageContent[`${selectedLang}`].password}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="off"
                className={classes.root}
                InputLabelProps={{
                  style: { color: "#ffffff" },
                }}
                error={Boolean(errors.password)}
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="Show/Hide Password"
                      aria-label="showPassword"
                      onClick={togglePassword}
                    >
                      <IconButton>
                        <Badge color="secondary">
                          <VisibilityIcon
                            fontSize="small"
                            style={{ color: "#76a6c2" }}
                          />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
              {errors.password && (
                <p style={{ color: "red", display : "flex", maxWidth : "33em" }}>{errorText[`${selectedLang}`].error121}</p>
              )}
              {user.errorCode && (
                <p style={{ color: "red" }}>
                  {errorText[`${selectedLang}`][`${user.errorCode}`]}
                </p>
              )}
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
              >
                {pageContent[`${selectedLang}`].submit} <ArrowForwardIosIcon className={classes.endIcon} />
              </Button>
            </Grid>
            {/* <Grid item>
              <Typography variant="body2">
                <Link
                  onClick={() => history.push("/")}
                  style={{
                    color: "#ffffff",
                    fontWeight: 350,
                    fontSize: "20px",
                    cursor : "pointer"
                  }}
                >
                  {"How to use User Signup Form"}
                </Link>
              </Typography>
            </Grid> */}
          </Grid>
        </form>
        </div>
      </Container>
      <Popup
        openPopup={popupState.userSignupMessage}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].attention}
      >
        <UserSignupMessgaeForm />
      </Popup>
    </React.Fragment>
  );
}
