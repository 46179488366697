import request from "superagent";
import {getProjectLines} from "./lineActions"
import fileDownload from "js-file-download";
import {checkJwt} from "../actions/jwtActions";
// export const baseUrl = "http://localhost:4000";
export const ADD_PROJECT = "ADD_PROJECT";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const PROJECT_LOG_IN = "PROJECT_LOG_IN";
export const CREATE_FIRST_LINE = "CREATE_FIRST_LINE";
export const JWT = "JWT";
export const GET_USER_PROJECTS = "GET_USER_PROJECTS";
export const SELECTED_PROJECT = "SELECTED_PROJECT";
export const SELECTED_COUNTRY = "SELECTED_COUNTRY";
export const UPDATE_PROJECT_LINES = "UPDATE_PROJECT_LINES";
export const CLEAR_PROJECT_SIGNUP_REDUCER = "CLEAR_PROJECT_SIGNUP_REDUCER";
export const CLEAR_PROJECT_REFERENCE_NUMBER_REDUCER = "CLEAR_PROJECT_REFERENCE_NUMBER_REDUCER";
export const CLEAR_PROJECT_LOGIN_REDUCER_MESSAGE = "CLEAR_PROJECT_LOGIN_REDUCER_MESSAGE";
// Project login

export function ProjectLogin(payload) {
  return {
    type: PROJECT_LOG_IN,
    payload: payload,
  };
}


// action for storing selected project in the user dashboard
export function selectedProjectReferenceNumber(payload) {
  console.log("A project was selected to configure");
  return {
    type: SELECTED_PROJECT,
    payload: payload,
  };
}

// This function logs into a project, used in the project dashboard
export const fakeProjectLogin = (
  referenceNumber,
  callback
) => (dispatch, getState) => {
  const { jwt, userName } = getState().userLoginReducer;
 
  const testJwt = dispatch(checkJwt(jwt));

  if(testJwt == false){
    console.log("I am falseeeee")
    request
    .post(`/projectLogin/user`)
    .set("Authorization", `Bearer ${jwt}`)
    .send({ userName, referenceNumber })
    .then((response) => {
      dispatch(ProjectLogin(response.body));
      typeof callback == "function" && callback(response.body);
      console.log("success");
      // dispatch(getProjects());
    })
    .catch((error) => {
      console.log(error);
    });
  }
};

export const projectLogin = (referenceNumber, password, callback) => (
  dispatch
) => {
  request
    .post(`/projectLogin`)
    .send({ referenceNumber, password })
    .then((response) => {
      dispatch(ProjectLogin(response.body));
      typeof callback == "function" && callback(response.body);
      console.log("success");
    })
    .catch((error) => {
      if(error){
        dispatch(ProjectLogin(error.response.body.message))
      }
    });
};

// Create a project

function makeProject(payload) {
  return {
    type: CREATE_PROJECT,
    payload: payload,
  };
}

export function clearProjectSignupReducer(){
  return {
    type : CLEAR_PROJECT_SIGNUP_REDUCER
  }
}

export function clearProjectReferenceNumberReducer(){
  return {
    type : CLEAR_PROJECT_REFERENCE_NUMBER_REDUCER
  }
}

export function clearProjectLoginReducerMessage(){
  return {
    type : CLEAR_PROJECT_LOGIN_REDUCER_MESSAGE
  }
}

// Create project as guests
export const createProject = (userName,referenceNumber,projectName,password, callback) => dispatch => {
  console.log("badabooooo")
  request
  .post(`/createProject`)
  .send({userName,referenceNumber,projectName,password })
  .then(response => {
    dispatch(makeProject(response.body));
    // dispatch(ProjectLogin(response.body))
    typeof callback == "function" && callback(response.body);
    console.log("success");
  })
  .catch(error => {
    console.log(error); 
  });
};

// Create project as user
export const createUserProject = (
  userName,
  referenceNumber,
  projectName,
  password,
  callback
) => (dispatch, getState) => {
  const jwt  = getState().userLoginReducer.jwt;

  const testJwt = dispatch(checkJwt(jwt));

  if(testJwt == false){
    request
    .post(`/createProject/user`)
    .set("Authorization", `Bearer ${jwt}`)
    .send({ userName, referenceNumber, projectName, password })
    .then((response) => {
      dispatch(makeProject(response.body));
      // dispatch(ProjectLogin(response.body))
      typeof callback == "function" && callback(response.body);
      dispatch(getProjects());
    })
    .catch((error) => {
      console.log(error);
    });
  }
};

// Action to get all the projects of a user
export function getUserProjects(payload) {
  return {
    // This action is in lineReducer in the redux
    type: GET_USER_PROJECTS,
    payload: payload,
  };
}

// Function to make a call to the backend to get all the projects of a user
export const getProjects = (callback) => (dispatch, getState) => {
  const { jwt, userName } = getState().userLoginReducer;
  console.log("jwt is ", jwt)

  const testJwt = dispatch(checkJwt(jwt));

  if(testJwt == false){
    request
    .get(`/projects/${userName}`)
    .set("Authorization", `Bearer ${jwt}`)
    // .send({referenceNumber: project.referenceNumber})
    .then((response) => {
      dispatch(getUserProjects(response.body));
      typeof callback == "function" && callback(response.body);
    })
    .catch((error) => {
      console.log(error);
    });
  }
};

// Function to make a call to the backend to delete a project from the user dashboard
export const deleteProject = (
  referenceNumber,
  callback
) => (dispatch, getState) => {
  const { jwt, userName } = getState().userLoginReducer;

  const testJwt = dispatch(checkJwt(jwt));

  if(testJwt == false){
    request
    .delete(`/deleteProject/${userName}/${referenceNumber}`)
    .set("Authorization", `Bearer ${jwt}`)
    .send({ userName, referenceNumber })
    .then((response) => {
      // dispatch(makeProject(response.body));
      // dispatch(ProjectLogin(response.body))
      typeof callback == "function" && callback(response.body);
      console.log("success");
      dispatch(getProjects());
    })
    .catch((error) => {
      console.log(error);
    });
  }
};


// Change Project Name
export const changeProjectName = (
  referenceNumber,
  projectName,
  callback
) => (dispatch, getState) => {
  const { jwt, userName } = getState().userLoginReducer;

  const testJwt = dispatch(checkJwt(jwt));

  if(testJwt == false){
    request
    .put(`/renameProject/${userName}/${referenceNumber}/${projectName}`)
    .set("Authorization", `Bearer ${jwt}`)
    .send({ userName, referenceNumber })
    .then((response) => {
      // dispatch(makeProject(response.body));
      // dispatch(ProjectLogin(response.body))
      typeof callback == "function" && callback(response.body);
      dispatch(getUserProjects(response.body));
    })
    .catch((error) => {
      console.log(error);
    });
  }
};

// Change Project Password
export const changeProjectPassword = (
  referenceNumber,
  password,
  callback
) => (dispatch, getState) => {
  const { jwt, userName } = getState().userLoginReducer;

  const testJwt = dispatch(checkJwt(jwt));

  if(testJwt == false){
    request
    .put(`/changeProjectPassword`)
    .set("Authorization", `Bearer ${jwt}`)
    .send({ referenceNumber, password })
    .then((response) => {
      typeof callback == "function" && callback(response.body);
      console.log("Project password changed.")
    })
    .catch((error) => {
      console.log(error);
    });
  }
};

export function updateProjectLine(payload){
  return{
      // This action is in the lineReducer in the redux 
      type: UPDATE_PROJECT_LINES,
      payload:payload
  }
}

// Change project Country
export const changeProjectCountry = (
  countryName,
  callback
) => (dispatch, getState) => {
  const { jwt } = getState().projectLoginReducer;

  const testJwt = dispatch(checkJwt(jwt));

  if(testJwt == false){
    const referenceNumber = getState().projectLoginReducer.project.referenceNumber;
    request
      .put(`/changeCountry/${referenceNumber}/${countryName}`)
      .set("Authorization", `Bearer ${jwt}`)
      .send({ countryName, referenceNumber })
      .then((response) => {
        dispatch(ProjectLogin(response.body));
        console.log("finding response zinno", response.body.data)
        dispatch(updateProjectLine(response.body.data));
        typeof callback == "function" && callback(response.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};


// Change Project Comment
export const changeProjectComment = (
  referenceNumber,
  projectComment,
  callback
) => (dispatch, getState) => {
  const { jwt, userName } = getState().userLoginReducer;

  const testJwt = dispatch(checkJwt(jwt));

  if(testJwt == false){
    request
    .put(`${referenceNumber}/projectComment`)
    .set("Authorization", `Bearer ${jwt}`)
    .send({ referenceNumber, projectComment })
    .then((response) => {
      dispatch(ProjectLogin(response.body));
      typeof callback == "function" && callback(response.body);
    })
    .catch((error) => {
      console.log(error);
    });
  }
};

