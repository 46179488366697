import React from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import "../semantic.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Icon } from "semantic-ui-react";
import "../semantic.css";


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "11ch",
    },
  },
}));

function UserSignupForm(props) {
  const classes = useStyles();

  return (
    <Container fluid>
      <div>
        {/* <h3>{!inputLabels == null && "Loading..."}</h3>
      {inputLabels && <h3>{inputLabels.LandingPageReference}</h3>} */}
        <h2>User Signup Page</h2>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            required
            id="standard-required"
            label="Email"
            // values={props.values.email}
            onChange={props.onChange}
          />
          <br></br>
          <TextField
            required
            id="standard-required"
            label="User Name"
            // values={props.values.userName}
            onChange={props.onChange}
          />
          <br></br>
          <TextField
            required
            id="standard-required"
            label="Password"
            // values={props.values.password}
            onChange={props.onChange}
          />
          <br></br>
          <Button
            className="ui color1 button"
            size="medium"
            animated
            type="submit"
            onClick={props.handleSubmit}
          >
            <Button.Content visible>Submit</Button.Content>
            <Button.Content hidden>
              <Icon name="arrow right" />
            </Button.Content>
          </Button>
        </form>
      </div>
    </Container>
  );
}

function mapStateToProps(reduxState) {
  return {
    inputLabels: reduxState.inputLabelsReducer,
  };
}

export default connect(mapStateToProps)(UserSignupForm);
