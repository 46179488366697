const initialState = "";

export default function lineInfoReducer(state = initialState, action) {
  switch (action.type) {
    case "LINE_DETAILS": {
      // => Ask yourself: what is action.payload?
      return state = action.payload;
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
