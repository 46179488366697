import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import {editComponent, getAllComponents} from "../../actions/adminActions";
import {closePopupAction} from "../../actions/popupActions";
import { useForm } from "react-hook-form";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
      // left: "50%",
    },
  },
  pageContent: {
    // margin: theme.spacing(1),
    // padding: theme.spacing(3),
    // width: "80%",
    alignContent:"center",
    padding: '0 15px 0 15px', 
    // width: 350,
    width: 500,
    spacing: theme.spacing(5)
  },
}));

export default function EditComponentForm() {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

 
  const [formData, setFormData] = useState();

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const dispatch = useDispatch();

  const componentData = useSelector(
    (reduxState) => reduxState.componentsReducer.selectedComponent.component
  );

  const onSubmit = (event) => {
    // event.preventDefault();
    console.log("form adata in edit component", formData);
    dispatch(editComponent(formData, formData.lotion,componentData.name,(response) => {
      if (response.message === "Success") {
        // console.log("checking response message aaloo freshdataa", response.freshData);
        // dispatch(getAllComponents(response.freshData));
        dispatch(closePopupAction());
      }
    }));
  };

  return (
    <Paper className={classes.pageContent} elevation={0}>
    <form  noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid 
      className={classes.root} 
      Container align='center' direction={"column"} spacing={3}>
           <Grid item xs={12}>
              <Typography
                variant="body2"
                style={{ fontSize: "14px" , fontWeight: 600, color: "#0146ab",paddingBottom : "2%"}}
                gutterBottom
              >
                Component Name : {componentData.name}
              </Typography>
            </Grid>
        <Grid Item >
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label="Grosss Price Netherlands"
            name="grossPriceNL"
            onChange={handleChange}
          />
        </Grid>
        <Grid Item >
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label="Grosss Price Germany"
            name="grossPriceGermany"
            onChange={handleChange}
          />
        </Grid>
        <Grid Item >
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label="Grosss Price Austria"
            name="grossPriceAustria"
            onChange={handleChange}
          />
        </Grid>
        <Grid Item >
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label="Grosss Price Switzerland"
            name="grossPriceSwitzerland"
            onChange={handleChange}
          />
        </Grid>
        <Grid Item >
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label="Grosss Price Belgium"
            name="grossPriceBelgium"
            onChange={handleChange}
          />
        </Grid>
        <Grid Item >
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label="Grosss Price France"
            name="grossPriceFrance"
            onChange={handleChange}
          />
        </Grid>
        <Grid Item >
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label="Grosss Price Spain"
            name="grossPriceSpain"
            onChange={handleChange}
          />
        </Grid>
        <Grid Item >
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label="Grosss Price Italy"
            name="grossPriceItaly"
            onChange={handleChange}
          />
        </Grid>
        <Grid Item >
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label="Grosss Price Portugal"
            name="grossPricePortugal"
            onChange={handleChange}
          />
        </Grid>
        <Grid Item >
          <TextField
            required
            variant="outlined"
            id="standard-required"
            label="Grosss Price Others"
            name="grossPriceOthers"
            onChange={handleChange}
          />
        </Grid>
        <Grid Item >
          <TextField
            variant="outlined"
            inputRef={register({
                required: "Password Required",
                minLength: { value: 10, message: "Too Short" },
              })}
            required
            id="lotion"
            label="Admin request Password"
            name="lotion"
            onChange={handleChange}
            error={Boolean(errors.lotion)}
          />
          {errors.lotion && (
                  <p style={{ color: "red" }}>{errors.lotion.message}</p>
                )}
        </Grid>
        <br></br>
        <Button
            type="submit"
            // fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
          >
            Submit
          </Button>
      </Grid>
    </form>
     </Paper> 
  );
}

