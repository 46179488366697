import React from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import "../semantic.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { addUser } from "../actions";
import { Redirect } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import "../semantic.css";
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
// import "../styles.css";
import UserSignUpFormContainer from "../components/UserSignupFormContainer"

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "11ch",
    },
  },
}));

function ExperimentPage({ inputLabels, user,userEmail }) {
  
    return (
      
      <Container fluid>
     <div>
      <h3>This is the experiment page</h3>
      {/* <UserSignUpFormContainer/> */}
     </div>
      </Container>
    );
}

function mapStateToProps(reduxState) {
  return {
    inputLabels: reduxState.inputLabelsReducer,
    user: reduxState.userSignupReducer,
    userEmail: reduxState.userSignupReducer.email
  };
}

export default connect(mapStateToProps)(ExperimentPage);
