import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {closePopupAction} from "../../actions/popupActions";
import { pageContent } from "../../lang/pageContent";
import {popupContent} from "../../lang/popupContent";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.spacing(2),
    },
  },
  pageContent: {
    alignContent: "center",
    // padding: "0 15px 0 15px",
    // width: "100%",
    width: 500,

    spacing: theme.spacing(5),
  },
  button: {
    width : "100px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "##0146ab",
    // marginTop:"5%",
    marginBottom:"3%",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

export default function FieldOneInfo() {
  const classes = useStyles();
  const history = useHistory();

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );
  const user = useSelector((reduxState) => reduxState.userLoginReducer);

  const dispatch = useDispatch();

  const allLines = useSelector((reduxState) => reduxState.lineReducer.data);

  const displayResultsPass = false;

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(closePopupAction());
    history.push("/inputPageSection1");
  };

  return (
    <Paper className={classes.pageContent} elevation={0}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid
          className={classes.root}
          Container
          align="center"
          direction={"column"}
          spacing={3}
        >
          <Grid Item>
            {/* <h3>Please enter the new Name</h3> */}
            <Typography
              variant="body1"
              style={{
                fontWeight: 300,
                lineHeight: 2,
                paddingBottom: "3%",
                paddingTop: "3%",
              }}
              gutterBottom
            >
              {popupContent[`${selectedLang}`].fieldOneInfo}
            </Typography>
          </Grid>
          <Button
            type="submit"
            // fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              dispatch(closePopupAction());
            }}
          >
            {pageContent[`${selectedLang}`].nextTranslation}
          </Button>
        </Grid>
      </form>
    </Paper>
  );
}
