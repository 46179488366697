import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Container,
  CssBaseline,
  Grid,
  Tooltip,
  IconButton,
  Badge,
  Button
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useForm } from "react-hook-form";
import { pageContent } from "../lang/pageContent";
import { errorText } from "../lang/errorText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { adminLogin, adminLoginState, clearAdminLoginReducerMessage, getComponents } from "../actions/adminActions";
import { useHistory } from "react-router-dom";



// import {UserLogin} from "../actions"

const useStyles = makeStyles((theme) => ({
  root: {
    //   this changes the border colour
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#9e9e9e",
    },
    // this changes the border colour on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000",
    },
    // this changes the border colour on focus(when typing)
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#9e9e9e",
    },
    // this changes the text colour on typing
    "& .MuiInputBase-root": {
      color: "#000000",
    },
    //   this changes the border radius of the field
    [`& fieldset`]: {
      borderRadius: 0,
      // background:"#ffffff"
    },

    width: "33em",

    // this changes the background to white
    // background: "#ffffff"
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    width:"20em",
    height: "50px",
    borderRadius: 35,
    backgroundColor: "red",
    justifyContent: "space-between",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "15px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "red",
    },
  },
  input: {
    color: "#FFFFFF",
  },
  endIcon: {
    fontSize: 12,
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(1),
  },
}));

export default function AdminLoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch( clearAdminLoginReducerMessage())
},[])

  const togglePassword = () => {
    setShow(!showPassword);
  };

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const admin = useSelector((reduxState) => reduxState.adminLoginReducer);

  const handleChange = (event) => {
    // setFormData({ ...formData, [event.target.name]: event.target.value });
    console.log("value of form data is:", event.target.value);
  };

  const onSubmit = (data) => {
    // event.preventDefault();
    dispatch(
      adminLogin(data.userName, data.password, (response) => {
        if (response.message === "Success") {
          dispatch(adminLoginState());
          history.push("/adminDashboard")
        }
      })
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxwidth="lg" direction="column">
        <div
          style={{
            marginTop: "30%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container direction={"column"} spacing={2}>
              <Grid item>
                <TextField
                  variant="outlined"
                  margin="normal"
                  inputRef={register({
                    required: "Username Required",
                    minLength: { value: 2, message: "Too Short" },
                  })}
                  required
                  fullWidth
                  className={classes.root}
                  InputLabelProps={{
                    style: { color: "#000000" },
                  }}
                  id="userName"
                  label={pageContent[`${selectedLang}`].userName}
                  name="userName"
                  autoComplete="off"
                  onChange={handleChange}
                  error={Boolean(errors.userName)}
                />
                {errors.userName && (
                  <p style={{ color: "red" }}>{errors.userName.message}</p>
                )}
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  margin="normal"
                  inputRef={register({
                    required: "Password Required",
                    minLength: { value: 2, message: "Too Short" },
                  })}
                  required
                  name="password"
                  label={pageContent[`${selectedLang}`].password}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="off"
                  className={classes.root}
                  InputLabelProps={{
                    style: { color: "#000000" },
                  }}
                  error={Boolean(errors.password)}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="Show/Hide Password"
                        aria-label="showPassword"
                        onClick={togglePassword}
                      >
                        <IconButton>
                          <Badge color="secondary">
                            <VisibilityIcon
                              fontSize="small"
                              style={{ color: "#76a6c2" }}
                            />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
                {errors.password && (
                  <p style={{ color: "red" }}>{errors.password.message}</p>
                )}
                {admin.errorCode && (
                  <p style={{ color: "red" }}>
                    {errorText[`${selectedLang}`][`${admin.errorCode}`]}
                  </p>
                )}
                {admin.message !== "success" && (
                <p style={{ color: "red" }}>{admin.message}</p>
                )}
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                >
                  {pageContent[`${selectedLang}`].submit}
                  <ArrowForwardIosIcon className={classes.endIcon} />
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </React.Fragment>
  );
}
