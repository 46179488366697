import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {editComponent, getAllComponents, resetLines} from "../../actions/adminActions";
import {closePopupAction} from "../../actions/popupActions";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
      // left: "50%",
    },
  },
  pageContent: {
    // margin: theme.spacing(1),
    // padding: theme.spacing(3),
    // width: "80%",
    alignContent: "center",
    padding: "0 15px 0 15px",
    // width: 350,
    width: 500,
    spacing: theme.spacing(5),
  },
}));

export default function ResetLineStatusForm() {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [showPassword, setShow] = useState(false);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState();


  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const togglePassword = () => {
    setShow(!showPassword);
  };

  const onSubmit = (event) => {
    dispatch(resetLines(formData.lotion,(response) => {
      if (response.message === "Success") {
        dispatch(closePopupAction());
      }
    }));
  };

  return (
    <Paper className={classes.pageContent} elevation={0}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid
          className={classes.root}
          Container
          align="center"
          direction={"column"}
          spacing={3}
        >
          <Grid Item>
            <TextField
              variant="outlined"
              inputRef={register({
                required: "Password Required",
                minLength: { value: 10, message: "Too Short" },
              })}
              required
              id="lotion"
              label="Password"
              name="lotion"
              onChange={handleChange}
              error={Boolean(errors.lotion)}
            />
            {errors.lotion && (
              <p style={{ color: "red" }}>{errors.lotion.message}</p>
            )}
          </Grid>
          <br></br>
          <Button
            type="submit"
            // fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </form>
    </Paper>
  );
}
