const initialState = {
  logoutPopup : false,
  userSignupMessage: false,
  renameLine: false,
  deleteLine: false,
  createLine: false,
  displayResultsError: false,
  createProject: false,
  renameProject: false,
  changeProjectPassword: false,
  deleteProject: false,
  addProjectComment: false,
  editComponent : false,
  lineConfigurationInfo: false,
  selectCountry: false,
  requestQuotation: false,
  addLineComment: false,
  lineFieldInfo: false,
  lineFieldInfoCompact: false,
  componentDetails: false,
  resetLineStatus : false,
  fieldOneInfo: false,
  fieldTwoInfo: false,
  fieldThreeInfo: false,
  fieldFourInfo: false,
  fieldFiveInfo: false,
  fieldSixInfo: false,
  fieldSevenInfo: false,
  fieldEightInfo: false,
  fieldNineInfo: false,
  fieldTenInfo: false,
  fieldElevenInfo: false,
  fieldTwelveInfo: false,
  fieldThirteenInfo: false,
  fieldFourteenInfo: false,
  fieldFifteenInfo: false,
  fieldSixteenInfo: false,
  fieldSeventeenInfo: false,
  fieldEighteenInfo: false,
  fieldNineteenInfo: false,
  fieldTwentyInfo: false,
  fieldTwentyOneInfo: false,
  fieldTwentyTwoInfo: false,
  fieldTwentyThreeInfo: false,
  tokenExpired : false,
  closePopupStatus: false,
};

export default function popupReducer(state = initialState, action) {
  switch (action.type) {
    case "USER_SIGNUP_MESSAGE": {
      state.userSignupMessage = action.payload;
      return { ...state };
    }
    case "RENAME_LINE_POPUP": {
      state.renameLine = action.payload;
      return { ...state };
    }
    case "DELETE_LINE_POPUP": {
      state.deleteLine = action.payload;
      return { ...state };
    }
    case "CREATE_LINE_POPUP": {
      state.createLine = action.payload;
      return { ...state };
    }
    case "DISPLAY_RESULTS_ERROR": {
      state.displayResultsError = action.payload;
      return { ...state };
    }
    case "CREATE_PROJECT_POPUP": {
      state.createProject = action.payload;
      return { ...state };
    }
    case "RENAME_PROJECT_POPUP": {
      state.renameProject = action.payload;
      return { ...state };
    }
    case "CHANGE_PROJECT_PASSWORD_POPUP": {
      state.changeProjectPassword = action.payload;
      return { ...state };
    }
    case "DELETE_PROJECT_POPUP": {
      state.deleteProject = action.payload;
      return { ...state };
    }
    case "ADD_PROJECT_COMMENT": {
      state.addProjectComment = action.payload;
      return { ...state };
    }
    case "EDIT_COMPONENT" : {
      state.editComponent = action.payload;
      return {...state};
    }
    case "LINE_CONFIGURATION_INFO_POPUP": {
      state.lineConfigurationInfo = action.payload;
      return { ...state };
    }
    case "SELECT_COUNTRY_POPUP": {
      state.selectCountry = action.payload;
      return { ...state };
    }
    case "REQUEST_QUOTATION_POPUP": {
      state.requestQuotation = action.payload;
      return { ...state };
    }
    case "ADD_LINE_COMMENT": {
      state.addLineComment = action.payload;
      return { ...state };
    }
    case "LINE_FIELD_INFO": {
      state.lineFieldInfo = action.payload;
      return { ...state };
    }
    case "LINE_FIELD_INFO_COMPACT": {
      state.lineFieldInfoCompact = action.payload;
      return { ...state };
    }
    case "FIELD_ONE_INFO": {
      state.fieldOneInfo = action.payload;
      return { ...state };
    }
    case "FIELD_TWO_INFO": {
      state.fieldTwoInfo = action.payload;
      return { ...state };
    }
    case "FIELD_THREE_INFO": {
      state.fieldThreeInfo = action.payload;
      return { ...state };
    }
    case "FIELD_FOUR_INFO": {
      state.fieldFourInfo = action.payload;
      return { ...state };
    }
    case "FIELD_FIVE_INFO": {
      state.fieldFiveInfo = action.payload;
      return { ...state };
    }
    case "FIELD_SIX_INFO": {
      state.fieldSixInfo = action.payload;
      return { ...state };
    }
    case "FIELD_SEVEN_INFO": {
      state.fieldSevenInfo = action.payload;
      return { ...state };
    }
    case "FIELD_EIGHT_INFO": {
      state.fieldEightInfo = action.payload;
      return { ...state };
    }
    case "FIELD_NINE_INFO": {
      state.fieldNineInfo = action.payload;
      return { ...state };
    }
    case "FIELD_TEN_INFO": {
      state.fieldTenInfo = action.payload;
      return { ...state };
    }
    case "FIELD_ELEVEN_INFO": {
      state.fieldElevenInfo = action.payload;
      return { ...state };
    }
    case "FIELD_TWELVE_INFO": {
      state.fieldTwelveInfo = action.payload;
      return { ...state };
    }
    case "FIELD_THIRTEEN_INFO": {
      state.fieldThirteenInfo = action.payload;
      return { ...state };
    }
    case "FIELD_FOURTEEN_INFO": {
      state.fieldFourteenInfo = action.payload;
      return { ...state };
    }
    case "FIELD_FIFTEEN_INFO": {
      state.fieldFifteenInfo = action.payload;
      return { ...state };
    }
    case "FIELD_SIXTEEN_INFO": {
      state.fieldSixteenInfo = action.payload;
      return { ...state };
    }
    case "FIELD_SEVENTEEN_INFO": {
      state.fieldSeventeenInfo = action.payload;
      return { ...state };
    }
    case "FIELD_EIGHTEEN_INFO": {
      state.fieldEighteenInfo = action.payload;
      return { ...state };
    }
    case "FIELD_NINETEEN_INFO": {
      state.fieldNineteenInfo = action.payload;
      return { ...state };
    }
    case "FIELD_TWENTY_INFO": {
      state.fieldTwentyInfo = action.payload;
      return { ...state };
    }
    case "FIELD_TWENTY_ONE_INFO": {
      state.fieldTwentyOneInfo = action.payload;
      return { ...state };
    }
    case "FIELD_TWENTY_TWO_INFO": {
      state.fieldTwentyTwoInfo = action.payload;
      return { ...state };
    }
    case "FIELD_TWENTY_THREE_INFO": {
      state.fieldTwentyThreeInfo = action.payload;
      return { ...state };
    }
    case "COMPONENT_DETAILS_INFO": {
      state.componentDetails = action.payload;
      return { ...state };
    }
    case "EDIT_COMPONENT_POPUP" : {
      state.editComponent = action.payload;
      return{...state};
    }
    case "RESET_LINE_STATUS" : {
      state.resetLineStatus = action.payload;
      return{...state};
    }
    case "LOG_OUT_POPUP" : {
      state.logoutPopup = action.payload;
      return{...state};
    }
    case "TOKEN_EXPIRED_POPUP" : {
      state.tokenExpired = action.payload;
      return{...state};
    }
    case "CLOSE_POPUP_ACTION": {
      state.userSignupMessage = false;
      state.renameLine = false;
      state.deleteLine = false;
      state.createLine = false;
      state.displayResultsError = false;
      state.createProject = false;
      state.renameProject = false;
      state.changeProjectPassword =  false;
      state.deleteProject = false;
      state.lineConfigurationInfo = false;
      state.selectCountry = false;
      state.requestQuotation = false;
      state.addProjectComment = false;
      state.addLineComment = false;
      state.lineFieldInfo = false;
      state.lineFieldInfoCompact = false;
      state.fieldOneInfo = false;
      state.fieldTwoInfo = false;
      state.fieldThreeInfo = false;
      state.fieldFourInfo = false;
      state.fieldFiveInfo = false;
      state.fieldSixInfo = false;
      state.fieldSevenInfo = false;
      state.fieldEightInfo = false;
      state.fieldNineInfo = false;
      state.fieldTenInfo = false;
      state.fieldElevenInfo = false;
      state.fieldTwelveInfo = false;
      state.fieldThirteenInfo = false;
      state.fieldFourteenInfo = false;
      state.fieldFifteenInfo = false;
      state.fieldSixteenInfo = false;
      state.fieldSeventeenInfo = false;
      state.fieldEighteenInfo = false;
      state.fieldNineteenInfo = false;
      state.fieldTwentyInfo = false;
      state.fieldTwentyOneInfo = false;
      state.fieldTwentyTwoInfo = false;
      state.fieldTwentyThreeInfo = false;
      state.componentDetails = false;
      state.editComponent = false;
      state.resetLineStatus = false;
      state.logoutPopup = false;
      state.tokenExpired = false;
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
