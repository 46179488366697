import React from "react";
import {
  makeStyles,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  CssBaseline,
  Container,
} from "@material-ui/core";
import Select, { SelectProps } from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { sendLineCountry } from "../actions/lineActions";
import { pageContent } from "../lang/pageContent";
import { inputLabelOptions } from "../lang/inputLabelOptions";
import { changeProjectCountry } from "../actions/projectActions";
import { closePopupAction } from "../actions/popupActions";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LanguageIcon from "@material-ui/icons/Language";
import { selectedLanguage } from "../actions/contentActions";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 200,
    textAlign: "center",
  },
  menu: {
    width: 200,
  },
  quantityRoot: {
    color: "#ffffff",
    // backgroundColor: "#000000",
    opacity: 1,
    borderRadius: "35px",
    // minWidth: "300px",
    // height: "50px",
    // this one changes colour of the background on hover
    "&:hover": {
      // backgroundColor: "#ffffff",
      borderRadius: "35px",
      opacity: 1,
    },
    // This changes the color of the border
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
      borderRadius: "35px",
      // color:"#ffffff"
    },
    // this changes the static text color when not focused
    "& .MuiOutlinedInput-input": {
      color: "#000000",
    },
    // this changes the static small text color
    "& .MuiInputLabel-root": {
      color: "#000000",
    },
    // This changes the color of text on hover
    "&:hover .MuiInputLabel-root": {
      color: "#000000",
    },
    // This changes the border color on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000",
    },
    // This changes the small text color
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#000000",
    },
    // This changes the text color of label
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#000000",
    },
    // This changes the border color on focus
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000",
    },
    // this changes background colour when field is selected
    "&:focus-within": {
      // backgroundColor: "#ffffff",
      borderRadius: "35px",
      opacity: 1,
    },
    // this one is when the curson hovers on the field
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #000000",
      borderRadius: "35px",
    },
    "& .Mui-disabled": {
      color: "#ffffff",
      opacity: 0.6,
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #4e7fc4",
      borderRadius: "35px",
    },
    // change the border when select is focused
    "& .MuiSelect-select:focus": {
      // backgroundColor: "#000000",
      borderRadius: "35px",
    },
  },
}));

const selectLanguageOptions = [
  {
    name: "English",
    id: "english",
  },
  {
    name: "Dutch",
    id: "dutch",
  },
  {
    name: "French",
    id: "french",
  },
  {
    name: "German",
    id: "german",
  },
  {
    name: "Spanish",
    id: "spanish",
  },
  {
    name: "Portugese",
    id: "portugese",
  },{
    name: "Finnish",
    id: "finnish",
  },
  {
    name: "Polish",
    id: "polish",
  },
  {
    name: "Italian",
    id: "italian",
  },
];

export default function SelectLanguage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const [language, setLanguage] = React.useState(`${selectedLang}`);

  const content = pageContent[`${selectedLang}`];

  let currentValueName = "";

  switch (selectedLang) {
    case "english":
      currentValueName = "English";
      break;
    case "dutch":
      currentValueName = "Dutch";
      break;
    case "french":
      currentValueName = "French";
      break;
    case "german":
      currentValueName = "German";
      break;
    case "spanish":
      currentValueName = "Spanish";
      break;
    case "portugese":
      currentValueName = "Portugese";
      break;
    case "finnish":
      currentValueName = "Finnish";
      break;
    case "polish":
      currentValueName = "Polish";
      break;
    case "italian":
      currentValueName = "Italian";
      break;
  }

  const handleChange = (event) => {
    setLanguage(event.target.value);
    dispatch(selectedLanguage(event.target.value));
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div
        style={{
          maxWidth: "45%",
          paddingRight: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <form className={classes.quantityRoot} noValidate autoComplete="off">
            <TextField
              id="outlined-select-language"
              select
              // label={language === "" ? "Language" : ""}
              // label={currentValueName}
              className={classes.textField}
              value={language}
              onChange={handleChange}
              InputLabelProps={{ shrink: false }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {<LanguageIcon />}
                  </InputAdornment>
                ),
              }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
            >
              {selectLanguageOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
