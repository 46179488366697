import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { projectLogin } from "../../actions/projectActions";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { numberOfLinesToConfigure } from "../../actions";
import { inputReferenceNumber } from "../../actions/contentActions";
import { renameLinePopup, closePopupAction } from "../../actions/popupActions";
import { pageContent } from "../../lang/pageContent";
import { useForm, Controller } from "react-hook-form";
import {changeLineComment} from "../../actions/lineActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
      // left: "50%",
    },
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flexStart",
  },
  textfield: {
    //   width : "100%"
  },
  pageContent: {
    padding: "0 15px 0 15px",
    width: 500,
    spacing: theme.spacing(5),
  },
}));

export default function AddLineComment() {
  const classes = useStyles();
  const { register, handleSubmit, control, errors } = useForm();

  const history = useHistory();

  const [error, setError] = React.useState("");

  const user = useSelector((reduxState) => reduxState.userLoginReducer);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const referenceNumber = useSelector((reduxState) => reduxState.projectLoginReducer.project.referenceNumber)

  const [commentLength, setCommentLength] = React.useState(0);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    // console.log("value of form data and errors is ", data, errors.projectName);
    if(commentLength <=110){
        dispatch(changeLineComment(referenceNumber, data.lineComment, (response) => {
            if(response.message === "Success"){
                dispatch(closePopupAction());
            }
        }))
        console.log("about to send comment in line")
    }
  };

  return (
    <React.Fragment>
      <Grid container justify="center">
        <Paper className={classes.pageContent} elevation={0}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              flexWrap: "wrap",
              width : "90%"
            }}
          >
            <Typography
              variant="body"
              style={{ fontWeight: 600, color: "#0146ab" }}
              gutterBottom
            >
              {commentLength}/110
            </Typography>
          </div>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid
              className={classes.root}
              Container
              align="center"
              direction={"column"}
              spacing={3}
            >
              <Grid Item>
                <TextField
                  className={classes.textfield}
                  id="outlined-multiline-static"
                  margin="normal"
                  required
                  variant="outlined"
                  inputRef={register({
                    required: "Project Comment Required",
                    maxLength: { value: 110, message: "Too Long" },
                    minLength : {value : 2, message : "Too Short"}
                  })}
                  id="lineComment"
                  label="Enter Comment"
                  multiline
                  rows={4}
                  name="lineComment"
                  onChange={e => setCommentLength(e.target.value.length)}
                  error={Boolean(errors.projectComment)}
                />
                {errors.lineComment && (
                  <p style={{ color: "red" }}>{errors.lineComment.message}</p>
                )}
              </Grid>
              <br></br>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={onSubmit}
              >
                {pageContent[`${selectedLang}`].submit}
              </Button>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
