import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
// import { numberOfLinesToConfigure } from "../actions";
import { fieldSeventeenInput, fieldNineteenInput,sendSectionInputs,lineStatus } from "../../actions/lineActions";
import { Select, withWidth, CssBaseline, Typography,MenuItem, Tooltip } from "@material-ui/core";
import { inputLabels } from "../../lang/inputLabels";
import { inputLabelOptions } from "../../lang/inputLabelOptions";
import {pageContent} from "../../lang/pageContent";

import Controls from "../controls/Controls";
import HelpIcon from "@material-ui/icons/Help";

import {fieldSeventeenInfo} from "../../actions/popupActions";
import Popup from "../../components/Popup";
import FieldSeventeenInfo from "../../components/fieldInfoComponents/FieldSeventeenInfo";


// We were doing this when using material ui
const useStyles = makeStyles((theme) => ({
  quantityRoot: {
    color: "#000000",
    // backgroundColor: "#000000",
    opacity: 1,
    borderRadius: "35px",
    minWidth: "650px",
    // this one changes colour of the background on hover
    "&:hover": {
      // backgroundColor: "#ffffff",
      borderRadius: "35px",
      opacity: 1,
    },
    // this one changes background colour when field is selected
    "&:focus-within": {
      // backgroundColor: "#1E1E24",
      borderRadius: "35px",
      opacity: 1,
    },
    // this changes the outline color in normal state
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #4e7fc4",
      borderRadius: "35px",
    },
    // this one is when the curson hovers on the field
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
      borderRadius: "35px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      // the border makes it look like a square when selecting option
      // border: "1px solid #ffffff",
      borderRadius: "35px",
    },
    "& .Mui-disabled": {
      color: "#FFFFFF",
      opacity: 0.6,
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #4e7fc4",
      borderRadius: "35px",
    },
    "& .MuiSelect-select:focus": {
      // backgroundColor: "#000000",
      borderRadius: "35px"
    },
  },
  selectRoot: {
    color: "#000000",
  },
  // this changes the colour of the pointer icon
  icon: {
    color: "#000000",
  },
  // paper comes out when the select is clicked
  selectPaper: {
    backgroundColor: "#ffffff",
    border: "1px solid #484850",
    borderRadius: "35px",
    // colour below changes the text colour in paper
    color: "#000000",
    "& li:hover": {
      // change colour of list items on hover
      backgroundColor: "#c2c0ba",
    },
    maxHeight: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 200,
  },
}));

export default function FieldSeventeen() {
  const classes = useStyles();
  const [selectedOption, setselectedOption] = React.useState("");
  const dispatch = useDispatch();

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const currentValue = useSelector(
    (reduxState) =>
      reduxState.selectedLineInputsReducer.data.fieldSeventeenInput
  );

  const data = useSelector((reduxState) => reduxState.inputSection4Reducer);

  const fieldOptions  = inputLabelOptions[`${selectedLang}`]

  const fieldSeventeenOptions = [
    {
      name: fieldOptions.FieldSeventeenOptionOne,
      id: "1",
    },
    {
      name: fieldOptions.FieldSeventeenOptionTwo,
      id: "2",
    },
  ];

  let currentValueName = "";

  if (currentValue === "1") {
    currentValueName = `${pageContent[`${selectedLang}`].savedValue}: ${fieldOptions.FieldSeventeenOptionOne}`;
  } else if (currentValue === "2") {
    currentValueName = `${pageContent[`${selectedLang}`].savedValue}: ${fieldOptions.FieldSeventeenOptionTwo}`;
  } else {
    currentValueName = pageContent[`${selectedLang}`].selectAnOption;
  }

  const handleChange = (event) => {
    setselectedOption(event.target.value);
    console.log("Field Two Option Selected", event.target.value);
    dispatch(fieldSeventeenInput(event.target.value));
    dispatch(fieldNineteenInput("1"));
    dispatch(lineStatus("false"));
    dispatch(sendSectionInputs(data), (response) => {
      if (response.message === "Success") {
        console.log("checking response message", response.message);
      }
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
        <div
         style={{
          maxWidth: "80%",
          marginLeft: "10%",
          // border: "1px solid black",
          borderRadius: "35px",
          // marginTop: "7%",
        }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems : "center",
              flexWrap: "wrap",
            }}
            >
                <Typography
            variant="body1"
            style={{ marginTop: "20px", fontSize : "18px"  }}
            // inputProps={{ style: { fontSize: 400 } }}
            gutterBottom
          >
             <Controls.ActionButton color="secondary">
              <Tooltip title="Field Info">
                <HelpIcon 
                fontSize="small"
                color="action"
                onClick={() => {
                  dispatch(
                    fieldSeventeenInfo({status:true})
                  )
                }}
                />
              </Tooltip>
            </Controls.ActionButton>
            {inputLabels[`${selectedLang}`].fieldSeventeen}
          </Typography>
          <br></br>
          <FormControl variant="outlined" className={classes.quantityRoot}>
          <InputLabel id="demo-simple-select-outlined-label">
              {currentValueName}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedOption}
              onChange={handleChange}
              label={currentValueName}
              classes={{
                root: classes.selectRoot,
                icon: classes.icon,
              }}
              MenuProps={{ classes: { paper: classes.selectPaper } }}
            >
              {fieldSeventeenOptions.map((option, i) => (
                <MenuItem style={{justifyContent : "center"}} value={option.id} key={i}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        </div>
        <Popup
      openPopup={popupState.fieldSeventeenInfo}
      setOpenPopup={setOpenPopup}
      title="Field Info"
      >
        <FieldSeventeenInfo/>
      </Popup>
    </React.Fragment>
  );
}

