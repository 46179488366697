import React, { Component } from "react";
import { connect } from "react-redux";
import UserSignupForm from "./UserSignupForm";
import { Redirect } from "react-router-dom";
import { addUser } from "../actions/userActions";

class SignupFormContainer extends Component {
  state = {
    userName: "",
    email: "",
    password: "",
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentDidUpdate() {
    if (this.props.message === "Success") {
      this.props.history.push("/userLogin");
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.addUser(
      this.state.userName,
      this.state.email,
      this.state.password
    ); 
    console.log("button was clicked")

    this.setState({ userName: "", email: "", password: "" });
  };

  render() {
    // if (this.props.user.email) {
    //   return <Redirect to="/userLogin" />;
    // }

    return (
      <div>
        <UserSignupForm
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          values={this.state}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.SignUpMessageReducer,
    user: state.signupReducer,
    // redirectToggle:state.redirect
  };
};

export default connect(mapStateToProps, { addUser })(SignupFormContainer);

// export default connect(mapStateToProps,{signup,})(SignUpContainer)
