const initialState = { errorCode: "" };

export default function userLoginReducer(state = {}, action = {}) {
  switch (action.type) {
    case "USER_LOG_IN":
      return { ...state, ...action.payload };
    case "CLEAR_USER_LOGIN_REDUCER_MESSAGE":
      state.errorCode = "";
    case "LOG_OUT" : 
      return initialState;
      // return { ...state };
    default:
      return state;
  }
}
