const initialState = 1;

export default function selectedLineIdReducer(state = initialState, action) {
  switch (action.type) {
    case "SELECTED_LINE": {
      return (state = action.payload);
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
