import { ADD_USER } from "../actions/userActions";

const initialState = {};

export default function userSignupReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_USER: {
      return { ...state, ...action.payload };
    }
    case "CLEAR_USER_SIGNUP_REDUCER_MESSAGE":
      state.errorCode = "";
      return { ...state };
    case "LOG_OUT": {
      return initialState;
    };
    default: {
      return state;
    }
  }
}
