import React from "react";
import FieldTwelve from "../components/fieldComponents/FieldTwelve";
import FieldThirteen from "../components/fieldComponents/FieldThirteen";
import FieldFourteen from "../components/fieldComponents/FieldFourteen";
import FieldFifteen from "../components/fieldComponents/FieldFifteen";
import FieldSixteen from "../components/fieldComponents/FieldSixteen";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendSectionInputs } from "../actions/lineActions";
import { clearInputSectionReducer } from "../actions/lineActions";
import { makeStyles } from "@material-ui/core/styles";
import { pageContent } from "../lang/pageContent";
import {errorText} from "../lang/errorText";

import "../material.css";
import {
  Grid,
  Paper,
  Typography,
  Link,
  TextField,
  Container,
  Button,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useForm, Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  button: {
    // color:"#ffffff",
    // maxWidth: "200px",
    width: "200px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "#0146ab",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

function InputSection3Page() {
  const history = useHistory();
  const classes = useStyles();

  const [checkValue, setCheckValue] = React.useState("");

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const inputLabels = useSelector(
    (reduxState) => reduxState.inputLabelsReducer
  );

  const projectLoginReducer = useSelector((reduxState) => reduxState.projectLoginReducer)

  const data = useSelector((reduxState) => reduxState.inputSectionReducer);
  const lineId = useSelector((reduxState) => reduxState.selectedLineIdReducer);
  const projectId = useSelector(
    (reduxState) => reduxState.projectLoginReducer.project.id
  );

  const currentValue = useSelector(
    (reduxState) => reduxState.selectedLineInputsReducer.data
  );

  const dispatch = useDispatch();

  function pageJump() {
    if (
      currentValue.daliSelected === "true" &&
      currentValue.fieldSevenInput !== "1"
    ) {
      history.push("/inputPageSection4");
    } else {
      history.push("/inputPageSection5");
    }
  }

  let message = "";

  if (checkValue === "invalid") {
    message = errorText[`${selectedLang}`].error114;
  } else if (checkValue === "valid") {
    message = "valid";
  }

  function checkFields() {
    // Check if user selected a value for field 12
    if (!currentValue.fieldTwelveInput) {
      if (!data.fieldTwelveInput) {
        setCheckValue("invalid");
        console.log("gyaaat");
        return; // Exit the function
      }
    }
  
    // Check if user has selected option 2 for field 13 but has not selected any option for field 14
    if (currentValue.fieldThirteenInput == 2 || data.fieldThirteenInput == 2) {
      if (!data.fieldFourteenInput && !currentValue.fieldFourteenInput) {
        setCheckValue("invalid");
        console.log("gyaaat 1");
        return; // Exit the function
      }
    }
  
    // Field 16 always comes with a default value, so if the user makes an error, we leave "invalid"
    if (data.fieldSixteenInput === "invalid") {
      setCheckValue("invalid");
      console.log("gyaaat 2");
      return; // Exit the function
    }
  
    // If none of the above conditions were met, execute the following block
    console.log("bago");
    dispatch(
      sendSectionInputs(data, (response) => {
        if (
          !response.line.fieldTwelveInput ||
          !response.line.fieldThirteenInput ||
          !response.line.fieldFifteenInput // ||
          // !response.line.fieldFourteenInput
        ) {
          setCheckValue("invalid");
          console.log("gyaaat 3");
        } else if (
          response.line.fieldTwelveInput &&
          response.line.fieldThirteenInput &&
          response.line.fieldFifteenInput
        ) {
          setCheckValue("valid");
          pageJump();
        }
      })
    );
  }
  

  const handleSubmit = (event) => {
    event.preventDefault();
    checkFields();
  };

  return (
    <React.Fragment>
      {!projectLoginReducer.jwt && history.push("/")}
      <CssBaseline />
      <Container maxWidth="sm" className={"inputSections"}>
        <Grid container direction={"column"} maxWidth="xs" spacing={2}>
          <Grid
            item
            style={{
              position: "absolute",
              left: "10%",
              top: "20%",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              // inputProps={{ style: { fontSize: 300 } }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].trunkingConfigurator}
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
             {pageContent[`${selectedLang}`].inputPageSectionThreeTranslation}
            </Typography>
            <br></br>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].lineName}: {currentValue.name}
              <br></br>
              <br></br>
            </Typography>
            
            {/* <Typography variant="body2">
              <Link
                onClick={() => history.push("/helpPage")}
                style={{
                  color: "#ffffff",
                  fontWeight: 350,
                  fontSize: "20px",
                  cursor : "pointer"
                }}
              >
                {pageContent[`${selectedLang}`].HowtoUseCreateProjectForm}
              </Link>
            </Typography> */}
          </Grid>
        </Grid>
      </Container>
      {/* <div
        style={{
          position: "absolute",
          left: "10%",
          paddingTop: "1%",
        }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: 600, color: "#0146ab", lineHeight: 1 }}
          inputProps={{ style: { fontSize: 300 } }}
          gutterBottom
        >
          Section 3
        </Typography>
      </div> */}

      <div
       style={{
        marginTop: "5%",
      }}
      >
      <FieldTwelve />
      <FieldThirteen />
      {(currentValue.fieldThirteenInput === "2" && currentValue.fieldSevenInput !== "1" )&& <FieldFourteen />}
      <FieldFifteen />
      <FieldSixteen />
      </div>

      <div>
        {checkValue === "invalid" && (
          <h5 style={{ color: "red" }}>{message}</h5>
        )}
      </div>
      <div
       style={{
        maxWidth: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginLeft: "7%",
        marginRight: "7%",
        marginBottom: "200px",
        padding: "50px",
        // borderColor : "#000000",
        // border: "1px solid black"
      }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={() => {
            dispatch(clearInputSectionReducer());
            history.push("/inputPageSection2");
          }}
        >
          {pageContent[`${selectedLang}`].backTranslation}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={handleSubmit}
        >
          {pageContent[`${selectedLang}`].nextTranslation}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default InputSection3Page;
