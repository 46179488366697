import React, { useState } from 'react';
import { AppBar, Toolbar, Grid, InputBase, IconButton, Badge, makeStyles , Typography, Tooltip } from '@material-ui/core'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import AddBoxIcon from '@material-ui/icons/AddBox';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LanguageIcon from '@material-ui/icons/Language';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SelectLanguage from "../components/SelectLanguage";
import logo from "../pictures/opple_logo.svg";
import { pageContent } from "../lang/pageContent";
import {logout} from "../actions/jwtActions";
import {logOutPopup} from "../actions/popupActions";
import LogoutForm from "../components/forms/LogoutForm";
import Popup from "../components/Popup";




const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff',
        ".MuiListItemText-primary" : {
            color : "#2d66b8",

            fontWeight : 600
        }
    },
    // searchInput: {
    //     opacity: '0.6',
    //     padding: `0px ${theme.spacing(1)}px`,
    //     fontSize: '0.8rem',
    //     '&:hover': {
    //         backgroundColor: '#f2f2f2'
    //     },
    //     '& .MuiSvgIcon-root': {
    //         marginRight: theme.spacing(1)
    //     }
    // },
    drawer: {
        zIndex: "0 !important",
    },
    drawerPaper: {
        width: 300,
        // width: "100%",
        elevation:"2"
    },
    toolbar:{
        minHeight : "120px"
    },
    logo : {
        maxWidth: 180,
        marginLeft : "5.5%",
        cursor: 'pointer',
    },
    listTitle :{
        color : "#0045a8",
    },
}))

export default function Header() {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();


    const selectedLang = useSelector(
        (reduxState) => reduxState.selectedLanguageReducer
      );

      const project = useSelector(
        (reduxState) => reduxState.projectLoginReducer.project
      );

      const user = useSelector(
          (reduxState) => reduxState.userLoginReducer.userName
      );

      const popupState = useSelector((reduxState) => reduxState.popupReducer);

    const [openPopup, setOpenPopup] = useState(popupState);


    const [drawerOpen, setDrawerOpen] = useState(false);

    const [message, setMessage] = useState("");

    const handleDrawerOpen = () => {
      setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
      setDrawerOpen(false);
    };

    const handleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleItemClick = () => {
        setDrawerOpen(!drawerOpen);
    }

    const handleGuestDashboard =() => {

        if(!project){
            history.push("/openProjectFormPage");
            setDrawerOpen(!drawerOpen);

        }else{
            history.push("/projectDashboard");
            setDrawerOpen(!drawerOpen);

        }        
    }

    const handleUserDashboard = () => {
        if(!project){
            history.push("/userLogin")
            setDrawerOpen(!drawerOpen);
        }else{
            history.push("/projectDashboard");
            setDrawerOpen(!drawerOpen);
        }
    }

    const handleMyProjects = () => {
        console.log("checkign the boo", user)
        if(!user){
            history.push("/userLogin")
            setDrawerOpen(!drawerOpen);
        }else{
            history.push("/userDashboard");
            setDrawerOpen(!drawerOpen);
        }
    }

    const handleLogoClick = () => {
        history.push("/")
    }

    const handlePowerButtonClick = () => {
        console.log("power button clicked")
        dispatch(logOutPopup({ status: true}));
    }

    return (
        <React.Fragment>
        <AppBar position="static" className={classes.root} >
            <Toolbar className={classes.toolbar}>
                <Grid container
                    alignItems="center">
                    <Grid item>
                        {/* <InputBase
                            placeholder="Search topics"
                            className={classes.searchInput}
                            startAdornment={<SearchIcon fontSize="small" />}
                        /> */}
                        <IconButton color="default" aria-label={drawerOpen ? "Close Menu" : "Open Menu"} onClick={handleDrawer} >
                            <Badge color="secondary">
                                <MenuIcon fontSize="large" style={{ color: "#0045A8" }}/>
                            </Badge>
                        </IconButton>
                    </Grid>
                    <img id="logo" src={logo} alt="opple_logo" className={classes.logo} onClick={handleLogoClick}/>
                    <Grid item sm></Grid>
                    <Grid item>
                    <SelectLanguage/>
                    </Grid>
                    <Grid item>
                        {/* <IconButton>
                            <Badge badgeContent={4} color="secondary">
                                <NotificationsNoneIcon fontSize="small" />
                            </Badge>
                        </IconButton> */}
                        {/* <IconButton onClick={() => { console.log('Billi'); }}>
                            <Badge badgeContent={"EN"} color="primary" >
                                <LanguageIcon fontSize="small" />
                            </Badge>
                        </IconButton> */}
                        <Tooltip title={pageContent[`${selectedLang}`].logout} >
                        <IconButton>
                            <PowerSettingsNewIcon fontSize="small" onClick={handlePowerButtonClick} />
                        </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <SwipeableDrawer
                className={classes.drawer}
                classes={{ paper: classes.drawerPaper }}
                open={drawerOpen}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
            >
                <div className={classes.toolbarMargin} />
                <List component="nav">
                    <ListItem button component={Link} to={"/"} onClick={handleItemClick} className={classes.listTitle}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText>
                        {pageContent[`${selectedLang}`].home}
                        </ListItemText>
                     </ListItem>
                     <ListItem button component={Link} to={"/helpPage"} onClick={handleItemClick} className={classes.listTitle}>
                        <ListItemIcon>
                            <HelpIcon />
                        </ListItemIcon>
                        <ListItemText>
                            {pageContent[`${selectedLang}`].howToUseTheConfigurator}
                        </ListItemText>
                     </ListItem>
                </List>
                <Divider />
                <List component="nav"  >
                    <ListItemText  primary={<Typography variant="h5" style={{ color: '#0045a8', fontWeight: 600, marginLeft : "5%"  }} >{pageContent[`${selectedLang}`].forGuests}</Typography>}/>
                    
                        <ListItem button component={Link} to={"/createProjectFormPage"} onClick={handleItemClick} className={classes.listTitle}>
                        <ListItemIcon>
                            <AddBoxIcon />
                        </ListItemIcon>
                        <ListItemText>
                        {pageContent[`${selectedLang}`].createAProject}
                        </ListItemText>
                     </ListItem>
                     <ListItem button component={Link} to={"/openProjectFormPage"} onClick={handleItemClick} className={classes.listTitle}>
                        <ListItemIcon>
                            <OpenInBrowserIcon />
                        </ListItemIcon>
                        <ListItemText>
                        {pageContent[`${selectedLang}`].openAProject}
                        </ListItemText>
                     </ListItem>
                     <ListItem button  onClick={handleGuestDashboard} className={classes.listTitle}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText>
                        {pageContent[`${selectedLang}`].projectDashboard}
                        </ListItemText>
                     </ListItem>
                </List>
                <Divider />
                <List component="nav"  >
                <ListItemText  primary={<Typography variant="h5" style={{ color: '#0045a8', fontWeight: 600, marginLeft : "5%"  }} >{pageContent[`${selectedLang}`].forUsers}</Typography>}/>

                <ListItem button component={Link} to={"/userSignup"} onClick={handleItemClick} className={classes.listTitle}>
                        <ListItemIcon>
                            <AddBoxIcon />
                        </ListItemIcon>
                        <ListItemText>
                        {pageContent[`${selectedLang}`].signup}
                        </ListItemText>
                     </ListItem>
                     <ListItem button component={Link} to={"/userLogin"} onClick={handleItemClick} className={classes.listTitle}>
                        <ListItemIcon>
                            <LockOpenIcon />
                        </ListItemIcon>
                        <ListItemText>
                        {pageContent[`${selectedLang}`].login}
                        </ListItemText>
                     </ListItem>
                     <ListItem button onClick={handleUserDashboard} className={classes.listTitle}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText>
                        {pageContent[`${selectedLang}`].projectDashboard}
                        </ListItemText>
                     </ListItem>
                     <ListItem button onClick={handleMyProjects} className={classes.listTitle}>
                        <ListItemIcon>
                            <LibraryBooksIcon />
                        </ListItemIcon>
                        <ListItemText>
                        {pageContent[`${selectedLang}`].myProjects}
                        </ListItemText>
                     </ListItem>
                </List>
            </SwipeableDrawer>
            <Popup
                openPopup={popupState.logoutPopup}
                setOpenPopup={setOpenPopup}
                title={pageContent[`${selectedLang}`].logout}
            >
        <LogoutForm />
      </Popup>
        </React.Fragment>
    )
}