import React, { useState } from "react";
// import { Container } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { projectLogin } from "../../actions/projectActions";
import Button from "@material-ui/core/Button";
// import "../../semantic.css";
import { useHistory } from "react-router-dom";
import { numberOfLinesToConfigure } from "../../actions";
import { inputReferenceNumber } from "../../actions/contentActions";
import {changeLineName, getProjectLines} from "../../actions/lineActions";
import {renameLinePopup} from "../../actions/popupActions";
import {displayResultsError, closePopupAction} from "../../actions/popupActions";
import { pageContent } from "../../lang/pageContent";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
      // left: "50%",
    },
  },
  pageContent: {
    // margin: theme.spacing(1),
    // padding: theme.spacing(3),
    // width: "80%",
    alignContent: "center",
    padding: "0 15px 0 15px",
    width: 300,
    spacing: theme.spacing(5),
  },
  button: {
    // color:"#ffffff",
    // maxWidth: "100px",
    width : "100px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "##0146ab",
    marginTop:"5%",
    marginBottom:"3%",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

export default function DisplayResultsErrorForm() {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((reduxState) => reduxState.userLoginReducer);

  const dispatch = useDispatch();

  const allLines = useSelector((reduxState) => reduxState.lineReducer.data);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const displayResultsPass = false;

//   function checkLineStatus(){
//     allLines.map((line) => (
//        console.log(line.status)
//     ))
//   }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("submit was clicked");
    // dispatch(displayResultsError(false));
    dispatch(closePopupAction());
    history.push("/displayResultsPage")
  };

  return (
    <Paper className={classes.pageContent} elevation={0}>
    <form  noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid 
      className={classes.root} 
      Container align='center' direction={"column"} spacing={3}>
        <Grid Item >
      {/* <h3>Please enter the new Name</h3> */}
          <p>Please set all line Status to complete and try again.</p>
        </Grid>
        <br></br>
        <Button
          type="submit"
          // fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleSubmit}
        >
          {pageContent[`${selectedLang}`].okay}
        </Button>
      </Grid>
    </form>
     </Paper> 
  );
}

