import { GET_PROJECT_LINES } from "../actions/lineActions";
import { UPDATE_PROJECT_LINES } from "../actions/projectActions";

const initialState = {};

export default function lineReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PROJECT_LINES: {
      return { ...state, ...action.payload };
    }
    case UPDATE_PROJECT_LINES: {
      return (state = { data: action.payload });
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
