import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { fieldOneInput, lineStatus } from "../../actions/lineActions";
import {
  Grid,
  Paper,
  Typography,
  Link,
  TextField,
  CssBaseline,
  Tooltip
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import HelpIcon from "@material-ui/icons/Help";
import { fieldSeventeenInput } from "../../actions/lineActions";
import { fieldSevenInput } from "../../actions/lineActions";
import {fieldSixInput} from "../../actions/lineActions";
import { inputLabels } from "../../lang/inputLabels";
import {pageContent} from "../../lang/pageContent";
import {errorText} from "../../lang/errorText";
import Controls from "../controls/Controls";

import {fieldOneInfo} from "../../actions/popupActions";
import Popup from "../../components/Popup";
import FieldOneInfo from "../../components/fieldInfoComponents/FieldOneInfo";



const useStyles = makeStyles((theme) => ({
  root: {
    //   this changes the border colour
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the border colour on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the border colour on focus(when typing)
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the text colour on typing
    "& .MuiInputBase-root": {
      color: "#000000",
    },
    //   this changes the border radius of the field
    [`& fieldset`]: {
      borderRadius: 35,
      // background:"#ffffff"
    },

    maxWidth: "650px",
    // alignItems : "center"

    // this changes the background to white
    // background: "#ffffff"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
 
  pageContent: {
    // margin: theme.spacing(1),
    // padding: theme.spacing(3),
    flexGrow: 1,
    // spacing: theme.spacing(5)
  },
}));

export default function FieldOne() {
  const classes = useStyles();
  const { register, handleSubmit, control, errors } = useForm();
  const [fieldOneValue, setFieldOneValue] = React.useState("");
  const [roundedValue, setRoundedValue] = React.useState("");
  const [checkValue, setCheckvalue] = React.useState("");

  const dispatch = useDispatch();

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);


  const currentValue = useSelector(
    (reduxState) =>
      reduxState.selectedLineInputsReducer.data.lengthOfLineRounded
  );

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );


  function checkNumber(value) {
    if (value >= 1.5) {
      if (value.match(/^((\d|[1-9]\d+)(\.\d{1,1})?|\.\d{1,1})$/)) {
        setRoundedValue(Math.floor(value / 1.5) * 1.5)
        setFieldOneValue(value);
        setCheckvalue("valid");
        dispatch(fieldOneInput(value));
        dispatch(lineStatus("false"));
        dispatch(fieldSeventeenInput("2"));
        dispatch(fieldSixInput("3"));
      } else if (value % 1.5 !== 0) {
        console.log("invalid value");
        dispatch(fieldOneInput("invalid"));
        setCheckvalue("invalid");
      }
    } else {
      console.log("invalid value");
      dispatch(fieldOneInput("invalid"));
      setCheckvalue("invalid");
    }
  }

  const handleChange = (event) => {
    event.preventDefault();
    checkNumber(event.target.value);
  };

  const onSubmit = (data) => {
    console.log("the data in the form is:", data);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div
        style={{
          maxWidth: "80%",
          marginLeft: "10%",
          // border: "1px solid black",
          borderRadius: "35px",
          // marginTop: "5%",
        }}
      >
        <div
          style={{
            display: "flex",
            // flexDirection: "row",
            flexDirection: "column",
            // justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Typography
            variant="body1"
            style={{ marginTop: "30px", fontSize : "18px" ,}}
            // inputProps={{ style: { fontSize: 400 } }}
            gutterBottom
          >
             <Controls.ActionButton color="secondary">
              <Tooltip title="Field Info" style={{ marginBottom: "1px"}}>
                <HelpIcon 
                fontSize="small"
                color="action"
                onClick={() => {
                  dispatch(
                    fieldOneInfo({status:true})
                  )
                }}
                />
              </Tooltip>
            </Controls.ActionButton>
            {inputLabels[`${selectedLang}`].fieldOne}
          </Typography>
          <form
            // className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            {" "}
            <TextField
              variant="outlined"
              margin="normal"
              inputRef={register({
                required: "Enter a Valid value",
                minLength: { value: 1, message: "Too Short" },
              })}
              fullWidth
              className={classes.root}
              // InputLabelProps={{ required: false}}
              InputProps={{
                inputProps: {
                  style: { textAlign: "center" }
                },
              }}
              id="fieldOneValue"
              name="fieldOneValue"
              label={`${pageContent[`${selectedLang}`].savedValue}:${currentValue}`}
              autoComplete="referenceNumber"
              onChange={handleChange}
              error={Boolean(errors.referenceNumber)}
            />
            {errors.referenceNumber && (
              <p style={{ color: "red" }}>{errors.fieldOneValue.message}</p>
            )}
            {checkValue === "invalid" && (
              <p style={{ color: "red" }}>{errorText[`${selectedLang}`].error115}</p>
            )}
            {checkValue === "valid" && (
              <p style={{ color: "#000000" }}>{`${pageContent[`${selectedLang}`].valueRounded}`} : {roundedValue}</p>
            )}
          </form>
        </div>
      </div>
      <Popup
      openPopup={popupState.fieldOneInfo}
      setOpenPopup={setOpenPopup}
      title="Field Info"
      >
        <FieldOneInfo/>
      </Popup>
    </React.Fragment>
  );
}

