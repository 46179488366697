import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { projectLogin } from "../../actions/projectActions";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import {createProjectPopup, closePopupAction} from "../../actions/popupActions";
import { createUserProject } from "../../actions/projectActions";
import {getProjects} from "../../actions/projectActions";
import { pageContent } from "../../lang/pageContent";
import { errorText } from "../../lang/errorText";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(2),
      // left: "50%",
    },
  },
  pageContent: {
    // margin: theme.spacing(1),
    // padding: theme.spacing(3),
    // width: "80%",
    alignContent: "center",
    padding: "0 15px 0 15px",
    width: 350,
    spacing: theme.spacing(5),
  },
}));

export default function CreateProjectUserDashboardForm() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const userName = useSelector((reduxState) => reduxState.userLoginReducer.userName);

  
  // const userName = user && user.userName
  const [formData, setFormData] = useState({
    projectName: "",
    password : "",
  });
  
  const referenceNumber = parseInt(Date.now());

  const [errorMessage, setErrorMessage] = React.useState("false");
  
  function checkFormData() {
    if (!formData.projectName || !formData.password) {
      setErrorMessage(true);
    }else{
      setErrorMessage(false);
     
    }
  }

  function newProjectRequest(){
    if(errorMessage == false){
      console.log("request sendu")
       dispatch(
        createUserProject(
          userName,
          `${referenceNumber}`,
          formData.projectName,
          formData.password,
          (response) => {
            if (response.message === "Success") {
              // dispatch(createProjectPopup(false))
              dispatch(closePopupAction());
              dispatch(getProjects());
            }
          }
        )
      );
    }
  }


  const handleChange1 = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    console.log("value of form data is:", formData.projectName);
    checkFormData();
  };
  const handleChange2 = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    console.log("value of form data is:", formData.projectName);
    checkFormData();
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    checkFormData();
    newProjectRequest();
  };

  return (
    <Paper className={classes.pageContent} elevation={0}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container
          className={classes.root}
          Container
          align="center"
          direction={"column"}
          spacing={3}
        >
          <Grid Item>
            {/* <h3>Please enter the new Name</h3> */}
            <TextField
              required
              variant="outlined"
              id="userProjectName"
              label={pageContent[`${selectedLang}`].projectName}
              name="projectName"
              onChange={handleChange1}
            />
          </Grid>
          <Grid Item>
            {/* <h3>Please enter the new Name</h3> */}
            <TextField
              required
              variant="outlined"
              id="userProjectPassword"
              label={pageContent[`${selectedLang}`].password}
              name="password"
              onChange={handleChange2}
            />
          </Grid>
          {errorMessage == true && (
            <p style={{ color: "red" }}>{errorText[`${selectedLang}`].error114}</p>
          )}{" "}
          <br></br>
          <Button
            type="submit"
            // fullWidth
            variant="contained"
            color="primary"
            className="Button"
            onClick={handleSubmit}
          >
            {pageContent[`${selectedLang}`].submit}
          </Button>
         
        </Grid>
      </form>
    </Paper>
  );
}

