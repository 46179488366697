import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { fieldSixteenInput, lineStatus } from "../../actions/lineActions";
import {
  Grid,
  Paper,
  Typography,
  Link,
  TextField,
  CssBaseline,
  Tooltip,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { inputLabels } from "../../lang/inputLabels";
import { inputLabelOptions } from "../../lang/inputLabelOptions";
import { pageContent } from "../../lang/pageContent";
import { errorText } from "../../lang/errorText";
import Controls from "../controls/Controls";
import HelpIcon from "@material-ui/icons/Help";

import { fieldSixteenInfo } from "../../actions/popupActions";
import Popup from "../../components/Popup";
import FieldSixteenInfo from "../../components/fieldInfoComponents/FieldSixteenInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    //   this changes the border colour
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the border colour on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the border colour on focus(when typing)
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the text colour on typing
    "& .MuiInputBase-root": {
      color: "#000000",
    },
    //   this changes the border radius of the field
    [`& fieldset`]: {
      borderRadius: 35,
      // background:"#ffffff"
    },

    width: "650px",

    // this changes the background to white
    // background: "#ffffff"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
}));

export default function FieldSixteen() {
  const classes = useStyles();
  const { register, handleSubmit, control, errors } = useForm();

  const [fieldSixteenValue, setFieldSixteenValue] = React.useState("");
  const [checkValue, setCheckvalue] = React.useState("");

  const dispatch = useDispatch();

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const currentValue = useSelector(
    (reduxState) => reduxState.selectedLineInputsReducer.data.fieldSixteenInput
    // (reduxState) => reduxState.selectedLineInputsReducer
  );

  function checkNumber(value) {
    if (value >= 1 && value <= 3) {
      if (value.match(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/g)) {
        console.log("valid value");
        setCheckvalue("valid");
        dispatch(fieldSixteenInput(value));
        dispatch(lineStatus("false"));
      } else {
        setCheckvalue("invalid");
        dispatch(fieldSixteenInput("invalid"));
      }
    } else {
      console.log("oogs value");
      setCheckvalue("invalid");
      dispatch(fieldSixteenInput("invalid"));
    }
  }

  const handleChange = (event) => {
    // console.log(event.target.value);
    // setFieldSixteenValue(event.target.value);
    checkNumber(event.target.value);
    //   dispatch(inputReferenceNumber(event.target.value))
    // dispatch(fieldSixteenInput(event.target.value));
  };

  const onSubmit = (data) => {
    console.log("the data in the form is:", data);
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <div
        style={{
          maxWidth: "80%",
          marginLeft: "10%",
          // border: "1px solid black",
          borderRadius: "35px",
          marginTop: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <Typography
            variant="body1"
            style={{ marginTop: "20px", fontSize: "18px" }}
            // inputProps={{ style: { fontSize: 400 } }}
            gutterBottom
          >
            <Controls.ActionButton color="secondary">
              <Tooltip title="Field Info">
                <HelpIcon
                  fontSize="small"
                  color="action"
                  onClick={() => {
                    dispatch(fieldSixteenInfo({ status: true }));
                  }}
                />
              </Tooltip>
            </Controls.ActionButton>
            {inputLabels[`${selectedLang}`].fieldSixteen}
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            {" "}
            <TextField
              variant="outlined"
              margin="normal"
              inputRef={register({
                required: "Enter a Valid value",
                minLength: { value: 1, message: "Too Short" },
              })}
              fullWidth
              className={classes.root}
              InputProps={{
                inputProps: {
                  style: { textAlign: "center" },
                },
              }}
              id="fieldSixteenValue"
              name="fieldSixteenValue"
              label={`${
                pageContent[`${selectedLang}`].savedValue
              }: ${currentValue}`}
              autoComplete="referenceNumber"
              onChange={handleChange}
              error={Boolean(errors.referenceNumber)}
            />
            {errors.referenceNumber && (
              <p style={{ color: "red" }}>{errors.fieldThreeValue.message}</p>
            )}
            {checkValue === "invalid" && (
              <p style={{ color: "red" }}>
                {errorText[`${selectedLang}`].error115}
              </p>
            )}
          </form>
        </div>
      </div>
      <Popup
        openPopup={popupState.fieldSixteenInfo}
        setOpenPopup={setOpenPopup}
        title="Field Info"
      >
        <FieldSixteenInfo />
      </Popup>
    </React.Fragment>
  );
}
