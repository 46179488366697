import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers/index'
import ReduxThunk from 'redux-thunk'
import { persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import persistReducer from "./reducers/index"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancer = composeEnhancers(
  applyMiddleware(ReduxThunk)
)

export const store = createStore(rootReducer, enhancer)

export const persistor = persistStore(store)

export default {store, persistor};

// export default store;