import request from "superagent";
// export const baseUrl = "http://localhost:4000";

export const SELECT_LANGUAGE_ENGLISH = "SELECT_LANGUAGE_ENGLISH";
export const SELECT_LANGUAGE_DUTCH = "SELECT_LANGUAGE_DUTCH";
export const SELECT_LANGUAGE_SPANISH = "SELECT_LANGUAGE_SPANISH";
export const INPUT_LABELS = "INPUT_LABELS";
export const INPUT_LABEL_OPTIONS = "INPUT_LABEL_OPTIONS";
export const SELECTED_LANGUAGE = "SELECTED_LANGUAGE";
export const NUMBER_OF_LINES_TO_CONFIGURE = "NUMBER_OF_LINES_TO_CONFIGURE";
export const INPUT_REFERENCE_NUMBER = "INPUT_REFERENCE_NUMBER";
export const SELECTED_LINE = "SELECTED_LINE";

// action for choosing language
export function selectedLanguage(payload) {
  return {
    type: SELECTED_LANGUAGE,
    payload: payload,
  };
}

// action to select number of lines to configure
export function numberOfLinesToConfigure(payload) {
  return {
    type: NUMBER_OF_LINES_TO_CONFIGURE,
    payload: payload,
  };
}

// action for storing selected line
export function selectedLineId(payload) {
  return {
    type: SELECTED_LINE,
    payload: payload,
  };
}

// action to store the reference number
export function inputReferenceNumber(payload) {
  return {
    type: INPUT_REFERENCE_NUMBER,
    payload: payload,
  };
}

// action to get the object of input labels
function inputLabels(payload) {
  return {
    type: INPUT_LABELS,
    payload: payload,
  };
}

export const getInputLabels = () => (dispatch, getState) => {
  const state = getState();
  const lang = state.selectedLanguageReducer;
  console.log("Fetching Language:", lang);

  request
    .get(`/api/inputLabels/${lang}`)
    .then((response) => {
      console.log("Trying to get the labels here", response.body);
      const action = inputLabels(response.body);
      dispatch(action);
    })
    .catch(console.error);
};

// action to get object for input label options
function inputLabelOptions(payload) {
  return {
    type: INPUT_LABEL_OPTIONS,
    payload: payload,
  };
}

export const getInputLabelOptions = () => (dispatch, getState) => {
  const state = getState();
  const lang = state.selectedLanguageReducer;

  request
    .get(`/api/inputLabelOptions/${lang}`)
    .then((response) => {
      console.log("Trying to get the label options YYYYAAOOO", response.body);
      const action = inputLabelOptions(response.body);
      dispatch(action);
    })
    .catch(console.error);
};


// export const setCountry