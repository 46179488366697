import request from "superagent";
import {closePopupAction} from "../actions/popupActions";
// export const baseUrl = "http://localhost:4000";
export const SIGN_UP_MESSAGE = "SIGN_UP_MESSAGE"
export const ADD_USER = "ADD_USER"
export const USER_LOG_IN = "USER_LOG_IN";
export const JWT = "JWT";
export const CLEAR_USER_LOGIN_REDUCER_MESSAGE = "CLEAR_USER_LOGIN_REDUCER_MESSAGE";
export const CLEAR_USER_SIGNUP_REDUCER_MESSAGE = "CLEAR_USER_SIGNUP_REDUCER_MESSAGE";
// Add a user - Sign up
// Taken from the old actions




// User login 

function UserLogin(payload) {
  return {
    type: USER_LOG_IN,
    payload: payload
  };
}

export const userLogin = (userName, password, callback) => dispatch => {
  request
    .post(`/userLogin`)
    .send({ userName, password })
    .then(response => {
      dispatch(UserLogin(response.body));
      typeof callback == "function" && callback(response.body);
      console.log("success");
    })
    .catch(error => {
      if(error){
        dispatch(UserLogin(error.response.body.message))
      }
    });
};

// Add a user - Sign up

export function AddUser(payload) {
  return {
    type: ADD_USER,
    payload: payload
  };
}

export const addUser = (email,userName,password, callback) => dispatch => {
  request
    .post(`/userSignup`)
    .send({ email,userName,password })
    .then(response => {
      dispatch(AddUser(response.body));
      typeof callback == "function" && callback(response.body);
      // console.log("success");
    })
    .catch(error => {
      // console.log("checkign errorororor", error.response); 
      dispatch(AddUser(error.response.body.message))
      dispatch(closePopupAction());
    });
};


// Clear error messages from userLoginReducer

export function clearUserLoginReducerMessage(){
  return{
    type : CLEAR_USER_LOGIN_REDUCER_MESSAGE
  }
}

export function clearUserSignupReducerMessage(){
  return {
    type : CLEAR_USER_SIGNUP_REDUCER_MESSAGE
  }
}