import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "semantic-ui-react";
import { fieldTwentyTwoInput, lineStatus } from "../../actions/lineActions";
import { Select, withWidth, CssBaseline, Typography,MenuItem } from "@material-ui/core";
import { inputLabels } from "../../lang/inputLabels";
import { inputLabelOptions } from "../../lang/inputLabelOptions";
import {pageContent} from "../../lang/pageContent";



const useStyles = makeStyles((theme) => ({
  quantityRoot: {
    color: "#000000",
    // backgroundColor: "#000000",
    opacity: 1,
    borderRadius: "35px",
    minWidth: "650px",
    // this one changes colour of the background on hover
    "&:hover": {
      // backgroundColor: "#ffffff",
      borderRadius: "35px",
      opacity: 1
    },
    // this one changes background colour when field is selected
    "&:focus-within": {
      // backgroundColor: "#1E1E24",
      borderRadius: "35px",
      opacity: 1
    },
    // this changes the outline color in normal state
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #4e7fc4",
      borderRadius: "35px"
    },
    // this one is when the curson hovers on the field
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
      borderRadius: "35px"
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      // the border makes it look like a square when selecting option
      // border: "1px solid #ffffff",
      borderRadius: "35px"
    },
    "& .Mui-disabled": {
      color: "#FFFFFF",
      opacity: 0.6
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #4e7fc4",
      borderRadius: "35px"
    },
    "& .MuiSelect-select:focus": {
      // backgroundColor: "#000000",
      borderRadius: "35px"
    }
  },
  selectRoot: {
    color: "#000000"
  },
  // this changes the colour of the pointer icon
  icon: {
    color: "#000000"
  },
  // paper comes out when the select is clicked
  selectPaper: {
    backgroundColor: "#ffffff",
    border: "1px solid #484850",
    borderRadius: "35px",
    // colour below changes the text colour in paper
    color: "#000000",
    "& li:hover": {
      // change colour of list items on hover
      backgroundColor: "#c2c0ba"
    },
    maxHeight: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 200,
  },
}));

export default function FieldTwentyTwo() {
  const classes = useStyles();
  const [selectedOption, setselectedOption] = React.useState("");
  const dispatch = useDispatch();

  const data = useSelector((reduxState) => reduxState.inputSection4Reducer);


  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const currentValue = useSelector(
    (reduxState) => reduxState.selectedLineInputsReducer.data.fieldTwentyTwoInput
  )

  const fieldOptions  = inputLabelOptions[`${selectedLang}`]

  const fieldTwentyTwoOptions = [
    {
      name:fieldOptions.FieldTwentyTwoOptionOne,
      id:"1"
    },
    {
      name:fieldOptions.FieldTwentyTwoOptionTwo,
      id:"2"
    }
  ];

  let currentValueName = ""

    if(currentValue === "1"){
      currentValueName = `${pageContent[`${selectedLang}`].savedValue}: ${fieldOptions.FieldTwentyTwoOptionOne}`
      console.log("current value is", currentValue)
    }else if(currentValue === "2"){
      currentValueName = `${pageContent[`${selectedLang}`].savedValue}: ${fieldOptions.FieldTwentyTwoOptionTwo}`
    }else{
      currentValueName = "Input Value"
    }
  


  const handleChange = (event) => {
    setselectedOption(event.target.value);
    console.log("Field Twenty two Option Selected", event.target.value);
    dispatch(fieldTwentyTwoInput(event.target.value));
    dispatch(lineStatus("false"));
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div
        style={{
          maxWidth: "80%",
          marginLeft: "10%",
          // border: "1px solid black",
          borderRadius: "35px",
          marginTop: "2%",
        }}
        >
          <div
           style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}>
              <Typography
            variant="body1"
            style={{ marginTop: "20px", fontSize : "18px"  }}
            inputProps={{ style: { fontSize: 400 } }}
            gutterBottom
          >
            22. {inputLabels[`${selectedLang}`].fieldTwentyTwo}
          </Typography>
        <FormControl variant="outlined" className={classes.quantityRoot}>
          <InputLabel id="demo-simple-select-outlined-label">{currentValueName}</InputLabel>
          <Select
             labelId="demo-simple-select-outlined-label"
             id="demo-simple-select-outlined"
            value={selectedOption}
            onChange={handleChange}
            label={currentValueName}
            classes={{
              root: classes.selectRoot,
              icon: classes.icon
            }}
            MenuProps={{ classes: { paper: classes.selectPaper } }}
            >
            {fieldTwentyTwoOptions.map((option,i) => (
              <MenuItem value={option.id} key={i}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      </div>
</React.Fragment>
  );
}

