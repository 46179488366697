import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  CssBaseline,
  Container,
  Button,
  Tooltip,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { getComponents } from "../../actions/adminActions";
import useTable from "../../components/useTable";
import Popup from "../../components/Popup";
import Controls from "../../components/controls/Controls";
import EditIcon from "@material-ui/icons/Edit";
import ComponentDetails from "../../components/adminComponents/ComponentDetails";
import EditComponentForm from "../../components/adminComponents/EditComponentForm";
import ResetLineStatusForm from "../../components/adminComponents/ResetLineStatusForm";
import {
    selectedComponent,
    componentDetailsInfo,
    editComponentPopup,
    resetLineStatus
  } from "../../actions/popupActions";


const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    // maxWidth : "1200px",
    // borderRadius: "35px",
    // marginLeft : "10%",
    // marginRight : "10%"
  },
  button: {
    // color:"#ffffff",
    // width : "20em",
    maxWidth: "200px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "#1a9fe0",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
  input: {
    color: "#FFFFFF",
  },
  endIcon: {
    fontSize: 12,
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(1),
  },
}));

// This function has the tabel of components that shows up in the admin dashboard under the tab "Manage components"
export default function ComponentTable() {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getComponents());
    console.log("inside use effect of admin dashboard");
  }, []);

  const togglePassword = () => {
    setShow(!showPassword);
  };

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const admin = useSelector((reduxState) => reduxState.adminLoginReducer);

  const allComponents = useSelector(
    (reduxState) => reduxState.componentsReducer.data
  );

  const records = allComponents;

  const headCells = [
    { id: "id", label: "Id" },
    { id: "code", label: "Code" },
    { id: "componentname", label: "Component name" },
    { id: "viewDetails", label: "View Details" },
    { id: "editComponent", label: "Component Actions" },
  ];

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells);

  const handleChange = (event) => {
    // setFormData({ ...formData, [event.target.name]: event.target.value });
    console.log("value of form data is:", event.target.value);
  };

  const onSubmit = (data) => {
    console.log("The data in the admin form is");
  };

  if (admin.message !== "Success") {
    history.push("/");
  }

  // console.log("records bzzzzzz", recordsAfterPagingAndSorting());

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper className={classes.pageContent} elevation={0}>
      <Grid
          container
          alignItems="center"
          direction="row"
          justify="center"
          spacing={1}
        >
          <Grid item xs={4} spacing={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.resultsButton}
              onClick={() => dispatch(resetLineStatus({status:true}))}
            >
              Reset all line status
            </Button>
          </Grid>
        </Grid>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting() &&
              recordsAfterPagingAndSorting().map((component, i) => (
                <TableRow key={component.id}>
                  <TableCell>{component.id}</TableCell>
                  <TableCell>{component.code}</TableCell>
                  <TableCell>{component.name}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.button}
                      onClick={() => {
                        dispatch(selectedComponent({ component }));
                        dispatch(componentDetailsInfo({ status: true }));
                      }}
                    >
                      View details
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Controls.ActionButton color="primary">
                      <Tooltip title="Change component price">
                        <EditIcon
                          fontSize="small"
                          onClick={() => {
                            dispatch(selectedComponent({ component }));
                            dispatch(editComponentPopup({ status: true }));
                          }}
                        />
                      </Tooltip>
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        
      </Paper>
      <Popup
        openPopup={popupState.componentDetails}
        setOpenPopup={setOpenPopup}
        title="Component Details"
      >
        <ComponentDetails />
      </Popup>
      <Popup
        openPopup={popupState.editComponent}
        setOpenPopup={setOpenPopup}
        title="Edit Component"
      >
        <EditComponentForm />
      </Popup>
      <Popup
        openPopup={popupState.resetLineStatus}
        setOpenPopup={setOpenPopup}
        title="Reset all line status"
      >
        <ResetLineStatusForm />
      </Popup>
    </React.Fragment>
  );
}
