import React from "react";
import "./App.css";
import 'semantic-ui-css/semantic.min.css'
import { Switch, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage"
import ProjectDashboard from "../pages/ProjectDashboard";
import DisplayResultsPage from "../pages/DisplayResultsPage";
import InputSection1Page from "../pages/InputPageSection1Page";
import InputSection2Page from "../pages/InputPageSection2Page";
import InputSection3Page from "../pages/InputPageSection3Page";
import InputSection4Page from "../pages/InputPageSection4Page";
import InputSection5Page from "../pages/InputPageSection5Page";
import UserLoginPage from "../pages/UserLoginPage"
import UserSignupPage from "../pages/UserSignupPage"
import UserDashboardPage from "../pages/UserDashboardPage"
import GuestDashboardPage from "../pages/GuestDashboardPage"
import AdminLoginPage from "../pages/AdminLoginPage"
import AdminDashboardPage from "../pages/AdminDashboardPage"
import ExperimentPage from "../pages/ExperimentPage"
import CreateProjectFormPage from "../pages/CreateProjectFormPage";
import OpenProjectFormPage from "../pages/OpenProjectFormPage";
import HelpPage from "../pages/HelpPage";
import Header from "../components/Header"
import { CssBaseline, createMuiTheme } from "@material-ui/core";

// Use this link to create Mui themes

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#333996",
      light: '#3c44b126'
    },
    secondary: {
      main: "#f83245",
      light: '#f8324526'
    },
    background: {
      default: "#f4f5fd"
    },
  },
  overrides:{
    MuiAppBar:{
      root:{
        transform:'translateZ(0)'
      }
    }
  },
  props:{
    MuiIconButton:{
      disableRipple:true
    }
  }
})


function App () {
    return (
      // <Provider store={store}>
        <React.Fragment>
        <div className="App">
              <Header/>
            <Switch>
              <Route path="/" exact component={LandingPage} />
              <Route path="/userLogin" component={UserLoginPage} />
              <Route path="/userSignup" component={UserSignupPage} />              
              <Route path="/userDashboard" component={UserDashboardPage} />
              <Route path="/guestDashboardPage" component={GuestDashboardPage}/>
              <Route path='/openProjectFormPage' component={OpenProjectFormPage}/>
              <Route path="/createProjectFormPage" component={CreateProjectFormPage}/>
              <Route path="/projectDashboard" component={ProjectDashboard} />
              <Route path= "/helpPage" component={HelpPage}/>
              
              <Route path="/inputPageSection1" component={InputSection1Page}/>
              <Route path="/inputPageSection2" component={InputSection2Page} />
              <Route path="/inputPageSection3" component={InputSection3Page} />
              <Route path="/inputPageSection4" component={InputSection4Page} />
              <Route path="/inputPageSection5" component={InputSection5Page} />
              <Route path="/displayResultsPage"component={DisplayResultsPage}/>
              <Route path="/admin"component={AdminLoginPage}/>
              <Route path="/experimentPage"component={ExperimentPage}/>
              <Route path="/adminDashboard"component={AdminDashboardPage}/>
            </Switch>
        </div>
        <CssBaseline/>
        </React.Fragment>
      // </Provider>
    );
}

export default App;
