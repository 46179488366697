const initialState = {
  loginState: false,
  message: "",
  selectedComponent: "1",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "ADMIN_LOG_IN": {
      return { ...state, ...action.payload };
    }
    case "ADMIN_LOG_IN_START": {
      state.loginState = "true";
      return { ...state };
    }
    case "ADMIN_LOG_OUT": {
      return { initialState };
    }
    case "ADMIN_SELECTED_COMPONENT": {
      return { ...state, selectedComponent: action.payload };
    }
    case "CLEAR_ADMIN_LOGIN_REDUCER_MESSAGE": {
      state.message = "";
      return { ...state };
    }
    case "LOG_OUT": {
      return initialState;
    }
    default:
      return state;
  }
}
