const initialState = {};

export default function createProjectReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case "CREATE_PROJECT": {
      return { ...state, ...action.payload };
    }
    case "CLEAR_PROJECT_SIGNUP_REDUCER": {
      return initialState;
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
