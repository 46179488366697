import "../material.css";
import { selectedLineId } from "../actions/contentActions";
import AddLineIcon from "../components/icons/AddLineIcon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData, getLines } from "../actions/lineActions";
import { useHistory } from "react-router-dom";
import { getSelectedLine } from "../actions/lineActions";
import useTable from "../components/useTable";
import SelectLanguage from "../components/SelectLanguage";
import Popup from "../components/Popup";
import RenameLineForm from "../components/forms/RenameLineForm";
import DeleteLineForm from "../components/forms/DeleteLineForm";
import DisplayResutlsErrorForm from "../components/forms/DisplayResultsErrorForm";
import TokenExpiredForm from "../components/forms/TokenExpiredForm";
import LineConfigurationInfoForm from "../components/forms/LineConfigurationInfoForm";
import SelectCountryForm from "../components/forms/SelectCountryForm";
import CreateLineProjectDashboardForm from "../components/forms/CreateLineProjectDashboardForm";
import Controls from "../components/controls/Controls";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { deleteLine } from "../actions/lineActions";
import Tooltip from "@material-ui/core/Tooltip";
import SelectCountry from "../components/SelectCountry";
import {
  renameLinePopup,
  deleteLinePopup,
  createLinePopup,
  displayResultsError,
  lineConfigurationInfoPopup
} from "../actions/popupActions";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Grid,
  Typography,
  Link,
  CssBaseline,
  Container,
  Button,
} from "@material-ui/core";
import { pageContent } from "../lang/pageContent";



// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       margin: theme.spacing(1),
//       width: "11ch",
//     },
//   },
// }));

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  button: {
    // color:"#ffffff",
    maxWidth: "200px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "#1a9fe0",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
  resultsButton : {
    width: "250px",
    height: "50px",
    borderRadius: 35,
    backgroundColor: "#1a9fe0",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  }
}));

export default function ProjectDashboard() {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();

  const project = useSelector(
    (reduxState) => reduxState.projectLoginReducer.project
  );

  useEffect(() => {
    dispatch(getLines());
  }, []);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const projectLoginReducer = useSelector(
    (reduxState) => reduxState.projectLoginReducer
  )

  const inputLabels = useSelector(
    (reduxState) => reduxState.inputLabelsReducer
  );

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const deletePopupState = useSelector(
    (reduxState) => reduxState.popupReducer.deleteLine
  );

  // const referenceNumber = useSelector(
  //   (reduxState) => reduxState.projectLoginReducer.project.referenceNumber
  // );

  const [openPopup, setOpenPopup] = useState(popupState);
  const [openDeleteLinePopup, setOpenDeleteLinePopup] = useState(
    deletePopupState
  );

  const allLines = useSelector((reduxState) => reduxState.lineReducer.data);

  const newLine = useSelector((reduxState) => reduxState.newLineReducer);

  const [state, setState] = useState({
    data: newLine,
  });

  const records = allLines;

  console.log("records", records);

  const headCells = [
    { id: "lineName", label: pageContent[`${selectedLang}`].lineName },
    { id: "lineModule", label: pageContent[`${selectedLang}`].baseModule  },
    { id: "lineStatus", label: pageContent[`${selectedLang}`].lineStatus  },
    { id: "configure", label: pageContent[`${selectedLang}`].configureLine},
    { id: "actions", label: pageContent[`${selectedLang}`].lineActions },
  ];

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells);

  console.log("records", records);
  console.log("allLines", allLines);

  const displayResultsPass = false;

function checkLineStatus() {
    const result = allLines.filter((line) => line.lineStatus === "true");
    if(allLines.length <1){
      dispatch(displayResultsError({ status: true }));
    }else
    if (result.length == allLines.length) {
      dispatch(getData ((response) => {
        if(response.message === "Success"){
          history.push("/displayResultsPage");
        }
      }))
    } else {
      dispatch(displayResultsError({ status: true }));
    }
  }

  return (
    <React.Fragment>
      {/* <div> */}
      <CssBaseline />
      {!projectLoginReducer.jwt && history.push("/")}
      <Container maxWidth="sm" className={"projectDashboard"}>
        <Grid container direction={"column"} maxWidth="xs" spacing={2}>
          <Grid
            item
            style={{
              position: "absolute",
              left: "10%",
              top: "10%",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              // inputProps={{ style: { fontSize: 300 } }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].trunkingConfigurator}
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].projectDashboard}
            </Typography>
            <br></br>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 }}
              // inputProps={{ style: { fontSize: 300 } }}
              gutterBottom
            >
              {pageContent[`${selectedLang}`].referenceNumber}: {project.referenceNumber}
              <br></br>
              <br></br>
              {pageContent[`${selectedLang}`].projectName}: {project.projectName}
              <br></br>
              <br></br>
              <SelectCountry/>
            </Typography>
            <br></br>
            <Typography variant="body2">
              <Link
                onClick={() => history.push("/helpPage")}
                style={{
                  color: "#ffffff",
                  fontWeight: 350,
                  fontSize: "15px",
                  cursor : "pointer"
                }}
              >
                {"How to use Create Project Form"}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <header></header>
      {/* <SelectLanguage /> */}
      <Paper className={classes.pageContent} elevation={0}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting() &&
              recordsAfterPagingAndSorting().map((line) => (
                <TableRow key={line.id}>
                  <TableCell>{line.name}</TableCell>
                  <TableCell width="20%">{line.baseTrunkingModule}</TableCell>
                  <TableCell width="20%">{(line.lineStatus == "true" && pageContent[`${selectedLang}`].complete || pageContent[`${selectedLang}`].incomplete )}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      onClick={() => {
                        dispatch(selectedLineId(line.id));
                        dispatch(getSelectedLine(line.id));
                        // history.push("/lineConfigurationInfoPage");
                        dispatch(
                          lineConfigurationInfoPopup({ status: true, lineId: line.id })
                        );
                      }}
                    >
                      {pageContent[`${selectedLang}`].configure}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Controls.ActionButton color="primary">
                      <Tooltip title={pageContent[`${selectedLang}`].renameLine}>
                        <EditIcon
                          fontSize="small"
                          onClick={() => {
                            dispatch(
                              renameLinePopup({ status: true, lineId: line.id })
                            );
                          }}
                        />
                      </Tooltip>
                    </Controls.ActionButton>

                    <Controls.ActionButton color="secondary">
                      <Tooltip title={pageContent[`${selectedLang}`].deleteLine}>
                        <DeleteIcon
                          fontSize="small"
                          onClick={() => {
                            dispatch(
                              deleteLinePopup({ status: true, lineId: line.id })
                            );
                          }}
                        />
                      </Tooltip>
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <Grid
          container
          alignItems="center"
          direction="row"
          justify="center"
          spacing={1}
        >
          <Grid xs={4} spacing={3}></Grid>

          <Grid item xs={4} spacing={3}>
            <AddLineIcon/>
          </Grid>

          <Grid item xs={4} spacing={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.resultsButton}
              onClick={() => checkLineStatus()}
            >
              {pageContent[`${selectedLang}`].displayResults}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <br></br>
      <Popup
        openPopup={popupState.renameLine}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].enterNewLineName}
      >
        <RenameLineForm />
      </Popup>
      <Popup
        openPopup={popupState.deleteLine}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].deleteLineConfirm}
      >
        <DeleteLineForm />
      </Popup>

      <Popup
        openPopup={popupState.displayResultsError}
        setOpenPopup={setOpenPopup}
        title="Not Allowed!"
      >
        <DisplayResutlsErrorForm />
      </Popup>
      <Popup
        openPopup={popupState.lineConfigurationInfo}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].lineConfigurationInfo}
      >
        <LineConfigurationInfoForm />
      </Popup>
      <Popup
        openPopup={popupState.selectCountry}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].pleaseSelectACountry}
      >
        <SelectCountryForm />
      </Popup>
      <Popup
        openPopup={popupState.createLine}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].enterNewLineName}
      >
        <CreateLineProjectDashboardForm />
      </Popup>
      <Popup
        openPopup={popupState.tokenExpired}
        setOpenPopup={setOpenPopup}
        title={pageContent[`${selectedLang}`].attention}
      >
        <TokenExpiredForm />
      </Popup>
    </React.Fragment>
  );
}
