import React from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../material.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "@material-ui/core";
import { pageContent } from "../lang/pageContent";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": { textDecorationThickness: "3px" },
  },
  endIcon: {
    fontSize: 12,
  },
  button: {
    // color:"#ffffff",
    // margin: theme.spacing(3, 0, 2),

    // width: "300px",
    width:"20em",
    height: "50px",
    borderRadius: 35,
    backgroundColor: "#1a9fe0",
    justifyContent: "space-between",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "20px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
}));

export default function LandingPage() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  // Use the following console logs to check the content
  // pageContent.selectedLang.loginAsUser
  // console.log("page content is", pageContent);
  // console.log("selectedlanguage is", selectedLang);

  // console.log('selected language is and content is', pageContent[`${selectedLang}`])
  // dispatch(getInputLabels());
  // dispatch(getInputLabelOptions());

  const handleClick = () => {
    history.push("/userLogin");
  };

  const handleClick2 = () => {
    history.push("/userSignup");
  };

  const handleClick3 = () => {
    history.push("/guestDashboardPage");
  };

  const handleClick4 = (event) => {
    history.push("/helpPage");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxwidth="lg" className="Landing" direction="column" style={{minHeight: "100vh"}}>
        <div
          style={{
            position: "absolute",
            left: "10%",
            top: "20%",
          }}
        >
          <Typography
            variant="h1"
            style={{ fontWeight: 600, color: "#ffffff", lineHeight: 1 , width : "10%"}}
            inputprops={{ style: { fontSize: 300 } }}
            gutterBottom
          >
            {pageContent[`${selectedLang}`].trunkingConfigurator}
          </Typography>
          
          <br></br>
          <br></br>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={handleClick}
          >
            {pageContent[`${selectedLang}`].loginAsUser}{" "}
            <ArrowForwardIosIcon className={classes.endIcon} />
          </Button>
          <br></br>
          <br></br>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            onClick={handleClick2}
          >
            {pageContent[`${selectedLang}`].signup}
            <ArrowForwardIosIcon className={classes.endIcon} />
          </Button>
          <br></br>
          <br></br>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            // styles={{padding: "200px"}}
            onClick={handleClick3}
          >
            {pageContent[`${selectedLang}`].continueAsGuest}
            <ArrowForwardIosIcon className={classes.endIcon} />
          </Button>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Typography variant="body2">
            <Link
              // href="/helpPage"
              onClick={handleClick4}
              // underline="always"
              style={{
                color: "#ffffff",
                fontWeight: 200,
                fontSize: "20px",
                cursor : "pointer"
              }}
            >
              {pageContent[`${selectedLang}`].howToUseTheConfigurator}
            </Link>
          </Typography>
        </div>
      </Container>
    </React.Fragment>
  );
}
