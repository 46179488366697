import { GET_SORTED_DATA } from "../actions/lineActions";
const initialState = {
  sortedData: {
    rows: [],
    emRows: [],
    componentRows: [],
    lineRows: [],
  },
};

export default function displayResultsReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case GET_SORTED_DATA: {
      state.sortedData = action.payload.sortedData;
      return { ...state, ...action.payload };
    }
    case "LOG_OUT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
