export const inputLabels = {
  english: {
    landingPageFieldOne: "Please pick your language",
    landingPageFieldTwo: "Please select no. of lines to configure",
    fieldOne: "1.What is the length of this line (m)?",
    fieldTwo: "2.Please select the preferred length of trunks (m)",
    fieldTwoAdvice: "Our experts advice 3m trunks",
    fieldThree: "3.How many lines do you want, based on these configuration setings?",
    fieldFour: "4.Please select a Trunking LED Module",
    fieldFourAdvice: "(Sensors are only for Dali)",
    fieldFive: "5.Do you already have a DALI Power Supply for this line?",
    fieldFiveAdvice: "Please select DALI Mounting Method to make a selection here",
    fieldSix: "6.Do you require integrated emergency lighting in this line?",
    fieldSeven: "7.What module allocation do you want to use?",
    fieldEight: "8.Please enter the custom number of modules",
    fieldNine: "9.Do you want to employ 1 hour or 3 hour Emergency Modules?",
    fieldTen: "10.How many Emergency Modules are needed in the line?",
    fieldEleven: "11.Please enter the number of Emergency Modules",
    fieldTwelve: "12.Do you want to use a Feed-In-Box or work with a 230V-connection from above?",
    fieldThirteen: "13.Do you require a Feed-Out-Box?",
    fieldFourteen: "14.Does the line start or end with a blind cover?",
    fieldFourteenAdvice: "Option available only for No Feed-Out-Box",
    fieldFifteen: "15.Please select a mounting method",
    fieldSixteen: "16.How far apart should the mounting points be (m)?",
    fieldSixteenAdvice: "(3m default,minimum 1m upto 2 decimals)",
    fieldSeventeen: "17.Do you want motion detection and/or daylight control?",
    fieldEighteen: "18.At what height (m) will this line be installed?",
    fieldNineteen: "19.Which sensor allocation do you want to use?",
    fieldTwenty: "20.Are any additional products required for this line?",
    fieldTwentyOne: "21.How many additional products are required for this line?",
    fieldTwentyTwo: "22.Which sensor sensitivity do you want to employ?",
    fieldTwentyThree: "23.Please enter a custom amount of sensors",
  },
  dutch: {
    landingPageFieldOne: "Selecteer uw taal",
    landingPageFieldTwo: "Selecteer het aantal lichtlijnen dat u wilt configureren",
    fieldOne: "Hoe lang is deze lichtlijn (m)?",
    fieldTwo: "Selecteer de gewenste lengte van de lichtlijn (m)",
    fieldTwoAdvice: "Our experts advice 3m trunks",
    fieldThree: "Hoeveel lichtlijnen wilt u, op basis van deze configuratie-instellingen?",
    fieldFour: "Kies een LED-lichtlijnmodule",
    fieldFourAdvice: "(Sensors are only for Dali)",
    fieldFive: "Heeft u al een DALI-voeding voor deze lichtlijn?",
    fieldFiveAdvice: "Please select DALI Mounting Method to make a selection here",
    fieldSix: "Heeft u geïntegreerde noodverlichting nodig voor deze lijn?",
    fieldSeven: "Welke modulebezetting wilt u gebruiken?",
    fieldEight: "Voer de aangepaste hoeveelheid modules in",
    fieldNine: "Wilt u noodverlichting met 1 of 3 uur gebruiken?",
    fieldTen: "Hoeveel noodverlichtingsmodules zijn er nodig per lichtlijn?",
    fieldEleven: "Voer het aantal noodverlichtingsmodules in",
    fieldTwelve: "Wilt u een invoerdoos gebruiken of werken met een 230V-aansluiting van boven?",
    fieldThirteen: "Heeft u een uitvoerdoos nodig?",
    fieldFourteen: "Begint of eindigt de lichtlijn met een blinde afdekking?",
    fieldFourteenAdvice: "Option available only for No Feed-Out-Box",
    fieldFifteen: "Selecteer een montagemethode",
    fieldSixteen: "Hoeveel ruimte moet er tussen de montagepunten zitten?",
    fieldSixteenAdvice: "(3m default,minimum 1m upto 2 decimals)",
    fieldSeventeen: "Wilt u bewegingsdetectie en/of daglichtregeling?",
    fieldEighteen: "Op welke hoogte (m) wordt deze lijn geïnstalleerd? ",
    fieldNineteen: "Welke sensorbezetting wilt u gebruiken?",
    fieldTwenty: "Zijn er aanvullende producten nodig voor deze lijn?",
    fieldTwentyOne: "Hoeveel aanvullende producten zijn er nodig voor deze lijn?",
    fieldTwentyTwo: "Welke sensorgevoeligheid wilt u gebruiken?",
    fieldTwentyThree: "Voer het aantal sensoren in",
  },
  french: {
    landingPageFieldOne: "Sélectionnez votre langue",
    landingPageFieldTwo: "Sélectionnez le nombre de lignes à configurer",
    fieldOne: "Quelle est la longueur de cette ligne lumineuse (m)?",
    fieldTwo: "Sélectionnez la longueur de rail souhaitée (m)",
    fieldTwoAdvice: "Our experts advice 3m trunks",
    fieldThree: "Sur la base de ces paramètres de configuration, combien voulez-vous de lignes lumineuses?",
    fieldFour: "Choisissez un module de ligne lumineuse LED",
    fieldFourAdvice: "(Sensors are only for Dali)",
    fieldFive: "Avez-vous déjà une alimentation DALI pour cette ligne lumineuse?",
    fieldFiveAdvice: "Please select DALI Mounting Method to make a selection here",
    fieldSix: "Avez-vous besoin d’un éclairage de secours intégré pour cette ligne lumineuse?",
    fieldSeven: "Quelle affectation de module souhaitez-vous utiliser?",
    fieldEight: "Indiquez le nombre de modules personnalisés",
    fieldNine: "Voulez-vous utiliser un éclairage de secours d’une ou trois heures?",
    fieldTen: "Combien de modules d’éclairage d’urgence sont nécessaires par ligne?",
    fieldEleven: "Indiquez le nombre de modules d’éclairage de secours.",
    fieldTwelve: "Voulez-vous utiliser une boîte d’entrée ou travailler avec un raccordement 230 V par le haut?",
    fieldThirteen: "Avez-vous besoin d’une boîte de sortie?",
    fieldFourteen: "La ligne commence-t-elle ou se termine-t-elle avec un cache d’obturation?",
    fieldFourteenAdvice: "Option available only for No Feed-Out-Box",
    fieldFifteen: "Sélectionnez la méthode de montage",
    fieldSixteen: "Quelle doit être la distance entre les points de montage?",
    fieldSixteenAdvice: "(3m default,minimum 1m upto 2 decimals)",
    fieldSeventeen: "Voulez-vous une détection de mouvement et/ou un réglage en fonction de la lumière du jour?",
    fieldEighteen: "À quelle hauteur (m) sera installée cette ligne? ",
    fieldNineteen: "Quelle affectation de capteurs souhaitez-vous utiliser?",
    fieldTwenty: "Des produits supplémentaires sont-ils nécessaires pour cette ligne?",
    fieldTwentyOne: "Combien de produits supplémentaires sont-ils nécessaires pour cette ligne?",
    fieldTwentyTwo: "Quelle sensibilité des capteurs souhaitez-vous utiliser?",
    fieldTwentyThree: "Saisissez le nombre voulu de détecteurs",
  },
  german: {
    landingPageFieldOne: "Sélectionnez votre langue",
    landingPageFieldTwo: "Sélectionnez le nombre de lignes à configurer",
    fieldOne: "Wie lang ist das Lichtband (m)?",
    fieldTwo: "Bitte wählen Sie die gewünschte Länge der Tragschiene (m)",
    fieldTwoAdvice: "Our experts advice 3m trunks",
    fieldThree: "Wie viele Lichtbänder wünschen Sie, basierend auf diesen Konfigurationseinstellungen?",
    fieldFour: "Bitte wählen Sie ein Lichtband-LED-Modul aus.",
    fieldFourAdvice: "(Sensors are only for Dali)",
    fieldFive: "Besitzen Sie bereits ein DALI-Netzteil?",
    fieldFiveAdvice: "Please select DALI Mounting Method to make a selection here",
    fieldSix: "Benötigen Sie eine integrierte Notbeleuchtung für das Lichtband?",
    fieldSeven: "Welche Modulbelegung möchten Sie nutzen?",
    fieldEight: "Bitte geben Sie eine benutzerdefinierte Anzahl an Modulen ein.",
    fieldNine: "Möchten Sie die Notbeleuchtung mit 1 oder 3 Stunden?",
    fieldTen: "Wie viele Notbeleuchtungsmodule werden pro Lichtband benötigt?",
    fieldEleven: "Bitte geben Sie die Anzahl der Notbeleuchtungsmodule ein.",
    fieldTwelve: "Wollen Sie eine Einspeisebox oder einen 230V-Anschluß von oben verwenden?",
    fieldThirteen: "Benötigen Sie eine Ausspeise Box (Feed-Out-Box)?",
    fieldFourteen: "Beginnt oder endet das Lichtband mit einer Blindabdeckung?",
    fieldFourteenAdvice: "Option available only for No Feed-Out-Box",
    fieldFifteen: "Montageart wählen",
    fieldSixteen: "Wie groß soll der Abstand zwischen den Montagepunkten sein?",
    fieldSixteenAdvice: "(3m default,minimum 1m upto 2 decimals)",
    fieldSeventeen: "Möchten Sie einen Bewegungsmelder und/oder einen Tageslichtsensor?",
    fieldEighteen: "In welcher Höhe (m) soll das Lichtband installiert werden?",
    fieldNineteen: "Welche Sensorbelegung möchten Sie nutzen?",
    fieldTwenty: "Werden für das Lichtband zusätzliche Produkte benötigt?",
    fieldTwentyOne: "Wie viele zusätzliche Produkte werden für das Lichtband benötigt?",
    fieldTwentyTwo: "Welche Sensor-Empfindlichkeit möchten Sie nutzen?",
    fieldTwentyThree: "Benutzerdefinierte Anzahl von Sensoren eingeben",
  },
  spanish: {
    landingPageFieldOne: "Seleccione su idioma",
    landingPageFieldTwo: "Seleccione el número de líneas que quiere configurar",
    fieldOne: "¿Cuánto mide esta línea (m)?",
    fieldTwo: "Seleccione la longitud deseada del trunking (m)",
    fieldTwoAdvice: "Our experts advice 3m trunks",
    fieldThree: "¿Cuántas líneas quiere, en base a esta configuración?",
    fieldFour: "Elija un Módulo LED troncal",
    fieldFourAdvice: "(Sensors are only for Dali)",
    fieldFive: "¿Cuenta ya con un suministro eléctrico DALI para esta línea?",
    fieldFiveAdvice: "Please select DALI Mounting Method to make a selection here",
    fieldSix: "¿Necesita iluminación integrada de emergencia en esta línea?",
    fieldSeven: "¿Qué asignación de módulo quiere usar?",
    fieldEight: "Escriba el número personalizado de módulos",
    fieldNine: "¿Quiere tener módulos de emergencia de 1 hora o 3?",
    fieldTen: "¿Cuántos módulos de iluminación de emergencia necesita por línea?",
    fieldEleven:"Escriba el número de módulos de emergencia",
    fieldTwelve: "¿Quiere usar una caja reguladora o una conexión superior de 230 V?",
    fieldThirteen: "¿Necesita una caja de alimentación?",
    fieldFourteen: "¿La línea empieza o termina con una tapa ciega?",
    fieldFourteenAdvice: "Option available only for No Feed-Out-Box",
    fieldFifteen: "Seleccione el método de montaje",
    fieldSixteen: "¿A qué distancia tienen que ponerse los puntos de montaje entre ellos?",
    fieldSixteenAdvice: "(3m default,minimum 1m upto 2 decimals)",
    fieldSeventeen: "¿Quiere detección de movimiento y/o control de luz diurna?",
    fieldEighteen: "¿A qué altura (m) se instalará esta línea?",
    fieldNineteen: "¿Qué asignación de sensores quiere usar?",
    fieldTwenty:"¿Hacen falta otros productos adicionales para esta línea?",
    fieldTwentyOne: "¿Cuántos productos adicionales hacen falta para esta línea?",
    fieldTwentyTwo: "¿Qué sensibilidad de sensores quiere usar?",
    fieldTwentyThree: "Escriba el número deseado de sensores",
  },
  portugese: {
    landingPageFieldOne: "Selecione o seu idioma",
    landingPageFieldTwo: "Selecione o número de linhas que pretende configurar",
    fieldOne: "Qual é o comprimento desta linha (m)?",
    fieldTwo: "Selecione o comprimento preferencial da junção (m)",
    fieldTwoAdvice: "Our experts advice 3m trunks",
    fieldThree: "Quantas linhas pretende com base nestas configurações?",
    fieldFour: "Selecione um módulo LED de junção (Trunking)",
    fieldFourAdvice: "(Sensors are only for Dali)",
    fieldFive: "Já tem uma fonte de alimentação DALI para esta linha?",
    fieldFiveAdvice: "Please select DALI Mounting Method to make a selection here",
    fieldSix: "Pretende iluminação de emergência integrada nesta linha?",
    fieldSeven: "Qual a ocupação do módulo que pretende utilizar?",
    fieldEight: "Introduza o número personalizado de módulos",
    fieldNine: "Pretende utilizar módulos de emergência de 1 hora ou 3 horas?",
    fieldTen: "Quantos módulos de iluminação de emergência são necessários por linha?",
    fieldEleven:"Introduza o número de módulos de emergência",
    fieldTwelve: "Pretende utilizar uma caixa de entrada (feed-in) ou trabalhar com uma ligação de 230 V pela parte de cima?",
    fieldThirteen: "Pretende uma caixa de saída (feed-out)?",
    fieldFourteen: "A linha começa ou termina com uma tampa cega?",
    fieldFourteenAdvice: "Option available only for No Feed-Out-Box",
    fieldFifteen: "Selecione o método de montagem",
    fieldSixteen: "A que distância devem estar os pontos de montagem?",
    fieldSixteenAdvice: "(3m default,minimum 1m upto 2 decimals)",
    fieldSeventeen: "Pretende dispor de deteção de movimento e/ou o controlo de luz diurna?",
    fieldEighteen: "A que altura (m) será instalada esta linha?",
    fieldNineteen: "Qual a ocupação do sensor que pretende utilizar?",
    fieldTwenty:"São necessários produtos adicionais para esta linha?",
    fieldTwentyOne: "Quantos produtos adicionais são necessários para esta linha?",
    fieldTwentyTwo: "Que tipo de sensibilidade de sensor pretende utilizar?",
    fieldTwentyThree: "Introduza a quantidade personalizada de sensores",
  },
  finnish: {
    landingPageFieldOne: "Valitse kieli",
    landingPageFieldTwo: "Valitse määritettävien rivien määrä",
    fieldOne: "Mikä on tämän linjan pituus (m)?",
    fieldTwo: "Valitse haluamasi kanavan pituus (m)",
    fieldTwoAdvice: "Our experts advice 3m trunks",
    fieldThree: "Kuinka monta linjaa haluat näiden kokoonpanoasetusten perusteella?",
    fieldFour: "Valitse kanavan LED-moduuli",
    fieldFourAdvice: "(Sensors are only for Dali)",
    fieldFive: "Onko sinulla jo DALI-virtalähde tälle linjalle?",
    fieldFiveAdvice: "Please select DALI Mounting Method to make a selection here",
    fieldSix: "Tarvitsetko integroidun hätävalaistuksen tällä linjalla?",
    fieldSeven: "Mitä moduulin allokointia haluat käyttää?",
    fieldEight: "Anna mukautettu moduulien määrä",
    fieldNine: "Haluatko käyttää 1 tai 3 tunnin hätämoduuleja?",
    fieldTen: "Kuinka monta hätävalaistusmoduulia tarvitaan riviä kohden?",
    fieldEleven:"Anna hätämoduulien määrä",
    fieldTwelve: "Haluatko käyttää syöttölaatikkoa tai työskennellä yläpuolisen 230 V liitännän kanssa?",
    fieldThirteen: "Tarvitsetko syöttölaatikkoa?",
    fieldFourteen: "Alkaako linja vai päättyykö umpilevyllä?",
    fieldFourteenAdvice: "Option available only for No Feed-Out-Box",
    fieldFifteen: "Valitse asennustapa",
    fieldSixteen: "Kuinka kaukana toisistaan ​​kiinnityskohtien tulisi olla?",
    fieldSixteenAdvice: "(3m default,minimum 1m upto 2 decimals)",
    fieldSeventeen: "Haluatko liiketunnistuksen ja/tai päivänvalon hallinnan?",
    fieldEighteen: "Mihin korkeuteen (m) tämä linja asennetaan?",
    fieldNineteen: "Mitä anturin allokointia haluat käyttää?",
    fieldTwenty:"Tarvitaanko tälle linjalle muita tuotteita?",
    fieldTwentyOne: "Kuinka monta uutta tuotetta tarvitaan tälle linjalle?",
    fieldTwentyTwo: "Millaista anturin herkkyyttä haluat käyttää?",
    fieldTwentyThree: "Anna mukautettu määrä antureita",
  },
  polish: {
    landingPageFieldOne: "Proszę wybrać język",
    landingPageFieldTwo: "Proszę wybrać liczbę linii, jaka ma być skonfigurowana",
    fieldOne: "Jaka jest długość tej linii (m)?",
    fieldTwo: "Proszę wybrać żądaną długość linii (m)",
    fieldTwoAdvice: "Our experts advice 3m trunks",
    fieldThree: "Ile linii chcesz mieć na podstawie tych ustawień konfiguracyjnych?",
    fieldFour: "Proszę wybrać moduł świetlny LED",
    fieldFourAdvice: "(Sensors are only for Dali)",
    fieldFive: "Czy masz już zasilanie DALI do tej linii?",
    fieldFiveAdvice: "Please select DALI Mounting Method to make a selection here",
    fieldSix: "Czy na tej linii wymagane jest zintegrowane oświetlenie alarmowe?",
    fieldSeven: "Jaki moduł zajętości chcesz stosować?",
    fieldEight: "Proszę podać wybraną liczbę modułów",
    fieldNine: "Czy chcesz mieć oświetlenie awaryjne na 1 godzinę czy na 3 godziny?",
    fieldTen: "Ile modułów oświetlenia awaryjnego jest potrzebnych na każdą linię?",
    fieldEleven:"Proszę podać liczbę modułów awaryjnych",
    fieldTwelve: "Czy chcesz używać Feed-In-Box czy korzystać z połączenia 230 V z góry?",
    fieldThirteen: "Czy potrzebny jest Feed-Out-Box?",
    fieldFourteen: "Czy linia zaczyna się albo kończy zaślepką?",
    fieldFourteenAdvice: "Option available only for No Feed-Out-Box",
    fieldFifteen: "Proszę wybrać metodę połączenia i mocowania",
    fieldSixteen: "W jakim odstępie powinny się znajdować punkty mocowania?",
    fieldSixteenAdvice: "(3m default,minimum 1m upto 2 decimals)",
    fieldSeventeen: "Czy chcą Państwo mieć czujnik ruchu i/lub sterowanie uzależnione od ilości światła dziennego?",
    fieldEighteen: "Na jakiej wysokości (m) ma być zainstalowana ta linia?",
    fieldNineteen: "Jaką zajętość czujnika chcesz stosować?",
    fieldTwenty:"Czy do tej linii wymagane są dodatkowe produkty?",
    fieldTwentyOne: "Ile dodatkowych produktów jest wymaganych do tej linii?",
    fieldTwentyTwo: "Jaką czułość czujnika chcesz stosować?",
    fieldTwentyThree: "Proszę podać wybraną liczbę czujników",
  },
  italian: {
    landingPageFieldOne: "Seleziona la tua lingua",
    landingPageFieldTwo: "Seleziona il numero di linee che vuoi configurare",
    fieldOne: "Qual è la lunghezza di questo binario (m)?",
    fieldTwo: "Si prega di selezionare la lunghezza prescelta della canalina (m)",
    fieldTwoAdvice: "Our experts advice 3m trunks",
    fieldThree: "Quanti binari richiedi, in base a queste impostazioni di configurazione?",
    fieldFour: "Seleziona un modulo LED per il sistema a binario",
    fieldFourAdvice: "(Sensors are only for Dali)",
    fieldFive: "Disponi già dell'alimentatore DALI per questo binario?",
    fieldFiveAdvice: "Please select DALI Mounting Method to make a selection here",
    fieldSix: "È necessaria l'illuminazione d'emergenza integrata in questo binario?",
    fieldSeven: "Quale assegnazione del modulo vuoi utilizzare?",
    fieldEight: "Inserisci il numero specifico di moduli",
    fieldNine: "Vuoi utilizzare moduli d'emergenza per 1 ora o 3 ore?",
    fieldTen: "Quanti moduli per l'illuminazione di emergenza sono necessari per linea?",
    fieldEleven:"Inserisci il numero di moduli d'emergenza",
    fieldTwelve: "Vuoi utilizzare una scatola di alimentazione Feed-In-Box o una connessione a 230V dall'alto?",
    fieldThirteen: "È necessaria una scatola di alimentazione Feed-Out-Box?",
    fieldFourteen: "La linea inizia o finisce con un modulo cieco?",
    fieldFourteenAdvice: "Option available only for No Feed-Out-Box",
    fieldFifteen: "Selezionare il metodo di montaggio",
    fieldSixteen: "Quanto devono essere distanti i punti di montaggio?",
    fieldSixteenAdvice: "(3m default,minimum 1m upto 2 decimals)",
    fieldSeventeen: "Desideri il rilevamento del movimento e/o il controllo della luce diurna?",
    fieldEighteen: "A quale altezza(m) verrà installato questo binario?",
    fieldNineteen: "Quale assegnazione del sensore vuoi utilizzare?",
    fieldTwenty:"Sono necessari altri prodotti per questo binario?",
    fieldTwentyOne: "Quanti altri prodotti sono necessari per questo binario?",
    fieldTwentyTwo: "Quale livello di sensibilità del sensore desideri impostare?",
    fieldTwentyThree: "Inserire la quantità personalizzata di sensori",
  },
};
