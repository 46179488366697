export const inputLabelOptions = {
    "english" :{
        FieldTwoOptionOne: "1.5m",
        FieldTwoOptionTwo: "3m",
        // non dali products listed as options
        FieldFourOptionOne: "LEDTrunking Module L15-35W-4000-120",
        FieldFourOptionOneCode: "542005008200",
        FieldFourOptionTwo: "LEDTrunking Module L15-50W-4000-60",
        FieldFourOptionTwoCode:"542005006000",
        FieldFourOptionThree: "LEDTrunking Module L15-50W-4000-90",
        FieldFourOptionThreeCode:"542005005900",
        FieldFourOptionFour: "LEDTrunking Module L15-50W-4000-120",
        FieldFourOptionFourCode:"542005005800",
        FieldFourOptionFive: "LEDTrunking Module L15-50W-4000-BW",
        FieldFourOptionFiveCode:"542005006100",
        FieldFourOptionSix: "LEDTrunking Module L15-70W-4000-60",
        FieldFourOptionSixCode:"542005006400",
        FieldFourOptionSeven: "LEDTrunking Module L15-70W-4000-90",
        FieldFourOptionSevenCode:"542005006300",
        FieldFourOptionEight: "LEDTrunking Module L15-70W-4000-120",
        FieldFourOptionEightCode:"542005006200",
        FieldFourOptionNine: "LEDTrunking Module L15-70W-4000-BW",
        FieldFourOptionNineCode:"542005006500",
        // dali products listed as options
        FieldFourOptionNineteen : "LEDTrunking Mod L15-35W-4000-DF-DALI-DZ",
        FieldFourOptionNineteenCode: "534001000100",
        FieldFourOptionTen: "LEDTrunking Module L15-35W-4000-120-DALI",
        FieldFourOptionTenCode:"542005008600",
        FieldFourOptionEleven: "LEDTrunking Module L15-50W-4000-60-DALI",
        FieldFourOptionElevenCode:"542005006800",
        FieldFourOptionTwelve: "LEDTrunking Module L15-50W-4000-90-DALI",
        FieldFourOptionTwelveCode:"542005006700",
        FieldFourOptionThirteen: "LEDTrunking Module L15-50W-4000-120-DALI",
        FieldFourOptionThirteenCode:"542005006600",
        FieldFourOptionFourteen: "LEDTrunking Module L15-50W-4000-BW-DALI",
        FieldFourOptionFourteenCode:"542005006900",
        FieldFourOptionFifteen: "LEDTrunking Module L15-70W-4000-60-DALI",
        FieldFourOptionFifteenCode:"542005007200",
        FieldFourOptionSixteen: "LEDTrunking Module L15-70W-4000-90-DALI",
        FieldFourOptionSixteenCode:"542005007100",
        FieldFourOptionSeventeen: "LEDTrunking Module L15-70W-4000-120-DALI",
        FieldFourOptionSeventeenCode:"542005007000",
        FieldFourOptionEighteen: "LEDTrunking Module L15-70W-4000-BW-DALI",
        FieldFourOptionEighteenCode:"542005007300",

        FieldFiveOptionOne: "Yes",
        FieldFiveOptionTwo: "No",

        FieldSixOptionOne: "Emergency Lighting 1 Hour",
        FieldSixOptionTwo: "Emergency Lighting 3 Hours",
        FieldSixOptionThree:"No",

        FieldSevenOptionOne: "Full",
        FieldSevenOptionTwo: "1/2",
        FieldSevenOptionThree: "1/3",
        FieldSevenOptionFour: "Custom",

        FieldNineOptionOne: "Emergency Lighting 1 Hour",
        FieldNineOptionTwo: "Emergency Lighting 3 Hours",

        FieldTenOptionOne: "Amount is known",
        FieldTenOptionTwo: "Amount is unknown",

        FieldTwelveOptionOne: "Feed-In-Box",
        FieldTwelveOptionTwo: "230V-connection from above",

        FieldThirteenOptionOne: "Yes",
        FieldThirteenOptionTwo: "No",

        FieldFourteenOptionOne: "Start",
        FieldFourteenOptionTwo: "End",
        FieldFourteenOptionThree:"No",

        FieldFifteenOptionOne: "Mounting Clip Chain",
        FieldFifteenOptionTwo: "Cord 3m",
        FieldFifteenOptionThree: "Mounting Clip",

        FieldSeventeenOptionOne: "Yes",
        FieldSeventeenOptionTwo: "No",

        FieldNineteenOptionOne: "Begin",
        FieldNineteenOptionTwo: "Begin-End",
        FieldNineteenOptionThree: "Full",
        FieldNineteenOptionFour: "Custom",

        FieldTwentyOptionOne: "3C Track Module",
        FieldTwentyOptionTwo: "No",

        FieldTwentyTwoOptionOne:"50%",
        FieldTwentyTwoOptionTwo:"100%",

        countryOptionOne : "Netherlands",
        countryOptionTwo : "Germany",
        countryOptionThree : "Austria",
        countryOptionFour : "Switzerland",
        countryOptionFive : "Belgium",
        countryOptionSix : "France",
        countryOptionSeven : "Spain",
        countryOptionEight : "Italy",
        countryOptionNine : "Portugal",
        countryOptionTen : "Others",
    },
    "dutch" :{
        FieldTwoOptionOne: "1.5m",
        FieldTwoOptionTwo: "3m",

        FieldFourOptionOne: "LEDTrunking Module L15-35W-4000-120",
        FieldFourOptionOneCode: "542005008200",
        FieldFourOptionTwo: "LEDTrunking Module L15-50W-4000-60",
        FieldFourOptionTwoCode:"542005006000",
        FieldFourOptionThree: "LEDTrunking Module L15-50W-4000-90",
        FieldFourOptionThreeCode:"542005005900",
        FieldFourOptionFour: "LEDTrunking Module L15-50W-4000-120",
        FieldFourOptionFourCode:"542005005800",
        FieldFourOptionFive: "LEDTrunking Module L15-50W-4000-BW",
        FieldFourOptionFiveCode:"542005006100",
        FieldFourOptionSix: "LEDTrunking Module L15-70W-4000-60",
        FieldFourOptionSixCode:"542005006400",
        FieldFourOptionSeven: "LEDTrunking Module L15-70W-4000-90",
        FieldFourOptionSevenCode:"542005006300",
        FieldFourOptionEight: "LEDTrunking Module L15-70W-4000-120",
        FieldFourOptionEightCode:"542005006200",
        FieldFourOptionNine: "LEDTrunking Module L15-70W-4000-BW",
        FieldFourOptionNineCode:"542005006500",

        FieldFourOptionNineteen : "LEDTrunking Mod L15-35W-4000-DF-DALI-DZ",
        FieldFourOptionNineteenCode: "534001000100",
        FieldFourOptionTen: "LEDTrunking Module L15-35W-4000-120-DALI",
        FieldFourOptionTenCode:"542005008600",
        FieldFourOptionEleven: "LEDTrunking Module L15-50W-4000-60-DALI",
        FieldFourOptionElevenCode:"542005006800",
        FieldFourOptionTwelve: "LEDTrunking Module L15-50W-4000-90-DALI",
        FieldFourOptionTwelveCode:"542005006700",
        FieldFourOptionThirteen: "LEDTrunking Module L15-50W-4000-120-DALI",
        FieldFourOptionThirteenCode:"542005006600",
        FieldFourOptionFourteen: "LEDTrunking Module L15-50W-4000-BW-DALI",
        FieldFourOptionFourteenCode:"542005006900",
        FieldFourOptionFifteen: "LEDTrunking Module L15-70W-4000-60-DALI",
        FieldFourOptionFifteenCode:"542005007200",
        FieldFourOptionSixteen: "LEDTrunking Module L15-70W-4000-90-DALI",
        FieldFourOptionSixteenCode:"542005007100",
        FieldFourOptionSeventeen: "LEDTrunking Module L15-70W-4000-120-DALI",
        FieldFourOptionSeventeenCode:"542005007000",
        FieldFourOptionEighteen: "LEDTrunking Module L15-70W-4000-BW-DALI",
        FieldFourOptionEighteenCode:"542005007300",

        FieldFiveOptionOne: "Ja",
        FieldFiveOptionTwo: "Nee",

        FieldSixOptionOne: "Noodverlichting 1 uur",
        FieldSixOptionTwo: "Noodverlichting 3 uur",
        FieldSixOptionThree:"Nee",

        FieldSevenOptionOne: "Volledig",
        FieldSevenOptionTwo: "1/2",
        FieldSevenOptionThree: "1/3",
        FieldSevenOptionFour: "Aangepast",

        FieldNineOptionOne: "Noodverlichting 1 uur",
        FieldNineOptionTwo: "Noodverlichting 3 uur",

        FieldTenOptionOne: "Aantal bekend",
        FieldTenOptionTwo: "Aantal onbekend",

        FieldTwelveOptionOne: "Feed-In-Box",
        FieldTwelveOptionTwo: "230V-aansluiting van boven",

        FieldThirteenOptionOne: "Ja",
        FieldThirteenOptionTwo: "Nee",

        FieldFourteenOptionOne: "Begin",
        FieldFourteenOptionTwo: "Eind",
        FieldFourteenOptionThree:"Nee",

        FieldFifteenOptionOne: "Ketting bevestigingsclip",
        FieldFifteenOptionTwo: "Kabel 3m",
        FieldFifteenOptionThree: "Bevestigingsclip",

        FieldSeventeenOptionOne: "Ja",
        FieldSeventeenOptionTwo: "Nee",

        FieldNineteenOptionOne: "Begin",
        FieldNineteenOptionTwo: "Begin-Eind",
        FieldNineteenOptionThree: "Volledig",
        FieldNineteenOptionFour: "Aangepast",

        FieldTwentyOptionOne: "3C Track Module",
        FieldTwentyOptionTwo: "Nee",

        FieldTwentyTwoOptionOne:"50%",
        FieldTwentyTwoOptionTwo:"100%",

        countryOptionOne : "Netherlands",
        countryOptionTwo : "Germany",
        countryOptionThree : "Austria",
        countryOptionFour : "Switzerland",
        countryOptionFive : "Belgium",
        countryOptionSix : "France",
        countryOptionSeven : "Spain",
        countryOptionEight : "Italy",
        countryOptionNine : "Portugal",
        countryOptionTen : "Others",

    },
    "french":{
        FieldTwoOptionOne: "1.5m",
        FieldTwoOptionTwo: "3m",

        FieldFourOptionOne: "LEDTrunking Module L15-35W-4000-120",
        FieldFourOptionOneCode: "542005008200",
        FieldFourOptionTwo: "LEDTrunking Module L15-50W-4000-60",
        FieldFourOptionTwoCode:"542005006000",
        FieldFourOptionThree: "LEDTrunking Module L15-50W-4000-90",
        FieldFourOptionThreeCode:"542005005900",
        FieldFourOptionFour: "LEDTrunking Module L15-50W-4000-120",
        FieldFourOptionFourCode:"542005005800",
        FieldFourOptionFive: "LEDTrunking Module L15-50W-4000-BW",
        FieldFourOptionFiveCode:"542005006100",
        FieldFourOptionSix: "LEDTrunking Module L15-70W-4000-60",
        FieldFourOptionSixCode:"542005006400",
        FieldFourOptionSeven: "LEDTrunking Module L15-70W-4000-90",
        FieldFourOptionSevenCode:"542005006300",
        FieldFourOptionEight: "LEDTrunking Module L15-70W-4000-120",
        FieldFourOptionEightCode:"542005006200",
        FieldFourOptionNine: "LEDTrunking Module L15-70W-4000-BW",
        FieldFourOptionNineCode:"542005006500",

        FieldFourOptionNineteen : "LEDTrunking Mod L15-35W-4000-DF-DALI-DZ",
        FieldFourOptionNineteenCode: "534001000100",
        FieldFourOptionTen: "LEDTrunking Module L15-35W-4000-120-DALI",
        FieldFourOptionTenCode:"542005008600",
        FieldFourOptionEleven: "LEDTrunking Module L15-50W-4000-60-DALI",
        FieldFourOptionElevenCode:"542005006800",
        FieldFourOptionTwelve: "LEDTrunking Module L15-50W-4000-90-DALI",
        FieldFourOptionTwelveCode:"542005006700",
        FieldFourOptionThirteen: "LEDTrunking Module L15-50W-4000-120-DALI",
        FieldFourOptionThirteenCode:"542005006600",
        FieldFourOptionFourteen: "LEDTrunking Module L15-50W-4000-BW-DALI",
        FieldFourOptionFourteenCode:"542005006900",
        FieldFourOptionFifteen: "LEDTrunking Module L15-70W-4000-60-DALI",
        FieldFourOptionFifteenCode:"542005007200",
        FieldFourOptionSixteen: "LEDTrunking Module L15-70W-4000-90-DALI",
        FieldFourOptionSixteenCode:"542005007100",
        FieldFourOptionSeventeen: "LEDTrunking Module L15-70W-4000-120-DALI",
        FieldFourOptionSeventeenCode:"542005007000",
        FieldFourOptionEighteen: "LEDTrunking Module L15-70W-4000-BW-DALI",
        FieldFourOptionEighteenCode:"542005007300",

        FieldFiveOptionOne: "Oui",
        FieldFiveOptionTwo: "Non",

        FieldSixOptionOne: "Éclairage d'urgence 1 heure",
        FieldSixOptionTwo: "Éclairage d'urgence 3 heures",
        FieldSixOptionThree:"Non",

        FieldSevenOptionOne: "Plein",
        FieldSevenOptionTwo: "1/2",
        FieldSevenOptionThree: "1/3",
        FieldSevenOptionFour: "Personnalisé",

        FieldNineOptionOne: "Éclairage d'urgence 1 heure",
        FieldNineOptionTwo: "Éclairage d'urgence 3 heures",

        FieldTenOptionOne: "Le nombre est connu",
        FieldTenOptionTwo: "Le nombre n’est pas connu",

        FieldTwelveOptionOne: "Boîtier d’alimentation entrante",
        FieldTwelveOptionTwo: "Connexion 230 V supérieure (Cable Gland)",

        FieldThirteenOptionOne: "Oui",
        FieldThirteenOptionTwo: "Non",

        FieldFourteenOptionOne: "Début",
        FieldFourteenOptionTwo: "Fin",
        FieldFourteenOptionThree:"Non",

        FieldFifteenOptionOne: "Clip de montage par chaîne",
        FieldFifteenOptionTwo: "Cordon 3 m",
        FieldFifteenOptionThree: "Clip de montage",

        FieldSeventeenOptionOne: "Oui",
        FieldSeventeenOptionTwo: "Non",

        FieldNineteenOptionOne: "Début",
        FieldNineteenOptionTwo: "Début-fin",
        FieldNineteenOptionThree: "Plein",
        FieldNineteenOptionFour: "Personnalisé",

        FieldTwentyOptionOne: "3C Track Module",
        FieldTwentyOptionTwo: "Non",

        FieldTwentyTwoOptionOne:"50%",
        FieldTwentyTwoOptionTwo:"100%",

        countryOptionOne : "Netherlands",
        countryOptionTwo : "Germany",
        countryOptionThree : "Austria",
        countryOptionFour : "Switzerland",
        countryOptionFive : "Belgium",
        countryOptionSix : "France",
        countryOptionSeven : "Spain",
        countryOptionEight : "Italy",
        countryOptionNine : "Portugal",
        countryOptionTen : "Others",

    },
    "german":{
        FieldTwoOptionOne: "1.5m",
        FieldTwoOptionTwo: "3m",

        FieldFourOptionOne: "LEDTrunking Module L15-35W-4000-120",
        FieldFourOptionOneCode: "542005008200",
        FieldFourOptionTwo: "LEDTrunking Module L15-50W-4000-60",
        FieldFourOptionTwoCode:"542005006000",
        FieldFourOptionThree: "LEDTrunking Module L15-50W-4000-90",
        FieldFourOptionThreeCode:"542005005900",
        FieldFourOptionFour: "LEDTrunking Module L15-50W-4000-120",
        FieldFourOptionFourCode:"542005005800",
        FieldFourOptionFive: "LEDTrunking Module L15-50W-4000-BW",
        FieldFourOptionFiveCode:"542005006100",
        FieldFourOptionSix: "LEDTrunking Module L15-70W-4000-60",
        FieldFourOptionSixCode:"542005006400",
        FieldFourOptionSeven: "LEDTrunking Module L15-70W-4000-90",
        FieldFourOptionSevenCode:"542005006300",
        FieldFourOptionEight: "LEDTrunking Module L15-70W-4000-120",
        FieldFourOptionEightCode:"542005006200",
        FieldFourOptionNine: "LEDTrunking Module L15-70W-4000-BW",
        FieldFourOptionNineCode:"542005006500",

        FieldFourOptionNineteen : "LEDTrunking Mod L15-35W-4000-DF-DALI-DZ",
        FieldFourOptionNineteenCode: "534001000100",
        FieldFourOptionTen: "LEDTrunking Module L15-35W-4000-120-DALI",
        FieldFourOptionTenCode:"542005008600",
        FieldFourOptionEleven: "LEDTrunking Module L15-50W-4000-60-DALI",
        FieldFourOptionElevenCode:"542005006800",
        FieldFourOptionTwelve: "LEDTrunking Module L15-50W-4000-90-DALI",
        FieldFourOptionTwelveCode:"542005006700",
        FieldFourOptionThirteen: "LEDTrunking Module L15-50W-4000-120-DALI",
        FieldFourOptionThirteenCode:"542005006600",
        FieldFourOptionFourteen: "LEDTrunking Module L15-50W-4000-BW-DALI",
        FieldFourOptionFourteenCode:"542005006900",
        FieldFourOptionFifteen: "LEDTrunking Module L15-70W-4000-60-DALI",
        FieldFourOptionFifteenCode:"542005007200",
        FieldFourOptionSixteen: "LEDTrunking Module L15-70W-4000-90-DALI",
        FieldFourOptionSixteenCode:"542005007100",
        FieldFourOptionSeventeen: "LEDTrunking Module L15-70W-4000-120-DALI",
        FieldFourOptionSeventeenCode:"542005007000",
        FieldFourOptionEighteen: "LEDTrunking Module L15-70W-4000-BW-DALI",
        FieldFourOptionEighteenCode:"542005007300",

        FieldFiveOptionOne: "Ja",
        FieldFiveOptionTwo: "Nein",

        FieldSixOptionOne: "Notbeleuchtung 1 Stunde",
        FieldSixOptionTwo: "Notbeleuchtung 3 Stunden",
        FieldSixOptionThree:"Nein",

        FieldSevenOptionOne: "Komplett",
        FieldSevenOptionTwo: "1/2",
        FieldSevenOptionThree: "1/3",
        FieldSevenOptionFour: "Kunde",

        FieldNineOptionOne: "Notbeleuchtung 1 Stunde",
        FieldNineOptionTwo: "Notbeleuchtung 3 Stunden",

        FieldTenOptionOne: "Anzahl ist bekannt",
        FieldTenOptionTwo: "Anzahl ist nicht bekannt",

        FieldTwelveOptionOne: "Einspeisebox",
        FieldTwelveOptionTwo: "230V-Anschluß von oben",

        FieldThirteenOptionOne: "Ja",
        FieldThirteenOptionTwo: "Nein",

        FieldFourteenOptionOne: "Start",
        FieldFourteenOptionTwo: "Ende",
        FieldFourteenOptionThree:"Nein",

        FieldFifteenOptionOne: "Kettenaufhänger",
        FieldFifteenOptionTwo: "Seil 3m",
        FieldFifteenOptionThree: "Klammer",

        FieldSeventeenOptionOne: "Ja",
        FieldSeventeenOptionTwo: "Nein",

        FieldNineteenOptionOne: "Beginn",
        FieldNineteenOptionTwo: "Beginn-Ende",
        FieldNineteenOptionThree: "Komplett",
        FieldNineteenOptionFour: "Kunde",

        FieldTwentyOptionOne: "3C Track Module",
        FieldTwentyOptionTwo: "Nein",

        FieldTwentyTwoOptionOne:"50%",
        FieldTwentyTwoOptionTwo:"100%",

        countryOptionOne : "Netherlands",
        countryOptionTwo : "Germany",
        countryOptionThree : "Austria",
        countryOptionFour : "Switzerland",
        countryOptionFive : "Belgium",
        countryOptionSix : "France",
        countryOptionSeven : "Spain",
        countryOptionEight : "Italy",
        countryOptionNine : "Portugal",
        countryOptionTen : "Others",

    },
    "spanish":{
        FieldTwoOptionOne: "1.5m",
        FieldTwoOptionTwo: "3m",

        FieldFourOptionOne: "LEDTrunking Module L15-35W-4000-120",
        FieldFourOptionOneCode: "542005008200",
        FieldFourOptionTwo: "LEDTrunking Module L15-50W-4000-60",
        FieldFourOptionTwoCode:"542005006000",
        FieldFourOptionThree: "LEDTrunking Module L15-50W-4000-90",
        FieldFourOptionThreeCode:"542005005900",
        FieldFourOptionFour: "LEDTrunking Module L15-50W-4000-120",
        FieldFourOptionFourCode:"542005005800",
        FieldFourOptionFive: "LEDTrunking Module L15-50W-4000-BW",
        FieldFourOptionFiveCode:"542005006100",
        FieldFourOptionSix: "LEDTrunking Module L15-70W-4000-60",
        FieldFourOptionSixCode:"542005006400",
        FieldFourOptionSeven: "LEDTrunking Module L15-70W-4000-90",
        FieldFourOptionSevenCode:"542005006300",
        FieldFourOptionEight: "LEDTrunking Module L15-70W-4000-120",
        FieldFourOptionEightCode:"542005006200",
        FieldFourOptionNine: "LEDTrunking Module L15-70W-4000-BW",
        FieldFourOptionNineCode:"542005006500",

        FieldFourOptionNineteen : "LEDTrunking Mod L15-35W-4000-DF-DALI-DZ",
        FieldFourOptionNineteenCode: "534001000100",
        FieldFourOptionTen: "LEDTrunking Module L15-35W-4000-120-DALI",
        FieldFourOptionTenCode:"542005008600",
        FieldFourOptionEleven: "LEDTrunking Module L15-50W-4000-60-DALI",
        FieldFourOptionElevenCode:"542005006800",
        FieldFourOptionTwelve: "LEDTrunking Module L15-50W-4000-90-DALI",
        FieldFourOptionTwelveCode:"542005006700",
        FieldFourOptionThirteen: "LEDTrunking Module L15-50W-4000-120-DALI",
        FieldFourOptionThirteenCode:"542005006600",
        FieldFourOptionFourteen: "LEDTrunking Module L15-50W-4000-BW-DALI",
        FieldFourOptionFourteenCode:"542005006900",
        FieldFourOptionFifteen: "LEDTrunking Module L15-70W-4000-60-DALI",
        FieldFourOptionFifteenCode:"542005007200",
        FieldFourOptionSixteen: "LEDTrunking Module L15-70W-4000-90-DALI",
        FieldFourOptionSixteenCode:"542005007100",
        FieldFourOptionSeventeen: "LEDTrunking Module L15-70W-4000-120-DALI",
        FieldFourOptionSeventeenCode:"542005007000",
        FieldFourOptionEighteen: "LEDTrunking Module L15-70W-4000-BW-DALI",
        FieldFourOptionEighteenCode:"542005007300",

        FieldFiveOptionOne: "Sí",
        FieldFiveOptionTwo: "No",

        FieldSixOptionOne: "Iluminación de emergencia 1 hora",
        FieldSixOptionTwo: "Iluminación de emergencia 3 horas",
        FieldSixOptionThree:"No",

        FieldSevenOptionOne: "Completo",
        FieldSevenOptionTwo: "1/2",
        FieldSevenOptionThree: "1/3",
        FieldSevenOptionFour: "Personalizar",

        FieldNineOptionOne: "Iluminación de emergencia 1 hora",
        FieldNineOptionTwo: "Iluminación de emergencia 3 horas",

        FieldTenOptionOne: "Se conoce la cantidad",
        FieldTenOptionTwo: "Se desconoce la cantidad",

        FieldTwelveOptionOne: "Caja Alimentación Feed-In",
        FieldTwelveOptionTwo: "Conexión de 230V desde arriba",

        FieldThirteenOptionOne: "Sí",
        FieldThirteenOptionTwo: "No",

        FieldFourteenOptionOne: "Inicio",
        FieldFourteenOptionTwo: "Fin",
        FieldFourteenOptionThree:"No",

        FieldFifteenOptionOne: "Cadena con clip de montaje",
        FieldFifteenOptionTwo: "Cordel 3 m",
        FieldFifteenOptionThree: "Clip de montaje",

        FieldSeventeenOptionOne: "Sí",
        FieldSeventeenOptionTwo: "No",

        FieldNineteenOptionOne: "Inicio",
        FieldNineteenOptionTwo: "Inicio-final",
        FieldNineteenOptionThree: "Completo",
        FieldNineteenOptionFour: "Personalizar",

        FieldTwentyOptionOne: "3C Track Module",
        FieldTwentyOptionTwo: "No",

        FieldTwentyTwoOptionOne:"50%",
        FieldTwentyTwoOptionTwo:"100%",

        countryOptionOne : "Netherlands",
        countryOptionTwo : "Germany",
        countryOptionThree : "Austria",
        countryOptionFour : "Switzerland",
        countryOptionFive : "Belgium",
        countryOptionSix : "France",
        countryOptionSeven : "Spain",
        countryOptionEight : "Italy",
        countryOptionNine : "Portugal",
        countryOptionTen : "Others",
    },
    "portugese":{
        FieldTwoOptionOne: "1.5m",
        FieldTwoOptionTwo: "3m",

        FieldFourOptionOne: "LEDTrunking Module L15-35W-4000-120",
        FieldFourOptionOneCode: "542005008200",
        FieldFourOptionTwo: "LEDTrunking Module L15-50W-4000-60",
        FieldFourOptionTwoCode:"542005006000",
        FieldFourOptionThree: "LEDTrunking Module L15-50W-4000-90",
        FieldFourOptionThreeCode:"542005005900",
        FieldFourOptionFour: "LEDTrunking Module L15-50W-4000-120",
        FieldFourOptionFourCode:"542005005800",
        FieldFourOptionFive: "LEDTrunking Module L15-50W-4000-BW",
        FieldFourOptionFiveCode:"542005006100",
        FieldFourOptionSix: "LEDTrunking Module L15-70W-4000-60",
        FieldFourOptionSixCode:"542005006400",
        FieldFourOptionSeven: "LEDTrunking Module L15-70W-4000-90",
        FieldFourOptionSevenCode:"542005006300",
        FieldFourOptionEight: "LEDTrunking Module L15-70W-4000-120",
        FieldFourOptionEightCode:"542005006200",
        FieldFourOptionNine: "LEDTrunking Module L15-70W-4000-BW",
        FieldFourOptionNineCode:"542005006500",

        FieldFourOptionNineteen : "LEDTrunking Mod L15-35W-4000-DF-DALI-DZ",
        FieldFourOptionNineteenCode: "534001000100",
        FieldFourOptionTen: "LEDTrunking Module L15-35W-4000-120-DALI",
        FieldFourOptionTenCode:"542005008600",
        FieldFourOptionEleven: "LEDTrunking Module L15-50W-4000-60-DALI",
        FieldFourOptionElevenCode:"542005006800",
        FieldFourOptionTwelve: "LEDTrunking Module L15-50W-4000-90-DALI",
        FieldFourOptionTwelveCode:"542005006700",
        FieldFourOptionThirteen: "LEDTrunking Module L15-50W-4000-120-DALI",
        FieldFourOptionThirteenCode:"542005006600",
        FieldFourOptionFourteen: "LEDTrunking Module L15-50W-4000-BW-DALI",
        FieldFourOptionFourteenCode:"542005006900",
        FieldFourOptionFifteen: "LEDTrunking Module L15-70W-4000-60-DALI",
        FieldFourOptionFifteenCode:"542005007200",
        FieldFourOptionSixteen: "LEDTrunking Module L15-70W-4000-90-DALI",
        FieldFourOptionSixteenCode:"542005007100",
        FieldFourOptionSeventeen: "LEDTrunking Module L15-70W-4000-120-DALI",
        FieldFourOptionSeventeenCode:"542005007000",
        FieldFourOptionEighteen: "LEDTrunking Module L15-70W-4000-BW-DALI",
        FieldFourOptionEighteenCode:"542005007300",

        FieldFiveOptionOne: "Sim",
        FieldFiveOptionTwo: "Não",

        FieldSixOptionOne: "Iluminação de emergência 1 hora",
        FieldSixOptionTwo: "Iluminação de emergência 3 horas",
        FieldSixOptionThree:"Não",

        FieldSevenOptionOne: "Total",
        FieldSevenOptionTwo: "1/2",
        FieldSevenOptionThree: "1/3",
        FieldSevenOptionFour: "Personalizar",

        FieldNineOptionOne: "Iluminação de emergência 1 hora",
        FieldNineOptionTwo: "Iluminação de emergência 3 horas",

        FieldTenOptionOne: "Quantidade conhecida",
        FieldTenOptionTwo: "Quantidade desconhecida",

        FieldTwelveOptionOne: "Caixa de entrada (feed-in)",
        FieldTwelveOptionTwo: "Ligação de 230 V pela parte de cima",

        FieldThirteenOptionOne: "Sim",
        FieldThirteenOptionTwo: "Não",

        FieldFourteenOptionOne: "Início",
        FieldFourteenOptionTwo: "Fim",
        FieldFourteenOptionThree:"Não",

        FieldFifteenOptionOne: "Corrente do grampo de montagem",
        FieldFifteenOptionTwo: "Cabo de 3 m",
        FieldFifteenOptionThree: "Grampo de montagem",

        FieldSeventeenOptionOne: "Sim",
        FieldSeventeenOptionTwo: "Não",

        FieldNineteenOptionOne: "Início",
        FieldNineteenOptionTwo: "Início-Fim",
        FieldNineteenOptionThree: "Total",
        FieldNineteenOptionFour: "Personalizar",

        FieldTwentyOptionOne: "3C Track Module",
        FieldTwentyOptionTwo: "Não",

        FieldTwentyTwoOptionOne:"50%",
        FieldTwentyTwoOptionTwo:"100%",

        countryOptionOne : "Netherlands",
        countryOptionTwo : "Germany",
        countryOptionThree : "Austria",
        countryOptionFour : "Switzerland",
        countryOptionFive : "Belgium",
        countryOptionSix : "France",
        countryOptionSeven : "Spain",
        countryOptionEight : "Italy",
        countryOptionNine : "Portugal",
        countryOptionTen : "Others",
    },
    "finnish":{
        FieldTwoOptionOne: "1.5m",
        FieldTwoOptionTwo: "3m",

        FieldFourOptionOne: "LEDTrunking Module L15-35W-4000-120",
        FieldFourOptionOneCode: "542005008200",
        FieldFourOptionTwo: "LEDTrunking Module L15-50W-4000-60",
        FieldFourOptionTwoCode:"542005006000",
        FieldFourOptionThree: "LEDTrunking Module L15-50W-4000-90",
        FieldFourOptionThreeCode:"542005005900",
        FieldFourOptionFour: "LEDTrunking Module L15-50W-4000-120",
        FieldFourOptionFourCode:"542005005800",
        FieldFourOptionFive: "LEDTrunking Module L15-50W-4000-BW",
        FieldFourOptionFiveCode:"542005006100",
        FieldFourOptionSix: "LEDTrunking Module L15-70W-4000-60",
        FieldFourOptionSixCode:"542005006400",
        FieldFourOptionSeven: "LEDTrunking Module L15-70W-4000-90",
        FieldFourOptionSevenCode:"542005006300",
        FieldFourOptionEight: "LEDTrunking Module L15-70W-4000-120",
        FieldFourOptionEightCode:"542005006200",
        FieldFourOptionNine: "LEDTrunking Module L15-70W-4000-BW",
        FieldFourOptionNineCode:"542005006500",

        FieldFourOptionNineteen : "LEDTrunking Mod L15-35W-4000-DF-DALI-DZ",
        FieldFourOptionNineteenCode: "534001000100",
        FieldFourOptionTen: "LEDTrunking Module L15-35W-4000-120-DALI",
        FieldFourOptionTenCode:"542005008600",
        FieldFourOptionEleven: "LEDTrunking Module L15-50W-4000-60-DALI",
        FieldFourOptionElevenCode:"542005006800",
        FieldFourOptionTwelve: "LEDTrunking Module L15-50W-4000-90-DALI",
        FieldFourOptionTwelveCode:"542005006700",
        FieldFourOptionThirteen: "LEDTrunking Module L15-50W-4000-120-DALI",
        FieldFourOptionThirteenCode:"542005006600",
        FieldFourOptionFourteen: "LEDTrunking Module L15-50W-4000-BW-DALI",
        FieldFourOptionFourteenCode:"542005006900",
        FieldFourOptionFifteen: "LEDTrunking Module L15-70W-4000-60-DALI",
        FieldFourOptionFifteenCode:"542005007200",
        FieldFourOptionSixteen: "LEDTrunking Module L15-70W-4000-90-DALI",
        FieldFourOptionSixteenCode:"542005007100",
        FieldFourOptionSeventeen: "LEDTrunking Module L15-70W-4000-120-DALI",
        FieldFourOptionSeventeenCode:"542005007000",
        FieldFourOptionEighteen: "LEDTrunking Module L15-70W-4000-BW-DALI",
        FieldFourOptionEighteenCode:"542005007300",

        FieldFiveOptionOne: "Kyllä",
        FieldFiveOptionTwo: "Ei",

        FieldSixOptionOne: "Hätävalaistus 1 tunti",
        FieldSixOptionTwo: "Hätävalaistus 3 tuntia",
        FieldSixOptionThree:"Ei",

        FieldSevenOptionOne: "Täysi",
        FieldSevenOptionTwo: "1/2",
        FieldSevenOptionThree: "1/3",
        FieldSevenOptionFour: "Mukautettu",

        FieldNineOptionOne: "Hätävalaistus 1 tunti",
        FieldNineOptionTwo: "Hätävalaistus 3 tuntia",

        FieldTenOptionOne: "Määrä on tiedossa",
        FieldTenOptionTwo: "Määrä ei ole tiedossa",

        FieldTwelveOptionOne: "Syöttölaatikko",
        FieldTwelveOptionTwo: "Yläpuolinen 230V-liitäntä",

        FieldThirteenOptionOne: "Kyllä",
        FieldThirteenOptionTwo: "Ei",

        FieldFourteenOptionOne: "Aloita",
        FieldFourteenOptionTwo: "Lopeta",
        FieldFourteenOptionThree:"Ei",

        FieldFifteenOptionOne: "Asennusketju",
        FieldFifteenOptionTwo: "Johto 3m",
        FieldFifteenOptionThree: "Kiinnike",

        FieldSeventeenOptionOne: "Kyllä",
        FieldSeventeenOptionTwo: "Ei",

        FieldNineteenOptionOne: "Aloita",
        FieldNineteenOptionTwo: "Aloita-Lopeta",
        FieldNineteenOptionThree: "Täysi",
        FieldNineteenOptionFour: "Mukautettu",

        FieldTwentyOptionOne: "3C Track Module",
        FieldTwentyOptionTwo: "Ei",

        FieldTwentyTwoOptionOne:"50%",
        FieldTwentyTwoOptionTwo:"100%",

        countryOptionOne : "Netherlands",
        countryOptionTwo : "Germany",
        countryOptionThree : "Austria",
        countryOptionFour : "Switzerland",
        countryOptionFive : "Belgium",
        countryOptionSix : "France",
        countryOptionSeven : "Spain",
        countryOptionEight : "Italy",
        countryOptionNine : "Portugal",
        countryOptionTen : "Others",
    },
    "polish":{
        FieldTwoOptionOne: "1.5m",
        FieldTwoOptionTwo: "3m",

        FieldFourOptionOne: "LEDTrunking Module L15-35W-4000-120",
        FieldFourOptionOneCode: "542005008200",
        FieldFourOptionTwo: "LEDTrunking Module L15-50W-4000-60",
        FieldFourOptionTwoCode:"542005006000",
        FieldFourOptionThree: "LEDTrunking Module L15-50W-4000-90",
        FieldFourOptionThreeCode:"542005005900",
        FieldFourOptionFour: "LEDTrunking Module L15-50W-4000-120",
        FieldFourOptionFourCode:"542005005800",
        FieldFourOptionFive: "LEDTrunking Module L15-50W-4000-BW",
        FieldFourOptionFiveCode:"542005006100",
        FieldFourOptionSix: "LEDTrunking Module L15-70W-4000-60",
        FieldFourOptionSixCode:"542005006400",
        FieldFourOptionSeven: "LEDTrunking Module L15-70W-4000-90",
        FieldFourOptionSevenCode:"542005006300",
        FieldFourOptionEight: "LEDTrunking Module L15-70W-4000-120",
        FieldFourOptionEightCode:"542005006200",
        FieldFourOptionNine: "LEDTrunking Module L15-70W-4000-BW",
        FieldFourOptionNineCode:"542005006500",

        FieldFourOptionNineteen : "LEDTrunking Mod L15-35W-4000-DF-DALI-DZ",
        FieldFourOptionNineteenCode: "534001000100",
        FieldFourOptionTen: "LEDTrunking Module L15-35W-4000-120-DALI",
        FieldFourOptionTenCode:"542005008600",
        FieldFourOptionEleven: "LEDTrunking Module L15-50W-4000-60-DALI",
        FieldFourOptionElevenCode:"542005006800",
        FieldFourOptionTwelve: "LEDTrunking Module L15-50W-4000-90-DALI",
        FieldFourOptionTwelveCode:"542005006700",
        FieldFourOptionThirteen: "LEDTrunking Module L15-50W-4000-120-DALI",
        FieldFourOptionThirteenCode:"542005006600",
        FieldFourOptionFourteen: "LEDTrunking Module L15-50W-4000-BW-DALI",
        FieldFourOptionFourteenCode:"542005006900",
        FieldFourOptionFifteen: "LEDTrunking Module L15-70W-4000-60-DALI",
        FieldFourOptionFifteenCode:"542005007200",
        FieldFourOptionSixteen: "LEDTrunking Module L15-70W-4000-90-DALI",
        FieldFourOptionSixteenCode:"542005007100",
        FieldFourOptionSeventeen: "LEDTrunking Module L15-70W-4000-120-DALI",
        FieldFourOptionSeventeenCode:"542005007000",
        FieldFourOptionEighteen: "LEDTrunking Module L15-70W-4000-BW-DALI",
        FieldFourOptionEighteenCode:"542005007300",

        FieldFiveOptionOne: "Tak",
        FieldFiveOptionTwo: "Nie",

        FieldSixOptionOne: "Oświetlenie awaryjne na 1 godzinę",
        FieldSixOptionTwo: "Oświetlenie awaryjne na 3 godziny",
        FieldSixOptionThree:"Nie",

        FieldSevenOptionOne: "Pełne",
        FieldSevenOptionTwo: "1/2",
        FieldSevenOptionThree: "1/3",
        FieldSevenOptionFour: "Do wyboru",

        FieldNineOptionOne: "Oświetlenie awaryjne na 1 godzinę",
        FieldNineOptionTwo: "Oświetlenie awaryjne na 3 godziny",

        FieldTenOptionOne: "Ilość jest znana",
        FieldTenOptionTwo: "Ilość jest nieznana",

        FieldTwelveOptionOne: "Feed-In-Box",
        FieldTwelveOptionTwo: "Połączenie 230 V od góry",

        FieldThirteenOptionOne: "Tak",
        FieldThirteenOptionTwo: "Nie",

        FieldFourteenOptionOne: "Start",
        FieldFourteenOptionTwo: "Zakończenie",
        FieldFourteenOptionThree:"Nie",

        FieldFifteenOptionOne: "Zacisk montażowy do łańcuszka",
        FieldFifteenOptionTwo: "Zawiesie linkowe 3 m",
        FieldFifteenOptionThree: "Zacisk montażowy",

        FieldSeventeenOptionOne: "Tak",
        FieldSeventeenOptionTwo: "Nie",

        FieldNineteenOptionOne: "Początek",
        FieldNineteenOptionTwo: "Początek-koniec",
        FieldNineteenOptionThree: "Pełne",
        FieldNineteenOptionFour: "Do wyboru",

        FieldTwentyOptionOne: "3C Track Module",
        FieldTwentyOptionTwo: "Nie",

        FieldTwentyTwoOptionOne:"50%",
        FieldTwentyTwoOptionTwo:"100%",

        countryOptionOne : "Netherlands",
        countryOptionTwo : "Germany",
        countryOptionThree : "Austria",
        countryOptionFour : "Switzerland",
        countryOptionFive : "Belgium",
        countryOptionSix : "France",
        countryOptionSeven : "Spain",
        countryOptionEight : "Italy",
        countryOptionNine : "Portugal",
        countryOptionTen : "Others",
    },
    "italian":{
        FieldTwoOptionOne: "1.5m",
        FieldTwoOptionTwo: "3m",

        FieldFourOptionOne: "LEDTrunking Module L15-35W-4000-120",
        FieldFourOptionOneCode: "542005008200",
        FieldFourOptionTwo: "LEDTrunking Module L15-50W-4000-60",
        FieldFourOptionTwoCode:"542005006000",
        FieldFourOptionThree: "LEDTrunking Module L15-50W-4000-90",
        FieldFourOptionThreeCode:"542005005900",
        FieldFourOptionFour: "LEDTrunking Module L15-50W-4000-120",
        FieldFourOptionFourCode:"542005005800",
        FieldFourOptionFive: "LEDTrunking Module L15-50W-4000-BW",
        FieldFourOptionFiveCode:"542005006100",
        FieldFourOptionSix: "LEDTrunking Module L15-70W-4000-60",
        FieldFourOptionSixCode:"542005006400",
        FieldFourOptionSeven: "LEDTrunking Module L15-70W-4000-90",
        FieldFourOptionSevenCode:"542005006300",
        FieldFourOptionEight: "LEDTrunking Module L15-70W-4000-120",
        FieldFourOptionEightCode:"542005006200",
        FieldFourOptionNine: "LEDTrunking Module L15-70W-4000-BW",
        FieldFourOptionNineCode:"542005006500",

        FieldFourOptionNineteen : "LEDTrunking Mod L15-35W-4000-DF-DALI-DZ",
        FieldFourOptionNineteenCode: "534001000100",
        FieldFourOptionTen: "LEDTrunking Module L15-35W-4000-120-DALI",
        FieldFourOptionTenCode:"542005008600",
        FieldFourOptionEleven: "LEDTrunking Module L15-50W-4000-60-DALI",
        FieldFourOptionElevenCode:"542005006800",
        FieldFourOptionTwelve: "LEDTrunking Module L15-50W-4000-90-DALI",
        FieldFourOptionTwelveCode:"542005006700",
        FieldFourOptionThirteen: "LEDTrunking Module L15-50W-4000-120-DALI",
        FieldFourOptionThirteenCode:"542005006600",
        FieldFourOptionFourteen: "LEDTrunking Module L15-50W-4000-BW-DALI",
        FieldFourOptionFourteenCode:"542005006900",
        FieldFourOptionFifteen: "LEDTrunking Module L15-70W-4000-60-DALI",
        FieldFourOptionFifteenCode:"542005007200",
        FieldFourOptionSixteen: "LEDTrunking Module L15-70W-4000-90-DALI",
        FieldFourOptionSixteenCode:"542005007100",
        FieldFourOptionSeventeen: "LEDTrunking Module L15-70W-4000-120-DALI",
        FieldFourOptionSeventeenCode:"542005007000",
        FieldFourOptionEighteen: "LEDTrunking Module L15-70W-4000-BW-DALI",
        FieldFourOptionEighteenCode:"542005007300",

        FieldFiveOptionOne: "Sì",
        FieldFiveOptionTwo: "No",

        FieldSixOptionOne: "Illuminazione di emergenza 1 ora",
        FieldSixOptionTwo: "Illuminazione di emergenza 3 ore",
        FieldSixOptionThree:"No",

        FieldSevenOptionOne: "Completo",
        FieldSevenOptionTwo: "1/2",
        FieldSevenOptionThree: "1/3",
        FieldSevenOptionFour: "Personalizzato",

        FieldNineOptionOne: "Illuminazione di emergenza 1 ora",
        FieldNineOptionTwo: "Illuminazione di emergenza 3 ore",

        FieldTenOptionOne: "La quantità è nota",
        FieldTenOptionTwo: "La quantità non è nota",

        FieldTwelveOptionOne: "Feed-In-Box",
        FieldTwelveOptionTwo: "Alimentazione 230 V dall'alto",

        FieldThirteenOptionOne: "Sì",
        FieldThirteenOptionTwo: "No",

        FieldFourteenOptionOne: "Inizio",
        FieldFourteenOptionTwo: "Fine",
        FieldFourteenOptionThree:"Ei",

        FieldFifteenOptionOne: "Catena clip di montaggio",
        FieldFifteenOptionTwo: "Cavo 3 m",
        FieldFifteenOptionThree: "Clip di montaggio",

        FieldSeventeenOptionOne: "Sì",
        FieldSeventeenOptionTwo: "No",

        FieldNineteenOptionOne: "Inizio",
        FieldNineteenOptionTwo: "Inizio-Fine",
        FieldNineteenOptionThree: "Completo",
        FieldNineteenOptionFour: "Personalizzato",

        FieldTwentyOptionOne: "3C Track Module",
        FieldTwentyOptionTwo: "No",

        FieldTwentyTwoOptionOne:"50%",
        FieldTwentyTwoOptionTwo:"100%",

        countryOptionOne : "Netherlands",
        countryOptionTwo : "Germany",
        countryOptionThree : "Austria",
        countryOptionFour : "Switzerland",
        countryOptionFive : "Belgium",
        countryOptionSix : "France",
        countryOptionSeven : "Spain",
        countryOptionEight : "Italy",
        countryOptionNine : "Portugal",
        countryOptionTen : "Others",
    },
}