import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Select, withWidth, CssBaseline, Typography, Tooltip } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { fieldFourInput } from "../../actions/lineActions";
import { useDispatch, useSelector } from "react-redux";
import { fieldFiveInput,fieldElevenInput,fieldSeventeenInput,sendSectionInputs,daliSelected,lineStatus } from "../../actions/lineActions";
import { inputLabels } from "../../lang/inputLabels";
import { inputLabelOptions } from "../../lang/inputLabelOptions";
import {pageContent} from "../../lang/pageContent";
import Controls from "../controls/Controls";
import HelpIcon from "@material-ui/icons/Help";
import {fieldFourInfo} from "../../actions/popupActions";
import Popup from "../../components/Popup";
import FieldFourInfo from "../../components/fieldInfoComponents/FieldFourInfo";


// We were doing this when using material ui
const useStyles = makeStyles((theme) => ({
  quantityRoot: {
    color: "#000000",
    // backgroundColor: "#000000",
    opacity: 1,
    borderRadius: "35px",
    // minWidth: "650px",
    width : "650px",
    // this one changes colour of the background on hover
    "&:hover": {
      // backgroundColor: "#ffffff",
      borderRadius: "35px",
      opacity: 1,
    },
    // this one changes background colour when field is selected
    "&:focus-within": {
      // backgroundColor: "#1E1E24",
      borderRadius: "35px",
      opacity: 1,
    },
    // this changes the outline color in normal state
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #4e7fc4",
      borderRadius: "35px",
    },
    // this one is when the curson hovers on the field
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
      borderRadius: "35px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      // the border makes it look like a square when selecting option
      // border: "1px solid #ffffff",
      borderRadius: "35px",
    },
    "& .Mui-disabled": {
      color: "#FFFFFF",
      opacity: 0.6,
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #4e7fc4",
      borderRadius: "35px",
    },
    "& .MuiSelect-select:focus": {
      // backgroundColor: "#000000",
      borderRadius: "35px"
    },
  },
  selectRoot: {
    color: "#000000",
  },
  // this changes the colour of the pointer icon
  icon: {
    color: "#000000",
  },
  // paper comes out when the select is clicked
  selectPaper: {
    backgroundColor: "#ffffff",
    border: "1px solid #484850",
    // borderRadius: "35px",
    // colour below changes the text colour in paper
    color: "#000000",
    "& li:hover": {
      // change colour of list items on hover
      backgroundColor: "#c2c0ba",
    },
    maxHeight: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 200,
  },
}));

export default function FieldFour() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );
  // const inputLabelOptions = useSelector(
  //   (reduxState) => reduxState.inputLabelOptionsReducer
  // );
  const currentValue = useSelector(
    (reduxState) =>
      reduxState.selectedLineInputsReducer.data.finalTrunkingModule
  );

  const lengthOfLineRounded = useSelector(
    (reduxState) =>
      reduxState.selectedLineInputsReducer.data.lengthOfLineRounded
  );

  const data = useSelector((reduxState) => reduxState.inputSection2Reducer);

  const [selectedOption, setSelectedOption] = React.useState("");

  let currentValueName = "";

  const defaultFieldElevenValue = lengthOfLineRounded / 1.5 - 1;

    const fieldOptions  = inputLabelOptions[`${selectedLang}`]

  if (!currentValue) {
    currentValueName = pageContent[`${selectedLang}`].selectAnOption;
  } else {
    currentValueName = `${pageContent[`${selectedLang}`].savedValue}: ${currentValue}`;
  }

  const fieldFourOptions = [
    {
      id: 1,
      name: fieldOptions.FieldFourOptionOne,
      code: "542005008200",
    },
    {
      id: 2,
      name: fieldOptions.FieldFourOptionTwo,
      code: "542005006000",
    },
    {
      id: 3,
      name: fieldOptions.FieldFourOptionThree,
      code: "542005005900",
    },
    {
      id: 4,
      name: fieldOptions.FieldFourOptionFour,
      code: "542005005800",
    },
    {
      id: 5,
      name: fieldOptions.FieldFourOptionFive,
      code: "542005006100",
    },
    {
      id: 6,
      name: fieldOptions.FieldFourOptionSix,
      code: "542005006400",
    },
    {
      id: 7,
      name: fieldOptions.FieldFourOptionSeven,
      code: "542005006300",
    },
    {
      id: 8,
      name: fieldOptions.FieldFourOptionEight,
      code: "542005006200",
    },
    {
      id: 9,
      name: fieldOptions.FieldFourOptionNine,
      code: "542005006500",
    },
    {
      id: 19,
      name: fieldOptions.FieldFourOptionNineteen,
      code: "534001000100",
    },
    {
      id: 10,
      name: fieldOptions.FieldFourOptionTen,
      code: "542005008600",
    },
    {
      id: 11,
      name: fieldOptions.FieldFourOptionEleven,
      code: "542005006800",
    },
    {
      id: 12,
      name: fieldOptions.FieldFourOptionTwelve,
      code: "542005006700",
    },
    {
      id: 13,
      name: fieldOptions.FieldFourOptionThirteen,
      code: "542005006600",
    },
    {
      id: 14,
      name: fieldOptions.FieldFourOptionFourteen,
      code: "542005006900",
    },
    {
      id: 15,
      name: fieldOptions.FieldFourOptionFifteen,
      code: "542005007200",
    },
    {
      id: 16,
      name: fieldOptions.FieldFourOptionSixteen,
      code: "542005007100",
    },
    {
      id: 17,
      name: fieldOptions.FieldFourOptionSeventeen,
      code: "542005007000",
    },
    {
      id: 18,
      name: fieldOptions.FieldFourOptionEighteen,
      code: "542005007300",
    },
  ];

  function checkDali(value) {
    if (
      value === "542005008200" ||
      value === "542005006000" ||
      value === "542005005900" ||
      value === "542005005800" ||
      value === "542005006100" ||
      value === "542005006400" ||
      value === "542005006300" ||
      value === "542005006200" ||
      value === "542005006500"
    ) {
      dispatch(fieldFiveInput("1"));
      dispatch(daliSelected("false"));
      dispatch(fieldSeventeenInput("2"));
    } else if (
      value === "542005008600" ||
      value === "542005006800" ||
      value === "542005006700" ||
      value === "542005006600" ||
      value === "542005006900" ||
      value === "542005007200" ||
      value === "542005007100" ||
      value === "542005007000" ||
      value === "542005007300" ||
      value === "534001000100"
    ) {
      dispatch(fieldFiveInput("1"));
      dispatch(daliSelected("true"));
    }
  }

  const handleChange = (event) => {
    event.preventDefault();
    setSelectedOption(event.target.value);
    dispatch(fieldFourInput(event.target.value));
    dispatch(fieldElevenInput(defaultFieldElevenValue));
    dispatch(lineStatus("false"));

    checkDali(event.target.value);
    // sendSectionTwoInput()
    dispatch(
      sendSectionInputs(data, (response) => {
        if (response.message === "Success") {
          console.log("checking response message aaloo", response.message);
        }
      })
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div
        style={{
          maxWidth: "80%",
          marginLeft: "10%",
          // border: "1px solid black",
          borderRadius: "35px",
          // marginTop: "7%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems : "center",
            flexWrap: "wrap",
          }}
        >
          <Typography
            variant="body1"
            style={{ marginTop: "20px", fontSize : "18px"  }}
            gutterBottom
          >
             <Controls.ActionButton color="secondary">
              <Tooltip title="Field Info">
                <HelpIcon 
                fontSize="small"
                color="action"
                onClick={() => {
                  dispatch(
                    fieldFourInfo({status:true})
                  )
                }}
                />
              </Tooltip>
            </Controls.ActionButton>
            {inputLabels[`${selectedLang}`].fieldFour}
          </Typography>
          <br></br>
          <FormControl variant="outlined" className={classes.quantityRoot}>
            <InputLabel id="demo-simple-select-outlined-label">
              {currentValueName}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              key="fieldFour"
              value={selectedOption}
              onChange={handleChange}
              label={currentValueName}
              classes={{
                root: classes.selectRoot,
                icon: classes.icon,
              }}
              MenuProps={{ classes: { paper: classes.selectPaper } }}
            >
              {fieldFourOptions.map((option, i) => (
                <MenuItem style={{justifyContent : "center"}} value={option.code} key={i}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Popup
      openPopup={popupState.fieldFourInfo}
      setOpenPopup={setOpenPopup}
      title="Field Info"
      >
        <FieldFourInfo/>
      </Popup>
    </React.Fragment>
  );
}

