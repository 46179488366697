import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  makeStyles,
  Grid,
  Typography,
  CssBaseline,
  Container,

} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { getComponents } from "../actions/adminActions";
import useTable from "../components/useTable";
import CustomTab from "../components/navigationTab/CustomTab";
import ComponentTable from "../components/adminComponents/ComponentTable";
import ChangeUserPasswordForm from "../components/adminComponents/ChangeUserPasswordForm";

const useStyles = makeStyles((theme) => ({
  root: {
    //   this changes the border colour
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#9e9e9e",
    },
    // this changes the border colour on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000",
    },
    // this changes the border colour on focus(when typing)
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#9e9e9e",
    },
    // this changes the text colour on typing
    "& .MuiInputBase-root": {
      color: "#000000",
    },
    //   this changes the border radius of the field
    [`& fieldset`]: {
      borderRadius: 0,
      // background:"#ffffff"
    },

    width: "33em",

    // this changes the background to white
    // background: "#ffffff"
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    // maxWidth : "1200px",
    // borderRadius: "35px",
    // marginLeft : "10%",
    // marginRight : "10%"
  },
  button: {
    // color:"#ffffff",
    // width : "20em",
    maxWidth: "200px",
    maxHeight: "50px",
    borderRadius: 35,
    backgroundColor: "#1a9fe0",
    justifyContent: "center",
    textTransform: "none",
    fontWeight: 600,
    fontSize: "12px",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#1a9fe0",
    },
  },
  input: {
    color: "#FFFFFF",
  },
  endIcon: {
    fontSize: 12,
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(1),
  },
}));

export default function AdminDashboardPage() {
  const classes = useStyles();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getComponents());
    console.log("inside use effect of admin dashboard");
  }, []);

  const togglePassword = () => {
    setShow(!showPassword);
  };

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const admin = useSelector((reduxState) => reduxState.adminLoginReducer);

  const allComponents = useSelector(
    (reduxState) => reduxState.componentsReducer.data
  );

  const records = allComponents;

  const headCells = [
    { id: "id", label: "Id" },
    { id: "code", label: "Code" },
    { id: "componentname", label: "Component name" },
    { id: "viewDetails", label: "View Details" },
    { id: "editComponent", label: "Component Actions" },
  ];

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(records, headCells);

  //These tabs are used for switching between component management and user password management
  const tabs = [
    { label: "Manage components" },
    { label: "Manage user passwords" },   
  ];

  const handleChange = (event) => {
    // setFormData({ ...formData, [event.target.name]: event.target.value });
    console.log("value of form data is:", event.target.value);
  };

  const onSubmit = (data) => {
    console.log("The data in the admin form is");
  };

  if (admin.message !== "Success") {
    history.push("/");
  }

  // console.log("records bzzzzzz", recordsAfterPagingAndSorting());

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className={"adminDashboard"}>
        <Grid container direction={"column"} maxWidth="xs" spacing={2}>
          <Grid
            item
            style={{
              position: "absolute",
              left: "10%",
              top: "10%",
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#0045a8", lineHeight: 1 }}
              gutterBottom
            >
              Trunking Configurator
            </Typography>
            <Typography
              variant="h1"
              style={{ fontWeight: 600, color: "#0045a8", lineHeight: 1 }}
              gutterBottom
            >
              Admin Dashboard
            </Typography>
            <br></br>
            <Typography
              variant="h5"
              style={{ fontWeight: 600, color: "#0045a8", lineHeight: 1 }}
              gutterBottom
            >
              Welcome Admin!
            </Typography>
            <br></br>
            <br></br>
            <br></br>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg"> {/*  Show tabs for components or chaning password*/}
        <CustomTab tabs={tabs}/>
      </Container>
      <br></br>
      <br></br>
     {admin.selectedComponent === "1" && <ComponentTable/>}
     {admin.selectedComponent === "2" && <ChangeUserPasswordForm/>}
    </React.Fragment>
  );
}
