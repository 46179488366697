// field 7, 12 and 15 have pictures
export const popupContent ={
    english :{
        selectCountryPopup: "Please select your country so that the project can be sent to the correct sales team, in case you request for a quotation later",
        lineConfigurationInfoText1 : "You are about to start configuring a line.",
        lineConfigurationInfoText2 : "Feel free to click on the Help icons in order to view more details.",
        
        fieldOneInfo : "The length of one single line.Note that this value will be rounded down to the closest value that can be divided by 1.5 since this is the length of the products employed.",
        fieldTwoInfo : "A trunking line consists of a back part (facing the ceiling) and a front part (facing the floor). The trunks form the back part. You need to have enough trunks to cover the full length of the line.The only difference between 3 meter and 1.5 meter trunks is the product's length and therefore the length of line that the product can cover. ",
        fieldThreeInfo : "This number determines how many lines you would like to replicate using the specifications that you are setting now.",
        fieldFourInfo : "When selecting a Trunking LED Module, note the following specifications:",
        fieldFourInfo1: "- All our LED Modules have a length of 1.5 meters",
        fieldFourInfo2:"- All our LED Modules have a colour temperature (CCT) of 4000 Kelvin",
        fieldFourInfo3: "- You can select a 35, 50 or 70 Watt module",
        fieldFourInfo4:"- You can select the following beam angles: 60˚, 90˚, 120˚, or Batwing (BW)",
        fieldFourInfo5: "- Daylight control and motion detection is only possible if you select a DALI LED module",
        fieldFiveInfo : "",
        fieldSixInfo : "In case you want to integrate emergency lighting in your line, please select whether these Emergency Modules should have an operating time of 1 or 3 hours.",
        fieldEightInfo: "",
        fieldNineInfo : "",
        fieldTenInfo : "If you already know how many Emergency Modules are needed in your trunking line, state that the amount is known.",
        fieldTenInfo1 : "If you do not know the exact amount, state here that the amount is unknown.",
        fieldElevenInfo: "Please enter the custom amount of Emergency Modules that you need per line. Note: This will replace this exact number of LED modules with their emergency lighting equivalents.",
        fieldThirteenInfo : "A Feed-Out-Box can be used to guide the power out of the end of a trunking line, without requiring a trunk.",
        fieldFourteenInfo : `The Module Occupation in the previous page already mentioned it in case your line does NOT end with and LED Module, using the default setting of "starting a line with an LED Module". If the line in this default case does end with an LED Module, no message was given.`,
        fieldFourteenInfo1 : "If you want to make any custom changes that vary from this situation, you can mark it here.",
        fieldSixteenInfo : "Our experts advise a distance between mounting points of 3 meters. The minimum distance is 1 meter.",
        fieldSeventeenInfo : "In case a DALI Module has been selected, you may add motion detection and/or daylight control.",
        fieldEighteenInfo : "Here you can mention the installation height in meters. When the line will be suspended, mention the height from the floor to the luminaire. In case of ceiling mounting, the height from the floor to the ceiling can be used.",
        fieldNineteenInfo : "The sensor allocation determines how the sensors will operate and the number of sensors required per line.",
        fieldNineteenInfo1 : "When you select the option for a line beginning with a sensor, one sensor will be added to the line, which can be positioned near to the start of the line.",
        fieldNineteenInfo2 : "When you select the option for a line beginning and ending with a sensor, two sensors will be added to the line, one will be positioned near to the start and the other near to the end of the line. ",
        fieldNineteenInfo3 : "When you select the option for full sensor allocation, the configurator will calculate how many sensors are required for achieving optimum operation for the motion detection and daylight control system, based on the line’s installation height.",
        fieldNineteenInfo4 : "When you select custom allocation, you can enter the desired number of sensors per line.",
        fieldTwentyInfo : "In this field, additional products for the line can be selected.The 3C Track Module replaces a blind cover in the line. It enables you to integrate OPPLE 3C Spots into your line.",
        fieldTwentyOneInfo : "Please enter the amount of additional products that you require.",
        fieldTwentyThreeInfo : "Mention in this field the number of sensors that you wish to integrate into this line.",
    },
    dutch : {
        selectCountryPopup: "",
        lineConfigurationInfoText : "U staat op het punt een lichtlijn te configureren.",
        lineConfigurationInfoText2 : "Klik voor verdere informatie op de helppictogrammen.",


        fieldOneInfo :"De lengte van een enkele lichtlijn.Let erop dat deze waarde naar beneden wordt afgerond op de dichtstbijzijnde waarde die deelbaar is door 1,5, omdat dit de lengte is van de producten die worden gebruikt.",
        fieldTwoInfo : "A trunking line consists of a back part (facing the ceiling) and a front part (facing the floor). The trunks form the back part. You need to have enough trunks to cover the full length of the line.The only difference between 3 meter and 1.5 meter trunks is the product's length and therefore the length of line that the product can cover. ",
        fieldThreeInfo : "This number determines how many lines you would like to replicate using the specifications that you are setting now.",
        fieldFourInfo : "When selecting a Trunking LED Module, note the following specifications:",
        fieldFourInfo1: "- All our LED Modules have a length of 1.5 meters",
        fieldFourInfo2: "- All our LED Modules have a colour temperature (CCT) of 4000 Kelvin",
        fieldFourInfo3: "- You can select a 35, 50 or 70 Watt module",
        fieldFourInfo4: "- You can select the following beam angles: 60˚, 90˚, 120˚, or Batwing (BW)",
        fieldFourInfo5: "- Daylight control and motion detection is only possible if you select a DALI LED module",
        fieldFiveInfo : "",
        fieldSixInfo : "Geef, als u noodverlichting wilt opnemen in uw lijn, a.u.b. aan of deze noodverlichtingsmodules 1 of 3 uur dienen te werken.",
        fieldEightInfo: "",
        fieldNineInfo : "",
        fieldTenInfo : "Geef, als u al weet hoeveel noodverlichtingsmodules u nodig heeft, het gewenste aantal aan.",
        fieldTenInfo1 : "Als u het exacte aantal nog niet weet, geeft u hier aan dat het aantal onbekend is.",
        fieldElevenInfo: "Voer het gewenste aantal noodverlichtingsmodules per lijn in. NB: Dit vervangt het exacte aantal LED-modules door de overeenkomstige noodverlichtingsmodules.",
        fieldThirteenInfo : "Een uitvoerdoos kan worden gebruikt om de stroom via het uiteinde van de lichtlijn uit te geleiden, zonder dat hiervoor een stroomrail nodig is.",
        fieldFourteenInfo : `Gebruik, zoals al genoemd bij modulebezetting op de vorige pagina, als uw lijn NIET eindigt met een LED-module, de standaardinstelling "een lijn beginnen met een LED-module". Als de lijn standaard eindigt met een LED-module, is er geen melding.`,
        fieldFourteenInfo1 : "Als u gebruikspecifieke wijzigingen wil doorvoeren die van deze situatie verschillen, kunt u deze hier aangeven.",
        fieldSixteenInfo : "Onze experts adviseren een afstand tussen de bevestigingspunten van 3 meter. De minimumafstand is 1 meter.",
        fieldSeventeenInfo : "Indien een DALI-module is geselecteerd, kunt u bewegingsdetectie en/of daglichtregeling toevoegen.",
        fieldEighteenInfo : "Hier kunt u de installatiehoogte in meters aangeven. Geef, als de lijn dient te worden opgehangen, de hoogte van de vloer tot het armatuur. In geval van plafondmontage kan de afstand van de vloer tot het plafond worden gebruikt.",
        fieldNineteenInfo : "Sensoren per lichtlijn.",
        fieldNineteenInfo1 : "Als u ervoor kiest dat een lichtlijn met een sensor begint, wordt er een sensor toegevoegd aan de lichtlijn. Deze kan bij het begin van de lijn worden geplaatst.",
        fieldNineteenInfo2 : "Als u ervoor kiest dat een lichtlijn met een sensor begint en eindigt, worden er twee sensoren toegevoegd aan de lichtlijn. Een dient bij het begin van de lijn te worden geplaatst en de tweede bij het eind van de lijn.",
        fieldNineteenInfo3 : "Als u kiest voor volledige sensorbezetting, berekent de configurator hoeveel sensoren er nodig zijn voor de optimale werking van de bewegingsdetectie en het daglichtregelsysteem, gebaseerd op de installatiehoogte van de lijn.",
        fieldNineteenInfo4 : "Als u kiest voor aangepaste bezetting, kunt u het gewenste aantal sensoren per lijn invoeren.",
        fieldTwentyInfo : "In dit veld kunt u aanvullende producten voor de lijn selecteren.De 3C Track Module vervangt een afdekkap in de lijn. Hierdoor heeft u de mogelijkheid OPPLE 3C Spots in uw lijn op te nemen.",
        fieldTwentyOneInfo : "Geef a.u.b. het aantal aanvullende producten aan dat u nodig heeft.",
        fieldTwentyThreeInfo : "Geef in dit veld het aantal sensoren aan dat u in deze lijn wilt opnemen.",
    },
    french:{
        selectCountryPopup: "Veuillez choisir votre pays afin que le projet soit envoyé à la bonne équipe de vente au cas où vous demanderiez un devis ultérieurement.",
        lineConfigurationInfoText : "Vous êtes sur le point de configurer une ligne lumineuse.",
        lineConfigurationInfoText2 : "Cliquez sur les icônes d’aide pour plus d’informations.",


        fieldOneInfo :"La longueur d’une seule ligne lumineuse. Attention, cette valeur est arrondie ver le bas à la valeur la plus proche divisible par 1,5, car c’est la longueur des produits utilisés.",
        fieldTwoInfo : "Een lichtlijn bestaat uit een achterste deel (tegen het plafond) en een voorste deel (naar de vloer gericht). De stroomrails vormen het achterste gedeelte. Er zijn voldoende stroomrails nodig voor de volledige lengte van de lijn. Het enige verschil tussen de lichtlijnen van 3 meter en 1,5 meter is de lengte van het product en daarmee de lengte van de lijn die het product kan bestrijken.",
        fieldThreeInfo : "Dit aantal bepaalt hoeveel lijnen u wilt vermenigvuldigen op basis van de specificaties die u nu bepaalt.",
        fieldFourInfo : "Let bij de keuze voor een LED-module voor een lichtlijn op de volgende specificaties:",
        fieldFourInfo1: "- Al onze LED-modules hebben een lengte van 1,5 meter",
        fieldFourInfo2: "- Al onze LED-modules hebben een kleurtemperatuur (CCT) van 4000 Kelvin",
        fieldFourInfo3: "- U kunt kiezen uit een 35, 50 of 70 Watt module",
        fieldFourInfo4: "- U kunt kiezen uit de volgende stralingshoeken: 60˚, 90˚, 120˚, of Batwing (BW)",
        fieldFourInfo5: "- Daglichtregeling en bewegingsdetectie zijn uitsluitend mogelijk als u een DALI LED-module kiest",
        fieldFiveInfo : "",
        fieldSixInfo : "Si vous souhaitez inclure un éclairage de secours dans votre ligne, veuillez préciser si ces modules d'éclairage de secours doivent fonctionner pendant 1 ou 3 heures.",
        fieldEightInfo: "",
        fieldNineInfo : "",
        fieldTenInfo : "Si vous savez déjà combien de modules d'éclairage de secours vous aurez besoin, indiquez le nombre souhaité.",
        fieldTenInfo1 : "Si vous ne connaissez pas encore le nombre exact, indiquez ici que le nombre est inconnu.",
        fieldElevenInfo: "Entrez le nombre de modules d'éclairage de secours souhaité par ligne. Remarque : le nombre exact de modules LED est remplacé par les modules d'éclairage de secours correspondants.",
        fieldThirteenInfo : "Un boîtier de sortie peut être utilisé pour acheminer l'alimentation à l'extrémité de la ligne d’éclairage, sans avoir besoin d'un rail d'alimentation.",
        fieldFourteenInfo : `Comme nous l'avons déjà mentionné dans l'affectation des modules à la page précédente, si votre ligne ne se termine PAS par un module LED, utilisez le paramètre par défaut "démarrer une ligne avec un module LED". Si la ligne se termine par un module LED par défaut, il n'y a pas de notification.`,
        fieldFourteenInfo1 : "Si vous souhaitez apporter des modifications spécifiques à l'utilisation qui diffèrent de cette situation, vous pouvez les indiquer ici.",
        fieldSixteenInfo : "Nos experts recommandent une distance entre les points de fixation de 3 mètres. La distance minimale est de 1 mètre.",
        fieldSeventeenInfo : "Si un module DALI est sélectionné, vous pouvez ajouter la détection de mouvement et/ou le contrôle de la lumière du jour.",
        fieldEighteenInfo : "Vous pouvez spécifier ici la hauteur d'installation en mètres. Si la ligne doit être suspendue, indiquez la hauteur entre le sol et le luminaire. En cas de montage au plafond, la distance entre le sol et le plafond peut être utilisée.",
        fieldNineteenInfo : "L’affectation des capteurs détermine leur mode de fonctionnement et le nombre de capteurs nécessaires par ligne lumineuse.",
        fieldNineteenInfo1 : "Si vous choisissez qu’une ligne lumineuse commence par un capteur, un capteur sera ajouté à la ligne lumineuse. Celui-ci pourra être placé au début de la ligne.",
        fieldNineteenInfo2 : "Si vous choisissez qu’une ligne lumineuse commence et se termine par un capteur, deux capteurs seront ajoutés à la ligne lumineuse. Un de ces capteurs devra être placé au début de la ligne et le second à la fin.",
        fieldNineteenInfo3 : "Si vous choisissez une affectation complète des capteurs, le configurateur calcule le nombre de capteurs nécessaires pour un fonctionnement optimal du système de détection de mouvement et de contrôle de la lumière du jour, en fonction de la hauteur d’installation de la ligne.",
        fieldNineteenInfo4 : "Si vous choisissez une affectation personnalisée, vous pouvez placer le nombre de capteurs souhaités par ligne.",
        fieldTwentyInfo : "Dans ce champ, vous pouvez sélectionner des produits supplémentaires pour la ligne.Le module 3C Track remplace un couvercle dans la ligne. Cela vous donne la possibilité d'inclure des Spots 3C d’OPPLE dans votre ligne.",
        fieldTwentyOneInfo : "Veuillez indiquer le nombre de produits supplémentaires dont vous avez besoin.",
        fieldTwentyThreeInfo : "Dans ce champ, veuillez indiquer le nombre de capteurs que vous souhaitez inclure dans cette ligne.",
    },
    german : {
        selectCountryPopup: "Bitte wählen Sie Ihr Land aus, damit das Projekt an das richtige Vertriebsteam übermittelt werden kann, falls Sie eine Angebotsanfrage senden.",
        lineConfigurationInfoText : "Sie sind dabei, ein Lichtband zu konfigurieren.",
        lineConfigurationInfoText2 : "Klicken Sie auf das jeweilige Hilfesymbol, um weitere Details angezeigt zu bekommen.",


        fieldOneInfo :"Die Länge eines einzelnen Lichtbandes. Beachten Sie, dass dieser Wert auf den nächsten Wert abgerundet wird, der durch 1,5 teilbar ist, da dies die Länge der verwendeten Produkte ist.",
        fieldTwoInfo : "Une ligne d'éclairage se compose d’une section arrière (contre le plafond) et d’une section avant (dirigée vers le sol). Les rails d’alimentation forment la section arrière. Il faut suffisamment de rails pour couvrir toute la longueur de la ligne.La seule différence entre les lignes d'éclairage de 3 mètres et 1,5 mètre est la longueur du produit et donc la longueur que le produit peut couvrir.",
        fieldThreeInfo : "Cette quantité détermine le nombre de lignes que vous voulez multiplier sur la base des spécifications que vous déterminez maintenant.",
        fieldFourInfo : "Lorsque vous choisissez un module LED pour une ligne d'éclairage, veuillez tenir compte des spécifications suivantes :",
        fieldFourInfo1: "- Tous nos modules LED ont une longueur de 1,5 mètre ",
        fieldFourInfo2: "- Tous nos modules LED ont une température de couleur (CCT) de 4 000 Kelvins",
        fieldFourInfo3: "- Vous pouvez choisir un module de 35, 50 ou 70 watts ",
        fieldFourInfo4: "- Vous pouvez choisir parmi les angles de faisceau suivants : 60˚, 90˚, 120˚, ou Batwing (BW)",
        fieldFourInfo5: "- Le contrôle de la lumière du jour et la détection de mouvement ne sont possibles que si vous choisissez un module LED DALI ",
        fieldFiveInfo : "",
        fieldSixInfo : "Falls Sie eine Notbeleuchtung in Ihre Anlage integrieren möchten, wählen Sie bitte aus, ob diese Notbeleuchtungsmodule eine Betriebszeit von 1 oder 3 Stunden haben sollen.",
        fieldEightInfo: "",
        fieldNineInfo : "",
        fieldTenInfo : "Wenn Sie bereits wissen, wie viele Notbeleuchtungsmodule in Ihrem Lichtband benötigt werden, geben Sie bitte an, dass die Anzahl bekannt ist.",
        fieldTenInfo1 : "Wenn Sie die genaue Anzahl nicht kennen, geben Sie hier an, dass die Anzahl nicht bekannt ist.",
        fieldElevenInfo: "Bitte tragen Sie die benutzerdefinierte Anzahl der Notbeleuchtungsmodule ein, die Sie pro Lichtband benötigen. Hinweis: Dadurch wird genau diese Anzahl von LED-Modulen durch ein Notbeleuchtungsmodul ersetzt.",
        fieldThirteenInfo : "Eine Feed-Out-Box kann verwendet werden, um den Strom aus dem Ende des Lichtbandes herauszuleiten, ohne dass eine Tragschiene erforderlich ist.",
        fieldFourteenInfo : `In der Modulbelegung auf der vorherigen Seite wurde bereits darauf hingewiesen: für den Fall, dass Ihr Lichtband NICHT mit einem LED-Modul endet, bei Verwendung der Standardeinstellung „Beginn des Lichtbandes mit einem LED-Modul". Wenn das Lichtband standardmäßig mit einem LED-Modul endet, wird diese Meldung nicht angezeigt.`,
        fieldFourteenInfo1 : "Wenn Sie benutzerdefinierte, vom Standard abweichende Änderungen vornehmen möchten, können Sie dies hier vermerken.",
        fieldSixteenInfo : "Unsere Experten empfehlen einen Abstand von 3 m zwischen den Montagepunkten. Der Mindestabstand beträgt 1 m.",
        fieldSeventeenInfo : "Wenn Sie ein DALI-Modul ausgewählt haben, können Sie einen Bewegungsmelder und/oder Tageslichtsensor hinzufügen.",
        fieldEighteenInfo : "Geben Sie hier bitte die Installationshöhe in Metern an. Wenn das Lichtband aufgehängt werden soll, geben Sie bitte die Höhe vom Boden bis zur Leuchte an. Bei einer Deckenmontage kann die Höhe vom Boden bis zur Decke angegeben werden.",
        fieldNineteenInfo : "Mit der Sensorbelegung wird festgelegt, wie die Sensoren positioniert und wie viele Sensoren pro Lichtband benötigt werden.",
        fieldNineteenInfo1 : "Wenn Sie als Option ein Lichtband mit Sensor am Anfang des Bandes auswählen, wird dem Lichtband ein Sensor hinzugefügt, der in der Nähe des Bandanfangs positioniert werden kann.",
        fieldNineteenInfo2 : "Wenn Sie als Option ein Lichtband mit Sensoren am Anfang und Ende des Bandes auswählen, werden dem Lichtband zwei Sensoren hinzugefügt, von denen einer in der Nähe des Anfangs und der andere in der Nähe des Endes des Bandes positioniert wird.",
        fieldNineteenInfo3 : "Wenn Sie als Option eine komplette Sensorbelegung wählen, berechnet der Konfigurator anhand der Installationshöhe des Lichtbandes, wie viele Sensoren für eine optimale Nutzung der Bewegungserkennung und Tageslichtsteuerung erforderlich sind.",
        fieldNineteenInfo4 : "Wenn Sie eine benutzerdefinierte Belegung wählen, können Sie die gewünschte Anzahl von Sensoren pro Lichtband eingeben.",
        fieldTwentyInfo : "Hier können Sie zusätzliche Produkte für das Lichtband auswählen. Das 3C-Trackmodul ersetzt eine Blindabdeckung im Lichtband. Dadurch erhalten Sie die Möglichkeit, 3C-Spots von OPPLE in das Lichtband zu integrieren.",
        fieldTwentyOneInfo : "Bitte geben Sie die Anzahl der zusätzlichen Produkte ein, die Sie benötigen.",
        fieldTwentyThreeInfo : "Bitte geben Sie hier die Anzahl der Sensoren an, die Sie in dem Lichtband verwenden möchten.",
    },
    spanish :{
        selectCountryPopup: "Elija un país para que el proyecto se envíe al equipo de ventas correcto, si más tarde necesita un presupuesto.",
        lineConfigurationInfoText : "Está a punto de empezar a configurar una línea.",
        lineConfigurationInfoText2 : "Haga clic en los iconos de Ayuda para ver más detalles.",


        fieldOneInfo :"El largo de una sola línea.Tenga en cuenta que este valor se redondeará al valor a la baja más cercano múltiplo de 1,5 ya que este es el largo de los productos empleados.",
        fieldTwoInfo : "Ein Lichtband besteht aus einem Rückteil (zur Decke hin) und einem Vorderteil (zum Boden hin). Die Tragschienen bilden das Rückteil. Es müssen genügend Tragschienen vorhanden sein, um die gesamte Länge des Lichtbandes abzudecken.Der einzige Unterschied zwischen Tragschienen mit 3 m und 1,5 m ist die Produktlänge und somit die Länge des Lichtbandes, die von dem Produkt abgedeckt werden kann.",
        fieldThreeInfo : "Diese Zahl legt fest, wie viele Lichtbänder Sie mit den jetzt eingestellten Spezifikationen nacheinander installieren wollen.w",
        fieldFourInfo : "Bei der Auswahl eines LED-Moduls für das Lichtband beachten Sie bitte Folgendes:",
        fieldFourInfo1: "- Alle unsere LED-Module haben eine Länge von 1,5 m",
        fieldFourInfo2: "- Alle unsere LED-Module haben eine Farbtemperatur von 4000 Kelvin",
        fieldFourInfo3: "- Zur Auswahl stehen Module mit 35, 50 oder 70 Watt",
        fieldFourInfo4: "- Folgende Abstrahlwinkel stehen zur Auswahl: 60˚, 90˚, 120˚ oder Batwing (BW)",
        fieldFourInfo5: "- Tageslichtsensor und Bewegungsmelder stehen nur bei Auswahl eines Dali LED-Moduls zur Verfügung",
        fieldFiveInfo : "",
        fieldSixInfo : "Si quiere integrar iluminación de emergencia en su línea, seleccione si estos módulos de emergencia deberían tener un tiempo operativo de 1 o 3 horas.",
        fieldEightInfo: "",
        fieldNineInfo : "",
        fieldTenInfo : "Si ya sabe cuántos módulos de emergencia necesita en su línea troncal, ponga que sabe la cantidad.",
        fieldTenInfo1 : "Si no sabe la cantidad exacta, ponga aquí que se desconoce la cantidad.",
        fieldElevenInfo: "Escriba la cantidad personalizada de módulos de emergencia que necesita por línea. Nota: Esto sustituirá el número exacto de módulos LED por sus equivalentes de luces de emergencia.",
        fieldThirteenInfo : "Se puede usar una caja de alimentación para direccionar el suministro al final de la línea troncal, sin que sea necesario un troncal.",
        fieldFourteenInfo : `En la página anterior sobre ocupación del módulo, se comentó que si su línea NO termina en un módulo LED, use la configuración predeterminada de «empezar una línea con un módulo LED». En este caso predeterminado, si la línea termina con un módulo LED, no se dio ningún mensaje.`,
        fieldFourteenInfo1 : "Si quiere hacer un cambio personalizado que difiera de esta situación, márquelo aquí.",
        fieldSixteenInfo : "Nuestros expertos aconsejan una distancia entre puntos de montaje de 3 metros. La distancia mínima es de 1 metro.",
        fieldSeventeenInfo : "Si se selecciona un módulo DALI, puede añadir la opción de detección de movimiento y/o control de luz diurna.",
        fieldEighteenInfo : "Aquí puede poner la altura de la instalación en metros. Si la línea está suspendida, ponga la altura desde el suelo hasta la luminaria. En caso de un montaje en techo, se puede usar la altura del suelo hasta el techo.",
        fieldNineteenInfo : "La asignación del sensor determina cómo funcionarán los sensores y cuántos hacen falta por línea.",
        fieldNineteenInfo1 : "Cuando elige una opción para una línea empezando con un sensor, se añadirá un sensor a la línea, que se puede colocar cerca del principio de la línea.",
        fieldNineteenInfo2 : "Cuando elige una opción para una línea empezando y terminando con un sensor, se añadirán dos sensores a la línea, uno se colocará cerca del principio de la línea y otro casi al final.",
        fieldNineteenInfo3 : "Cuando elige la opción de asignación completa de sensores, el configurador asignará la cantidad de sensores necesarios para optimizar el funcionamiento del sistema de detección de movimiento y control de luz diurna, basándose en la altura de la instalación de la línea.",
        fieldNineteenInfo4 : "Cuando elige una asignación personalizada, puede escribir el número deseado de sensores por línea.",
        fieldTwentyInfo : "En este campo, se pueden seleccionar productos adicionales para la línea.",
        fieldTwentyOneInfo : "Escriba la cantidad de productos adicionales que necesite.",
        fieldTwentyThreeInfo : "Ponga en este campo el número de sensores que desea integrar en esta línea.",
    },
    portugese :{
        selectCountryPopup: "Selecione o seu país para que o projeto possa ser enviado para a equipa de vendas correta, caso pretenda solicitar um orçamento mais tarde.",
        lineConfigurationInfoText : "Está prestes a começar a configurar uma linha.",
        lineConfigurationInfoText2 : "Clique nos ícones de Ajuda para ver mais detalhes.",


        fieldOneInfo :"O comprimento de uma única linha.Note que este valor será arredondado para baixo o valor mais próximo que pode ser dividido por 1,5, uma vez que este é o comprimento dos produtos utilizados.",
        fieldTwoInfo : "Una línea troncal se compone de una parte de atrás (de cara al techo) y una parte frontal (de cara al suelo). Los troncales forman la parte de atrás. Tiene que contar con suficientes troncales para cubrir el largo completo de la línea.La única diferencia entre los troncales de 3 y 1,5 metros es el largo del producto y por lo tanto la distancia de línea que el producto puede cubrir.",
        fieldThreeInfo : "Este número determina cuántas líneas quiere replicar usando los ajustes que está configurando ahora.",
        fieldFourInfo : "Al elegir un módulo troncal LED, tenga en cuenta las siguientes especificaciones:",
        fieldFourInfo1: "- Todos nuestros módulos LED tienen un largo de 1,5 metros",
        fieldFourInfo2: "- Todos nuestros módulos LED tienen una temperatura de color (CCT) de 4000 Kelvin",
        fieldFourInfo3: "- Puede seleccionar un módulo de 35, 50 o 70 Watt",
        fieldFourInfo4: "- Puede elegir los siguientes ángulos de haz de luz: 60˚, 90˚, 120˚, o Batwing (Ala de murciélago)",
        fieldFourInfo5: "- Solo se puede hacer un control de luz diurna y detección de movimiento si selecciona un módulo DALI LED",
        fieldFiveInfo : "",
        fieldSixInfo : "Si quiere integrar iluminación de emergencia en su línea, seleccione si estos módulos de emergencia deberían tener un tiempo operativo de 1 o 3 horas.",
        fieldEightInfo: "",
        fieldNineInfo : "",
        fieldTenInfo : "Se já souber quantos módulos de emergência são necessários na sua linha de junção, indique que a quantidade é conhecida.",
        fieldTenInfo1 : "Se não souber a quantidade exata, indique aqui que a quantidade é desconhecida.",
        fieldElevenInfo: "Introduza a quantidade personalizada de módulos de emergência de que necessita por linha. Nota: Isto irá substituir este número exato de módulos LED pelos seus equivalentes de iluminação de emergência.",
        fieldThirteenInfo : "Pode ser usada uma caixa de saída (feed-out) para guiar a energia para fora da extremidade de uma linha de junção, sem precisar de uma junção.",
        fieldFourteenInfo : `A Ocupação do módulo na página anterior já indicava isso, caso a sua linha NÃO termine com um módulo LED, utilizando a predefinição de "iniciar uma linha com um módulo LED". Se a linha neste caso predefinido terminar com um módulo LED, não terá sido apresentada qualquer mensagem.`,
        fieldFourteenInfo1 : "Se pretender fazer quaisquer alterações personalizadas que variem desta situação, pode assinalá-las aqui.",
        fieldSixteenInfo : "Os nossos especialistas recomendam uma distância entre pontos de montagem de 3 metros. A distância mínima é de 1 metro.",
        fieldSeventeenInfo : "No caso de ter sido selecionado um módulo DALI, pode adicionar a deteção de movimento e/ou o controlo da luz diurna.",
        fieldEighteenInfo : "Aqui pode indicar a altura de instalação em metros. Se for suspender a linha, indique a altura do pavimento até à luminária. No caso de pretender a montagem no teto, pode ser utilizada a altura do pavimento ao teto.",
        fieldNineteenInfo : "La asignación del sensor determina cómo funcionarán los sensores y cuántos hacen falta por línea.",
        fieldNineteenInfo1 : "Cuando elige una opción para una línea empezando con un sensor, se añadirá un sensor a la línea, que se puede colocar cerca del principio de la línea.",
        fieldNineteenInfo2 : "Cuando elige una opción para una línea empezando y terminando con un sensor, se añadirán dos sensores a la línea, uno se colocará cerca del principio de la línea y otro casi al final.",
        fieldNineteenInfo3 : "Cuando elige la opción de asignación completa de sensores, el configurador asignará la cantidad de sensores necesarios para optimizar el funcionamiento del sistema de detección de movimiento y control de luz diurna, basándose en la altura de la instalación de la línea.",
        fieldNineteenInfo4 : "Cuando elige una asignación personalizada, puede escribir el número deseado de sensores por línea.",
        fieldTwentyInfo : "Neste campo, podem ser selecionados produtos adicionais para a linha. O acessório 3C Track Module substitui uma tampa cega na linha. Permite-lhe integrar pontos OPPLE 3C na sua linha.",
        fieldTwentyOneInfo : "Introduza a quantidade de produtos adicionais que pretende.",
        fieldTwentyThreeInfo : "Indique neste campo o número de sensores que pretende integrar nesta linha.",
    },
    finnish : {
        selectCountryPopup: "Valitse maasi, jotta projekti voidaan lähettää oikealle myyntitiimille, mikäli pyydät tarjousta myöhemmin.",
        lineConfigurationInfoText : "Olet aloittamassa linjan määritystä.",
        lineConfigurationInfoText2 : "Napsauta vapaasti Ohje-kuvakkeita saadaksesi lisätietoja.",


        fieldOneInfo :"Yhden linjan pituus.Huomaa, että tämä arvo pyöristetään alaspäom lähimpään arvoon, joka voidaan jakaa 1,5:llä, koska tämä on käytettyjen tuotteiden pituus.",
        fieldTwoInfo : "Uma linha de junção consiste numa parte posterior (voltada para o teto) e numa parte frontal (voltada para o pavimento). As junções formam a parte traseira. Precisa de ter junções suficientes para cobrir todo o comprimento da linha.A única diferença entre junções de 3 e 1,5 metros é o comprimento do produto e, portanto, o comprimento da linha que o produto pode cobrir. ",
        fieldThreeInfo : "Este número determina quantas linhas gostaria de replicar usando as especificações que está a definir agora.",
        fieldFourInfo : "Ao selecionar um módulo LED de junção (Trunking), observe as seguintes especificações:",
        fieldFourInfo1: "- Todos os nossos módulos LED têm um comprimento de 1,5 metros",
        fieldFourInfo2: "- Todos os nossos módulos LED têm uma temperatura de cor (CCT) de 4000 Kelvin",
        fieldFourInfo3: " Pode selecionar um módulo de 35, 50 ou 70 Watts",
        fieldFourInfo4: "- Pode selecionar os seguintes ângulos de feixe: 60˚, 90 ˚, 120 ˚ ou Batwing (BW)",
        fieldFourInfo5: "- O controlo da luz diurna e a deteção de movimento só são possíveis se selecionar um módulo LED DALI",
        fieldFiveInfo : "",
        fieldSixInfo : "Jos haluat integroida hätävalaistuksen linjaasi, valitse, pitäisikö näiden hätämoduulien olla käyttöajaltaan 1 vai 3 tuntia.",
        fieldEightInfo: "",
        fieldNineInfo : "",
        fieldTenInfo : "Jos tiedät jo, kuinka monta hätämoduulia tarvitaan kanavajohtoosi, ilmoita, että määrä on tiedossa.",
        fieldTenInfo1 : "Jos et tiedä tarkkaa määrää, ilmoita tässä, että määrää ei tunneta. ",
        fieldElevenInfo: "Anna mukautettu hätämoduulien määrä linjaa kohti. Huomaa: Tämä korvaa tarkan määrän LED-moduuleja vastaavilla hätävalaistuksilla.",
        fieldThirteenInfo : "Syöttölaatikkoa voidaan käyttää virran johtamiseen kanavajohdon päästä tarvitsematta kanavaa.",
        fieldFourteenInfo : `Edellisellä sivulla oleva moduulien sijainti mainitsi sen, että jos linjasi EI pääty ja LED-moduuliin, käyttämällä oletusasetusta "rivin aloittaminen LED-moduulilla" ". Jos rivi tässä oletusarvoisessa tapauksessa päättyy LED-moduuli, viestiä ei annettu.`,
        fieldFourteenInfo1 : "Jos haluat tehdä mukautettuja muutoksia jotka poikkeavat tästä tilanteesta, voit merkitä sen tähän.",
        fieldSixteenInfo : "Asiantuntijamme suosittelevat 3 metrin kiinnityskohtien välistä etäisyyttä. Pienin etäisyys on 1 metri.",
        fieldSeventeenInfo : "Jos DALI-moduuli on valittu, voit lisätä liiketunnistuksen ja / tai päivänvalon hallinnan.",
        fieldEighteenInfo : "Täällä voit mainita asennuskorkeuden metreinä. Kun linja keskeytetään, mainitse korkeus lattiasta valaisimeen. Kattoasennuksessa voidaan käyttää korkeutta lattiasta kattoon.",
        fieldNineteenInfo : "A ocupação do sensor determina como os sensores funcionarão e o número de sensores necessários por linha.",
        fieldNineteenInfo1 : "Quando seleciona a opção para uma linha que começa com um sensor, é adicionado um sensor à linha, que pode ser posicionado próximo do início da linha.",
        fieldNineteenInfo2 : "Quando seleciona a opção para uma linha que começa e termina com um sensor, serão adicionados dois sensores à linha, um será posicionado próximo do início e o outro próximo do fim da linha.",
        fieldNineteenInfo3 : "Quando seleciona a opção para a ocupação total de sensores, o configurador calcula quantos sensores são necessários para obter um funcionamento ideal para a deteção de movimento e o sistema de controlo da luz diurna, com base na altura de instalação da linha.",
        fieldNineteenInfo4 : "Ao selecionar a ocupação personalizada, pode inserir o número pretendido de sensores por linha.",
        fieldTwentyInfo : "Tältä kentältä voidaan valita muita tuotteita linjalle. 3C-raitamoduuli korvaa linjan sokean kannen. Sen avulla voit integroida OPPLE 3C -paikat linjaasi. ",
        fieldTwentyOneInfo : "Anna tarvitsemiesi lisätuotteiden määrä.",
        fieldTwentyThreeInfo : "Mainitse tässä kentässä anturien määrä, jotka haluat integroida tähän linjaan.",
    },
    polish : {
        selectCountryPopup: "Proszę wybrać kraj, aby projekt mógł zostać przesłany do odpowiedniego zespołu ds. sprzedaży, jeżeli chcesz później otrzymać ofertę.",
        lineConfigurationInfoText : "Zaraz rozpoczniesz konfigurację linii.",
        lineConfigurationInfoText2 : "Aby zobaczyć dalsze szczegóły, kliknij ikonki pomocy.",


        fieldOneInfo :"Długość pojedynczej linii. Należy pamiętać, że ta wartość zostanie zaokrąglona w dół do najbliższej wartości, która może być podzielona przez 1,5, ponieważ taka jest długość stosowanych produktów.",
        fieldTwoInfo : "Kanavalinja koostuu takaosasta (kattoon päin) ja etuosasta (lattiaa vasten). Kanavat muodostavat takaosan. Kanavien täytyy olla riittävät kattamaan linjan koko pituus. Ainoa ero 3 metrin ja 1,5 metrin linjatyyppien välillä on tuotteen pituus ja siten linjan pituus, johonka tuote riittää. ",
        fieldThreeInfo : "Tämä numero määrittää, kuinka monta linjaa haluat kopioida käyttämällä määrittämiäsi asetuksia.",
        fieldFourInfo : "Kun valitset kanavan LED-moduulin, huomioi seuraavat tekniset tiedot:",
        fieldFourInfo1: "- Kaikkien LED-moduulien pituus on 1,5 metriä",
        fieldFourInfo2: "- Kaikkien LED-moduulien värilämpötila (CCT) on 4 000 kelviniä",
        fieldFourInfo3: "- Voit valita 35, 50 tai 70 watin moduulin",
        fieldFourInfo4: "- Voit valita seuraavat sädekulmat: 60˚, 90˚, 120˚ tai Batwing (BW)",
        fieldFourInfo5: "- Päivänvalon hallinta ja liiketunnistus ovat mahdollisia vain, jos valitset DALI-LED-moduulin ",
        fieldFiveInfo : "",
        fieldSixInfo : "Jeżeli na linii wymagane jest zintegrowane oświetlenie alarmowe, proszę wybrać, czy moduły awaryjne powinny działać przez 1 godzinę, czy 3 godziny.",
        fieldEightInfo: "",
        fieldNineInfo : "",
        fieldTenInfo : "W przypadku gdy liczba potrzebnych modułów awaryjnych na linii świetlnej jest znana, proszę podać liczbę.",
        fieldTenInfo1 : "Jeżeli dokładna liczba jest nieznana, proszę to zaznaczyć.",
        fieldElevenInfo: "Proszę podać wymaganą liczbę modułów oświetlenia alarmowego na linię. Uwaga: zastąpi ona dokładną liczbę modułów LED ekwiwalentami oświetlenia awaryjnego.",
        fieldThirteenInfo : "Feed-Out-Box zostanie użyty do odprowadzenia energii z końca linii świetlnej, bez potrzeby zakładania listwy.",
        fieldFourteenInfo : "Zostało to już wspomniane na poprzedniej stronie Modułu zajętości, w razie gdyby linia NIE kończyła się Modułem LED, korzystając z ustawienia domyślnego: „linia rozpoczyna się Modułem LED”. W przypadku gdy w ustawieniach domyślnych linia nie kończy się Modułem LED, nie było żadnego komunikatu.",
        fieldFourteenInfo1 : `Jeżeli chcesz wprowadzić jakiekolwiek niestandardowe zmiany, które różnią się od tej sytuacji, możesz je tutaj zaznaczyć.`,
        fieldSixteenInfo : "Nasi eksperci zalecają odległość między punktami montażowymi wynoszącą 3 metry. Minimalna odległość wynosi 1 metr.",
        fieldSeventeenInfo : "W przypadku wybrania Modułu DALI można dodać czujnik ruchu i/lub kontrolę światła dziennego.",
        fieldEighteenInfo : "Tutaj można podać wysokość instalacji w metrach. Kiedy linia zostanie zawieszona, należy podać wysokość od podłogi do oprawy oświetleniowej. W przypadku montażu na suficie można wykorzystać wysokość od podłogi do sufitu.",
        fieldNineteenInfo : "Anturien kohdentaminen määrittää sen, kuinka anturit toimivat ja kuinka monta anturia linjaa kohden tarvitaan.",
        fieldNineteenInfo1 : "Kun valitset anturilla alkavan linjan vaihtoehdon, linjalle lisätään yksi anturi, joka voidaan sijoittaa lähelle linjan alkua.",
        fieldNineteenInfo2 : "Kun valitset vaihtoehdon anturilla alkavalle ja päättyvälle linjalle, kaksi anturia lisätään linjaan, yksi sijoitetaan lähelle linjan alkua ja toinen lähelle linjan loppua.",
        fieldNineteenInfo3 : "Kun valitset anturin täyden allokoinnin vaihtoehdon, konfiguroija laskee linjan asennuskorkeuden perusteella, kuinka monta anturia tarvitaan liiketunnistimen ja päivänvalon ohjausjärjestelmän optimaalisen toiminnan saavuttamiseksi.",
        fieldNineteenInfo4 : "Kun valitset mukautetun kohdistuksen, voit syöttää haluamasi määrän antureita linjaa kohden.",
        fieldTwentyInfo : "To pole służy do dokonania wyboru dodatkowych produktów na linię, Moduł 3C Track zastępuje zaślepkę na linii. Umożliwia zintegrowanie opraw punktowych OPPLE 3C na linii.",
        fieldTwentyOneInfo : "Proszę podać liczbę wymaganych dodatkowych produktów.",
        fieldTwentyThreeInfo : "W tym polu należy wpisać liczbę czujników, jakie mają być zintegrowane z tą linią.",
    },
    italian : {
        selectCountryPopup: "Seleziona il tuo paese, così il progetto potrà essere inviato al team delle vendite di quel paese, nell'evenienza che tu possa richiedere successivamente una stima.",
        lineConfigurationInfoText : "Stai per configurare un binario.",
        lineConfigurationInfoText2 : "Non esitare a cliccare sulle icone di Help per visualizzare maggiori dettagli.",


        fieldOneInfo :"La lunghezza di un singolo binario. Ti facciamo notare che questo valore verrà arrotondato per difetto per ottenere il valore più vicino divisibile per 1,5, poiché questa è la lunghezza dei prodotti utilizzati.",
        fieldTwoInfo : "Linia świetlna składa się z tylnej części (zwróconej w stronę sufitu) oraz przedniej części (w kierunku podłogi). Listwy tworzą tylną część. Potrzebna jest odpowiednia liczba listew, aby pokryć całą długość linii. Jedyną różnicą między listwą 3-metrową a 1,5-metrową jest długość produktu, a zatem długość linii, jaką może pokryć produkt. ",
        fieldThreeInfo : "Liczba ta decyduje o tym, ile linii można odtworzyć przy użyciu ustawianych teraz specyfikacji.",
        fieldFourInfo : "Wybierając moduł świetlny LED, należy uwzględnić następujące specyfikacje:",
        fieldFourInfo1: "- Wszystkie nasze moduły LED mają długość 1,5 metra",
        fieldFourInfo2: "- Wszystkie nasze moduły LED mają barwę światła (CCT) 4 000 Kelvina",
        fieldFourInfo3: "- Można wybrać moduł 35, 50 lub 70-watowy",
        fieldFourInfo4: "- Można wybrać spośród następujących kątów świecenia: 60˚, 90˚, 120˚ lub Batwing (BW)",
        fieldFourInfo5: "- Kontrola światła dziennego i czujnik ruchu są możliwe wyłącznie po wybraniu modułu DALI LED",
        fieldFiveInfo : "",
        fieldSixInfo : "Se vuoi integrare l'illuminazione di emergenza nel sistema a binario, indica se questi moduli d'emergenza debbano avere un tempo di funzionamento di 1 ora o di 3 ore.",
        fieldEightInfo: "",
        fieldNineInfo : "",
        fieldTenInfo : "Se sai già quanti moduli d'emergenza sono necessari nel tuo sistema a binario, indica che il numero di moduli è noto.",
        fieldTenInfo1 : `Se non conosci la quantità precisa, indica che il numero non è noto.`,
        fieldElevenInfo: "Inserisci il numero specifico di moduli d'emergenza necessari per binario. Attenzione: così sostituirai questo numero esatto di moduli LED con i loro equivalenti per l'illuminazione di emergenza.",
        fieldThirteenInfo : "Si può utilizzare una scatola di alimentazione Feed-Out-Box per condurre l'energia elettrica fuori da un sistema a binario senza dover installare un canale portante.",
        fieldFourteenInfo : `L'assegnazione del modulo alla pagina precedente lo menzionava già nel caso in cui questo sistema a binario NON finisce con un modulo LED, utilizzando l'impostazione prestabilita "inizio del sistema a binario con un modulo LED". Se il sistema a binario, in presenza di questa impostazione prestabilita, finisce con un modulo LED, non comparirà nessun messaggio.`,
        fieldFourteenInfo1 : "Se intendi apportare delle modifiche per personalizzare il sistema, che differiscono dalla situazione qui presentata, puoi indicarlo qui.",
        fieldSixteenInfo : "Gli esperti di Opple raccomandano una distanza tra i punti di fissaggio di 3 metri. La distanza minima è di 1 metro.",
        fieldSeventeenInfo : "Se hai selezionato un modulo DALI, puoi aggiungere il sensore di rilevazione movimento e/o luce diurna.",
        fieldEighteenInfo : "Qui puoi indicare l'altezza dell'installazione in metri. Quando il sistema a binario è a sospensione, indica l'altezza dal pavimento fino all'apparecchio. In caso di installazione a plafone, indica l'altezza dal pavimento al soffitto.",
        fieldNineteenInfo : "Zajętość czujnika określa, jak będą działać czujniki oraz ile czujników jest wymaganych na każdej linii.",
        fieldNineteenInfo1 : "Po wybraniu opcji z czujnikiem na początku linii zostanie dodany jeden czujnik, który może zostać umieszczony na początku linii.",
        fieldNineteenInfo2 : "Po wybraniu opcji z czujnikiem na początku i na końcu linii dwa czujniki zostaną dodane do linii: jeden zostanie umieszczony na początku linii, a drugi przy końcu linii.",
        fieldNineteenInfo3 : "W przypadku wybrania opcji pełnej zajętości czujnika konfigurator obliczy na podstawie wysokości instalacji linii, ile czujników jest wymaganych w celu uzyskania optymalnego działania czujnika ruchu i systemu kontroli światła dziennego.",
        fieldNineteenInfo4 : "Wybierając opcję żądanej zajętości, można podać wymaganą liczbę czujników na linię.",
        fieldTwentyInfo : "In questo campo puoi selezionare prodotti addizionali per il sistema a binario. Il modulo 3C Track sostituisce un coperchio cieco nel  sistema a binario. Consente l'integrazione dei faretti 3C OPPLE nel tuo sistema a binario.",
        fieldTwentyOneInfo : "Inserisci la quantità di prodotti addizionali necessari.",
        fieldTwentyThreeInfo : "Indica in questo campo il numero di sensori che desideri integrare in questo sistema a binario.",
    }
}