// comment starting with 1 are for projects
export const comments ={
    english :{
        "comment101" : "Begin-End allocation is not possible, please try a different allocation or change the installation height",
        "comment102" : "Full allocation is not possible, please try a different allocation or change the installation height",
        "comment103" : "The line does not have enough free space for this custom number, please try a lower number",
        "comment104" : "Invalid entry, this value will not be saved",
        "comment105" : "There is not enough free space in the line for extra components",
        "comment106" : "Custom number cannot be more than blind covers. Please try a smaller number",
        "comment107" : "Amount of Emergency modules is unknown"
    },
    dutch : {
        "comment101" : "Begin-eindbezetting is niet mogelijk, probeer een andere bezetting of wijzig de installatiehoogte",
        "comment102" : "Volledige bezetting is niet mogelijk, probeer een andere bezetting of wijzig de installatiehoogte",
        "comment103" : "De lijn heeft niet voldoende vrije ruimte voor dit klantnummer, probeer een lager aantal",
        "comment104" : "Ongeldige invoer, deze waarde wordt niet opgeslagen",
        "comment105" : "Er is niet voldoende ruimte in de lichtlijn voor extra componenten",
        "comment106" : "Het aangepaste aantal kan niet meer zijn dan het aantal Blind Covers. Probeer een kleiner aantal",
        "comment107" : "Aantal noodmodules is onbekend"
    },
    french : {
        "comment101" : "L’affectation du début à la fin n’est pas possible, veuillez essayer une autre affectation ou modifier la hauteur d’installation",
        "comment102" : "L’affectation complète n’est pas possible, veuillez essayer une autre affectation ou modifier la hauteur d’installation",
        "comment103" : "La ligne n'a pas assez d'espace libre pour ce numéro de client, essayez un numéro plus petit.",
        "comment104" : "Entrée incorrecte, cette valeur ne sera pas sauvegardée",
        "comment105" : "Il n’y a pas suffisamment d’espace dans la ligne lumineuse pour des composants supplémentaires",
        "comment106" : "Le numéro personnalisé ne peut pas être plus grand que les couvertures aveugles, veuillez essayer un numéro plus petit",
        "comment107" : "Le montant des modules d'urgence est inconnu"
    },
    german : {
        "comment101" : "Eine Belegung am Anfang oder Ende ist nicht möglich, bitte versuchen Sie eine andere Belegung oder ändern Sie die Einbauhöhe.",
        "comment102" : "Eine vollständige Belegung ist nicht möglich, bitte versuchen Sie eine andere Belegung oder ändern Sie die Einbauhöhe.",
        "comment103" : "Das Lichtband hat nicht genügend Platz für die benutzerdefinierte Anzahl, bitte versuchen Sie es mit einer niedrigeren Nummer.",
        "comment104" : "Ungültige Eingabe, dieser Wert wird nicht gespeichert.",
        "comment105" : "Das Lichtband bietet nicht genügend Platz für zusätzliche Produkte.",
        "comment106" : "Benutzerdefinierte Anzahl kann nicht mehr als Blindabdeckungen sein, bitte versuchen Sie eine kleinere Anzahl",
        "comment107" : "Die Anzahl der Notfallmodule ist unbekannt"
    },
    spanish : {
        "comment101" : "No se puede asignar inicio-final. Pruebe una asignación diferente o cambie la altura de la instalación.",
        "comment102" : "No se puede completar la asignación. Pruebe una asignación diferente o cambie la altura de la instalación.",
        "comment103" : "La línea no tiene suficiente espacio para este número personalizado. Escriba un valor inferior",
        "comment104" : "Entrada no válida. No se guardará este valor",
        "comment105" : "No hay suficiente espacio libre en la línea para añadir componentes",
        "comment106" : "El número personalizado no puede ser superior a las tapas ciegas",
        "comment107" : "Se desconoce la cantidad de módulos de emergencia"
    },
    portugese : {
        "comment101" : "Não é possível iniciar-terminar a ocupação. Tente uma ocupação diferente ou altere a altura de instalação",
        "comment102" : "Não é possível uma ocupação total. Tente uma ocupação diferente ou altere a altura de instalação",
        "comment103" : "A linha não possui espaço livre suficiente para este número personalizado. Tente um número inferior",
        "comment104" : "Entrada inválida, este valor não será guardado",
        "comment105" : "Não existe espaço livre suficiente na linha para componentes extra",
        "comment106" : "O número personalizado não pode ser mais do que uma tampa cega, por favor tente um número menor",
        "comment107" : "Quantidade de módulos de emergência é desconhecida"
    },
    finnish : {
        "comment101" : "Alku-loppu allokaatio ei ole mahdollista, kokeile toista kohdentamista tai muuta asennuskorkeutta",
        "comment102" : "Täysi allokointi ei ole mahdollista, kokeile toista allokointia tai muuta asennuskorkeutta",
        "comment103" : "Linjalla ei ole tarpeeksi vapaata tilaa tälle mukautetulle numerolle, kokeile pienempää numeroa",
        "comment104" : "Virheellinen merkintä, tätä arvoa ei tallenneta",
        "comment105" : "Linjassa ei ole tarpeeksi vapaata tilaa ylimääräisille komponenteille",
        "comment106" : "Mukautettu numero ei voi olla enemmän kuin sokea covers. Kiitos kokeilla pienempi numero",
        "comment107" : "Hätämoduulien määrä ei ole tiedossa"
    },
    polish : {
        "comment101" : "Zajętość początek-koniec nie jest możliwa, proszę wybrać inną zajętość lub zmienić wysokość instalacji",
        "comment102" : "Pełna zajętość nie jest możliwa, proszę wybrać inną zajętość lub zmienić wysokość instalacji",
        "comment103" : "Na linii nie ma wystarczająco dużo wolnego miejsca na tę liczbę. Proszę podać mniejszą liczbę",
        "comment104" : "Nieprawidłowy wpis, ta wartość nie zostanie zapisana",
        "comment105" : "Nie ma wystarczająco dużo wolnego miejsca na linii na dodatkowe elementy",
        "comment106" : "Numer niestandardowy nie może być większy niż osłony, proszę spróbować mniejszego numeru",
        "comment107" : "Ilość modułów awaryjnych jest nieznana"
    },
    italian : {
        "comment101" : "Assegnazione inizio-fine impossibile, per favore prova un'assegnazione diversa o modifica l'altezza dell'impianto",
        "comment102" : "Assegnazione di tutte le unità impossibile, per favore prova un'assegnazione diversa o modifica l'altezza dell'impianto",
        "comment103" : "Il binario non ha sufficiente spazio libero per questo numero specifico di unità, per favore prova un numero inferiore ",
        "comment104" : "Input non valido, questo valore non sarà salvato",
        "comment105" : "Non c'è sufficiente spazio libero nel binario per altri componenti",
        "comment106" : "Il numero personalizzato non può essere più di coperture cieche.Si prega di provare un numero più piccolo",
        "comment107" : "La quantità di moduli di emergenza è sconosciuta"
    }
}