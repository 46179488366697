import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { fieldTwentyThreeInput, lineStatus } from "../../actions/lineActions";
import {Grid,Paper,Typography,Link,TextField,CssBaseline,Tooltip} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { inputLabels } from "../../lang/inputLabels";
import { inputLabelOptions } from "../../lang/inputLabelOptions";
import {pageContent} from "../../lang/pageContent";
import {errorText} from "../../lang/errorText";

import Controls from "../controls/Controls";
import HelpIcon from "@material-ui/icons/Help";

import {fieldTwentyThreeInfo} from "../../actions/popupActions";
import Popup from "../../components/Popup";
import FieldTwentyThreeInfo from "../../components/fieldInfoComponents/FieldTwentyThreeInfo";


const useStyles = makeStyles((theme) => ({
  root: {
    //   this changes the border colour
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the border colour on hover
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the border colour on focus(when typing)
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4e7fc4",
    },
    // this changes the text colour on typing
    "& .MuiInputBase-root": {
      color: "#000000",
    },
    //   this changes the border radius of the field
    [`& fieldset`]: {
      borderRadius: 35,
      // background:"#ffffff"
    },

    width: "650px",

    // this changes the background to white
    // background: "#ffffff"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
}));

export default function FieldTwentyThree() {
  const classes = useStyles();
  const { register, handleSubmit, control, errors } = useForm();

  const [fieldTwentyThreeValue, setFieldTwentyThreeValue] = React.useState("");
  const [checkValue, setCheckvalue] = React.useState("");
  const dispatch = useDispatch();

  const popupState = useSelector((reduxState) => reduxState.popupReducer);

  const [openPopup, setOpenPopup] = useState(popupState);

  const selectedLang = useSelector(
    (reduxState) => reduxState.selectedLanguageReducer
  );

  const currentValue = useSelector(
    (reduxState) => reduxState.selectedLineInputsReducer.data.fieldTwentyThreeInput
    // (reduxState) => reduxState.selectedLineInputsReducer
  );

  const numberOfBlindCovers = useSelector(
    (reduxState) => reduxState.selectedLineInputsReducer.data.numberOfBlindCovers
  )

  function checkNumber(value) {


    if(value==="0"){
      setCheckvalue("invalid")
    }else
    if (value.match(/^\d+$/) && value<=numberOfBlindCovers) {
      console.log("valid value");
      setFieldTwentyThreeValue(value);
      setCheckvalue("valid");
      dispatch(fieldTwentyThreeInput(value));
      dispatch(lineStatus("false"));
    } else {
      console.log("invalid value");
      setCheckvalue("invalid");
      dispatch(fieldTwentyThreeInput("invalid"));
    }
  }


  const handleChange = (event) => {
    checkNumber(event.target.value);
    console.log(event.target.value);
    // setFieldOneValue(event.target.value);
    // dispatch(fieldOneInput(event.target.value));
  };

  const onSubmit = (data) => {
    console.log("the data in the form is:", data);
  };

  return (
    <React.Fragment>
    <CssBaseline />
    <div
      style={{
        maxWidth: "80%",
        marginLeft: "10%",
        // border: "1px solid black",
        borderRadius: "35px",
        marginTop: "2%",
      }}
    >
      <div
        style={{
          ddisplay: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <Typography
          variant="body1"
          style={{ marginTop: "20px", fontSize : "18px"  }}
          // inputProps={{ style: { fontSize: 400 } }}
          gutterBottom
        >
           <Controls.ActionButton color="secondary">
              <Tooltip title="Field Info">
                <HelpIcon 
                fontSize="small"
                color="action"
                onClick={() => {
                  dispatch(
                    fieldTwentyThreeInfo({status:true})
                  )
                }}
                />
              </Tooltip>
            </Controls.ActionButton>
          {inputLabels[`${selectedLang}`].fieldTwentyThree}
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          {" "}
          <TextField
            variant="outlined"
            margin="normal"
            inputRef={register({
              required: "Enter a Valid value",
              minLength: { value: 1, message: "Too Short" },
            })}
            fullWidth
            className={classes.root}
            InputProps={{
              inputProps: {
                style: { textAlign: "center" }
              },
            }}            
            id="fieldTwentyThreeValue"
            name="fieldTwentyThreeValue"
            label={`${pageContent[`${selectedLang}`].savedValue}: ${currentValue}`}
            autoComplete="off"
            onChange={handleChange}
            error={Boolean(errors.referenceNumber)}
          />
          {errors.referenceNumber && (
            <p style={{ color: "red" }}>{errors.fieldTwentyThreeValue.message}</p>
          )}
          {checkValue === "invalid" && (
            <p style={{ color: "red" }}>{errorText[`${selectedLang}`].error115}</p>
          )}
        </form>
      </div>
    </div>
    <Popup
      openPopup={popupState.fieldTwentyThreeInfo}
      setOpenPopup={setOpenPopup}
      title="Field Info"
      >
        <FieldTwentyThreeInfo/>
      </Popup>
  </React.Fragment>
  );
}

